import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Marker as GoogleMapsMarker, InfoWindow } from '@react-google-maps/api';

import { DiningOptionBehavior, useRestaurantQuery, RestaurantLocationsQuery } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { ToggleInput } from 'shared/components/common/forms';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { getDiningOptionBehaviorAvailablility } from 'public/components/online_ordering/FulfillmentContext';
import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';

type OORestaurant = NonNullable<NonNullable<RestaurantLocationsQuery['restaurants']>[0]> & { __typename: 'Restaurant' };

type InfoProps = {
  restaurant: OORestaurant;
  onSelect: (guid: string, diningOptionBehavior?: DiningOptionBehavior) => void;
  selectedDiningOption?: DiningOptionBehavior;
  isSelected?: boolean;
}

type Props = {
  useOrderFulfillment?: boolean;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  restaurant: OORestaurant;
  onClick: () => void;
  onSelect: (guid: string, diningOptionBehavior?: DiningOptionBehavior) => void;
  isInfoOpen?: boolean;
  isSelected?: boolean;
  selectedDiningOption?: DiningOptionBehavior;
}

const LocationSelection = ({ restaurant, onSelect }: InfoProps) => {
  return (
    <div className="mapInfoWindow">
      <h3>{formatLocationAddress(restaurant.location, false)}</h3>
      <br />
      <Button
        className="orderHereButton"
        onClick={() => onSelect(restaurant.guid, undefined)}>
        Select location
      </Button>
    </div>
  );
};

const OrderFulfillment = ({ restaurant, onSelect, selectedDiningOption, isSelected }: InfoProps) => {
  // This grabs the dining options for the location represented by the map pin that was clicked on. This means
  // FulfillmentContext can't be used here because that represents the location that the user is currently navigated to.
  const client = useOOClient();
  const { data, loading } = useRestaurantQuery({
    variables: { restaurantGuid: restaurant.guid },
    client
  });

  const { takeoutEnabled, deliveryEnabled, canOrderTakeout, canOrderDelivery } = getDiningOptionBehaviorAvailablility(data?.diningOptions);

  const isDeliverySelected = isSelected && selectedDiningOption === DiningOptionBehavior.Delivery;
  const isTakeOutSelected = isSelected && selectedDiningOption === DiningOptionBehavior.TakeOut;

  const [selected, setSelected] = useState<DiningOptionBehavior | undefined>(isTakeOutSelected ? DiningOptionBehavior.TakeOut : isDeliverySelected ? DiningOptionBehavior.Delivery : undefined);

  return (
    <div className="mapInfoWindow">
      <h3>{formatLocationAddress(restaurant.location, false)}</h3>
      <hr />
      <div className="options">
        {loading ?
          <Skeleton width="100%" height="20px" /> :
          <>
            {!canOrderTakeout && !canOrderDelivery && <div>We&apos;re not accepting orders right now but you can still browse our menu!</div>}
            {takeoutEnabled &&
              <ToggleInput
                onChange={() => setSelected(DiningOptionBehavior.TakeOut)}
                id={`${restaurant.guid}_pickup`}
                name={restaurant.guid}
                type="radio"
                disabled={!canOrderTakeout}
                checked={selected === DiningOptionBehavior.TakeOut}>
                <div className="itemLabel">
                  <span>Pickup</span>
                  <Image alt="Pickup icon" src="icons/pickup.svg" />
                </div>
              </ToggleInput>}
            {deliveryEnabled &&
              <ToggleInput
                onChange={() => setSelected(DiningOptionBehavior.Delivery)}
                id={`${restaurant.guid}_delivery`}
                name={restaurant.guid}
                type="radio"
                disabled={!canOrderDelivery}
                checked={selected === DiningOptionBehavior.Delivery}>
                <div className="itemLabel">
                  <span>Delivery</span>
                  <Image alt="Delivery icon" src="icons/delivery.svg" />
                </div>
              </ToggleInput>}
          </>}
      </div>
      <Button
        disabled={loading || !selected && (canOrderTakeout || canOrderDelivery)}
        className="orderHereButton"
        onClick={() => onSelect(restaurant.guid, selected)}>
        {loading || canOrderTakeout || canOrderDelivery ? 'Order here' : 'View menu'}
      </Button>
    </div>
  );
};

const Marker = (props: Props) => {
  return (
    <GoogleMapsMarker
      label={props.isSelected ? undefined : ' '}
      key={props.restaurant.guid}
      position={props.position}
      onClick={props.onClick}>
      {props.isInfoOpen ?
        <InfoWindow>
          {props.useOrderFulfillment ?
            <OrderFulfillment
              isSelected={props.isSelected}
              restaurant={props.restaurant}
              onSelect={props.onSelect}
              selectedDiningOption={props.selectedDiningOption} />
            : <LocationSelection restaurant={props.restaurant} onSelect={props.onSelect} />}
        </InfoWindow>
        : null}
    </GoogleMapsMarker>
  );
};

export default Marker;
