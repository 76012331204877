import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { FormProvider, useFormContext, useForm, useWatch } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';

import { isInternationalCountryIsoCode } from '@toasttab/buffet-pui-country-utilities';
import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DeliveryProvider } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useMarketing } from 'src/public/components/online_ordering/MarketingContext';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';
import Warning from 'src/shared/components/common/warning/Warning';

import Image from 'shared/components/common/Image';
import AdyenPaymentOverlay from 'shared/components/common/loading_spinner/AdyenPaymentOverlay';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';

import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutErrorModal from 'public/components/default_template/online_ordering/checkout/CheckoutErrorModal';
import { GuestCheckoutButton, ToastCheckoutButton } from 'public/components/default_template/online_ordering/checkout/CheckoutModeButtons';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import FulfillmentSection from 'public/components/default_template/online_ordering/checkout/FulfillmentSection';
import OrderSection from 'public/components/default_template/online_ordering/checkout/OrderSection';
import OrderPrices from 'public/components/default_template/online_ordering/checkout/payment/OrderPrices';
import PaymentSection from 'public/components/default_template/online_ordering/checkout/payment/PaymentSection';
import { AcceptedPaymentMethods } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import { useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import CheckoutWithVenmoOrPayPal from 'public/components/default_template/paypal/CheckoutWithPayPalOrVenmoButton';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, OrderError, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment, getCheckoutInfo } from 'public/components/online_ordering/PaymentContext';
import { Cart, CompletedOrder, ToastPaymentType } from 'public/components/online_ordering/types';

import { getCustomerInfo, useHandleCompletedOrderCallback, getPaymentOption } from './checkoutUtils';
import { useIsCartValid } from './useIsCartValid';

class PaymentNeedsUserInfoError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeedsUserInfoError';
  }
}

class PaymentNeededError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeededError';
  }
}

export type PaymentCompletedParams = { paymentMethodId?: string|null, surchargeAmount?: number|null };

const CheckoutForm = () => {
  const { ooSpiPreCheckoutValidationEnabled } = useFlags();
  const { spiEnabled } = useSpi();
  const { isCartValid } = useIsCartValid(true);
  const tracker = useTracker();
  const { customer } = useCustomer();
  const { placeOrder, setOrderError, canCheckout, giftCardAppliedAmount, toastCashAppliedAmount, orderTotal } = useCheckout();
  const { loadingCart, cartGuid, cart, error: cartError } = useCart();
  const { completePayment, setUserInfoRequired, createPaymentIntent, confirmedPayment, billingDetails, tipAmount, paymentType, paymentOption } = usePayment();
  const isCartLoading = !cart && loadingCart;
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: getCustomerInfo(customer)
  });

  const { reset, setValue } = formMethods;
  useEffect(() => {
    if(customer) {
      reset(getCustomerInfo(customer));
    }
  }, [reset, customer]);

  const disableSubmit = useMemo(() => !canCheckout(formMethods.formState), [canCheckout, formMethods.formState]);

  const handleCompletedOrder = useHandleCompletedOrderCallback();

  const onPaymentCompleted = useCallback(
    (checkoutFormData: CheckoutFormData, resolve: (arg: any) => void) =>
      async (paymentId?: string, firstName?: string, lastName?: string, phone?: string, email?: string, paymentCompletedParams?: PaymentCompletedParams) => {
        // when paying with a digital wallet, user info may not be populated in the form
        await placeOrder(
          cartGuid!,
          {
            ...checkoutFormData,
            yourInfoFirstName: checkoutFormData.yourInfoFirstName || firstName || '',
            yourInfoLastName: checkoutFormData.yourInfoLastName || lastName || '',
            yourInfoPhone: checkoutFormData.yourInfoPhone || phone || '',
            yourInfoEmail: checkoutFormData.yourInfoEmail || email || ''
          },
          handleCompletedOrder,
          paymentId,
          paymentCompletedParams
        );
        resolve(true);
      },
    [placeOrder, cartGuid, handleCompletedOrder]
  );

  const onPaymentCancelled = useCallback(
    (resolve: (val: boolean) => void, reject: (err: Error) => void) => (needsInput: boolean, firstName?: string, lastName?: string, phone?: string, email?: string) => {
      if(needsInput) {
      // populate form fields if some of the user info is available
        if(firstName) setValue('yourInfoFirstName', firstName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(lastName) setValue('yourInfoLastName', lastName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(phone) setValue('yourInfoPhone', phone, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(email) setValue('yourInfoEmail', email, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        reject(new PaymentNeedsUserInfoError('We couldn\'t get your information from your digital wallet. Please enter it above.'));
      } else {
        resolve(true);
      }
    }, [setValue]
  );

  const onSubmitHelper = useCallback(async (checkoutFormData: CheckoutFormData) => {
    // payment intent logic not needed (and cannot be used) if there is no amount to charge
    if(orderTotal > 0) {
      return new Promise((resolve, reject) => {
        if(confirmedPayment === null) {
          // we haven't confirmed a payment, so complete the payment
          completePayment(
            checkoutFormData,
            giftCardAppliedAmount,
            toastCashAppliedAmount,
            ooSpiPreCheckoutValidationEnabled ?
              () => isCartValid(true, {
                firstName: checkoutFormData.yourInfoFirstName,
                lastName: checkoutFormData.yourInfoLastName,
                email: checkoutFormData.yourInfoEmail,
                phone: checkoutFormData.yourInfoPhone
              })
              : null,
            onPaymentCompleted(checkoutFormData, resolve),
            onPaymentCancelled(resolve, reject),
            err => reject(err)
          );
        } else if(confirmedPayment.amount === orderTotal * 100) {
          // we have already confirmed a payment and the order total hasn't changed, so we can place the order without
          // updating the payment
          const { firstName, lastName, phoneNumber, email } = getCheckoutInfo(checkoutFormData, billingDetails);
          onPaymentCompleted(checkoutFormData, resolve)(confirmedPayment.externalReferenceId, firstName, lastName, phoneNumber, email);
        } else {
          // the order total changed, so the confirmed payment is no longer valid and we need to collect payment again
          createPaymentIntent().then(() => reject(new PaymentNeededError('Your order has changed. Please re-enter your payment information above.')));
        }
      });
    } else {
      if(!(checkoutFormData.yourInfoFirstName || checkoutFormData.yourInfoLastName || checkoutFormData.yourInfoPhone || checkoutFormData.yourInfoEmail)) {
        // the user info fields may have been hidden if a digital wallet is selected and a gift card is used to cover the entire amount
        setUserInfoRequired(true);
        throw new PaymentNeedsUserInfoError('Please enter your name, phone number, and email address.');
      } else {
        return await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      }
    }
  },
  [
    orderTotal,
    completePayment,
    giftCardAppliedAmount,
    toastCashAppliedAmount,
    onPaymentCompleted,
    onPaymentCancelled,
    setUserInfoRequired,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    billingDetails,
    confirmedPayment,
    createPaymentIntent,
    ooSpiPreCheckoutValidationEnabled,
    isCartValid
  ]);

  const onSubmit = useCallback(async (checkoutFormData: CheckoutFormData) => {
    tracker.track('Place Order clicked', {
      spiEnabled,
      preCheckoutValidationEnabled: ooSpiPreCheckoutValidationEnabled,
      restaurantGuid: cart?.restaurant?.guid,
      diningOption: cart?.diningOptionBehavior,
      fulfillmentTime: cart?.fulfillmentType,
      numItems: cart?.order?.numberOfSelections,
      subtotal: cart?.order?.preDiscountItemsSubtotal,
      tax: cart?.order?.taxV2,
      deliveryChargeTotal: cart?.order?.deliveryServiceCharges,
      gratuityServiceCharges: cart?.order?.gratuityServiceCharges,
      processingServiceCharges: cart?.order?.processingServiceCharges,
      nonDeliveryNonGratuityNonUbpServiceCharges: cart?.order?.nonDeliveryNonGratuityNonUbpServiceCharges,
      discounts: cart?.order?.discountsTotal,
      total: cart?.order?.totalV2,
      paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal),
      giftCardApplied: giftCardAppliedAmount > 0,
      tipAmount
    });

    if(spiEnabled) {
      try {
        await onSubmitHelper(checkoutFormData);
      } catch(error) {
        if(error instanceof PaymentNeedsUserInfoError || error instanceof PaymentNeededError) {
          setOrderError({
            type: 'CUSTOM_ERROR_MESSAGE',
            message: error.message
          });
        } else if(error) {
          setOrderError(error);
        }
        // if the onSubmitHelper promise is rejected without an error, it is assumed that orderError has already been set
      }
    } else {
      try {
        await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      } catch(error) {
        setOrderError(error);
      }
    }
  }, [
    tracker,
    cart,
    tipAmount,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    spiEnabled,
    onSubmitHelper,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    setOrderError,
    ooSpiPreCheckoutValidationEnabled
  ]);

  if(cartError) {
    return <UhOh {...getUhOhPropsForError(cartError)} />;
  }

  if(isCartLoading) {
    return (
      <div className="checkoutForm emptyCart">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if((cart?.order?.selections?.length || 0) === 0) {
    return <EmptyCart />;
  }

  return (
    <div className="checkoutForm">
      <FormProvider {...formMethods}>
        <form className="checkoutFormContents" onSubmit={formMethods.handleSubmit(onSubmit)}>
          {formMethods.formState.isSubmitting && <LoadingSpinnerOverlay withBorderRadius={false} fullScreen={true} />}
          <CheckoutSections disableSubmit={disableSubmit} formMethods={formMethods} />
        </form>
      </FormProvider>
      <CheckoutErrorModal />
    </div>
  );
};

export const CheckoutSections = ({ disableSubmit, formMethods }: { disableSubmit: boolean, formMethods: any }) => {
  const { customer } = useCustomer();
  const { cart } = useCart();
  const { orderTotal, orderError } = useCheckout();
  const { paymentOption } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant();
  const [selectedCheckoutMode, setSelectedCheckoutMode] = useState(false);
  const [showIntlPayment, setShowIntlPayment] = useState<boolean>(false);
  const canPayAtCheckout = !(cart?.paymentOptions.atCheckout.length === 0 && cart?.paymentOptions.uponReceipt.length > 0);
  const showUSPayments = useMemo(() => (selectedCheckoutMode || Boolean(customer)) && !showIntlPayment, [customer, selectedCheckoutMode, showIntlPayment]);

  useEffect(() => {
    if(isIntlRestaurant && orderError) {
      setShowIntlPayment(false);
    }
  }, [isIntlRestaurant, orderError]);

  return (
    <div className="checkoutFormContents" role="form">
      <FulfillmentSection complete={selectedCheckoutMode} />
      <OrderSection />
      {!selectedCheckoutMode && !customer &&
          <>
            <OrderPrices />
            <div className="checkoutModeButtonGroup">
              <ShowForUS>
                <ToastCheckoutButton />
              </ShowForUS>
              <GuestCheckoutButton onClick={() => setSelectedCheckoutMode(true)} />
            </div>
            {canPayAtCheckout &&
              <CheckoutSection>
                <span>We accept</span>
                <AcceptedPaymentMethods />
              </CheckoutSection>}
          </>}
      <AnimatedSection expanded={showUSPayments} slowTransition testid="animated-payment-section" >
        <div className="checkoutFormContents" data-testid="checkoutFormInfo">
          <PaymentSection isExpanded={showUSPayments} />
          <CheckoutSection>
            <div className="sectionButton">
              {isIntlRestaurant && orderTotal > 0 && canPayAtCheckout && paymentOption === PaymentOption.CreditCard ?
                <ContinueIntlPayment disableSubmit={disableSubmit} formMethods={formMethods} enableIntlPayment={() => setShowIntlPayment(true)} /> :
                <SubmitOrderButton disableSubmit={disableSubmit} formMethods={formMethods} />}
              <CheckoutNote showIntlPayment={showIntlPayment} paymentOption={paymentOption} />
            </div>
          </CheckoutSection>
        </div>
      </AnimatedSection>
      <InternationalPaymentContainer expanded={showIntlPayment} hideIntlPayment={() => setShowIntlPayment(false)} />
      {showUberDisclaimer(cart) && selectedCheckoutMode && <UberCheckoutNote />}
    </div>
  );
};

const PlaceOrderTipWarning = () => {
  return (
    <Warning dataTestId="place-order-tip-warning" message="Please add another payment method for the tip" />
  );
};

/**
 * if a GC is applied and a non-zero tip is selected, present a warning to the guest stating they need to provide another payment method.
 * OO does not support allowing GC's to cover tip amounts
 * @param disableSubmit
 *  Used to check if submission is disabled due to lacking form information.
 *  For international rxs we can show the warning regardless, since payment is taken after this form is submitted.
 * @param formMethods used to ensure the place order form is not currently being submit
 * @param giftCardAppliedAmount
 * @param tipAmount
 * @returns true, if warning should show to guest
 */
function shouldDisplayTipWarning(disableSubmit: boolean, formMethods: any, giftCardAppliedAmount: number, tipAmount: number, isIntlRestaurant: boolean) {
  return !formMethods?.formState?.isSubmitting
          && (disableSubmit || isIntlRestaurant) && giftCardAppliedAmount > 0 && tipAmount > 0;
}

export const SubmitOrderButton = ({ disableSubmit, formMethods }: {disableSubmit: boolean, formMethods: any}) => {
  const { paymentOption, tipAmount, paymentType, showRemoveRoundupTemporaryMessaging } = usePayment();
  const { giftCardAppliedAmount, orderTotal } = useCheckout();
  const isIntlRestaurant = useIsIntlRestaurant();

  if((paymentOption === PaymentOption.ApplePay || paymentType === ToastPaymentType.APPLE_PAY) && orderTotal > 0) {
    return <button data-testid="applePaySubmitButton" type="submit" className="submitButton applePayBtn" disabled={disableSubmit} />;
  }
  if(paymentOption === PaymentOption.Paypal || paymentOption === PaymentOption.Venmo) {
    return <CheckoutWithVenmoOrPayPal
      disableSubmit={disableSubmit}
      paymentOption={paymentOption} />;
  }

  const displayTipPaymentMethodWarning = shouldDisplayTipWarning(disableSubmit, formMethods, giftCardAppliedAmount, tipAmount, isIntlRestaurant);
  return (
    <>
      <button
        data-testid="basicSubmitButton"
        type="submit"
        className={classnames('submitButton primaryCta', !disableSubmit && 'primaryColorBackground primaryColorHover')}
        disabled={disableSubmit}>
        Place Order
      </button>
      {showRemoveRoundupTemporaryMessaging &&
        <Warning dataTestId="remove-round-up-warning" message="Error: please remove the Round Up amount to place the order" />}
      {displayTipPaymentMethodWarning &&
      <PlaceOrderTipWarning />}
    </>
  );
};

const ContinueIntlPayment = ({ disableSubmit, formMethods, enableIntlPayment }: {disableSubmit: boolean, formMethods: any, enableIntlPayment: () => void}) => {
  const { giftCardAppliedAmount } = useCheckout();
  const { tipAmount } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant(); // should always be true in this component, but use for consistency

  const displayTipPaymentMethodWarning = shouldDisplayTipWarning(disableSubmit, formMethods, giftCardAppliedAmount, tipAmount, isIntlRestaurant);

  return (
    <>
      <button
        data-testid="continueButtonPayment"
        type="submit"
        onClick={e => {
          e.preventDefault();
          enableIntlPayment();
        }}
        disabled={disableSubmit}
        className={classnames('submitButton primaryCta', !disableSubmit && 'primaryColorBackground primaryColorHover')}>
          Continue to payment
      </button>
      {displayTipPaymentMethodWarning &&
        <PlaceOrderTipWarning />}
    </>
  );
};

// eslint-disable-next-line react/display-name
export const InternationalPaymentContainer = memo(({ expanded, hideIntlPayment }: { expanded: boolean, hideIntlPayment: () => void }) => {
  const { ooRestaurant } = useRestaurant();
  const { orderTotal, placeOrder, orderError, setOrderError } = useCheckout();
  const { tipAmount } = usePayment();

  const { isCartValid } = useIsCartValid();

  const { cartGuid, clearCart } = useCart();
  const { getValues: getCheckoutValues } = useFormContext<CheckoutFormData>();
  const { confirmationPath } = useRestaurantRoutes();
  const tracker = useTracker();


  const i18n = ooRestaurant?.i18n;
  const guid = ooRestaurant?.guid;
  const onlineOrderingEnabled = ooRestaurant?.onlineOrderingEnabled;
  const history = useHistory();

  const [showAdyenOverlay, setShowAdyenOverlay] = useState<boolean>(false);

  const orderErrorRef = useRef<OrderError | null>(null);

  useEffect(() => {
    if(orderError) {
      setShowAdyenOverlay(false);
      orderErrorRef.current = orderError;
    } else {
      orderErrorRef.current = null;
    }
  }, [orderError, setOrderError, isCartValid]);


  const handleCompletedOrder = useCallback((completedOrder: CompletedOrder) => {
    if(completedOrder?.guid) {
      clearCart();
      setShowAdyenOverlay(false);
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      history.push(confirmationPath, { completedOrder });
    }
  }, [clearCart, history, confirmationPath]);

  const placeCCOrder = useCallback(async (paymentIntentID: string | undefined, cartGuid: string | null | undefined, checkoutFormData: CheckoutFormData) => {
    const formData = {
      ...checkoutFormData,
      semiPaymentIntentId: paymentIntentID
    };

    try {
      await placeOrder(cartGuid!, formData, handleCompletedOrder);
    } catch(error) {
      setOrderError(error);
    }
  }, [placeOrder, handleCompletedOrder, setOrderError]);

  const guestInfo = {
    yourInfoFirstName: useWatch({ name: 'yourInfoFirstName' }),
    yourInfoLastName: useWatch({ name: 'yourInfoLastName' }),
    yourInfoEmail: useWatch({ name: 'yourInfoEmail' }),
    yourInfoPhone: useWatch({ name: 'yourInfoPhone' })
  };

  const { intlOoAdyenBlockRedirect } = useFlags();

  if(!onlineOrderingEnabled) {
    return <div data-testid="paymentUnavailable">Online ordering payments unavailable</div>;
  }

  return (
    <>
      {showAdyenOverlay && <AdyenPaymentOverlay fullScreen />}
      <AnimatedSection expanded={expanded}>
        <div className="internationalPaymentContainer">
          <div className={classnames('internationalPaymentHeader collapsable')} onClick={() => hideIntlPayment()}>
            Payment details
            <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
          </div>

          <div data-testid={'internationalPaymentContainer'}>
            {expanded && i18n &&
              <AdyenPayment
                payment={{ checkAmount: orderTotal - tipAmount, tipAmount, emailAddress: guestInfo.yourInfoEmail }}
                onEvent={event => {
                  if(event === 'PAYMENT_SUBMITTED') {
                    setShowAdyenOverlay(true);
                  }
                  if(
                    [
                      'POLLING_ERROR',
                      'POLLING_TIMEOUT',
                      'PAYMENT_REJECTED'
                    ].includes(event) || !isCartValid || orderErrorRef.current
                  ) {
                    setShowAdyenOverlay(false);
                  }
                }}
                config={{
                  currency: i18n.currency,
                  locale: i18n.locale,
                  country: i18n.country,
                  guid,
                  channel: 'ONLINE_ORDERING',
                  attributes: cartGuid ? { cartGuid } : undefined
                }}
                preSubmitEvent={async () => {
                  const { yourInfoFirstName, yourInfoLastName, yourInfoEmail, yourInfoPhone } = guestInfo;
                  return await isCartValid(true, {
                    firstName: yourInfoFirstName,
                    lastName: yourInfoLastName,
                    email: yourInfoEmail,
                    phone: yourInfoPhone
                  });
                }}
                shouldBlockRedirect={data => {
                  tracker.track('adyen_should_block_redirect', {
                    cartGuid,
                    url: data?.url,
                    method: data?.method
                  });

                  return intlOoAdyenBlockRedirect;
                }}
                onActionHandled={data => {
                  tracker.track('adyen_action_handled', {
                    cartGuid,
                    componentType: data?.componentType,
                    actionDescription: data?.actionDescription
                  });
                }}
                onPaymentCompleted={paymentIntentID => {
                  tracker.track('adyen_payment_completed', {
                    cartGuid,
                    paymentIntentID
                  });

                  placeCCOrder(paymentIntentID, cartGuid, getCheckoutValues());
                }} />}
            <p data-testid="intlPaymentLegalStatement" className="note checkoutNote" role="contentinfo">
              I understand my information will be used to process this payment and for other purposes outlined in Toast&apos;s{' '}
              <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>, and will also be disclosed to the merchant.
            </p>
          </div>
        </div>
        <CheckoutNote />
      </AnimatedSection>
    </>
  );
});

const CheckoutNote = ({ showIntlPayment, paymentOption }: {showIntlPayment?: boolean, paymentOption?: PaymentOption | null}) => {
  const { ooRestaurant } = useRestaurant();
  const { smsAccountEnabled } = useMarketing();

  const i18n = ooRestaurant?.i18n;
  const international = isInternationalCountryIsoCode(i18n?.country || 'US');

  if(international) {
    const payUponReceipt = paymentOption === PaymentOption.UponReceipt;
    const openingText = showIntlPayment || payUponReceipt ? 'By placing your order' : 'By continuing to payment and placing your order';
    return (
      <p data-testid="intlCheckoutLegalStatement" className="note checkoutNote" role="contentinfo">
        {openingText}, you authorise Toast and this restaurant/restaurant group to send you informational messages
        (such as order status updates and digital receipts) by SMS and email using the contact details provided. Message &
        data rates may apply, msg frequency varies. Reply STOP to opt out. Service is subject to Toast&apos;s{' '}
        <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Terms of Service</a>{' '}
       and{' '}<a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>{' '}and Merchant&apos;s Terms and Policies apply.
      </p>
    );
  }

  // SMS is not available for international restaurants
  if(smsAccountEnabled) {
    return (
      <p data-testid="checkoutLegalStatement" className="note checkoutNote" role="contentinfo">
        By completing your order, you give Toast and this restaurant/restaurant group permission
        to send you informational text messages and emails (such as order status updates and digital receipts). Consent
        to marketing is not a condition of purchase. Message & data rates may apply, msg frequency
        varies. Reply STOP to opt out. Service is subject to Toast&apos;s{' '}
        <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Terms of Service</a>{' '}
        and <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>{' '}
        and Merchant&apos;s Terms and Policies apply. Info for CA residents available{' '}
        <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">here</a>.
      </p>
    );
  }

  return (
    <p data-testid="checkoutLegalStatement" className="note checkoutNote" role="contentinfo">
      By completing your order, you give Toast and this restaurant/restaurant group permission
      to send you informational messages (such as order updates and digital receipts). Consent
      to marketing is not a condition of purchase. Message & data rates may apply, msg frequency
      varies. Reply STOP to opt out. Service is subject to Toast&apos;s{' '}
      <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Terms of Service</a>{' '}
      and <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>{' '}
      and Merchant&apos;s Terms and Policies apply. Info for CA residents available{' '}
      <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">here</a>.
    </p>
  );
};

const UberCheckoutNote = () =>
  <p data-testid="checkout-uber-disclaimer" className="note checkoutNote" role="contentinfo">
    By placing this order for delivery, you authorize Toast to share your delivery information with our delivery partner Uber.
     Use of your information by Uber is governed by{' '}
    <a href="https://www.uber.com/legal/en/document/?name=privacy-notice&country=united-states&lang=en" rel="noopener noreferrer" target="_blank">Uber&apos;s Privacy Notice</a>.
  </p>;

/**
 * Returns whether the confirmation page should render the uber disclaimer. Currently TDS restaurants will
 * only have one enabled provider, so checking if the Uber config is enabled is sufficient
 */
const showUberDisclaimer = (cart: Cart | null | undefined) => cart?.deliveryProviderInfo?.provider === DeliveryProvider.ToastDeliveryServices &&
  cart?.restaurant?.deliveryProviders.find(({ provider, enabled }) => enabled && provider === DeliveryProvider.Uber);

export default CheckoutForm;
