import { DigitalSurface } from 'src/apollo/onlineOrdering';

import ToastProduct from './ToastProduct';

export const getDigitalSurface = (product: ToastProduct): DigitalSurface => {
  switch(product) {
    case ToastProduct.OOBasic:
      return DigitalSurface.OoBasic;
    case ToastProduct.OOPro:
      return DigitalSurface.OoPro;
    case ToastProduct.Sites:
      return DigitalSurface.Websites;
    case ToastProduct.ToastLocal:
      return DigitalSurface.Local;
  }
};

export const getIsCustomDomain = (hostname: string): boolean => {
  return !hostname.endsWith('toasttab.com') && !hostname.endsWith('toast.site') && !hostname.endsWith('toast.ventures');
};
