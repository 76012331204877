export enum TestSurface {
  OOV4,
  TOAST_LOCAL,
  CUSTOM_DOMAINS
}

export type ABTest = {
  variants: { [key: string]: number }
  controlVariant: string,
  testSurfaces: Array<TestSurface>,
  disabled?: boolean
}

export type Tests = Record<string, ABTest>
