import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';

import { GiftCardPurchaseSource, GiftCardSendType, useCreateGiftCardOrderMutation } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import Image from 'src/shared/components/common/Image';
import Button from 'src/shared/components/common/button';
import FormInput from 'src/shared/components/common/form_input';
import TextArea from 'src/shared/components/common/form_input/TextArea';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { alertError } from 'src/shared/js/alertUtils';

import CreditCardFormSPI from 'public/components/default_template/online_ordering/checkout/payment/CreditCardFormSPI';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentContextProvider, usePayment } from 'public/components/online_ordering/PaymentContext';

import config from 'config';

const AMOUNT_REGEX = new RegExp(/^\d{1,3}(?:\.\d{1,2})?$/);
const DEFAULT_MIN_PURCHASE_AMOUNT = 1;
const DEFAULT_MAX_PURCHASE_AMOUNT = 150;

type Props = {
  maxPurchaseAmount?: number;
  minPurchaseAmount?: number;
}

const GiftCardPayment = () => {
  const { isPaymentValid, completeGiftCardPayment } = usePayment();
  const { ooRestaurant, selectedLocation } = useRestaurant();
  const [createGiftCardOrder] = useCreateGiftCardOrderMutation();
  const { getValues } = useFormContext();
  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const currency = useMemo(() => ooRestaurant?.i18n?.currency || 'USD', [ooRestaurant]);
  const value = parseInt(parseFloat(getValues()['amount']) * 100 + '');

  const onPaymentCompleted = useCallback(async (paymentId: string) => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(config.giftCardRecaptchaSiteKey, { action: 'EGiftcard_PlaceOrder' });

      try {
        await createGiftCardOrder({
          variables: {
            input: {
              restaurantGuid: selectedLocation.externalId,
              purchaseSource: GiftCardPurchaseSource.Websites,
              recaptchaToken: token,
              orderDetails: {
                paymentGuid: paymentId,
                totalAmount: { currency, value },
                sender: {
                  email: getValues()['senderEmail'],
                  from: getValues()['senderName']
                },
                giftCards: [
                  {
                    amount: { currency, value },
                    recipient: {
                      email: getValues()['recipientEmail'],
                      to: getValues()['recipientName']
                    },
                    sendType: GiftCardSendType.Email,
                    message: getValues()['message']
                  }
                ]
              }
            }
          }
        });

        setComplete(true);
      } catch(e) {
        alertError('Payment failed.');
      } finally {
        setSubmitting(false);
      }
    });
  }, [createGiftCardOrder, currency, getValues, selectedLocation, value]);

  const checkout = useCallback(async () => {
    setSubmitting(true);

    completeGiftCardPayment(
      { email: getValues()['senderEmail'] },
      onPaymentCompleted,
      () => { setSubmitting(false); },
      () => { alertError('Payment failed.'); setSubmitting(false); }
    );
  }, [completeGiftCardPayment, onPaymentCompleted, getValues]);

  if(complete) {
    return (
      <div className="giftCardPurchaseForm">
        <Image src="icons/checkmark-success.svg" alt="" />
        <p className="successMessage">Gift card successfully sent</p>
      </div>
    );
  }

  return (
    <div className="giftCardPurchaseForm">
      <CreditCardFormSPI anchorClassName="ccForm" />
      <Button className="button" variant={ButtonType.Primary} disabled={!isPaymentValid || submitting} onClick={checkout}>Purchase</Button>
    </div>
  );
};

export const GiftCardPurchaseForm = ({ maxPurchaseAmount, minPurchaseAmount }: Props) => {
  const { isEditor } = useEditor();
  const formMethods = useFormContext();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const { customer } = useCustomer();

  const { ooRestaurant } = useRestaurant();
  const ooClient = useOOClient();

  const currencySymbol = useMemo(() => new Intl.NumberFormat(ooRestaurant?.i18n.locale || 'en-US', {
    style: 'currency',
    currency: ooRestaurant?.i18n.currency || 'USD'
  }).format(1)
    .charAt(0), [ooRestaurant]);

  if(isCheckingOut) {
    return (
      <ApolloProvider client={ooClient}>
        <CartContextProvider>
          <PaymentContextProvider useGiftCardFlow giftCardAmount={parseFloat(formMethods.getValues()['amount'])}>
            <GiftCardPayment />
          </PaymentContextProvider>
        </CartContextProvider>
      </ApolloProvider>
    );
  }

  // Clamp min and max values between the ones enforced by the backend fraud limits
  const effectiveMaxValue = Math.min(maxPurchaseAmount || DEFAULT_MAX_PURCHASE_AMOUNT, DEFAULT_MAX_PURCHASE_AMOUNT);
  const effectiveMinValue = Math.max(minPurchaseAmount || DEFAULT_MIN_PURCHASE_AMOUNT, DEFAULT_MIN_PURCHASE_AMOUNT);

  return (
    <div className="giftCardPurchaseForm">
      <FormInput
        disabled={isEditor}
        id="amount"
        label={`Gift amount (${currencySymbol}${effectiveMinValue}-${effectiveMaxValue})`}
        required type="number" inputMode="decimal" pattern="\d{1,3}(?:\.\d{1,2})?$"
        prefix={currencySymbol}
        validate={
          value => AMOUNT_REGEX.test(value) && parseFloat(value) >= effectiveMinValue && parseFloat(value) <= effectiveMaxValue
        } />
      <div className="row">
        <FormInput disabled={isEditor} id="recipientName" label="Who is this gift for?" />
        <FormInput disabled={isEditor} id="recipientEmail" required type="email" label="Recipient email" />
      </div>
      <div className="row">
        <FormInput disabled={isEditor} id="senderName" defaultValue={customer ? (customer?.firstName || '') + ' ' + (customer?.lastName || '') : undefined} label="Who is this gift from?" />
        <FormInput disabled={isEditor} id="senderEmail" required type="email" defaultValue={customer?.email} label="Sender email" />
      </div>
      <TextArea disabled={isEditor} id="message" label="Add a custom message (125 characters max)" maxLength={125} />
      <Button type="button" className="button" variant={ButtonType.Primary} disabled={!formMethods.formState.isValid || isEditor} onClick={() => {
        setIsCheckingOut(true);
      }}>
        Continue to payment
      </Button>
    </div>
  );
};
