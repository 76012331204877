import React, { useCallback, useMemo, useState } from 'react';

import { DeliveryInfo, Location as RxLocation, OrderStatusData, DeliveryDriverLocation } from 'src/apollo/onlineOrdering';

import { CompletedOrder } from 'public/components/online_ordering/types';

import config from 'config';

export type Location = {
  lat: number,
  long: number
}

export type OrderTrackerMapProps = {
  order: CompletedOrder,
  orderStatusData?: OrderStatusData,
}

export const OrderTrackerMap = ( { order, orderStatusData } : OrderTrackerMapProps) => {
  const [mapWidth, setMapWidth] = useState(490);

  const mapRef = useCallback((node: HTMLDivElement | null) => {
    if(node !== null) {
      setMapWidth(Math.round(node.getBoundingClientRect().width));
    }
  }, []);

  const rxAddress = order.restaurant.location;

  const pickupLocation = useMemo(() => rxLocationToPickupLocation(rxAddress), [rxAddress]);

  const dropoffLocation = useMemo(() => deliveryInfoToDropoffLocation(order.deliveryInfo), [order.deliveryInfo]);

  const driverLocation = useMemo(() => deliveryDriverLocationToLocation(orderStatusData?.trackingDetails?.driverLocation), [orderStatusData?.trackingDetails?.driverLocation]);

  const renderMap = driverLocation || pickupLocation || dropoffLocation;

  const src = useMemo(() => {
    let markers = [];

    if(pickupLocation) markers.push(locationToMarker(pickupLocation, pickupIconUrl));
    if(dropoffLocation) markers.push(locationToMarker(dropoffLocation, dropoffIconUrl));
    if(driverLocation) {
      // to prevent the driver icon from rendering under the other icons
      const atPickup = positionsOverlap(driverLocation, pickupLocation);
      const atDropoff = positionsOverlap(driverLocation, dropoffLocation);
      const anchor = atPickup || atDropoff ? '20, 20' : 'center';

      markers.push(locationToMarker(driverLocation, driverIconUrl, anchor));
    }

    const style = hiddenMapFeatures.map(feature => `style=feature:${feature}|visibility:off`).join('&');

    return `${config.resources.mapEndpoint}/multilocationMap?${markers.join('&')}&${style}&size=${mapWidth}x199`;
  }, [driverLocation, pickupLocation, dropoffLocation, mapWidth]);

  if(renderMap) {
    return (
      <div ref={mapRef} className="trackerMapFrame">
        <iframe data-testid="order-tracker-map" title="Google Maps" width="100%" height="100%" loading="lazy" allowFullScreen src={src} className="mapsFrame" />
      </div>
    );
  }

  return <></>;
};

const deliveryInfoToDropoffLocation = (deliveryInfo: DeliveryInfo | null | undefined) => {
  if(deliveryInfo) {
    return { lat: deliveryInfo.latitude, long: deliveryInfo.longitude };
  }
  return undefined;
};

const rxLocationToPickupLocation = (rxLocation: RxLocation) => {
  if(rxLocation && rxLocation.latitude && rxLocation.longitude) {
    return { lat: rxLocation.latitude, long: rxLocation.longitude };
  }
  return undefined;
};

const deliveryDriverLocationToLocation = (driverLocation: DeliveryDriverLocation | undefined | null) => {
  if(driverLocation) return { lat: driverLocation.latitude, long: driverLocation.longitude };
  return undefined;
};

// features of the returned google maps image to remove, e.g. points of interest, station names, etc.
const hiddenMapFeatures = [
  'transit.station',
  'poi',
  'administrative.neighborhood'
];

const positionsOverlap = (location1: Location, location2?: Location) => {
  return location1.lat.toFixed(3) === location2?.lat.toFixed(3) && location1.long.toFixed(3) === location2.long.toFixed(3);
};

const locationToMarker = (location: Location, icon: string, anchor?: string) => {
  const anchorParam = anchor ? `anchor:${anchor}|` : '';
  return `markers=${anchorParam}icon:${icon}|${location.lat},${location.long}`;
};

const getIconUrl = (iconName: string) => `${config.resources.publicAssetURL}icons/${iconName}.png`;

const pickupIconUrl = getIconUrl('restaurant-map-icon');
const driverIconUrl = getIconUrl('driver-map-icon');
const dropoffIconUrl = getIconUrl('delivery-map-icon');
