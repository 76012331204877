import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import Image from 'shared/components/common/Image';
import { useAlertModalContext } from 'shared/components/common/alert_modal/AlertModal';
import Button from 'shared/components/common/button';
import DropDown from 'shared/components/common/dropdown';
import ErrorNotice from 'shared/components/common/error_notice';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { REQUEST_FAILURE_MESSAGE } from 'shared/js/constants';
import { toDayString, toLocalTime } from 'shared/js/timeUtils';

import OOLocationSelector from 'public/components/default_template/nav/OOLocationSelector';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { LegacyDiningBehaviorToggle } from 'public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentControl';
import { DeliveryAddressSelection } from 'public/components/default_template/online_ordering/dining_options/delivery_address_selection/DeliveryAddressSelection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { getDateTimeIndexes, useFulfillment, useLocationSchedules } from 'public/components/online_ordering/FulfillmentContext';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

type Props = {
  forceSelectMessage?: string;
  behaviorToggleClassName?: string;
  onButtonClick?: () => void;
  removeItem?: boolean;
}

const DiningOptions = (props: Props) => {
  const { onButtonClick, removeItem } = props;
  const { cart, deleteFromCart } = useCart();
  const {
    fulfillmentData,
    canOrderTakeout,
    canOrderDelivery,
    canScheduleSelectedBehavior,
    selectedFulfillmentType,
    setSelectedFulfillmentType,
    selectedDiningOptionBehavior,
    selectedDiningOptionBehaviorSchedules,
    diningOptionBehaviorAvailability,
    updateCartFulfillment,
    fulfillmentError,
    setFulfillmentModalOpen
  } = useFulfillment();
  const { validDeliveryAddress } = useDelivery();
  const onClose = useCallback(async () => {
    await setFulfillmentModalOpen(false);
    onButtonClick?.();
  }, [setFulfillmentModalOpen, onButtonClick]);
  const { getFilteredFutureSchedules, itemInCartWithMaxLeadTime, itemsInCartWithPreorderRule, itemsInCartWithPickupWindowRule, getEarliestFulfillmentTime } = useTimeBasedRules();
  const futureSchedules = useMemo(() =>
    getFilteredFutureSchedules(
      getEarliestFulfillmentTime(itemInCartWithMaxLeadTime?.itemGuid, selectedDiningOptionBehavior),
      selectedDiningOptionBehaviorSchedules?.futureScheduleDates ?? [],
      selectedDiningOptionBehavior
    ),
  [selectedDiningOptionBehaviorSchedules, selectedDiningOptionBehavior, getFilteredFutureSchedules, getEarliestFulfillmentTime, itemInCartWithMaxLeadTime?.itemGuid]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(-1);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(-1);
  const [submitting, setSubmitting] = useState(false);

  // can something in the modal be updated?
  const canUpdate = useMemo(() =>
    canScheduleSelectedBehavior || selectedDiningOptionBehavior === DiningOptionBehavior.Delivery || canOrderTakeout && canOrderDelivery,
  [canScheduleSelectedBehavior, selectedDiningOptionBehavior, canOrderTakeout, canOrderDelivery]);

  const scheduledOrdersOnly = useMemo(() => {
    const behavior = selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut ? diningOptionBehaviorAvailability.takeout : diningOptionBehaviorAvailability.delivery;
    return behavior?.scheduledAvailable && !behavior.asapAvailable;
  },
  [diningOptionBehaviorAvailability, selectedDiningOptionBehavior]);

  const { openAlertModal } = useAlertModalContext();

  const { locations } = useRestaurant();

  const locationSchedules = useLocationSchedules();
  const ooLocations = useMemo(() => locations?.filter(l => locationSchedules[l.externalId]?.onlineOrderingEnabled) || [], [locations, locationSchedules]);

  const itemToRemove = itemInCartWithMaxLeadTime ?? itemsInCartWithPreorderRule[itemsInCartWithPreorderRule.length - 1] ??
    itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1];
  const asapAvailable = selectedDiningOptionBehaviorSchedules?.asapAvailableNow && !itemToRemove;

  useEffect(() => {
    if(!asapAvailable) {
      setSelectedFulfillmentType(FulfillmentType.Future);
    }
  }, [asapAvailable, setSelectedFulfillmentType]);

  const submit = useCallback(async () => {
    if(!selectedFulfillmentType || !selectedDiningOptionBehavior || selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && !validDeliveryAddress && !cart?.order?.deliveryInfo) {
      return;
    }
    if(!canUpdate) {
      await onClose();
    } else {
      setSubmitting(true);

      let success = false;
      try {
        success = await updateCartFulfillment({
          fulfillmentType: selectedFulfillmentType,
          fulfillmentDateTime:
            selectedFulfillmentType === FulfillmentType.Future ?
              futureSchedules[Math.max(selectedDayIndex, 0)]?.times[Math.max(selectedTimeIndex, 0)]?.time :
              undefined,
          diningOptionBehavior: selectedDiningOptionBehavior,
          deliveryInfo: validDeliveryAddress
        });
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error changing cart fulfillment.', error);
      }

      if(success) {
        await onClose();
      }
      setSubmitting(false);
    }
  }, [
    canUpdate,
    onClose,
    updateCartFulfillment,
    selectedDayIndex,
    selectedTimeIndex,
    selectedFulfillmentType,
    selectedDiningOptionBehavior,
    futureSchedules,
    validDeliveryAddress,
    cart?.order?.deliveryInfo,
    openAlertModal
  ]);

  useEffect(() => {
    const dateTimeIndexes =
      getDateTimeIndexes(fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime || futureSchedules?.find(date => date?.times?.length)?.times?.[0]?.time,
        futureSchedules);

    if(!dateTimeIndexes) {
      return;
    }

    const [dayIndex, timeIndex] = dateTimeIndexes;
    setSelectedDayIndex(Math.max(dayIndex || 0, 0));
    setSelectedTimeIndex(Math.max(timeIndex || 0, 0));
  }, [fulfillmentData, selectedDiningOptionBehaviorSchedules, futureSchedules]);

  if(!futureSchedules?.length && !selectedDiningOptionBehaviorSchedules?.asapAvailableNow || !selectedFulfillmentType) {
    return null;
  }

  const normalizedDayIndex = Math.max(0, selectedDayIndex);
  const normalizedTimeIndex = Math.max(0, selectedTimeIndex);

  const day = futureSchedules?.length && futureSchedules[normalizedDayIndex]?.times.length
    ? futureSchedules[normalizedDayIndex]
    : futureSchedules?.find(date => date?.times?.length);
  const time = day?.times?.length ? day.times[normalizedTimeIndex] : null;

  const isDelivery = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery;

  if(selectedFulfillmentType === FulfillmentType.Future && (!day || !time)) {
    const canOrderOppositeFulfillmentType = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? canOrderTakeout : canOrderDelivery;
    const diningBehaviorString = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'pickup';
    const oppositeDiningBehaviorString = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'pickup' : 'delivery';
    return (
      <div className="diningOptionsContent" role="form" data-testid="diningOptions">
        {canOrderOppositeFulfillmentType &&
          <div className={props.behaviorToggleClassName}>
            <LegacyDiningBehaviorToggle themed={false} showQuoteTimes={false} />
          </div>}
        <div className="noOrderTimesAvailableNote">
          <p>
            {removeItem
              ? `${itemToRemove?.name ?? 'This item'} cannot be added to your cart because its available ${diningBehaviorString} times do not overlap with the restaurant's online ordering hours.`
              : `This restaurant does not have any ${diningBehaviorString} times available for online ordering.`}
          </p>
          <p>
            {`Please ${canOrderOppositeFulfillmentType ? `try switching to ${oppositeDiningBehaviorString} or ` : ''}contact the restaurant to order.`}
          </p>
        </div>
        <Button id="diningOptionRemove" variant={ButtonType.Primary} onClick={async () => {
          if(removeItem && itemToRemove) {
            deleteFromCart(itemToRemove.selectionGuid!);
          }
          await onClose();
        }}>
          {removeItem ? 'Remove item from cart' : 'Close'}
        </Button>
      </div>
    );
  }

  const futureArrivalString = isDelivery && cart?.deliveryQuoteTime ?
    `ASAP - ${cart.deliveryQuoteTime} - ${cart.deliveryQuoteTime + 5} minutes`
    : 'ASAP';
  const dayLabel = selectedFulfillmentType === FulfillmentType.Future && day ? toDayString(day.date) : 'Today';
  const timeLabel = selectedFulfillmentType === FulfillmentType.Future && time
    ? toLocalTime(time.time)
    : futureArrivalString;
  const timeOptions = futureSchedules[normalizedDayIndex]?.times || [];

  return (
    <>
      <div className="diningOptionsContent" role="form" data-testid="diningOptions">
        {removeItem && itemToRemove &&
          <div className="itemAddedToCartNote"><Image src="icons/check-mark.svg" alt="Success" />{`${itemToRemove.name ?? 'An item'} has been added to cart.`}</div>}
        {props.forceSelectMessage && <div className="error"><ErrorNotice>{props.forceSelectMessage}</ErrorNotice></div>}
        <div className={props.behaviorToggleClassName}>
          <LegacyDiningBehaviorToggle themed={false} showQuoteTimes={false} />
          {scheduledOrdersOnly && <div>You can only place scheduled orders right now.</div>}
          {Boolean(selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut && ooLocations?.length && ooLocations.length > 1) &&
            <>
              <div className="prompt">Order from</div>
              <OOLocationSelector />
              {(cart?.order?.selections?.length ?? 0) > 0 && <div className="warning"><Image src="icons/warning-orange.svg" alt="Warning" /><span>Changing locations will clear your cart.</span></div>}
            </>}
          {selectedDiningOptionBehavior === DiningOptionBehavior.Delivery &&
            <>
              <div className="prompt">Deliver to</div>
              <DeliveryAddressSelection />
            </>}
        </div>
        <AnimatedSection expanded={canScheduleSelectedBehavior}>
          <div className="prompt" id="date-dropdown-label">Date</div>
          <div className="options">
            <DropDown label={dayLabel} withBorder testId="fulfillment-date-selector" ariaDescription="select a date" labelledBy={'#date-dropdown-label'}>
              {({ close }) => futureSchedules?.map((date, index) =>
                date.times.length ?
                  <DropDownOption className="option" testId="fulfillment-date-list-option" key={date.date}
                    onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedDayIndex(index);
                      setSelectedFulfillmentType(FulfillmentType.Future);
                      close();
                    }}>
                    {toDayString(date.date)}
                  </DropDownOption>
                  : null)}
            </DropDown>
          </div>
          <div className="prompt" id="time-dropdown-label">{isDelivery ? 'Delivery ' : ''}Time</div>
          <div className="options">
            <DropDown label={timeLabel} withBorder disabled={timeOptions.length === 0} testId="fulfillment-time-selector" ariaDescription="select a time" labelledBy="#time-dropdown-label">
              {({ close }) =>
                <>
                  {asapAvailable && selectedDayIndex <= 0 &&
                    <DropDownOption className="option" onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFulfillmentType(FulfillmentType.Asap);
                      close();
                    }}>
                      {futureArrivalString}
                    </DropDownOption>}
                  {timeOptions.map((time, index) =>
                    <DropDownOption key={time.time} className="option" onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedTimeIndex(index);
                      setSelectedFulfillmentType(FulfillmentType.Future);
                      close();
                    }} >
                      {toLocalTime(time.time)}
                    </DropDownOption>)}
                </>}
            </DropDown>
          </div>
        </AnimatedSection>
      </div>
      <Button id="diningOptionSubmit" data-testid="diningOptionSubmit"
        variant={ButtonType.Primary}
        disabled={selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && !validDeliveryAddress && !cart?.order?.deliveryInfo}
        onClick={async () => await submit()}>{canUpdate ? 'Update' : 'Close'}
      </Button>
      {removeItem && itemToRemove?.selectionGuid &&
        <Button variant={ButtonType.Text} id="diningOptionRemove" className="removeItem" onClick={async () => {
          deleteFromCart(itemToRemove.selectionGuid!);
          onButtonClick?.();
          await onClose();
        }}>
          Remove item
        </Button>}
      <AnimatedSection expanded={Boolean(fulfillmentError?.message)}>
        <div className="error"><ErrorNotice>{fulfillmentError?.message}</ErrorNotice></div>
      </AnimatedSection>
      {submitting && <LoadingSpinnerOverlay withBorderRadius />}
    </>
  );
};

export default DiningOptions;
