import { useEffect } from 'react';


/**
 * Calls the given callback when a page is viewed. Uses PerformanceNavigationTiming to
 * determine whether the view was a "refresh"
 *
 * @param onView
 */
export const usePageViewed = (onView: (isRefresh: boolean) => any) => {
  useEffect(() => {
    const navigationPerformance = window.performance.getEntries()[0] as PerformanceNavigationTiming;
    const isRefresh = navigationPerformance.type === 'reload';
    onView(isRefresh);
  }, [onView]);
};


