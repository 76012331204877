import React, { useCallback, useRef } from 'react';

import classnames from 'classnames';

import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';

import QuantitySelector from 'public/components/default_template/online_ordering/item_modal/QuantitySelector';
import { useModifierContext } from 'public/components/online_ordering/ModifierContext';
import { getSubselectionNames, Modifier } from 'public/components/online_ordering/types';

type RowProps = {
  modifier: Modifier;
  groupGuid: string;
  inputType: 'radio' | 'checkbox';
  disabled?: boolean;
  canAddMore?: boolean;
  autoFocus?: boolean;
}

const SelectionRow = ({ groupGuid, modifier, inputType, disabled, canAddMore, autoFocus }: RowProps) => {
  const toggleInputRef = useRef<HTMLInputElement>();
  const hasModifierGroups = modifier.modifierGroups?.length;
  const { pushModifier, displayedModifier, select, deselect, modifierGroupData } = useModifierContext();
  const selectedItem = displayedModifier?.modifierGroups[groupGuid]?.[modifier.itemGuid];
  const selected = !!selectedItem;
  const thisPrice = modifier.price;
  const selectAction = inputType === 'radio' ? 'replace' : 'merge';
  const name = modifier.name || undefined;
  const subSelectionNames = displayedModifier ? getSubselectionNames(displayedModifier, groupGuid, modifier.itemGuid) : [];
  const isIncludedDefault = modifier.isDefault && modifierGroupData[groupGuid]?.defaultOptionsChargePrice === 'NO';
  const shouldShowPrice = modifier.price && modifier.price !== 0 && (!isIncludedDefault || selectedItem && selectedItem.quantity > 1);

  const onToggle = useCallback(() => {
    if(selected && inputType === 'checkbox') {
      deselect(groupGuid, modifier.itemGuid);
    } else {
      select(groupGuid, modifier.itemGuid, 1, selectAction, modifier.itemGroupGuid, name, thisPrice, modifier.isDefault);
    }

    if(!thisPrice) {
      return;
    }
  }, [selected, inputType, name, selectAction, deselect, groupGuid, select, thisPrice, modifier]);

  const onQuantityChange = useCallback((quantity: number, _: number, defaultValueChange?: boolean) => {
    if(defaultValueChange) {
      return;
    }

    select(groupGuid, modifier.itemGuid, quantity, selectAction, modifier.itemGroupGuid, name, thisPrice, modifier.isDefault);
  }, [thisPrice, name, groupGuid, modifier, select, selectAction]);

  const modifierId = `${groupGuid}_${modifier.itemGuid}`;
  const expandId = `${modifierId}-expand`;
  return (
    <div className={`row ${hasModifierGroups ? 'subGroups' : ''}`} key={modifier.itemGuid} data-testid={'mod-' + modifier.itemGuid}>
      <ToggleInput
        ref={toggleInputRef}
        type={inputType}
        name={groupGuid}
        id={modifierId}
        dataTestId={`${modifierId}-input`}
        autoFocus={autoFocus}
        textOnly
        disabled={disabled || !!modifier.outOfStock}
        checked={selected}
        onClick={e => {
          if(hasModifierGroups) {
            e.stopPropagation();
            e.preventDefault();
            pushModifier(groupGuid, modifier, selectAction);
          }
        }}
        onChange={e => {
          if(hasModifierGroups) {
            e.stopPropagation();
            e.preventDefault();
          } else if(!selectedItem || selectedItem.quantity < 1 || !modifier.allowsDuplicates) {
            onToggle();
          }
        }}
        ariaLabel={`${modifierId}-label ${modifierId}-expand`}>
        <div className={classnames('toggleContents', { hasModifiers: hasModifierGroups, selectedWithQuantity: selected && modifier.allowsDuplicates })}>
          <div className={classnames('modifierTextContent', { leftAligned: !modifier.price || modifier.price === 0 })} id={`${modifierId}-label`} data-testid={`${modifierId}-label`}>
            <div className="modifierText">
              <div className={`name ${selected ? 'selected' : ''}`}>{modifier.name}</div>
              <div className="subtitle">{subSelectionNames.join(', ')}</div>
            </div>
            {modifier.price && shouldShowPrice ?
              <span className={`price ${selected ? 'selected' : ''}`}>
                {modifier.price > 0 ? '+' : '-'}<FormattedPrice value={(selected ? isIncludedDefault ? selectedItem.quantity - 1 : selectedItem.quantity : 1) * Math.abs(modifier.price)} />
              </span>
              : null}
          </div>
          {selected && modifier.allowsDuplicates && !hasModifierGroups ?
            <div className="actions" id={expandId} data-testid={`${expandId}-quantity-selector`}>
              <QuantitySelector
                allowDelete
                className="rowSelector"
                onDelete={() => {
                  onToggle();
                  toggleInputRef.current?.focus();
                }}
                min={1}
                canIncrease={canAddMore}
                defaultValue={selectedItem?.quantity || 1}
                onQuantityChange={onQuantityChange} />
            </div>
            : null}
          {hasModifierGroups ?
            <div className="actions" id={expandId} data-testid={`${expandId}-caret`}>
              <Image alt="Click to expand modifier group" src="/icons/caret-right.svg" />
            </div>
            : null}
        </div>
      </ToggleInput>

    </div>
  );
};

export default SelectionRow;
