import React, { useCallback, useReducer } from 'react';

import classnames from 'classnames';

import { useOrderHistoryQuery } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import Button from 'src/shared/components/common/button';
import { useModal } from 'src/shared/components/common/modal';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';

import OrderCard from './OrderCard';
import OrderHistoryModal from './OrderHistoryModal';

const OrderAgain = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const [didReorder, setDidReorder] = useReducer(() => true, false);
  const { selectedLocation } = useRestaurant();
  const { data, loading, error } = useOrderHistoryQuery({
    variables: { input: { restaurantGuid: selectedLocation.externalId, pageSize: 4 } },
    errorPolicy: 'ignore',
    ssr: false
  });
  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  const onModalClose = useCallback(() => {
    onClose();
    setDidReorder();
  }, [onClose, setDidReorder]);

  if(!loading && !data?.orderHistory?.orders?.length || error) {
    return null;
  }

  return (
    <>
      <OrderHistoryModal isOpen={isOpen} onReorder={onModalClose} onClose={onClose} />
      <AnimatedSection expanded={!didReorder && !loading && !!data?.orderHistory?.orders?.length}>
        <div className="orderAgain">
          <div className={classnames('paddedContentWrapper', { sideBySide })}>
            <div className="paddedContent">
              <h3>Order Again</h3>
            </div>
          </div>
          <div className="ordersWrapper">
            <div className={classnames('scrollWrapper', { sideBySide })}>
              <div className="orders">
                {data?.orderHistory.orders.map(order => {
                  return <OrderCard key={order.guid} order={order} onReorder={setDidReorder} />;
                })}
              </div>
            </div>
          </div>
          <div className={classnames('paddedContentWrapper', { sideBySide })}>
            <div className="paddedContent">
              <Button className="seeMore" variant={ButtonType.Text} onClick={onOpen}>See more</Button>
            </div>
          </div>
        </div>
      </AnimatedSection>
    </>
  );
};

const ContextualOrderAgain = () => {
  const { customer } = useCustomer();
  return customer ? <OrderAgain /> : null;
};

export default ContextualOrderAgain;
