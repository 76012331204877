import React from 'react';

import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

type Props = {
  phoneNumber?: string | null;
  countryCode?: CountryCode;
  className?: string;
}

const PhoneLink = ({ phoneNumber, countryCode = 'US', className } : Props) => {
  const parsedPhoneNumber = phoneNumber ? parsePhoneNumber(phoneNumber, countryCode) : null;
  return parsedPhoneNumber ? <a className={className} href={parsedPhoneNumber.getURI()}>{parsedPhoneNumber.formatNational()}</a> : null;
};

export default PhoneLink;
