import React from 'react';

import { PaymentOption } from 'src/public/components/online_ordering/PaymentContext';
import Image from 'src/shared/components/common/Image';

interface Props {
  paymentOption: PaymentOption.Paypal | PaymentOption.Venmo
}

export default function PayPalCheckoutMethod({ paymentOption }: Props) {
  return (
    <div className="paypalLogo">
      <Image alt={`${paymentOption} icon`} src={`/icons/${paymentOption.toLowerCase()}-logo.svg`} />
      {paymentOption}
    </div>);
}
