import * as Sentry from '@sentry/react';

/**
  * Generic error handler. This error handler can be extended to hook into error reporting tools like Sentry.
  * Unlike src/lib/js/error.js, this file is webpack/browser compatible.
  *
  * @param {string} message - The error message to report.
  * @param {object} err - The error object to report. OPTIONAL.
  * @param {object} data - A custom data object to report. OPTIONAL.
  */
export const reportError = (message?: string, err?: object, data?: object) => {
  // stringifying the errors makes them a little more difficult to read, but prevents
  // Datadog from counting each line of these errors as a separate error
  console.error(message, JSON.stringify(err), JSON.stringify(data));
  Sentry.captureException(err || message);
};

export const reportErrorMessageWithData = (message: string, extra?: object) => {
  // stringifying the errors makes them a little more difficult to read, but prevents
  // Datadog from counting each line of these errors as a separate error
  console.error(message, JSON.stringify(extra));
  Sentry.captureMessage(message, { level: 'error', extra: { ...extra } });
};
