import React, { useContext, createContext, useCallback, useState, useEffect } from 'react';

import { useGiftCardBalanceLazyQuery, useGiftCardExtensionBalanceLazyQuery } from 'src/apollo/onlineOrdering';

export type GiftCardContextType = {
  applyGiftCard: (cartGuid: string, giftCard: string) => Promise<void>;
  applyGiftCardExtension: (cartGuid: string, giftCard: string, captchaToken: string, pinCode?: string) => Promise<void>;
  removeGiftCard: () => void;
  recalculateGiftCard: (cartGuid: string, cartTotal: number) => Promise<void>;
  loadingGiftCard: boolean;
  giftCardNumber: string;
  verificationCode?: string;
  giftCardAvailableBalance: number;
  giftCardError: string;
};

export const GiftCardContext = createContext<GiftCardContextType | undefined>(undefined);

export const GiftCardContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [giftCardBalanceQuery, { data, error }] = useGiftCardBalanceLazyQuery({ fetchPolicy: 'no-cache' });
  const [giftCardBalanceExtensionQuery, { data: extensionData, error: extensionError }] = useGiftCardExtensionBalanceLazyQuery({ fetchPolicy: 'no-cache' });
  const [loadingGiftCard, setLoadingGiftCard] = useState(false);
  const [giftCardNumber, setGiftCardNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState<string>();
  const [giftCardAvailableBalance, setGiftCardAvailableBalance] = useState(0.00);
  const [giftCardError, setGiftCardError] = useState('');

  const removeGiftCard = useCallback(() => {
    setGiftCardNumber('');
    setVerificationCode(undefined);
    setGiftCardAvailableBalance(0);
  }, [setGiftCardNumber, setGiftCardAvailableBalance]);

  useEffect(() => {
    // Even though the return data for giftCardBalanceQuery is a union of GiftCardBalanceInquiryResponse and GiftCardBalanceInquiryError,
    // GiftCardBalanceInquiryError is defined as a child class of ApolloError, so it comes back as a graphQLError.
    if(error?.graphQLErrors?.length && error.graphQLErrors[0]) {
      setGiftCardError(error.graphQLErrors[0].message);
      removeGiftCard();
    } else if(extensionError?.graphQLErrors?.length && extensionError.graphQLErrors[0]) {
      setGiftCardError(extensionError.graphQLErrors[0].message);
      removeGiftCard();
    } else {
      setGiftCardError('');
    }
    setLoadingGiftCard(false);
  }, [error, extensionError, setGiftCardError, removeGiftCard]);

  useEffect(() => {
    if(data?.giftCardBalanceInquiry?.__typename === 'GiftCardBalanceInquiryResponse') {
      setGiftCardNumber(data.giftCardBalanceInquiry.cardNumber);
      setGiftCardAvailableBalance(data.giftCardBalanceInquiry.availableBalance);
      setGiftCardError('');
    } else if(data?.giftCardBalanceInquiry?.__typename === 'GiftCardBalanceInquiryError') {
      setGiftCardError(data.giftCardBalanceInquiry.message);
      removeGiftCard();
    }
    setLoadingGiftCard(false);
  }, [data, setGiftCardNumber, setGiftCardAvailableBalance, setGiftCardError, removeGiftCard]);

  useEffect(() => {
    if(extensionData?.giftCardExtensionBalanceInquiry?.__typename === 'GiftCardBalanceInquiryResponse') {
      setGiftCardNumber(extensionData.giftCardExtensionBalanceInquiry.cardNumber);
      setGiftCardAvailableBalance(extensionData.giftCardExtensionBalanceInquiry.availableBalance);
      setGiftCardError('');
    } else if(extensionData?.giftCardExtensionBalanceInquiry?.__typename === 'GiftCardBalanceInquiryError') {
      setGiftCardError(extensionData.giftCardExtensionBalanceInquiry.message);
      removeGiftCard();
    }
    setLoadingGiftCard(false);
  }, [extensionData, setGiftCardNumber, setGiftCardAvailableBalance, setGiftCardError, removeGiftCard]);

  const applyGiftCard = async (cartGuid: string, giftCard: string) => {
    setLoadingGiftCard(true);
    await giftCardBalanceQuery({ variables: { input: { cartGuid, cardNumber: giftCard.replace(/\s/g, '') } } });
  };

  const applyGiftCardExtension = async (cartGuid: string, giftCard: string, captchaToken: string, pin?: string) => {
    setLoadingGiftCard(true);

    if(pin !== undefined) {
      pin = pin.replace(/\s/g, '');
      setVerificationCode(pin);
    }

    giftCardBalanceExtensionQuery({ variables: { input: { cartGuid, captchaToken, pin, cardNumber: giftCard.replace(/\s/g, '') } } });
  };

  const recalculateGiftCard = async (cartGuid: string) => {
    setLoadingGiftCard(true);
    await applyGiftCard(cartGuid, giftCardNumber);
  };

  return (
    <GiftCardContext.Provider value={{
      applyGiftCard,
      applyGiftCardExtension,
      removeGiftCard,
      recalculateGiftCard,
      loadingGiftCard,
      giftCardNumber,
      verificationCode,
      giftCardAvailableBalance,
      giftCardError
    }}>
      {props.children}
    </GiftCardContext.Provider>);
};

export const useGiftCard = () => {
  const context = useContext(GiftCardContext);
  if(!context) {
    throw new Error('useGiftCard must be used within a GiftCardContextProvider');
  }

  return context;
};
