import React, { useCallback, useContext, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import crc from 'crc-32';
import { v4 as uuid } from 'uuid';

import { RequestContext, RequestContextProps } from 'src/lib/js/context';
import { isToastLocalRequest, isToastOrderRequest } from 'src/public/js/siteUtilities';


import { TESTS } from './tests.config';
import { Tests, TestSurface } from './types';

export const COOKIE_NAME = 'toast-sites-experiment-id';
const getOverrideCookieName = (experimentName: string): string => `toast-sites-experiment-override|${experimentName}`;
const canRunTestOnSurface = (staticContext: RequestContext | undefined, surfaces: TestSurface[]): boolean => {
  if(isToastLocalRequest(staticContext)) {
    return surfaces.includes(TestSurface.TOAST_LOCAL);
  } else if(isToastOrderRequest(staticContext)) {
    return surfaces.includes(TestSurface.OOV4);
  }

  return surfaces.includes(TestSurface.CUSTOM_DOMAINS);
};

type ExperimentContextType = {
  getSelectedVariant: (experimentName: string) => string
}

const ExperimentContext = React.createContext<ExperimentContextType | undefined>(undefined);

type ProviderProps = {
  onVariantSelected?: (experimentName: string, variantName: string) => void
  tests?: Tests
}

export const getSelectedVariant = (
  { experimentName, userId, cookies, onVariantSelected, staticContext, tests }:
  {
    experimentName: string;
    cookies: {[key: string]: string};
    userId: string;
    onVariantSelected?: (experimentName: string, variantName: string) => void;
    staticContext?: RequestContext
    tests: Tests;
  }
): string => {
  const overrideCookieName = getOverrideCookieName(experimentName as string);
  const overrideCookie = cookies[overrideCookieName];
  if(overrideCookie) {
    // If we have a test overridden, use that as our selected variant
    onVariantSelected?.(experimentName, overrideCookie);
    return overrideCookie;
  }

  const test = tests[experimentName];
  const variants = test?.variants;

  if(!test || !variants) {
    throw new Error('Attempted to render a test that does not exist');
  }

  if(test.disabled || !canRunTestOnSurface(staticContext, test.testSurfaces) || staticContext?.isBot) {
    return test.controlVariant;
  }

  /*
  * For a set of variants { a: 20, b: 50, c: 30 }
  * we create lists [a, b, c] and [20, 50, 30].
  * Then we can create a running sum [20, 70, 100],
  * and return the variant at the first index where
  * the normalized userId is less than that running sum.
  */
  const variantNames = Object.keys(variants).sort(); // sort for determinism
  const variantWeights = variantNames.map(name => variants[name as keyof typeof variants]) as number[];
  const runningSum = variantWeights.reduce((sum, val, index) => {
    const prevWeight = sum[index - 1];
    return [...sum, prevWeight ? prevWeight + val : val];
  }, []);
  const sum = variantWeights.reduce((acc, curr) => acc + curr, 0);

  // Adding experiment name here so that when multiple experiments are set at 50/50 it's not the same group of users in each bucket
  const weightedIndex = Math.abs(crc.str(`${userId}:${experimentName}`)) % sum;
  const variantIndex = runningSum.findIndex(weight => weightedIndex < weight);
  const selectedVariant = variantNames[variantIndex] || test.controlVariant;

  onVariantSelected?.(experimentName, selectedVariant);

  return selectedVariant;
};

export const useExperimentUserId = (): string => {
  const [cookies] = useCookies([COOKIE_NAME]);
  return cookies[COOKIE_NAME];
};

export const getExperimentUserId = (cookies: { [key: string ]: string }): string | undefined => {
  return cookies[COOKIE_NAME];
};

const WrappedExperimentContextProvider = ({
  children,
  onVariantSelected: onVariantSelectedProp,
  tests = TESTS,
  staticContext
}: React.PropsWithChildren<ProviderProps & RequestContextProps>) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME, ...Object.keys(tests).map(getOverrideCookieName)]);
  const selectedVariantRef = useRef<{ [key: string]: string }>({});

  const onVariantSelected = useCallback((experimentName: string, variantName: string) => {
    selectedVariantRef.current[experimentName] = variantName;
    if(onVariantSelectedProp) {
      onVariantSelectedProp(experimentName, variantName);
    }
  }, [onVariantSelectedProp]);

  let userId = useExperimentUserId();
  if(!userId) {
    userId = uuid();
    setCookie(COOKIE_NAME, userId, { expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
  }


  const getVariant = useCallback((experimentName: string): string => {
    const cachedSelectedVariant = selectedVariantRef.current[experimentName];
    if(cachedSelectedVariant) {
      // We've already selected a variant, just return it
      return cachedSelectedVariant;
    }

    return getSelectedVariant({ tests, cookies, onVariantSelected, userId, staticContext, experimentName });
  }, [tests, cookies, onVariantSelected, userId, staticContext]);

  return (
    <ExperimentContext.Provider value={{ getSelectedVariant: getVariant }}>{children}</ExperimentContext.Provider>
  );
};

const RoutedExperimentContextProvider = withRouter<ProviderProps & RequestContextProps, React.ComponentType<ProviderProps & RequestContextProps>>(WrappedExperimentContextProvider);

export const ExperimentContextProvider = ({ tests = TESTS, ...props }: React.PropsWithChildren<ProviderProps>) => {
  const { isEditor } = useEditor();


  if(isEditor) {
    return (
      <ExperimentContext.Provider value={{
        getSelectedVariant: experimentName => {
          const test = tests[experimentName];

          if(!test) {
            throw new Error('Attempted to render a test that does not exist');
          }

          return test.controlVariant;
        }
      }}>
        {props.children}
      </ExperimentContext.Provider>
    );
  }

  return <RoutedExperimentContextProvider {...props} tests={tests} />;
};

export const useExperiment = (experimentName: string, tests: Tests = TESTS) => {
  const context = useContext(ExperimentContext);
  return { selectedVariant: context ? context.getSelectedVariant(experimentName) : tests[experimentName]?.controlVariant };
};
