import React from 'react';

import Link from 'shared/components/common/link';

import { formatRXName } from 'public/components/default_template/restaurant_profile/formatting';
import { RestaurantProfileRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';
import { rxLocationSearchUrl } from 'public/components/toast_local/common/searchUtils';

import { resources } from 'config';

type Props = {
  restaurant: RestaurantProfileRestaurantType;
};

const Breadcrumbs = ({ restaurant }: Props) => {
  const crumbs = [];

  const locationSearchUrl = rxLocationSearchUrl(restaurant.location);
  if(locationSearchUrl) {
    crumbs.push({
      name: restaurant.location.city,
      path: locationSearchUrl
    });
  }

  // Some work needs to be done in order to map the cuisineType returned with the Rx and
  // the supported cuisine types on the Search page
  /*
  const cuisine = restaurant.cuisineType;
  if(cuisine) {
    if(!locationFilters) {
      crumbs.push({ name: cuisine, path: `https://${resources.toastLocalHost}/local/search?${queryString.stringify({ filters: toBase64(JSON.stringify({ cuisine })) })}` });
    } else {
      crumbs.push({
        name: cuisine,
        path: `https://${resources.toastLocalHost}/local/search?${queryString.stringify({ filters: toBase64(JSON.stringify(locationFilters)) })}`
      });
    }
  }
  */
  crumbs.push({ name: formatRXName(restaurant.name) || 'Restaurant', path: 'rx-name' }); // Don't need real path here

  const numCrumbs = crumbs.length;
  const crumbNodes = crumbs.map((crumb, i) => {
    return (
      <React.Fragment key={i}>
        <li className="crumb slash">/</li>
        <li className="crumb">{i < numCrumbs - 1 ? <Link href={crumb.path} sameTab>{crumb.name}</Link> : crumb.name}</li>
      </React.Fragment>);
  });
  // prepend the crumbs with the "Home" text.
  crumbNodes.unshift(<li className="crumb" key={-1}><Link href={`https://${resources.toastLocalHost}/local`} sameTab>Home</Link></li>);

  return (
    <div className="breadcrumbs">
      <ul>
        {crumbNodes}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
