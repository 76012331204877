import React from 'react';

import { ContainsEditableProps } from '@toasttab/sites-components';
import classnames from 'classnames';

import { ButtonType, CardType, ThemeTypeEnum, SectionImagePosition, SectionBackgroundSize } from 'src/apollo/sites';


import Card from './Card';

type Props = {
  contentWrapperClassName?: string;
  contentClassName?: string;
  theme?: ThemeTypeEnum | null;
  card: CardType;
  isFirstSection?: boolean;
  imgPosition?: SectionImagePosition | null;
  textWithBackground?: boolean | null;
  textBackgroundSize?: SectionBackgroundSize | null;
  buttonName?: string;
  isHero?: boolean;
} & EditableProps;

type EditableProps = {
  pathToImageOverride?: string | null;
} & ContainsEditableProps;

export const PositionedCard = ({
  editPath, contentWrapperClassName, contentClassName,
  theme, card, isFirstSection, imgPosition, textWithBackground,
  textBackgroundSize, buttonName, isHero = false, parentEditPath, ...rest
}: Props) => {
  if(!card.image) {
    return null;
  }

  return (
    <div className={classnames('positionedCard', theme, imgPosition, textBackgroundSize, { textWithBackground, isFirstSection })}>
      <Card
        editPath={editPath}
        parentEditPath={parentEditPath}
        theme={theme}
        contentWrapperClassName={classnames(contentWrapperClassName, { paddedSection: imgPosition === SectionImagePosition.Center })}
        contentClassName={contentClassName}
        image={card.image}
        header={card.header}
        subheader={card.subheader}
        title={card.title}
        body={card.body}
        button={
          card.button ?
            {
              ...card.button,
              type: textWithBackground ? card.button.type || ButtonType.Inverted : card.button.type
            } :
            null
        }
        buttonName={buttonName}
        alignment={card.alignment}
        isHero={isHero}
        {...rest} />
    </div>
  );
};

export default PositionedCard;
