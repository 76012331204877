import React, { useMemo } from 'react';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="profileFooter">
      <div className="footer-container generic-container">
        <div className="footer-content">
          <div className="content-cell pure-g">
            <div className="footer-nav pure-u-12-24 pure-u-sm-6-24">
              <div>© {year} Toast, Inc.</div>
              <Image src="/icons/toast-logo.svg" alt="Toast" height={45} className="toast-badge" />
            </div>

            <ul className="footer-nav nav-down pure-u-12-24 pure-u-sm-6-24">
              <li><Link href="https://pos.toasttab.com/about">About Toast</Link></li>
              <li><Link href="https://pos.toasttab.com/">For restaurants</Link></li>
            </ul>

            <ul className="footer-nav nav-down pure-u-12-24 pure-u-sm-6-24">
              <li><Link href="https://pos.toasttab.com/terms-of-service" rel="nofollow">Terms of Service</Link></li>
              <li><Link href="https://pos.toasttab.com/privacy" rel="nofollow">Privacy Statement</Link></li>
              <li><Link href="https://pos.toasttab.com/privacy#addendum" rel="nofollow">Do not sell my<br />personal information</Link></li>
            </ul>

            <div className="footer-nav nav-down pure-u-12-24 pure-u-sm-6-24">
              Made with <Image src="/icons/heart-orange.svg" alt="love" height={11} className="heart-icon" /> in Boston

              <ul className="footer-nav social-media">
                <li><a href="https://instagram.com/toasttab" target="_blank" rel="noreferrer"><Image src="icons/instagram-gray.svg" alt="Instagram" className="instagram" height={12} /></a></li>
                <li><a href="https://x.com/toasttab" target="_blank" rel="noreferrer"><Image src="icons/x-white-1.svg" alt="X (formerly Twitter)" className="twitter" height={12} /></a></li>
                <li><a href="https://www.facebook.com/toasttab/" target="_blank" rel="noreferrer"><Image src="icons/facebook-gray.svg" alt="Facebook" className="facebook" height={12} /></a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
