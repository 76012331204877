import React, { useMemo } from 'react';

import { _div, _heading } from '@toasttab/sites-components';

import { Block as BlockType } from 'src/apollo/sites';
import { EditableButton } from 'src/shared/components/common/button';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import EmbeddedCode from 'shared/components/common/embedded_code/EmbeddedCode';
import EditableMenuItem from 'shared/components/common/menu_item/MenuItem';
import { EditableShape } from 'shared/components/common/shape/Shape';

import { getNumRowsFromBlock, MOBILE } from 'public/components/default_template/dynamic_section/DynamicSectionUtils';


type BlockProps = {
  block: BlockType;
  editPath: string;
}

type BlockWrapperProps = {
  block: BlockType;
  // The starting row location for this block in mobile view.
  // Will only be listened to if the mobile location isn't set.
  mobileOffset: number;
}

export const BlockWrapper = ({ children, block, mobileOffset }: React.PropsWithChildren<BlockWrapperProps>) => {
  const isMobile = useIsMobile(ScreenWidth.SMALL);

  // Computes the grid area for this block. Most of the logic here is only used
  // on mobile if the block has no set mobile layout
  const gridArea = useMemo(() => {
    if(!isMobile) {
      return `${block.startY}/${block.startX}/${block.endY}/${block.endX}`;
    }

    if(
      block.mobileStartX &&
        block.mobileEndX &&
        block.mobileStartY &&
        block.mobileEndY
    ) {
      return `${block.mobileStartY}/${block.mobileStartX}/${block.mobileEndY}/${block.mobileEndX}`;
    }

    const startY = mobileOffset + 1;
    const endY = mobileOffset + 1 + getNumRowsFromBlock(block, isMobile);
    // All blocks are full width on mobile.
    const startX = 1;
    const endX = MOBILE.NUM_COLS + 1;

    return `${startY}/${startX}/${endY}/${endX}`;
  }, [isMobile, block, mobileOffset]);

  return <div data-testid="blockWrapper" className="blockWrapper" style={{ gridArea, zIndex: block.layer, display: 'flex' }}>{children}</div>;
};

export const Block = ({ block, editPath }: BlockProps) => {
  switch(block.kind) {
    case 'paragraph':
    case 'text':
      return <_div editPath={editPath} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading1':
      return <_heading editPath={editPath} styleLevel={1} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading2':
      return <_heading editPath={editPath} styleLevel={2} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading3':
      return <_heading editPath={editPath} styleLevel={3} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading4':
      return <_heading editPath={editPath} styleLevel={4} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'image':
      return <_Image style={{ height: '100%', width: '100%' }} imageObjectPath={editPath} imageObject={block.contents} alt={block.contents?.altText ?? ''} decorated={true} />;
    case 'button':
      return <EditableButton editPath={editPath} {...block.contents} />;
    case 'shape':
      return <EditableShape editPath={editPath} {...block.contents} />;
    case 'menuItem':
      return (
        <EditableMenuItem editPath={editPath} {...block.contents} />
      );
    case 'embeddedCode':
      return <EmbeddedCode editPath={editPath} block={block} />;
    default:
      return null;
  }
};
