import React, { useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import CuisineFilter from './CuisineFilter';
import { CUISINES } from './cuisines.data';

type Props = {
  title: React.ReactNode;
  citySubPath: string;
  className?: string;
  selectedCuisine: string;
  onClickFilter: (evt: React.MouseEvent<Element, MouseEvent>, cuisineKey: string) => void
}

const CuisineFilters = ({ title, citySubPath, className, selectedCuisine, onClickFilter }: Props) => {
  const filtersRef = useRef<HTMLDivElement>(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const toggleArrows = () => {
    if(filtersRef.current) {
      if(filtersRef.current.scrollLeft > 0) {
        setHideLeftArrow(false);
      } else {
        setHideLeftArrow(true);
      }

      if(filtersRef.current.scrollLeft + filtersRef.current.offsetWidth === filtersRef.current.scrollWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
    }
  };

  useEffect(() => {
    const filterElement = filtersRef ? filtersRef.current : null;
    if(filterElement) {
      filterElement.addEventListener('scroll', toggleArrows);
    }

    return () => {
      if(filterElement) {
        filterElement.removeEventListener('scroll', toggleArrows);
      }
    };
  });


  const cuisineFilters = useMemo(() =>
    CUISINES.map(cuisine =>
      <CuisineFilter cuisine={cuisine} subPath={citySubPath}
        className={classnames({ selected: selectedCuisine && selectedCuisine.toString() === cuisine.value })} onClickFilter={onClickFilter} key={cuisine.value} />),
  [citySubPath, selectedCuisine, onClickFilter]);

  return (
    <div className={classnames('cuisine-filters', className)}>
      <div className="filter-header">
        <h3>{title || 'Filter by cuisine'}</h3>
      </div>
      <div className="filters" ref={filtersRef} >
        {!hideLeftArrow &&
          <span className="arrow-wrapper left-arrow">
            <Image onClick={() => filtersRef.current?.scrollBy({ left: -200, behavior: 'smooth' })} src="icons/arrow-left-gray.svg" alt="Scroll left" />
          </span>}
        {cuisineFilters}
        {!hideRightArrow &&
          <span className="arrow-wrapper right-arrow">
            <Image onClick={() => filtersRef.current?.scrollBy({ left: 200, behavior: 'smooth' })} src="icons/arrow-right-gray.svg" alt="Scroll right" />
          </span>}
      </div>
    </div>);
};

export default CuisineFilters;
