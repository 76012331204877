import React from 'react';

import { ToastLogo } from '@toasttab/buffet-pui-toast-logo';
import { useWindowSize } from '@toasttab/buffet-use-window-size';

import Image from 'src/shared/components/common/Image';

import { useTTODeepLink } from './useTTODeepLink';

const TakeoutCTA = ({ orderGuid } : { orderGuid: string}) => {
  const deepLink = useTTODeepLink(orderGuid);

  const stars = Array.from({ length: 5 }, (_, i) => {
    if(i === 4) {
      return <Image key={`star-icon-${i}`} src="/icons/star-half-yellow.svg" alt="" />;
    }
    return <Image key={`star-icon-${i}`} src="/icons/star-full-yellow.svg" alt="" />;
  });

  const { width } = useWindowSize();
  const isMobile = width < 768;


  if(!isMobile) return null;
  return (
    <section className="checkoutSection currentSection takeoutCta" data-testid="cta-oov4">
      <div className="ctaHeader">
        Order like a regular on the Toast Takeout app!
      </div>
      <div className="ctaBody">
        <div className="logoContainer">
          <ToastLogo className="logo" />
        </div>

        <div className="middle">
          <div className="appName">
            Toast Takeout
          </div>
          <div className="rating">
            <span className="ratingNumber">+50k Ratings </span>
            <span className="stars" aria-label="4 and a half stars">{stars}</span>
          </div>
        </div>

        <a
          className="ctaButton"
          target="_blank"
          data-testid="cta-oov4-link"
          rel="noopener nofollow noreferrer"
          aria-label="Toast takeout page"
          href={deepLink}>
          Get App
        </a>
      </div>
    </section>
  );
};


export default TakeoutCTA;
