import React, { SyntheticEvent, useCallback, useMemo } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { ButtonType } from 'src/apollo/sites';
import Button from 'src/shared/components/common/button';

import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import DropDown from 'shared/components/common/dropdown';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { EditablePrimaryCTA, PrimaryCTAClasses } from 'shared/components/primary_cta/PrimaryCTA';

import { NavItem } from 'public/components/default_template/nav/NavItems';

import { CTA } from './';

type CTAItemProps = {
  cta?: CTA | null;
  isPrimary?: boolean;
  className?: string;
  leftAligned?: boolean;
  editPath?: string;
};

type CTAItemModalProps = {
  cta?: CTA | null;
  onClick?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const CTAItemModal = ({ cta, isOpen, onClose }: CTAItemModalProps) => {
  const overrideCTAOnClick = useCallback(
    (cta: CTA) => () => {
      if(cta.onClick) {
        cta.onClick();
      }
      onClose();
    },
    [onClose]
  );

  if(!cta) {
    return null;
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent wrapperClassName="ctaItemModal" contentClassName="ctaItemModalContent">
        <div className="header">
          <div className="txt-title-md">Select a location</div>
          <ModalCloseButton />
        </div>
        <div className="body">
          <AttributionContextProvider utm_campaign={cta.utm_campaign}>
            {cta.submenus?.map((subCta: CTA, i: number) =>
              <NavItem key={`cta${i}`} cta={{ ...subCta, onClick: overrideCTAOnClick(subCta) }} expandable={false} />)}
          </AttributionContextProvider>
        </div>
      </ModalContent>
    </Modal>
  );
};

const CTAItem = ({ cta, isPrimary, className, leftAligned, editPath, children }: React.PropsWithChildren<CTAItemProps>) => {
  const { isEditor } = useEditor();
  const { isOpen, onClose, onOpen } = useModal();

  const buttonType = useMemo(() => isPrimary ? ButtonType.Primary : ButtonType.Secondary, [isPrimary]);

  const buttonContent = (toggle: (e: SyntheticEvent<Element, Event>) => void, cta: CTA, editPath?: string, isPrimary?: boolean, isEditor?: boolean) =>
    isPrimary && editPath && isEditor
      ?
      <EditablePrimaryCTA type={'customAction'} text={cta.text} editPath={editPath} iconRight={<div className="dropDownArrow"></div>} />
      :
      <Button variant={buttonType} onClick={toggle} iconRight={<div className="dropDownArrow"></div>}>
        {cta.text}
      </Button>;
  if(!cta) {
    return null;
  }

  if(!cta.submenus?.length) {
    return <AttributionContextProvider utm_campaign={cta.utm_campaign}>{children}</AttributionContextProvider>;
  }

  return (
    <>
      <div className="hidden-sm-down">
        <DropDown
          label={cta.text}
          className={classnames(className, isPrimary && PrimaryCTAClasses)}
          leftAligned={leftAligned}
          opener={toggle => buttonContent(toggle, cta, editPath, isPrimary, isEditor)}>
          <AttributionContextProvider utm_campaign={cta.utm_campaign}>
            {cta.submenus.map((subCta: CTA, i: number) => <NavItem key={`cta${i}`} cta={subCta} expandable={false} />)}
          </AttributionContextProvider>
        </DropDown>
      </div>
      <div className="hidden-md-up">
        <CTAItemModal cta={cta} isOpen={isOpen} onClose={onClose} />
        <Button className="cta" variant={buttonType} onClick={onOpen}>{cta.text}</Button>
      </div>
    </>
  );
};

export default CTAItem;
