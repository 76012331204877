import { apolloClient, authClient } from '../initialization'
import { confirmPasswordlessMutation } from './confirm-passwordless-mutation'
import { parseConfirmVerificationCodeError } from './error-util'

export interface GuestContact {
  email: string
  firstName: string
  lastName: string
}

/**
 * Confirms the phone number previously used in startPasswordless and / or requestVerificationCode using the
 * verification code sent to the phone number, creates a profile in guest-profiles service, and updates the
 * Auth0 profile.
 *
 * See https://github.com/toasttab/toast-authentication/blob/main/toast-authentication-api/src/main/kotlin/com/toasttab/service/authentication/api/enums/ToastAuthenticationProviderErrorCode.kt
 * for response details from verification confirmation via authentication service.
 * Note that these are general errors that can come from Auth0 and should be presented according to the context of the use case.
 * For example, we do not send email to the guest like how this message (https://github.com/toasttab/toast-authentication/blob/main/toast-authentication-api/src/main/kotlin/com/toasttab/service/authentication/api/enums/ToastAuthenticationProviderErrorCode.kt#L15)
 * suggests, so the message for this error should be customized.
 *
 * See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L216
 * for response details from confirmGuest via guest-profiles service.
 *
 * GraphQL playground can also be spun up to view response details from confirmGuest via guest-profiles service.
 * See https://github.com/toasttab/toast-do-federated-gateway#introspection-on-preproduction
 * or https://github.com/toasttab/toast-guest-profiles#graphql for detail.
 *
 * @param verificationCode: Used to initialize a profile in Auth0 and receive the verification code.
 * @param source: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L207.
 */
export async function confirmVerificationCode(
  verificationCode: string,
  source: string
) {
  try {
    await authClient.loginWithCode(verificationCode)
  } catch (error) {
    return parseConfirmVerificationCodeError(error)
  }

  return confirmPasswordless(source)
}

/**
 * Creates a profile in guest-profiles service and updates the Auth0 profile, supports workflows that collect contact information,
 * such as email and names, after confirming the phone number via a verification code using confirmVerificationCode.
 *
 * See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L216
 * for response details.
 *
 * GraphQL playground can also be spun up to view response details.
 * See https://github.com/toasttab/toast-do-federated-gateway#introspection-on-preproduction
 * or https://github.com/toasttab/toast-guest-profiles#graphql for detail.
 *
 * @param source: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L207.
 * @param contact: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L152.
 */
export async function confirmPasswordless(
  source: string,
  contact?: GuestContact
) {
  const authHeader = await authClient.getAuthHeader()

  const response = await confirmPasswordlessMutation(
    source,
    authHeader,
    () => authClient.refreshAuthClient(),
    apolloClient,
    contact
  )

  return response
}
