import React, { useMemo } from 'react';

import classNames from 'classnames';

export const calculateScale = (parentHeight?: number | null, parentWidth?: number | null, contentHeight?: number | null, contentWidth?: number | null) => {
  if(contentHeight && contentWidth && parentHeight && parentWidth) {
    // The default dimensions for an iframe is 300x150. Even if the content is smaller than these dimensions,
    // contentHeight and contentWidth will be set to these minimum values.
    // If the content is smaller that 300x150, we shouldnt scale down the content if the parent is smaller.
    // This means if the content is 300x150 or smaller and the parent is made smaller than the content, not all the content will be shown.
    if(
      (parentWidth < contentWidth || parentHeight < contentHeight)
      && contentWidth > 300
      && contentHeight > 150
    ) {
      const heightRatio = parentHeight ? Math.min(parentHeight / contentHeight, 1) : 1;
      const widthRatio = parentWidth ? Math.min(parentWidth / contentWidth, 1) : 1;
      return Math.min(widthRatio, heightRatio);
    }
  }

  return null;
};

type Props = {
  className?: string
  code?: string
  setRef: React.Dispatch<React.SetStateAction<HTMLIFrameElement | null>>
  iframeOnLoad?: () => void
  scaleRatio?: number
  offset?: number
};

const IFramedCodeBase = ({ className, code, setRef, iframeOnLoad, scaleRatio, offset }: Props) => {
  const wrappedCode = useMemo(() => {
    const iframeBodyStyles = `
      border: none;
      margin: auto;
      overflow: hidden;
      height: fit-content;
      width: fit-content;
      ${scaleRatio ? `transform: scale(${scaleRatio}); transform-origin: 0 0; translate: ${offset}px;` : ''}
    `;

    return `
      <html>
        <head></head>
        <body style="${iframeBodyStyles}">
          ${code}
        </body>
      </html>
    `;
  }, [scaleRatio, code, offset]);

  if(!code) {
    return null;
  }

  /*
   * Per SECR-796, never enable the following features in the iframe sandbox
   *  - allow-top-navigation
   *  - allow-top-navigation-by-user-activation
   *  - allow-popups-to-escape-sandbox
   *  - allow-pointer-lock
   *  - allow-orientation-lock
   */
  return (
    <>
      <iframe
        ref={setRef}
        srcDoc={wrappedCode}
        onLoad={iframeOnLoad}
        referrerPolicy="same-origin"
        sandbox="allow-scripts allow-forms allow-presentation allow-popups allow-same-origin"
        className={classNames('iframePage', className)} />
    </>
  );
};

export default IFramedCodeBase;
