import React, { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import { SearchHighlightedText } from 'public/components/default_template/search';

export const MenuHeader = ({ header, badge }: { header: string | null | undefined; badge?: ReactNode } ) => {
  return (
    <h2>
      {header ? <SearchHighlightedText text={header} /> : <Skeleton width="100%" height="100%" />}
      {badge ? <div className="headerBadge">{badge}</div> : null}
    </h2>
  );
};
