import React from 'react';

import { formatGuestLabel } from './GuestSelector';
import { OPTIONS } from './OccasionSelector';
import { formatDateString, formatTimeString } from './reservationUtils';
import { SeatingLocation } from './reservationUtils';

type Props = {
  numGuests: number;
  serviceArea: SeatingLocation | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  timezone: string;
  occasion?: string | null;
};

// Four / Five squares that show the basic details of a reservation.
const ReservationOverview = ({ numGuests, serviceArea, selectedDate, selectedTime, timezone, occasion }: Props) => {
  const selectedOption = OPTIONS.find(option => option.label === occasion);

  return (
    <div>
      <div className="squaresSection">
        <div className="square">{formatGuestLabel(numGuests)}</div>
        <div className="square">{serviceArea?.name || ''}</div>
        <div className="square">{selectedDate ? formatDateString(selectedDate, timezone) : ''}</div>
        <div className="square">{selectedTime ? formatTimeString(selectedTime, timezone) : ''}</div>
        {occasion &&
          <div className="square" data-testid="occasion-square">
            {selectedOption &&
              <div className="occasionIcon" key={selectedOption.label} data-testid={`${occasion.toLowerCase()}-icon`}>
                {selectedOption.icon} {occasion}
              </div> }
          </div>}
      </div>
    </div>
  );
};

export default ReservationOverview;
