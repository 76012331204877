import React from 'react';

import { useGiaAuth } from 'src/shared/js/hooks/useGiaAuth';

import { CustomerContextProvider } from './CustomerContext';
import { CustomerContextGiaProvider } from './CustomerContextGia';

export const CustomerContextProviderWrapper = (props: React.PropsWithChildren<{}> ) => {
  const giaAuth = useGiaAuth();
  if(giaAuth) {
    return <CustomerContextGiaProvider {...props} />;
  }
  return <CustomerContextProvider {...props} />;
};
