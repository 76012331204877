import React from 'react';

import classnames from 'classnames';

import useElementInBounds from 'src/lib/js/hooks/useElementInBounds';
import useScrollDirection from 'src/lib/js/hooks/useScrollDirection';

import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';

import NavContent from 'public/components/default_template/nav/NavContent';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { RestaurantProfileRestaurantType, NearbyRestaurantSearchResultRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';

import { resources } from 'config';

import RestaurantProfile from './restaurant_profile/RestaurantProfile';

type RestaurantProfilePageProps = {
  restaurantProfileData: RestaurantProfileRestaurantType;
  nearbyRxs?: NearbyRestaurantSearchResultRestaurantType[];
};

export const RestaurantProfilePage = ({ restaurantProfileData, nearbyRxs }: RestaurantProfilePageProps) => {
  const [scrollNavRef, inBounds] = useElementInBounds<HTMLDivElement>({ maxScrollY: 1 });
  const { scrollDirection } = useScrollDirection();
  const cartPopoverContext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const navOpen = (scrollDirection === 'up' || cartPopoverContext?.isOpen) && inBounds;

  return (
    <div className="defaultTemplate">
      <NavContent
        withCart
        withOOOptions
        navType="stickyNav"
        logoLink={`https://${resources.toastLocalHost}/local`}
        logoLinkSameTab
        logoSrc="/icons/toast-logo-orange.svg"
        className={classnames({ collapsed: !navOpen && inBounds })}
        shouldShowPreviewBanner={false} />
      <RestaurantProfile
        restaurant={restaurantProfileData}
        nearbyRxs={nearbyRxs}
        scrollNavRef={scrollNavRef}
        navOpen={navOpen}
        usingSecondaryNav={inBounds} />
    </div>
  );
};

export default RestaurantProfilePage;
