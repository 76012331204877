import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'
import { AuthHeader } from '@toasttab/guest-authentication-js'

export interface GuestContact {
  email: string
  firstName: string
  lastName: string
}

const confirmGuest = gql`
  mutation confirmGuest($input: ConfirmGuestInput!) {
    confirmGuest(input: $input) {
      __typename
      ... on ConfirmGuestSuccess {
        guest {
          id
        }
        mustRefreshToken
        profileProgress {
          incompleteContactFields
        }
      }
      ... on CompleteGuestProfileInputRequiredError {
        profileProgress {
          incompleteContactFields
        }
        message
      }
      ... on Error {
        message
      }
    }
  }
`

/**
 * Creates a profile in guest-profiles service and updates the Auth0 profile, supports workflows that collect contact information,
 * such as email and names, after confirming the phone number via a verification code using confirmVerificationCode.
 *
 * See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L216
 * for response details.
 *
 * GraphQL playground can also be spun up to view response details.
 * See https://github.com/toasttab/toast-do-federated-gateway#introspection-on-preproduction
 * or https://github.com/toasttab/toast-guest-profiles#graphql for detail.
 *
 * @param source: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L207.
 * @param authHeader: header to add to the graphql context. This should be null for SecureCustomDomainAuthentication
 * @param refershAuthClient: Callback to force an auth token refresh
 * @param apolloClient: The apollo client to be used
 * @param contact: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L152.
 */
export async function confirmPasswordlessMutation(
  source: string,
  authHeader: AuthHeader | null,
  refreshAuthClient: () => Promise<void>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  contact?: GuestContact
) {
  const response = await apolloClient.mutate({
    mutation: confirmGuest,
    variables: {
      input: {
        profile: contact
          ? {
              contact
            }
          : null,
        source
      }
    },
    context: {
      headers: authHeader && {
        [authHeader.key]: authHeader.value
      }
    }
  })

  const data = response.data?.confirmGuest
  if (data?.__typename === 'ConfirmGuestSuccess' && data?.mustRefreshToken) {
    await refreshAuthClient()
  }

  return response
}
