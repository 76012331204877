import React from 'react';

import classnames from 'classnames';

import LoadingSpinner from './LoadingSpinner';

type Props = {
  fullScreen?: boolean;
}


const AdyenPaymentOverlay = (props: Props) => {
  return (
    <div className={classnames('adyenPaymentOverlay', { fullScreen: props.fullScreen }) }
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      data-testid="adyen-payment-overlay">
      <div className="px-8" style={{ paddingBottom: '50px' }}>
        <LoadingSpinner />
      </div>
      <h3 className="text-center font-medium pb-6 text-gray-125 text-2xl">
          Processing Your Payment.
      </h3>
      <p className="text-center text-gray-100">
          Please wait a moment. Leaving this page may cause an error.
      </p>
    </div>
  );
};

export default AdyenPaymentOverlay;
