import React from 'react';

import { ButtonType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';


type Props = {
  errorCode: string
  errorMessage: string
  showCta?: boolean
}
const BaseErrorComponent = ({ errorCode, errorMessage, showCta = true }: Props) => {
  return (
    <div className="errorWrapper">
      <div className="errorContainer">
        <div className="errorCode">{errorCode}</div>
        <p className="errorMessage">{errorMessage}</p>
        {showCta &&
            <div className="backBtn">
              <Button as={'button'} variant={ButtonType.Primary} onClick={() => {
                window?.location?.reload?.();
              }}>
                    Try Again
              </Button>
            </div>}
      </div>
      <div className="errorImage"><Image src={'icons/error_question_mark.svg'} alt="" /></div>
    </div>
  );
};

export default BaseErrorComponent;
