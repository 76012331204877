import React from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import Image, { ImageProps } from 'shared/components/common/Image';

type BaseEditableProps = {
  editable?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<any>, any>; // for restProps

type EditableImageProps = {
  editPath?: string;
} & BaseEditableProps & ImageProps;

export const EditableImage = ({ editable, editPath, className, ...restProps }: EditableImageProps) => {
  const { isEditor } = useEditor();
  const isEditable = editable || isEditor;
  return <Image className={classnames(className, { editable: isEditable })} editPath={editPath} {...restProps} />;
};

export const _Image = (props: EditableImageProps) => <EditableImage {...props} />;
