import React, { useCallback, useEffect, useMemo } from 'react';

import * as Sentry from '@sentry/react';

import { LoyaltySignUpPage, PageConfig, SitePagesDataFragment, useLoyaltySettingsLazyQuery, useLoyaltySignUpLazyQuery } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { SubmitState, useSubmissionState } from 'shared/js/hooks/useSubmissionState';

import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import BetterTogetherPage from 'public/components/pages/better_together/BetterTogetherPage';

type AdditionalPageType = NonNullable<NonNullable<SitePagesDataFragment>['additionalPages']>;
export type LoyaltyPageType = AdditionalPageType[0]['content'] & { __typename: 'LoyaltySignUpPage' };


type LoyaltyContextType = {
  onSubmit: (data: any) => void,
  programDescription: string,
  useEmail: boolean,
  submitState: SubmitState,
  programName: string | null
}

export const LOYALTY_SIGNUP_CONTENT = {
  defaultHeader: 'Sign up for rewards',
  errorLine1: 'We had trouble submitting',
  errorLine2: 'Please try again later',
  successLine1: 'You are signed up for rewards!',
  successLine2: 'You\'ll earn toward rewards with every order, whether online or in person.',
  emailDisclaimer: <>By providing your email, you are agreeing to participate in the rewards program and to be contacted through this email as part of the program.</>,
  phoneDisclaimer:
    <>
      By providing your phone number, you are agreeing to participate in the rewards program and to be contacted
      through this number as part of the program. Rewards program information is subject to{' '}
      <a href="https://pos.toasttab.com/terms-of-service" rel="noopener noreferrer" target="_blank">Toast&apos;s Terms of Service</a> and{' '}
      <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>.
      Message and data rates may apply. Msg frequency varies. Reply STOP to opt out.
    </>
};

export const getSuccessHeader = (programName: string | null) => {
  return programName ? `Welcome to ${programName}!` : LOYALTY_SIGNUP_CONTENT.successLine1;
};

export const useLoyaltyContext = (): LoyaltyContextType => {
  const [geLoyaltySettings, { data: loyaltySettingsData }] = useLoyaltySettingsLazyQuery();
  const [loyaltySignUp, { data: loyaltySignUpResponse }] = useLoyaltySignUpLazyQuery();

  const { restaurant } = useRestaurant();
  const { submitState, startSubmission, endSubmission, failSubmission } = useSubmissionState();


  useEffect(() => {
    if(restaurant.locations && restaurant.locations[0]?.id) {
      geLoyaltySettings({ variables: { restaurantGuid: restaurant.locations[0].externalId } });
    }
  }, [restaurant.locations, geLoyaltySettings]);

  const loyaltySettings: any = useMemo(() => {
    return loyaltySettingsData?.loyaltySettings;
  }, [loyaltySettingsData]);


  useEffect(() => {
    if(loyaltySignUpResponse) {
      if(loyaltySignUpResponse.loyaltySignUp == null) {
        failSubmission();
      }
      endSubmission();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loyaltySignUpResponse]);

  const useEmail: boolean = useMemo(() => loyaltySettings?.signupMethod === 'EMAIL', [loyaltySettings]);
  const programDescription: string = useMemo(() => loyaltySettings?.programDescription, [loyaltySettings]);
  const programName: string = useMemo(() => loyaltySettings?.programName || null, [loyaltySettings]);

  const onSubmit = useCallback(async (data: any) => {
    startSubmission();
    try {
      if(restaurant.locations && restaurant.locations[0]?.externalId) {
        loyaltySignUp({
          variables: {
            restaurantGuid: restaurant.locations[0].externalId,
            phoneNumber: data.phone,
            email: data.email
          }
        });
      }
    } catch(error) {
      failSubmission();
      Sentry.captureMessage(`ERROR: ${error}`);
    }
  }, [restaurant.locations, loyaltySignUp, startSubmission, failSubmission]);

  return {
    onSubmit,
    programDescription,
    useEmail,
    submitState,
    programName
  };
};

type Props = {
  content?: LoyaltySignUpPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
}

const LoyaltyPage = (props: Props) => {
  const {
    onSubmit,
    programDescription,
    useEmail,
    submitState,
    programName
  } = useLoyaltyContext();

  return (
    <BetterTogetherPage
      content={props.content}
      editPathRoot={props.editPathRoot}
      config={props.config}
      onSubmit={onSubmit}
      useEmail={useEmail}
      header={props.content?.headerText || LOYALTY_SIGNUP_CONTENT.defaultHeader}
      subheader={programDescription}
      disclaimer={
        <>
          {useEmail
            ? LOYALTY_SIGNUP_CONTENT.emailDisclaimer
            : LOYALTY_SIGNUP_CONTENT.phoneDisclaimer}
        </>
      }
      {...LOYALTY_SIGNUP_CONTENT}
      submitState={submitState}
      successLine1={getSuccessHeader(programName)} />
  );
};

export default LoyaltyPage;
