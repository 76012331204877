import React from 'react';

import LoyaltyRedemption from 'src/public/components/default_template/online_ordering/checkout/loyalty/LoyaltyRedemption';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import { OORestaurant, useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import OffersSection from './OffersSection';

interface DiscountsSectionProps {
  testId?: string;
}

const DiscountsSection = ({ testId }: DiscountsSectionProps) => {
  const { ooRestaurant, toastProduct } = useRestaurant();
  if(!ooRestaurant) {
    return null;
  }
  return (
    <div data-testid={testId} className="discountsSection">
      <OffersSection />
      {showLoyaltySection(ooRestaurant, toastProduct) && <LoyaltyRedemption />}
    </div>
  );
};

const showLoyaltySection = (ooRestaurant: OORestaurant, toastProduct: ToastProduct) => {
  const loyaltyConfig = ooRestaurant.loyaltyConfig;
  const is3PLoyalty = ooRestaurant.loyaltyConfig?.programName === 'INTEGRATION';
  return loyaltyConfig?.loyaltyRedemptionEnabled && (is3PLoyalty ? [ToastProduct.OOPro, ToastProduct.Sites].includes(toastProduct) : true);
};

export default DiscountsSection;
