import React, { useMemo } from 'react';

interface DepositMessageProps {
  deposit: {
    depositPolicy: string | undefined;
    strategyType: string | undefined;
    amountPerGuest: number | undefined;
    actualAmount: number | undefined;
  };
  numGuests: number;
  serviceArea: string | undefined;
}

const DepositMessage = ({ deposit, numGuests, serviceArea }: DepositMessageProps) => {
  const policyText = useMemo(() => {
    if(deposit.depositPolicy) {
      return deposit.depositPolicy;
    }
    let dollarAmount: string = '';
    if(deposit.strategyType === 'ByPartySize' && deposit.amountPerGuest) {
      dollarAmount = `$${deposit.amountPerGuest % 1 !== 0 ? deposit.amountPerGuest.toFixed(2) : deposit.amountPerGuest} per guest`;
    } else if(deposit.strategyType === 'ByBooking' && deposit.actualAmount) {
      dollarAmount = `$${deposit.actualAmount % 1 !== 0 ? deposit.actualAmount.toFixed(2) : deposit.actualAmount}`;
    }
    return `We require a ${dollarAmount} deposit for all parties of ${numGuests} or more for this time in the ${serviceArea}`;
  }, [deposit, numGuests, serviceArea]);

  return (
    <div>
      <hr className="menuDividingLine menuDividingLineMarginTop" />
      <p className="depositMessage extraPadding">{policyText}
      </p>
      <hr className="menuDividingLine menuDividingLinePaddingBottom" />
    </div>
  );
};

export default DepositMessage;
