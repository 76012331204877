import React, { useMemo } from 'react';

import { ContainsEditableProps, FieldType, ModuleField, useEditor } from '@toasttab/sites-components';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

type TestProps = { testId?: string }

type EditableProps = {
  classNames?: string;
  type: string;
  color?: string;
  outline?: boolean;
  outlineColor?: string;
  opacity?: number;
  editableRef?: any;
} & TestProps & ContainsEditableProps;

const DEFAULT_COLOR = '#ACACAC';
const DEFAULT_OUTLINE_COLOR = '#000000';
const DEFAULT_OPACITY = 100;

export const CircleBlock = ({ color = DEFAULT_COLOR, outlineColor = DEFAULT_OUTLINE_COLOR, outline = false, opacity = DEFAULT_OPACITY, editableRef }: EditableProps) => {
  return (
    <div className={'circleBlockWrapper'} ref={editableRef} aria-label={'Circle shape'}>
      <div
        className={'baseShapeBlock'}
        style={{
          borderRadius: '100%',
          border: `${outline ? 2 : 0}px solid ${outlineColor}`,
          backgroundColor: `${color}`,
          opacity: `${opacity / 100}`
        }}>
      </div>
    </div>
  );
};

export const SquareBlock = ({ color = DEFAULT_COLOR, outlineColor = DEFAULT_OUTLINE_COLOR, outline = false, opacity = DEFAULT_OPACITY, editableRef }: EditableProps) => {
  return (
    <div
      className={'baseShapeBlock'}
      style={{
        border: `${outline ? 2 : 0}px solid ${outlineColor}`,
        backgroundColor: `${color}`,
        opacity: `${opacity / 100}`
      }}
      ref={editableRef}
      aria-label={'Square shape'}>
    </div>
  );
};

export const LeafBlock = ({ color = DEFAULT_COLOR, outlineColor = DEFAULT_OUTLINE_COLOR, outline = false, opacity = DEFAULT_OPACITY, editableRef }: EditableProps) => {
  return (
    <div
      className={'baseShapeBlock'}
      style={{
        border: `${outline ? 2 : 0}px solid ${outlineColor}`,
        backgroundColor: `${color}`,
        opacity: `${opacity / 100}`,
        borderRadius: '100px 100px 100px 0'
      }}
      ref={editableRef}
      aria-label={'Leaf shape'} >
    </div>
  );
};

export const EditableShape = (props: EditableProps) => {
  const { restaurant } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();
  const { meta } = restaurant;
  const { editPath, color, outlineColor, outline, opacity } = props;
  const { useEditableRef } = useEditor();

  const [defaultColor, defaultOutlineColor] = getColorFromTheme(theme => [
    theme.colorScheme.background.primary,
    theme.colorScheme.border.default
  ], [
    meta.primaryColor,
    DEFAULT_OUTLINE_COLOR
  ]);

  const schemaFields: ModuleField[] = useMemo(() => {
    return [
      {
        path: `${editPath}.color`,
        displayName: 'Color',
        type: FieldType.Color,
        value: color || defaultColor,
        defaultValue: defaultColor
      },
      {
        path: `${editPath}.outline`,
        displayName: 'Show outline',
        type: FieldType.Boolean,
        value: outline === undefined ? false : outline
      },
      {
        path: `${editPath}.outlineColor`,
        displayName: 'Outline color',
        type: FieldType.Color,
        value: outlineColor || defaultOutlineColor,
        defaultValue: defaultOutlineColor
      },
      {
        path: `${editPath}.opacity`,
        displayName: 'Shape opacity',
        type: FieldType.Slider,
        value: opacity || DEFAULT_OPACITY,
        defaultValue: DEFAULT_OPACITY,
        validation: { minLen: 0, maxLen: 100, step: 1 }
      }
    ];
  }, [editPath, color, defaultColor, outline, outlineColor, defaultOutlineColor, opacity]);

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'shape block',
    displayName: 'Shape',
    actions: ['delete'],
    path: editPath,
    schema: { fields: schemaFields }
  });

  const configs = {
    ...props,
    color: props.color || defaultColor,
    outlineColor: props.outlineColor || defaultOutlineColor
  };

  switch(props.type) {
    case 'Square': return <SquareBlock editableRef={editableRef} {...configs} />;
    case 'Circle': return <CircleBlock editableRef={editableRef} {...configs} />;
    case 'Leaf': return <LeafBlock editableRef={editableRef} {...configs} />;
    default: return null;
  }
};
