import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

import { Block, DynamicSection, SitePagesByShortUrlQuery, useSiteContentByShortUrlQuery, useSitePagesByShortUrlQuery } from 'src/apollo/sites';

import IFramedCode from 'shared/components/common/embedded_code/IFramedCode';
import { RestaurantRoutesContextProvider, useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

type SitePagesRestaurant = SitePagesByShortUrlQuery['restaurantByShortUrl'] & { __typename: 'Restaurant' };
type RestaurantPage = SitePagesRestaurant['additionalPages'] & { __typename: 'RestaurantPage' };
type TemplatePage = RestaurantPage[0]['content'] & { __typename: 'TemplatePage' };
type Sections = TemplatePage['sections'];

const getBlockFromSections = (blockGuid: string, sections: Sections) => {
  return sections
    ?.filter(section => section.__typename === 'DynamicSection')
    ?.flatMap((dynamicSection: DynamicSection) => dynamicSection.blocks)
    ?.find((block: Block) => block.guid === blockGuid);
};

export const IFramePage = () => {
  const { homePath } = useRestaurantRoutes();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const blockGuid = params.get('blockGuid');
  const path = params.get('path');
  const shortUrl = params.get('shortUrl');
  const heightParam = params.get('height');
  const height = heightParam ? parseInt(heightParam, 10) || null : null;
  const widthParam = params.get('width');
  const width = widthParam ? parseInt(widthParam, 10) || null : null;

  const { data: siteContentData, error: siteDataError, loading: siteDataLoading } = useSiteContentByShortUrlQuery({
    variables: { shortUrl: shortUrl! },
    skip: !shortUrl
  });

  const { data: sitePagesData, error: sitePagesError, loading: sitePagesLoading } = useSitePagesByShortUrlQuery({
    variables: { shortUrl: shortUrl! },
    skip: !shortUrl
  });

  // find the block for this iframe by searching the content of the site's homepage and additional pages
  let block = useMemo(() => {
    if(!blockGuid || !siteContentData?.restaurantByShortUrl || !sitePagesData?.restaurantByShortUrl) return null;

    // This assumes the home path loads the MainPage component, which pulls data from the content.sections attached to the Site.
    // Based on SitesRoutes, and that IFramePage should only be loaded by a Site, this is a safe assumption.
    // All other routes should pull data from the content.sections attached to the additional page with the matching route.
    if(path === homePath && siteContentData.restaurantByShortUrl.content?.sections) {
      return getBlockFromSections(blockGuid, siteContentData?.restaurantByShortUrl?.content?.sections);
    } else if(path) {
      const sitePage = sitePagesData.restaurantByShortUrl.additionalPages?.find(p => p.route === path);
      if(sitePage?.content.__typename === 'TemplatePage') {
        return getBlockFromSections(blockGuid, sitePage.content.sections);
      }
    }

    return null;
  }, [blockGuid, siteContentData, sitePagesData, path, homePath]);

  if(!blockGuid || !height || !width || !block || siteDataError || sitePagesError || siteDataLoading || sitePagesLoading) {
    return null;
  }

  return <IFramedCode code={block.contents.code} parentHeight={height} parentWidth={width} />;
};

const WrappedIFramePage = () =>
  <RestaurantRoutesContextProvider>
    <IFramePage />
  </RestaurantRoutesContextProvider>;

export default WrappedIFramePage;
