import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DeliveryInfoInput, DiningOptionBehavior, FulfillmentType, useRestaurantSchedulesQuery, useUpdateFulfillmentMutation } from 'src/apollo/onlineOrdering';
import { DiningBehavior } from 'src/apollo/sites';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { DiningOptions, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { CartErrorType, useCart } from 'public/components/online_ordering/CartContext';
import { ScheduleType } from 'public/components/online_ordering/types';

import { saveDeliveryInfo, useDelivery } from './DeliveryContext';
import { schedulesByGuid } from './scheduleUtils';

export type FutureFulfillmentDates = NonNullable<NonNullable<NonNullable<DiningOptions>[0]>['futureSchedule']>['dates'];

type CartFulfillmentData = {
  fulfillmentType: FulfillmentType;
  fulfillmentDateTime?: string | null;
  diningOptionBehavior?: DiningOptionBehavior;
  deliveryInfo?: DeliveryInfoInput | null;
};

type FulfillmentSchedules = {
  asapAvailableNow: boolean;
  futureScheduleDates: FutureFulfillmentDates;
};

export type FulfillmentData = {
  cartFulfillmentData: CartFulfillmentData,
  scheduleData: FulfillmentSchedules
};

export type FulfillmentContextType = {
  // Rx config data
  takeoutEnabled: boolean;
  deliveryEnabled: boolean;
  canOrderTakeout: boolean;
  canOrderDelivery: boolean;
  canUpdateFulfillment: boolean;
  uses3pd: boolean;
  // From cart or default values
  fulfillmentData?: FulfillmentData;
  // Data not yet saved to cart
  deliveryAddressNeeded: boolean;
  setDeliveryAddressNeeded: (b: boolean) => void;
  setDefaultDiningOptionBehavior: (behavior: DiningOptionBehavior | null) => void;
  selectedFulfillmentType: FulfillmentType | null;
  setSelectedFulfillmentType: (type: FulfillmentType | null) => void;
  selectedDiningOptionBehavior: DiningOptionBehavior | null;
  setSelectedDiningOptionBehavior: (behavior: DiningOptionBehavior | null) => void;
  selectedDiningOptionBehaviorSchedules: FulfillmentSchedules | null;
  canScheduleSelectedBehavior: boolean;
  diningOptionBehaviorAvailability: {[key: string]: {asapAvailable: boolean, scheduledAvailable: boolean}},
  // Update cart fulfillment data
  updateCartFulfillment: (cartFulfillmentData: CartFulfillmentData) => Promise<boolean>;
  loadingFulfillment: boolean;
  fulfillmentError: CartErrorType | null;
  guestSelectedFulfillmentTime: boolean;
  setGuestSelectedFulfillmentTime: (guestSelectedFulfillmentTime: boolean) => void;
  openFulfillmentTooltip: () => void;
  closeFulfillmentTooltip: () => void;
  fulfillmentTooltipIsOpen: boolean;
  fulfillmentModalOpen: boolean;
  setFulfillmentModalOpen: (open: boolean) => Promise<void>;
};

// Find the day and time from fulfillmentDateTime in the futureScheduleDates and return indexes where each is located.
// Returns nulls if the inputs are not valid.
export const getDateTimeIndexes = (fulfillmentDateTime?: string | null, futureScheduleDates?: FutureFulfillmentDates) => {
  const existingFulfillmentDateTime = fulfillmentDateTime ? parseISO(fulfillmentDateTime) : null;
  if(!existingFulfillmentDateTime || (futureScheduleDates?.length || 0) === 0) {
    return [null, null];
  }

  const date = format(existingFulfillmentDateTime, 'yyyy-MM-dd');
  const dayIndex = futureScheduleDates!.findIndex(d => d.date === date);
  const overnightDayIndex = dayIndex > 0 ? dayIndex - 1 : -1;

  const existingDateTime = existingFulfillmentDateTime.getTime();
  const timeIndex = futureScheduleDates![dayIndex]?.times.findIndex(t => parseISO(t.time).getTime() === existingDateTime) || 0;

  // If we couldn't find the time in today's values, try yesterday for times past midnight but before the cutoff
  const overnightTimeIndex = futureScheduleDates![overnightDayIndex]?.times.findIndex(t => parseISO(t.time).getTime() === existingDateTime) || 0;

  return timeIndex === -1 ? [overnightDayIndex, overnightTimeIndex] : [dayIndex, timeIndex];
};

export const getDiningOptionBehaviorAvailablility = (diningOptions?: DiningOptions) => {
  const takeoutOptions = diningOptions?.find(option => option.behavior === DiningOptionBehavior.TakeOut);
  const deliveryOptions = diningOptions?.find(option => option.behavior === DiningOptionBehavior.Delivery);

  return {
    takeoutEnabled: Boolean(takeoutOptions),
    deliveryEnabled: Boolean(deliveryOptions),
    canOrderTakeout: Boolean(takeoutOptions?.asapSchedule?.availableNow || takeoutOptions?.futureSchedule?.dates?.filter(date => date?.times?.length)?.length),
    canOrderDelivery: Boolean(deliveryOptions?.asapSchedule?.availableNow || deliveryOptions?.futureSchedule?.dates?.filter(date => date?.times?.length)?.length)
  };
};

export const useLocationSchedules = () => {
  const { locations } = useRestaurant();
  const ooClient = useOOClient();
  const locationsGuids = locations?.map(l => l.externalId) || [];
  const { data: scheduleData } = useRestaurantSchedulesQuery({
    variables: { restaurantGuids: locationsGuids },
    skip: !locationsGuids || locationsGuids.length === 0,
    client: ooClient
  });

  return useMemo(() => schedulesByGuid(scheduleData), [scheduleData]) as { [key: string]: ScheduleType };
};

export const use3pdConfig = () => {
  const { tdsDeliveryProvidersApiV2SitesWeb } = useFlags();
  const locationSchedules = useLocationSchedules();
  const { selectedLocation } = useRestaurant();

  // Comes from delivery-providers API V2
  const deliveryServiceAvailability = useMemo(
    () => locationSchedules[selectedLocation.externalId]?.deliveryServiceAvailability?.available ?? false, [locationSchedules, selectedLocation]
  );
  // Comes from delivery-providers API V1
  const availableDeliveryProviders = useMemo(
    () => (locationSchedules[selectedLocation.externalId]?.deliveryProviders?.filter(provider => provider.enabled && !provider.pending)?.length || 0) > 0 || false,
    [locationSchedules, selectedLocation]
  );
  const uses3pd = useMemo(
    () => tdsDeliveryProvidersApiV2SitesWeb ? availableDeliveryProviders : deliveryServiceAvailability,
    [tdsDeliveryProvidersApiV2SitesWeb, deliveryServiceAvailability, availableDeliveryProviders]
  );

  return { uses3pd };
};

export const FULFILLMENT_TOAST_ID = 'fulfillmentToastId';
const FULFILLMENT_TOOLTIP_TEXT = 'Pickup and delivery available!';

export const FulfillmentContext = createContext<FulfillmentContextType | undefined>(undefined);

type EditorContextProps = {
  diningOptionBehavior: DiningOptionBehavior
}

/** A dummy/empty fulfillment context provider that users cannot interact with. */
export const EditorFulfillmentContextProvider = (props: React.PropsWithChildren<EditorContextProps>) => {
  const validDeliveryAddress: DeliveryInfoInput = {
    address1: '123 Main Street',
    city: 'Boston',
    state: 'MA',
    zipCode: '02110',
    latitude: 42.3744917,
    longitude: -71.0634802
  };
  const { diningOptions } = useRestaurant();
  const defaultFulfillment: FulfillmentData = {
    cartFulfillmentData: {
      fulfillmentType: FulfillmentType.Asap,
      diningOptionBehavior: props.diningOptionBehavior,
      deliveryInfo: props.diningOptionBehavior === DiningOptionBehavior.Delivery ? validDeliveryAddress : undefined
    },
    scheduleData: {
      asapAvailableNow: true,
      futureScheduleDates: []
    }
  };
  const deliveryAddressNeeded = false;
  const defaultDiningOptionBehavior: DiningOptionBehavior = props.diningOptionBehavior;
  const selectedFulfillmentType = FulfillmentType.Asap;
  const selectedDiningOptionBehavior = defaultDiningOptionBehavior;
  const loadingFulfillment = false;
  const fulfillmentError = null;

  const diningOptionsByBehavior = useMemo(() => ({
    takeout: diningOptions?.find(opt => opt.behavior === DiningOptionBehavior.TakeOut),
    delivery: diningOptions?.find(opt => opt.behavior === DiningOptionBehavior.Delivery)
  }), [diningOptions]);

  const fulfillmentData = defaultFulfillment;

  const selectedDiningOptionBehaviorSchedules = useMemo(() => ({
    asapAvailableNow:
      (selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ?
        diningOptionsByBehavior.delivery?.asapSchedule?.availableNow :
        diningOptionsByBehavior.takeout?.asapSchedule?.availableNow)
        || false,
    futureScheduleDates:
      (selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ?
        diningOptionsByBehavior.delivery?.futureSchedule?.dates :
        diningOptionsByBehavior.takeout?.futureSchedule?.dates)
        || []
  }), [selectedDiningOptionBehavior, diningOptionsByBehavior]);

  const canScheduleSelectedBehavior = useMemo(() => (selectedDiningOptionBehaviorSchedules?.futureScheduleDates?.length || 0) > 0, [selectedDiningOptionBehaviorSchedules?.futureScheduleDates]);

  return (
    <FulfillmentContext.Provider value={{
      ...getDiningOptionBehaviorAvailablility(diningOptions),
      canUpdateFulfillment: true,
      fulfillmentData,
      deliveryAddressNeeded,
      uses3pd: false,
      setDeliveryAddressNeeded: () => undefined,
      setDefaultDiningOptionBehavior: () => undefined,
      selectedFulfillmentType,
      setSelectedFulfillmentType: () => undefined,
      selectedDiningOptionBehavior,
      setSelectedDiningOptionBehavior: () => undefined,
      selectedDiningOptionBehaviorSchedules,
      canScheduleSelectedBehavior,
      diningOptionBehaviorAvailability: {},
      updateCartFulfillment: () => Promise.resolve(true),
      loadingFulfillment,
      fulfillmentError,
      guestSelectedFulfillmentTime: true,
      setGuestSelectedFulfillmentTime: () => undefined,
      openFulfillmentTooltip: () => undefined,
      closeFulfillmentTooltip: () => undefined,
      fulfillmentTooltipIsOpen: false,
      fulfillmentModalOpen: false,
      setFulfillmentModalOpen: () => Promise.resolve(undefined)
    }}>
      {props.children}
    </FulfillmentContext.Provider>
  );
};

export const FulfillmentContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { validDeliveryAddress } = useDelivery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { diningOptions, restaurant, refetchOORestaurant } = useRestaurant();
  const { cart, cartGuid, refetchCart } = useCart();
  const [defaultFulfillment, setDefaultFulfillment] = useState<FulfillmentData>();
  const [deliveryAddressNeeded, setDeliveryAddressNeeded] = useState(false);
  const [defaultDiningOptionBehavior, setDefaultDiningOptionBehavior] = useState<DiningOptionBehavior | null>(null);
  const [selectedFulfillmentType, setSelectedFulfillmentType] = useState<FulfillmentType | null>(null);
  const [selectedDiningOptionBehavior, setSelectedDiningOptionBehavior] = useState<DiningOptionBehavior | null>(null);
  const [updateFulfillmentMutation] = useUpdateFulfillmentMutation();
  const [loadingFulfillment, setLoadingFulfillment] = useState(false);
  const [fulfillmentError, setFulfillmentError] = useState<CartErrorType | null>(null);
  const [guestSelectedFulfillmentTime, setGuestSelectedFulfillmentTime] = useState(false); // has the guest actively selected a fulfillment time?
  const { uses3pd } = use3pdConfig();
  const [fulfillmentModalOpen, _setFulfillmentModalOpen] = useState(false);
  const setFulfillmentModalOpen = useCallback(async (open: boolean) => {
    // refetch the rx in case something has changed (e.g. some times in futureScheduleDates may be outdated)
    await refetchOORestaurant();
    _setFulfillmentModalOpen(open);
  }, [refetchOORestaurant, _setFulfillmentModalOpen]);

  useEffect(() => {
    if(validDeliveryAddress) {
      setDeliveryAddressNeeded(false);
    }
  }, [validDeliveryAddress]);

  useEffect(() => {
    // By the time the user has an item in their cart, they've acknowledged their fulfillment time
    if(cartGuid) {
      setGuestSelectedFulfillmentTime(true);
    }
  }, [cartGuid, setGuestSelectedFulfillmentTime]);

  const diningOptionsByBehavior = useMemo(() => ({
    takeout: diningOptions?.find(opt => opt.behavior === DiningOptionBehavior.TakeOut),
    delivery: diningOptions?.find(opt => opt.behavior === DiningOptionBehavior.Delivery)
  }), [diningOptions]);

  const canUpdateFulfillment = useMemo(() =>
    // Allow updaing fulfillment if any of the editable fields in the fulfillement modal can be changed.
    // This should be true if the following are editable:
    // 1) You can edit the future takeout time
    // 2) You can edit the future delivery time
    // 3) You can switch between takeout and delivery
    // 4) You can edit the delivery address
    Boolean(
      diningOptionsByBehavior.takeout?.futureSchedule?.dates?.length
      || diningOptionsByBehavior.delivery?.futureSchedule?.dates?.length
      || diningOptionsByBehavior.delivery?.asapSchedule?.availableNow
    ), [diningOptionsByBehavior]);

  const paramDiningOption = queryParams.get('diningOption') as DiningBehavior | null;

  const rxDefaultDiningBehavior =
    paramDiningOption ?
      diningOptionsByBehavior[paramDiningOption]
      : restaurant.config.ooConfig?.defaultDiningBehavior ?
        diningOptionsByBehavior[restaurant.config.ooConfig?.defaultDiningBehavior]
        : null;

  // set default values based on query params, restaurant defaults, or Rx data
  useEffect(() => {
    if(rxDefaultDiningBehavior) {
      if(rxDefaultDiningBehavior.asapSchedule?.availableNow) {
        setDefaultFulfillment({
          cartFulfillmentData: {
            fulfillmentType: FulfillmentType.Asap,
            diningOptionBehavior: rxDefaultDiningBehavior.behavior,
            deliveryInfo: rxDefaultDiningBehavior.behavior === DiningOptionBehavior.Delivery ? validDeliveryAddress : undefined
          },
          scheduleData: {
            asapAvailableNow: true,
            futureScheduleDates: rxDefaultDiningBehavior.futureSchedule?.dates || []
          }
        });
        return;
      }

      const defaultDateTime = rxDefaultDiningBehavior.futureSchedule?.dates?.find(date => date?.times?.length)?.times?.[0];
      if(defaultDateTime) {
        setDefaultFulfillment({
          cartFulfillmentData: {
            fulfillmentType: FulfillmentType.Future,
            fulfillmentDateTime: defaultDateTime.time,
            diningOptionBehavior: rxDefaultDiningBehavior.behavior,
            deliveryInfo: rxDefaultDiningBehavior.behavior === DiningOptionBehavior.Delivery ? validDeliveryAddress : undefined
          },
          scheduleData: {
            asapAvailableNow: rxDefaultDiningBehavior.asapSchedule?.availableNow || false,
            futureScheduleDates: rxDefaultDiningBehavior.futureSchedule!.dates
          }
        });
        return;
      }
    }

    if(diningOptionsByBehavior.takeout?.asapSchedule?.availableNow) {
      setDefaultFulfillment({
        cartFulfillmentData: {
          fulfillmentType: FulfillmentType.Asap,
          diningOptionBehavior: DiningOptionBehavior.TakeOut
        },
        scheduleData: {
          asapAvailableNow: true,
          futureScheduleDates: diningOptionsByBehavior.takeout.futureSchedule?.dates || []
        }
      });
      return;
    }

    if(diningOptionsByBehavior.delivery?.asapSchedule?.availableNow) {
      setDefaultFulfillment({
        cartFulfillmentData: {
          fulfillmentType: FulfillmentType.Asap,
          diningOptionBehavior: DiningOptionBehavior.Delivery,
          deliveryInfo: validDeliveryAddress
        },
        scheduleData: {
          asapAvailableNow: true,
          futureScheduleDates: diningOptionsByBehavior.delivery.futureSchedule?.dates || []
        }
      });
      return;
    }

    const takeoutDefaultDateTime = diningOptionsByBehavior.takeout?.futureSchedule?.dates?.find(date => date?.times?.length)?.times?.[0];
    if(takeoutDefaultDateTime) {
      setDefaultFulfillment({
        cartFulfillmentData: {
          fulfillmentType: FulfillmentType.Future,
          fulfillmentDateTime: takeoutDefaultDateTime.time,
          diningOptionBehavior: DiningOptionBehavior.TakeOut
        },
        scheduleData: {
          asapAvailableNow: diningOptionsByBehavior.takeout!.asapSchedule?.availableNow || false,
          futureScheduleDates: diningOptionsByBehavior.takeout!.futureSchedule!.dates
        }
      });
      return;
    }

    const deliveryDefaultDateTime = diningOptionsByBehavior.delivery?.futureSchedule?.dates?.find(date => date?.times?.length)?.times?.[0];
    if(deliveryDefaultDateTime) {
      setDefaultFulfillment({
        cartFulfillmentData: {
          fulfillmentType: FulfillmentType.Future,
          fulfillmentDateTime: deliveryDefaultDateTime.time,
          diningOptionBehavior: DiningOptionBehavior.Delivery,
          deliveryInfo: validDeliveryAddress
        },
        scheduleData: {
          asapAvailableNow: diningOptionsByBehavior.delivery!.asapSchedule?.availableNow || false,
          futureScheduleDates: diningOptionsByBehavior.delivery!.futureSchedule!.dates
        }
      });
      return;
    }
  // only reset default data when the options change (i.e. the Rx changes)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diningOptionsByBehavior, validDeliveryAddress]);

  // Returns false if an error occurred, otherwise true
  const updateCartFulfillment = useCallback(async (cartFulfillmentData: CartFulfillmentData) => {
    setFulfillmentError(null);
    setDeliveryAddressNeeded(false);

    if(cartGuid) {
      setLoadingFulfillment(true);

      let response : Awaited<ReturnType<typeof updateFulfillmentMutation>>;

      try {
        response = await updateFulfillmentMutation({ variables: { input: { cartGuid, cartFulfillmentInput: cartFulfillmentData } } });
      } catch(error) {
        setLoadingFulfillment(false);
        throw error;
      }

      refetchCart();
      setLoadingFulfillment(false);

      if(response.data?.updateFulfillmentAndValidate.__typename === 'CartModificationError' || response.data?.updateFulfillmentAndValidate.__typename === 'CartOutOfStockError') {
        setFulfillmentError({
          kind: response.data?.updateFulfillmentAndValidate.__typename,
          message: response.data?.updateFulfillmentAndValidate.message
        });
        return false;
      }
    } else {
      setDefaultFulfillment({
        cartFulfillmentData,
        scheduleData: {
          asapAvailableNow:
            (cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ?
              diningOptionsByBehavior.delivery?.asapSchedule?.availableNow :
              diningOptionsByBehavior.takeout?.asapSchedule?.availableNow)
            || false,
          futureScheduleDates:
            (cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ?
              diningOptionsByBehavior.delivery?.futureSchedule?.dates :
              diningOptionsByBehavior.takeout?.futureSchedule?.dates)
            || []
        }
      });
    }

    // update local storage with the cart's delivery info
    if(cartFulfillmentData.deliveryInfo && cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery) {
      saveDeliveryInfo(cartFulfillmentData.deliveryInfo, restaurant.externalId);
    }

    return true;
  }, [cartGuid, setDefaultFulfillment, updateFulfillmentMutation, setLoadingFulfillment, setFulfillmentError, refetchCart, diningOptionsByBehavior, restaurant.externalId]);

  const fulfillmentData = useMemo(() => {
    const fulfillment = cart ?
      {
        cartFulfillmentData: {
          fulfillmentType: cart.fulfillmentType,
          fulfillmentDateTime: cart.fulfillmentDateTime,
          diningOptionBehavior: cart.diningOptionBehavior,
          deliveryInfo: cart.order?.deliveryInfo as DeliveryInfoInput
        },
        scheduleData: {
          asapAvailableNow:
            (cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
              diningOptionsByBehavior.delivery?.asapSchedule?.availableNow :
              diningOptionsByBehavior.takeout?.asapSchedule?.availableNow)
              || false,
          futureScheduleDates:
            (cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
              diningOptionsByBehavior.delivery?.futureSchedule?.dates :
              diningOptionsByBehavior.takeout?.futureSchedule?.dates)
              || []
        }
      } :
      defaultFulfillment;

    // When the Rx location and fulfillment are updated at the same time,
    // defaultDiningOptionBehavior allows the behavior to be set after the Rx and cart
    // have updated
    if(fulfillment && defaultDiningOptionBehavior) {
      fulfillment.cartFulfillmentData.diningOptionBehavior = defaultDiningOptionBehavior;
      fulfillment.scheduleData = {
        asapAvailableNow:
          (defaultDiningOptionBehavior === DiningOptionBehavior.Delivery ?
            diningOptionsByBehavior.delivery?.asapSchedule?.availableNow :
            diningOptionsByBehavior.takeout?.asapSchedule?.availableNow)
            || false,
        futureScheduleDates:
          (defaultDiningOptionBehavior === DiningOptionBehavior.Delivery ?
            diningOptionsByBehavior.delivery?.futureSchedule?.dates :
            diningOptionsByBehavior.takeout?.futureSchedule?.dates)
            || []
      };
      setDefaultDiningOptionBehavior(null);
    }

    return fulfillment;
  // only update when the cart is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, defaultFulfillment]);

  // Reset the selected behavior and fulfillment type when the cart is updated
  useEffect(() => setSelectedDiningOptionBehavior(fulfillmentData?.cartFulfillmentData.diningOptionBehavior || null), [fulfillmentData?.cartFulfillmentData.diningOptionBehavior]);
  useEffect(() => setSelectedFulfillmentType(fulfillmentData?.cartFulfillmentData.fulfillmentType || null), [fulfillmentData?.cartFulfillmentData.fulfillmentType]);

  const selectedDiningOptionBehaviorSchedules = useMemo(() => ({
    asapAvailableNow:
      (selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ?
        diningOptionsByBehavior.delivery?.asapSchedule?.availableNow :
        diningOptionsByBehavior.takeout?.asapSchedule?.availableNow)
        || false,
    futureScheduleDates:
      (selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ?
        diningOptionsByBehavior.delivery?.futureSchedule?.dates :
        diningOptionsByBehavior.takeout?.futureSchedule?.dates)
        || []
  }), [selectedDiningOptionBehavior, diningOptionsByBehavior]);

  const canScheduleSelectedBehavior = useMemo(() => (selectedDiningOptionBehaviorSchedules?.futureScheduleDates?.length || 0) > 0, [selectedDiningOptionBehaviorSchedules?.futureScheduleDates]);

  const diningOptionBehaviorAvailability = useMemo(() => {
    return {
      delivery: {
        asapAvailable: Boolean(diningOptionsByBehavior?.delivery?.asapSchedule?.availableNow),
        scheduledAvailable: Boolean(diningOptionsByBehavior?.delivery?.futureSchedule?.dates?.length)
      },
      takeout: {
        asapAvailable: Boolean(diningOptionsByBehavior?.takeout?.asapSchedule?.availableNow),
        scheduledAvailable: Boolean(diningOptionsByBehavior?.takeout?.futureSchedule?.dates?.length)
      }
    };
  }, [diningOptionsByBehavior]);

  const [fulfillmentTooltipIsOpen, setFulfillmentTooltipIsOpen] = useState(false);
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);

  const openFulfillmentTooltip = useCallback(() => {
    if(isMobile) {
      toast(FULFILLMENT_TOOLTIP_TEXT, { autoClose: false, toastId: FULFILLMENT_TOAST_ID, bodyClassName: 'fulfillmentTooltip' });
    }
    setFulfillmentTooltipIsOpen(true);
  }, [isMobile]);

  const closeFulfillmentTooltip = useCallback(() => {
    if(isMobile) {
      toast.dismiss(FULFILLMENT_TOAST_ID);
    }
    setFulfillmentTooltipIsOpen(false);
  }, [isMobile]);

  return (
    <FulfillmentContext.Provider value={{
      ...getDiningOptionBehaviorAvailablility(diningOptions),
      canUpdateFulfillment,
      fulfillmentData,
      deliveryAddressNeeded,
      uses3pd,
      setDeliveryAddressNeeded,
      setDefaultDiningOptionBehavior,
      selectedFulfillmentType,
      setSelectedFulfillmentType,
      selectedDiningOptionBehavior,
      setSelectedDiningOptionBehavior,
      selectedDiningOptionBehaviorSchedules,
      canScheduleSelectedBehavior,
      diningOptionBehaviorAvailability,
      updateCartFulfillment,
      loadingFulfillment,
      fulfillmentError,
      guestSelectedFulfillmentTime,
      setGuestSelectedFulfillmentTime,
      openFulfillmentTooltip,
      closeFulfillmentTooltip,
      fulfillmentTooltipIsOpen,
      fulfillmentModalOpen,
      setFulfillmentModalOpen
    }}>
      {props.children}
    </FulfillmentContext.Provider>
  );
};

export const useFulfillment = () => {
  const context = useContext(FulfillmentContext);
  if(!context) {
    throw new Error('useFulfillment must be used within a FulfillmentContextProvider');
  }

  return context;
};

export const useOptionalFulfillment = () => {
  return useContext(FulfillmentContext);
};
