import React, { useState } from 'react';

import classnames from 'classnames';

import DropdownArrow from './DropdownArrow';

type ConsumerProps = {
  close: () => void;
}


type Props = {
  disabled?: boolean;
  opener?: (toggle: (e: React.SyntheticEvent) => void) => JSX.Element;
  label: React.ReactNode;
  className?: string;
  withBorder?: boolean;
  children: ((t: ConsumerProps) => React.ReactNode) | React.ReactNode;
  leftAligned?: boolean;
  hideArrow?: boolean;
  style?: React.CSSProperties;
  describedBy?: string;
  testId?: string
  labelledBy?: string;
  ariaDescription?: string;
}

const DropDown = (props: Props) => {
  const [expanded, setExpanded] = useState(false);


  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.key === 'Enter' || e.key === ' ') {
      toggle(e);
    }
  };

  const toggle = (e: React.SyntheticEvent) => {
    if(props.disabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    setExpanded(expanded => !expanded);
  };
  const close = () => setExpanded(false);
  const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // ignore clicks inside of the menu
    if(!event.currentTarget.contains(event.relatedTarget as Node)) {
      setExpanded(false);
    }
  };

  return (
    <div
      className={classnames('dropDown', props.className, {
        withBorder: props.withBorder,
        disabled: props.disabled
      })}
      {...props.describedBy ? { 'aria-describedby': props.describedBy } : {}}
      {...props.labelledBy ? { 'aria-labelledby': props.labelledBy } : {}}
      {...props.ariaDescription ? { 'aria-description': props.ariaDescription } : {}}
      role="combobox"
      aria-haspopup={!!props.disabled}
      aria-expanded={expanded}
      data-testid={props.testId ?? 'dropdown-selector'}
      tabIndex={0}
      onKeyDown={handleKeyDown} onBlur={onBlur} onClick={toggle}>

      {props.opener && props.opener(toggle) ||
                <>
                  <div className="dropDownLabel" onClick={toggle} style={props.style}>{props.label}</div>
                  {!props.hideArrow && <div onClick={toggle} className="arrow"><DropdownArrow /></div>}
                </>}
      <div
        className={classnames('dropDownContent', !expanded && 'hide', props.leftAligned && 'leftAligned')}
        data-testid={props.testId ?? 'dropdown-content'}
        onClick={e => {
          e.stopPropagation();
          close();
        }}
        role="listbox">
        {typeof props.children === 'function' ? props.children({ close }) : props.children}
      </div>
    </div>
  );
};

export default DropDown;
