import React, { useCallback } from 'react';

import { PhoneIcon } from '@toasttab/buffet-pui-icons';
import classNames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import useTracker from 'src/lib/js/hooks/useTracker';

import Button from 'shared/components/common/button';

import { AlertIcon } from 'public/components/default_template/online_ordering/order_tracker/order_status_bar/OrderStatusIcons';
import {
  statusToCopy,
  statusToIcons,
  UnifiedOrderStatus
} from 'public/components/default_template/online_ordering/order_tracker/order_status_bar/statusUtil';


export type OrderStatusBarProps = {
  orderStatus: UnifiedOrderStatus,
  diningOptionBehavior: DiningOptionBehavior,
  curbside: boolean,
  restaurantName: string,
  driverName?: string,
  driverNumber?: string,
  restaurantNumber: string,
  pickupInstructions?: string | null,
  completedDate?: Date
}

const OrderStatusBar = ({ orderStatus, diningOptionBehavior, curbside, restaurantName, driverName, pickupInstructions, driverNumber, restaurantNumber, completedDate } : OrderStatusBarProps) => {
  const tracker = useTracker();
  const statusCopy = statusToCopy(orderStatus, diningOptionBehavior, restaurantName, pickupInstructions, driverName, completedDate, curbside);
  const showAlert = orderStatus === UnifiedOrderStatus.DELIVERY_FAILED || orderStatus === UnifiedOrderStatus.CANCELLED;
  const showRxContact = orderStatus === UnifiedOrderStatus.CANCELLED || orderStatus === UnifiedOrderStatus.DELIVERY_FAILED || orderStatus === UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
  const showDriverContact = orderStatus === UnifiedOrderStatus.UNDELIVERABLE;

  const icons = statusToIcons(orderStatus, diningOptionBehavior, curbside);

  const onRxContactClick = useCallback(() => {
    tracker.track('TDS Rx Contact Clicked');
    window.open(`tel:${restaurantNumber}`);
  }, [tracker, restaurantNumber]);

  const onDriverContactClick = useCallback(() => {
    tracker.track('TDS Driver Contact Clicked');
    window.open(`tel:${driverNumber}`);
  }, [tracker, driverNumber]);

  return (
    statusCopy &&
    <div data-testid="order-status">
      <h4 data-testid="order-status-header" className="orderStatusHeader">
        {showAlert && <div data-testid="order-status-alert" style={{ marginRight: '4px' }}><AlertIcon full /></div>}
        {statusCopy.title}
      </h4>
      {icons.length > 0 && <div data-testid="order-status-bar" className="orderStatusBarWrapper">{icons.map(component => component)}</div>}
      <div data-testid="order-status-footer" className="orderStatusFooter">{statusCopy.subtitle}</div>
      {showRxContact && <ContactButton testId="contact-rx-button" label="Contact restaurant" onClick={onRxContactClick} />}
      {showDriverContact && <ContactButton testId="contact-driver-button" label="Contact driver" onClick={onDriverContactClick} />}
    </div>
  );
};

const ContactButton = ({ testId, label, onClick } : {testId: string, label: string, onClick: () => any}) =>
  <Button
    data-testid={testId}
    className="contactButton"
    onClick={onClick}
    variant={ButtonType.Primary}
    iconRight={<PhoneIcon />}>
    {label}
  </Button>;

type StageDividerProps = {
  inProgress?: boolean,
  upcoming?: boolean
}

export const StageDivider = ({ inProgress, upcoming }: StageDividerProps) =>
  <div className={classNames('statusStageDivider', { inProgress: inProgress, upcoming: upcoming })} />;


export default OrderStatusBar;
