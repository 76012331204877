import * as yup from 'yup';

export const profileInformationSchema = yup.object({
  firstName: yup.string().trim()
    .max(255)
    .required(),
  lastName: yup.string().trim()
    .max(255)
    .required(),
  email: yup.string().trim()
    .required(),
  phone: yup.string().trim()
    .required()
}).required();
