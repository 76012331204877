import React from 'react';

import classnames from 'classnames';
import queryString from 'query-string';

import { toBase64 } from 'src/lib/js/base64';

import Image from 'shared/components/common/Image';

import { resources } from 'config';

type CusineType = {
  name: string,
  value: string,
  icon: string,
  image: string
};

const cuisineSearchUrl = (cuisine: CusineType) => `https://${resources.toastLocalHost}/local/search?${queryString.stringify({ filters: toBase64(JSON.stringify({ cuisine: cuisine.value })) })}`;

type CuisineCardProps = {
  cuisine: CusineType,
  className: string
};

const CuisineCard = ({ cuisine, className }: CuisineCardProps) => {
  const ccImage = cuisine.image;
  return (
    <div className={classnames('cuisine-card', className)}>
      <div className="cuisine-card-content">
        <a href={cuisineSearchUrl(cuisine)}>
          <div className="cuisine-image-wrapper">
            {ccImage && <Image src={ccImage} className="cuisine-image" alt={`${cuisine.name} food`} />}
          </div>

          <h3 className="cuisine-title">{cuisine.name}</h3>
        </a>
      </div>
    </div>

  );
};

export default CuisineCard;
