import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink
} from '@apollo/client'
import {
  lazyInitAuthClient,
  AuthClient
} from '@toasttab/guest-authentication-js'

import {
  ToastEnvironment
} from '@toasttab/authentication-utils'
import packageJson from '../../package.json'

import fetch from 'cross-fetch'

export let authClient: AuthClient
export let apolloClient: ApolloClient<NormalizedCacheObject>

const getSessionId = () => {
  try {
    const tokenObj = typeof document !== 'undefined' ? document.getElementById('session')?.getAttribute('data-content') : null;

    return tokenObj ? JSON.parse(atob(tokenObj))?.id : undefined;
  } catch (e) {
    return undefined;
  }
}

export function initGuestAuthentication(
  gatewayOrigin: string,
  guestSessionResumed: () => void,
  toastEnv?: ToastEnvironment
): AuthClient {
  authClient = lazyInitAuthClient({
    whenUserSessionHasResumed: () => {
      if (!isProfileCreated(authClient)) {
        return
      }

      guestSessionResumed()
    },
    toastEnv
  })

  apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    name: packageJson.name,
    version: packageJson.version,
    link: new HttpLink({ uri: `${gatewayOrigin}/do-federated-gateway/v1/graphql`, fetch }),
    headers: {
      'Toast-Session-ID': getSessionId()
    }
  })
  
  return authClient
}

/**
 * This value is populated from guest-profiles, and will either be:
 *   `true` - guest has a profile
 *   `false` - guest does not have a profile
 *   `undefined` - from before we started setting this value, before “startIdentityProfile/completeIdentityProfile” endpoints existed.
 *
 * Edge case: If the value is `undefined`, there is a chance that they don't actually have a profile
 * if they never verified their original profile, thus letting it expire.
 * This should impact a small enough amount of users that it's ok to still always assume
 * a value of `undefined` is equivalent to having a profile.
 */
export function isProfileCreated(authClient: AuthClient) {
  return (
    authClient.userInfo?.isProfileCreated === true ||
    (authClient.userInfo && authClient.userInfo?.isProfileCreated === undefined)
  )
}
