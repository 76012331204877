import React, { useEffect, useState } from 'react';

import { OnReservationCancellation, useReservationCancellation } from './reservationUtils';

type TimeLeft = {
  hours: number
  minutes: number
  seconds: number
}

type CountdownProps = {
  expirationDate: Date
  reservationGuid: string
  restaurantGuid: string
  step: number
  onCancellation: OnReservationCancellation
}

const formatTime = (time: number) => {
  return time.toString().padStart(2, '0');
};

const calculateTimeLeft = (expirationDate: Date) => {
  const difference =
    expirationDate.getTime() - new Date().getTime();

  return {
    hours: Math.max(Math.floor(difference / (1000 * 60 * 60) % 24), 0),
    minutes: Math.max(Math.floor(difference / 1000 / 60 % 60), 0),
    seconds: Math.max(Math.floor(difference / 1000 % 60), 0)
  };
};

export const Countdown = ({
  expirationDate,
  reservationGuid,
  restaurantGuid,
  step,
  onCancellation
}: CountdownProps) => {
  const { cancel } = useReservationCancellation(reservationGuid, restaurantGuid, onCancellation, true);

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(expirationDate));
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(expirationDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [setTimeLeft, expirationDate]);

  useEffect(() => {
    if(
      step === 6 &&
      !isExpired &&
      timeLeft.hours <= 0 &&
      timeLeft.minutes <= 0 &&
      timeLeft.seconds <= 0
    ) {
      cancel();
      setIsExpired(true);
    }
  }, [cancel, restaurantGuid, timeLeft, isExpired, step]);

  const { hours, minutes, seconds } = timeLeft;

  const showHours = hours !== 0;

  return (
    <span data-testid="countdown-timer">
      {showHours ? `${formatTime(hours)}:` : undefined}
      {formatTime(minutes)}:{formatTime(seconds)}
    </span>
  );
};
