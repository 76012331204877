import React from 'react';

import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import DropDown from 'shared/components/common/dropdown';

export const formatGuestLabel = (num: number): string => num === 1 ? '1 person' : `${num} people`;

type Props = {
  maxPartySize: number;
  minPartySize: number;
  numGuests: number;
  setNumGuests: any;
};

const GuestSelector = ({ maxPartySize, minPartySize, numGuests, setNumGuests }: Props) => {
  const options = Array.from({ length: maxPartySize - minPartySize + 1 }, (_, i) => i + minPartySize);

  const dropDownLabel = <div className="selected">{formatGuestLabel(numGuests)}</div>;

  return (
    <div className="guestSelector" data-testid="guest-selector">
      <div className="dropDownWrapper">
        <DropDown label={dropDownLabel} withBorder leftAligned>
          {({ close }) =>
            options.map(guestCount => {
              return (
                <DropDownOption
                  key={guestCount}
                  testId={`guest-selector-option-${guestCount}`}
                  onSelect={() => {
                    close();
                    setNumGuests(guestCount);
                  }}>
                  {formatGuestLabel(guestCount)}
                </DropDownOption>
              );
            })}
        </DropDown>
      </div>
    </div>
  );
};

export default GuestSelector;
