import { useState } from 'react';

export class SubmitState {
  public static NONE = new SubmitState('SUBMISSION NOT STARTED');
  public static IN_PROGRESS = new SubmitState('SUBMISSION IN PROGRESS');
  public static DONE = new SubmitState('SUBMISSION DONE');
  public static FAILED = new SubmitState('SUBMISSION FAILED');
  constructor(private value: string) {}
  public name() { return this.value; }
  public toString() { return this.name(); }
  public isInProgress() { return this === SubmitState.IN_PROGRESS; }
  public isDone() { return this === SubmitState.DONE; }
  public isFailed() { return this === SubmitState.FAILED; }
}

export type SubmissionStatesType = {
  submitState: SubmitState,
  startSubmission: () => void,
  endSubmission: () => void,
  failSubmission: () => void,
  resetSubmission: () => void
}

export const useSubmissionState = (): SubmissionStatesType => {
  const [currentState, setSubmitState] = useState(SubmitState.NONE);

  return {
    submitState: currentState,
    startSubmission: () => setSubmitState(SubmitState.IN_PROGRESS),
    endSubmission: () => setSubmitState(SubmitState.DONE),
    failSubmission: () => setSubmitState(SubmitState.FAILED),
    resetSubmission: () => setSubmitState(SubmitState.NONE)
  };
};
