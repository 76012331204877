import { useEffect, useState } from 'react';

const useGeocoder = () => {
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);

  useEffect(() => {
    if(typeof google !== 'undefined') {
      setGeocoder(new google.maps.Geocoder());
    }
  }, []);

  return () => {
    if(geocoder) {
      return geocoder;
    }

    // In case this hook runs before the APIs get initialized, manually return a new geocoder
    return new google.maps.Geocoder();
  };
};

export default useGeocoder;
