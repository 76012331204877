import React from 'react';
import { ScrollElement } from 'react-scroll';

import classNames from 'classnames';

type ElementProps = {
  name: string
  id?: string
}

const CarouselElement = ({ parentBindings, ...props }: any & ElementProps) => {
  return (
    <li {...props} ref={el => { parentBindings.domNode = el; }} className={classNames('no-list-style', props.className)}>
      {props.children}
    </li>
  );
};

export default ScrollElement(CarouselElement);
