import { apolloClient, authClient } from '../initialization'
import { startPasswordlessMutation } from './start-passwordless-mutation'

/**
 * Starts the passwordless authentication process by initializing a profile in Auth0 using
 * the input phone number and requests a verification code to be sent to that phone number.
 *
 * See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L120
 * for response details.
 *
 * GraphQL playground can also be spun up to view response details.
 * See https://github.com/toasttab/toast-do-federated-gateway#introspection-on-preproduction
 * or https://github.com/toasttab/toast-guest-profiles#graphql for detail.
 *
 * @param phoneNumber: Used to initialize a profile in Auth0 and receive the verification code.
 * @param source: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L207.
 */
export async function startPasswordless(phoneNumber: string, source: string) {
  return await startPasswordlessMutation(
    phoneNumber,
    source,
    requestVerificationCode,
    apolloClient
  )
}

/**
 * Requests a verification code to be sent to the input phone number without setting up an Auth0 profile,
 * supports workflows such as "Resend Code".
 * This functionality requires a profile to be set up in Auth0, previously done via startPasswordless.
 *
 * @param phoneNumber: Receives the verification code.
 */
export async function requestVerificationCode(phoneNumber: string) {
  return authClient.requestPasswordless(phoneNumber)
}
