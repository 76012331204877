import React, { useCallback, useRef, useState } from 'react';

export const scrollToRef = (ref: React.RefObject<HTMLDivElement>, isEditor: boolean = false) => {
  if(isEditor) {
    const container = document.getElementById('editor-wrapper');
    if(container) {
      container.scrollTo({ top: ref.current?.getBoundingClientRect().top || 0, behavior: 'smooth' });
    }
  } else if(typeof window !== 'undefined' && !!ref.current) {
    window.scrollTo({
      top: window.scrollY + (ref.current.getBoundingClientRect().y || 0) - window.innerHeight / 3,
      behavior: 'smooth'
    });
    // preventScroll here is so that it does not stop the smooth scroll above
    ref.current.focus({ preventScroll: true });
  }
};

export const useHorizontalScroll = () => {
  const { scroll, scrollEvent, scrollContainerRef, showFirstArrow: showLeftArrow, showSecondArrow: showRightArrow } = useScroll(true);
  return { scroll, scrollEvent, scrollContainerRef, showLeftArrow, showRightArrow };
};

export const useVerticalScroll = () => {
  const { scroll, scrollEvent, scrollContainerRef, showFirstArrow: showUpArrow, showSecondArrow: showDownArrow } = useScroll(false);
  return { scroll, scrollEvent, scrollContainerRef, showUpArrow, showDownArrow };
};

export enum ScrollDirection {
  Forwards = 1,
  Backwards = -1,
}

const useScroll = (horizontal: boolean) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showFirstArrow, setShowFirstArrow] = useState(false);
  const [showSecondArrow, setShowSecondArrow] = useState(false);

  const scroll = useCallback((direction: ScrollDirection) => () => {
    if(scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ [horizontal ? 'left' : 'top']: 100 * direction, behavior: 'smooth' });
    }
  }, [scrollContainerRef, horizontal]);

  const scrollEvent = useCallback(() => {
    if(scrollContainerRef.current) {
      const ref = scrollContainerRef.current;
      const position = ref[horizontal ? 'scrollLeft' : 'scrollTop'];
      if(position === 0) {
        setShowFirstArrow(false);
      } else {
        setShowFirstArrow(true);
      }

      if(Math.abs(position - (ref[horizontal ? 'scrollWidth' : 'scrollHeight'] - ref.getBoundingClientRect()[horizontal ? 'width' : 'height'])) <= 1) {
        setShowSecondArrow(false);
      } else {
        setShowSecondArrow(true);
      }
    }
  }, [scrollContainerRef, horizontal]);

  return { scroll, scrollEvent, scrollContainerRef, showFirstArrow, showSecondArrow };
};
