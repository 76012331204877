import React from 'react';

import Image from 'shared/components/common/Image';

const Mission = () => {
  return (
    <div className="mission">
      <Image className="missionImg" src="toastLocal/splash/driver-picking-up-delivery-order-1128.jpg" alt="Food pickup" />
      <div className="missionContent">
        <Image src="toastLocal/splash/flares-hollow-white.svg" alt="Flares" height={64} className="flares" />
        <h2>Takeout and delivery that&apos;s good for you (and restaurants, too)</h2>
        <p>
          When you use commission-free platforms like Toast, you save your favorite restaurants expensive commission fees. You
          also make it easier for the new spots you fall in love with to stick around. So go ahead, order your favorite takeout
          for the third time this week. (It&apos;s good for everyone.)
        </p>
      </div>
    </div>
  );
};

export default Mission;
