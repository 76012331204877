import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PayPalButtons } from '@paypal/react-paypal-js';

import { PlaceOrderErrorCode, useSetPaymentIntentMutation } from 'src/apollo/onlineOrdering';
import { useHandleCompletedOrderCallback } from 'src/public/components/default_template/online_ordering/checkout/checkoutUtils';
import { PaymentOptionToAlternativePaymentMap, PaymentOptionToPayPalFundingSourceMap } from 'src/public/components/online_ordering/paypalUtils';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, OrderError, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

interface Props {
  paymentOption: PaymentOption.Paypal | PaymentOption.Venmo
  disableSubmit: boolean
}

export default function CheckoutWithVenmoOrPayPal({ paymentOption, disableSubmit }: Props) {
  const [setPaymentIntentMutation] = useSetPaymentIntentMutation({ fetchPolicy: 'no-cache' });
  const { ooRestaurant } = useRestaurant();
  const { cart, cartGuid } = useCart();
  const { tipAmount } = usePayment();
  const { orderTotal, placeOrder, setOrderError } = useCheckout();
  const { refetchCustomer } = useCustomer();
  const { getValues } = useFormContext<CheckoutFormData>();
  const handleCompletedOrderCallback = useHandleCompletedOrderCallback();


  const taxAmount = cart?.order?.taxV2 || 0;
  const amount = orderTotal - tipAmount - taxAmount;


  return <PayPalButtons
    disabled={disableSubmit}
    // PayPal does not mention this prop in their documentation, but it causes the
    // component to update some memo'd props (like the createOrder callback below).
    forceReRender={[orderTotal, paymentOption]}
    style={{ shape: 'pill', label: 'pay', color: 'blue' }}
    // This callback is memo'd by the PayPalButtons component, it will be updated
    // only when an argument in the forceReRender prop changes.
    createOrder={async () => {
      if(!cartGuid || !ooRestaurant || !cart) {
        return '';
      }
      const { data } = await setPaymentIntentMutation({
        variables: {
          input: {
            amount,
            cartGuid,
            paymentMethod: PaymentOptionToAlternativePaymentMap[paymentOption],
            restaurantGuid: ooRestaurant.guid,
            taxAmount,
            tipAmount,
            totalAmount: orderTotal
          }
        }
      });

      if(data?.setPaymentIntent.__typename === 'PaymentIntentResponse') {
        return data?.setPaymentIntent.apmInitiationToken;
      } else {
        const errorData: OrderError = {
          type: PlaceOrderErrorCode.PlaceOrderFailed,
          message: `We were unable to process your order using ${paymentOption}. Please choose a different payment option and try again.`
        };
        setOrderError(errorData);
        return '';
      }
    }}
    onApprove={async () => {
      try {
        await placeOrder(cartGuid!, getValues(), handleCompletedOrderCallback);
        refetchCustomer();
      } catch(error) {
        setOrderError(error);
      }
    }}
    fundingSource={PaymentOptionToPayPalFundingSourceMap[paymentOption]} />;
}
