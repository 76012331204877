import React, { useState, useEffect } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

type Size = {
  width?: number;
  height?: number;
}

const useRefSize = (node: React.RefObject<HTMLElement>) => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    if(node?.current) {
      const observer = new ResizeObserver(entries => {
        entries.forEach(({ contentRect }) => {
          setWindowSize({
            width: contentRect.width,
            height: contentRect.height
          });
        });
      });

      // Start observing the target node for configured mutations
      observer.observe(node.current);

      return () => {
        observer.disconnect();
      };
    }

    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current]);

  return windowSize;
};

export default useRefSize;
