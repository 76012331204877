import React from 'react';

import classNames from 'classnames';

import { PaymentType } from 'src/apollo/onlineOrdering';

import ItemPrice from 'public/components/default_template/online_ordering/order/ItemPrice';
import { Surcharges } from 'public/components/online_ordering/CartContext';

export type CreditCardSurchargeDisclaimerProps = {
  surcharges?: Surcharges|null,
  spiSurchargingEnabled?: boolean,
  className?: string|null
}

type CreditCardSurchargeDisclaimerWithItemPriceProps = {
  loadingCart: boolean
}

type CreditCardSurchargeDisclaimerBaseProps = {
  surchargeAmount: number,
  surchargeRatePercent?: number|null,
  className?: string|null
}

export const CreditCardSurchargeDisclaimerBase = ({
  surchargeAmount,
  surchargeRatePercent,
  className
}: CreditCardSurchargeDisclaimerBaseProps) => {
  const surchargeRateForDisplay = surchargeRatePercent && surchargeRatePercent >= 0 ? `${surchargeRatePercent.toFixed(2)}%` : '';

  return surchargeAmount > 0 ?
    <div className={classNames('creditCardSurchargeDisclaimerBase', className)} data-testid="surcharge-disclaimer">
      {`If you use a credit card, this establishment will charge an additional ${surchargeRateForDisplay}
        ($${surchargeAmount.toFixed(2)}) to help offset processing costs. ` +
        'This amount is not more than what this establishment pays in fees.'}
    </div>
    : null;
};

export const CreditCardSurchargeDisclaimer = ({
  surcharges,
  spiSurchargingEnabled,
  className
}: CreditCardSurchargeDisclaimerProps) => {
  const creditSurcharge = surcharges?.[PaymentType.Credit];
  return spiSurchargingEnabled && creditSurcharge && creditSurcharge?.surchargeAmount && creditSurcharge?.surchargeRate ?
    <CreditCardSurchargeDisclaimerBase surchargeAmount={creditSurcharge.surchargeAmount} surchargeRatePercent={creditSurcharge.surchargeRate * 100} className={className} />
    : null;
};

/**
 * For use in the OrderPrices component on the CheckoutPage
 */
export const CreditCardSurchargeDisclaimerWithItemPrice = ({
  surcharges,
  spiSurchargingEnabled,
  loadingCart
}: CreditCardSurchargeDisclaimerProps & CreditCardSurchargeDisclaimerWithItemPriceProps) => {
  const creditSurcharge = surcharges?.[PaymentType.Credit];

  return spiSurchargingEnabled && creditSurcharge && creditSurcharge?.surchargeAmount && creditSurcharge?.surchargeRate
    ? <div className="row surcharge">
      <ItemPrice
        loading={loadingCart}
        title={`Credit card surcharge (${creditSurcharge.surchargeRate * 100}%)`}
        amount={creditSurcharge.surchargeAmount}
        amountPrefix="+" />
      <CreditCardSurchargeDisclaimer surcharges={surcharges} spiSurchargingEnabled={spiSurchargingEnabled} />
      {/* eslint-disable-next-line react/jsx-closing-tag-location */}
    </div>
    : null;
};
