import React, { useEffect, useMemo, useState } from 'react';

import { getPlaceholderImage } from './getPlaceholderImage';

export interface PlaceHolderMenuImageProps {
  text: string;
  textColor: string;
  fontFamily?: string;
  fontSize?: string;
  rotation: number; // in degrees
  height: number;
  width: number;
  alpha?: number;
  className?: string;
}

export const PlaceHolderMenuImage = ({ text, fontFamily = '', fontSize = '', textColor, rotation, height, width, alpha = 1.0, className }: PlaceHolderMenuImageProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const placeholderImage = useMemo(() => {
    return getPlaceholderImage({ text, width, height, rotation, fontSize, fontFamily, textColor, alpha });
  }, [text, width, height, rotation, fontSize, fontFamily, textColor, alpha]);

  if(!isClient) return null;

  return (
    <img key="placeholder" data-testid="placeholder-image" src={placeholderImage || ''} className={className} alt="" />
  );
};
