import React, { useEffect, useState } from 'react';

type Props = {
  expireAfterSeconds?: number;
}

const ErrorNotice = (props: React.PropsWithChildren<Props>) => {
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    if(props.expireAfterSeconds) {
      setTimeout(() => setIsDismissed(true), props.expireAfterSeconds * 1000);
    }
  });

  if(isDismissed) {
    return null;
  }

  return (
    <div className="errorNotice">{props.children}</div>
  );
};

export default ErrorNotice;
