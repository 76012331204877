import { useFlags as useFlagsClient } from 'launchdarkly-react-client-sdk';

import { useFlagContext } from './FlagContext';

export const useFlags = () => {
  const clientFlags = useFlagsClient();
  const serverFlags = useFlagContext();

  if(typeof window !== 'undefined') {
    return clientFlags;
  }

  return serverFlags;
};
