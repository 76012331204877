import React, { cloneElement } from 'react';
import { Helmet } from 'react-helmet-async';

import parse from 'html-react-parser';

export type PixelsMetaProps = {
  pixels?: string[];
}

const PixelsMeta = ({ pixels }: PixelsMetaProps) => {
  const pixelTags = (pixels || []).flatMap(pixel => createScriptTagsFromString(pixel));
  // render each tag into a react element with a key.
  const pixelComponents = pixelTags.map((pixel, index) => cloneElement(pixel, { key: index }));
  return (
    <Helmet>
      {pixelComponents}
    </Helmet>
  );
};


export const createScriptTagsFromString = (pixelString: string): JSX.Element[] => {
  const supportedHeadTags = ['script', 'link'];
  const parsed = parse(pixelString.trim());
  if(Array.isArray(parsed)) {
    return parsed.filter(el => typeof el !== 'string' && supportedHeadTags.includes(el.type)).map(forceHelmetCompatibilty);
  } else if(typeof parsed === 'string') {
    return [];
  } else {
    // parsed is JSX.Element
    if(!supportedHeadTags.includes(parsed.type)) {
      return [];
    }
    return [forceHelmetCompatibilty(parsed)];
  }
};

const forceHelmetCompatibilty = (element: JSX.Element) => {
  const innerhtml = element.props.dangerouslySetInnerHTML?.__html;
  const src = element.props.src;
  const { dangerouslySetInnerHTML, ...otherProps } = element.props;
  if(src) {
    return <script {...otherProps} src={src} type="text/javascript" >{innerhtml}</script>;
  } else if(!src && innerhtml) {
    // Helment requires elements without a 'src' attribute to be constructed as a JSX.IntrinsicElement, which is why we must recreate the element here.
    // This is an undocumented behavior of Helmet, and is probably a bug.
    return <script {...otherProps} type="text/javascript">{innerhtml}</script>;
  }
  return element;
};


export default PixelsMeta;
