import React, { useMemo } from 'react';

import Image from 'shared/components/common/Image';

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="toastLocalFooter">
      <div className="container paddedSection">
        <div className="footerContent">
          <div className="contentCell pure-g">
            <div className="footerNav pure-u-12-24 pure-u-sm-6-24">
              <div>© {year} Toast, Inc.</div>
              <Image src="/icons/toast-logo-filled.svg" alt="Toast" height={45} className="toastBadge" />
            </div>

            <ul className="footerNav navDown pure-u-12-24 pure-u-sm-6-24">
              <li><a href="https://pos.toasttab.com/about">About Toast</a></li>
              <li><a href="https://pos.toasttab.com/">For restaurants</a></li>
            </ul>

            <ul className="footerNav navDown pure-u-12-24 pure-u-sm-6-24">
              <li><a href="https://pos.toasttab.com/terms-of-service" rel="nofollow">Terms of Service</a></li>
              <li><a href="https://pos.toasttab.com/privacy" rel="nofollow">Privacy Statement</a></li>
              <li><a href="https://pos.toasttab.com/privacy#addendum" rel="nofollow">Do not sell my<br />personal information</a></li>
            </ul>

            <div className="footerNav navDown pure-u-12-24 pure-u-sm-6-24">
              Made with <Image src="/icons/heart-orange.svg" alt="love" height={11} className="heartIcon" /> in Boston

              <ul className="footerNav socialMedia">
                <li><a href="https://instagram.com/toasttab" target="_blank" rel="noreferrer"><Image src="icons/instagram-gray.svg" alt="Instagram" className="instagram" height={12} /></a></li>
                <li><a href="https://twitter.com/toasttab" target="_blank" rel="noreferrer"><Image src="icons/twitter-gray.svg" alt="Twitter" className="twitter" height={12} /></a></li>
                <li><a href="https://www.facebook.com/toasttab/" target="_blank" rel="noreferrer"><Image src="icons/facebook-gray.svg" alt="Facebook" className="facebook" height={12} /></a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
