import React from 'react';

import { ToggleInput } from 'shared/components/common/forms';

import { usePayment } from 'public/components/online_ordering/PaymentContext';

const SaveCardCheckbox = () => {
  const { newCreditCard, setSaveNewCreditCard } = usePayment();

  return (
    <>
      <ToggleInput
        type="checkbox"
        name="saveCreditCard"
        id="saveCreditCard"
        disabled={!newCreditCard}
        checked={newCreditCard?.saveCard || false}
        dataTestId="input-savecard"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSaveNewCreditCard(event.target.checked)}>
        Save this card
      </ToggleInput>
      <div className="note">
        By selecting &#34;Save this card&#34; and clicking on &#34;Place Order&#34; you are authorizing Toast, Inc. to store your credit card information provided above{' '}
        (the &#34;Card&#34;), and to charge your Card for future purchases initiated by you. This authorization will remain in effect until updated or canceled.
      </div>
    </>
  );
};

export default SaveCardCheckbox;
