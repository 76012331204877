export async function parseConfirmVerificationCodeError(rawError: any) {
  const genericError = {
    data: {
      confirmGuest: {
        __typename: 'FailedToConfirmCodeError',
        message: 'Something went wrong. Please try signing in again.'
      }
    }
  }

  let error

  try {
    error = await rawError.response.json()
  } catch {
    return genericError
  }

  // error_description doesn't have the response code in the message.
  // See https://github.com/toasttab/toast-authentication/blob/main/toast-authentication-application/src/main/kotlin/com/toasttab/service/authentication/exceptions/AuthenticationErrorMessage.kt.
  if (error.messageKey && error.error_description) {
    return {
      data: {
        confirmGuest: {
          __typename: error.messageKey,
          message: error.error_description
        }
      }
    }
  } else {
    return genericError
  }
}
