import React, { useState } from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import { CUISINES } from 'public/components/toast_local/common/cuisine_filters/cuisines.data';

import CuisineCard from './CuisineCard';

type CuisineFarmProps = {
  numCuisines: number // The number of cuisines to display by default. Note that ALL are loaded for SEO
};

const CuisineFarm = ({ numCuisines }: CuisineFarmProps) => {
  const [expanded, setExpanded] = useState(false);

  const onClickShowMore = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="cuisine-farm">
      <h2>Popular near you</h2>

      <div className="pure-g cuisine-cards">
        {CUISINES.map((cuisine, i) =>
          <CuisineCard cuisine={cuisine} className={classnames('pure-u-12-24 pure-u-md-6-24 pure-u-lg-4-24', { hidden: !expanded && i > numCuisines - 1 })} key={cuisine.value} />)}
      </div>

      <div className="link-text show-more v-align-children" onClick={onClickShowMore}>
        <span>Show {expanded ? 'fewer' : 'more'}</span>
        <Image className="caret" src={`toastLocal/splash/caret-${expanded ? 'up' : 'down'}-blue.svg`} alt="Caret" height={5} />
      </div>

    </div>
  );
};

export default CuisineFarm;
