import { useEffect } from 'react';

type Props = {
  sourceId?: string | null;
  origin: string;
  messageHandlers: { [key: string]: (data: any) => void };
}

export const useWindowMessage = ({ sourceId, origin, messageHandlers }: Props) => {
  useEffect(() => {
    const receiveMessage = (event?: MessageEvent) => {
      if(!event || origin !== event.origin || typeof event.data !== 'string') {
        // Ignore invalid messages
        return;
      }

      const { name, data } = JSON.parse(event.data) || {};
      const handler = messageHandlers[name];
      if(handler && (!sourceId || sourceId === data.sourceId)) {
        handler(data);
      }
    };

    if(window) {
      window.addEventListener('message', receiveMessage, false);
      return () => {
        window.removeEventListener('message', receiveMessage, false);
      };
    }

    return () => { };
  }, [sourceId, messageHandlers, origin]);
};

export default useWindowMessage;
