import { useCallback, useEffect, useRef, useState } from 'react';

export type ScrollDirection = 'up' | 'down';

const SCROLL_HEIGHT_PADDING = 10;

const useScrollDirection = () => {
  const prevScrollY = useRef(0);
  const prevClientWidth = useRef(0);
  const prevScrollHeight = useRef(0);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>();

  const onScroll = useCallback((e: Event) => {
    const window = e.currentTarget as Window;
    const scrollY = window.scrollY;
    const scrollHeight = window.document.body.scrollHeight;
    const clientWidth = window.document.body.clientWidth;

    // Don't treat zooms as scroll direction changes
    if(clientWidth !== prevClientWidth.current) {
      prevClientWidth.current = clientWidth;
      return;
    }

    // Ignore any height added or removed by shifting elements into view, with some padding tolerance
    const heightDiff = Math.abs(prevScrollHeight.current - scrollHeight) + SCROLL_HEIGHT_PADDING;

    if(scrollY < prevScrollY.current && Math.abs(scrollY - prevScrollY.current) > heightDiff) {
      setScrollDirection('up');
    } else if(scrollY > prevScrollY.current && Math.abs(scrollY - prevScrollY.current) > heightDiff) {
      setScrollDirection('down');
    }

    prevScrollY.current = scrollY;
    prevScrollHeight.current = scrollHeight;
    prevClientWidth.current = clientWidth;
  }, []);

  useEffect(() => {
    prevScrollHeight.current = window.document.body.scrollHeight;
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return { scrollDirection };
};

export default useScrollDirection;
