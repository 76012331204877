import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { parsePhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import Button from 'shared/components/common/button';
import FormInput from 'shared/components/common/form_input';
import { useModalContext } from 'shared/components/common/modal';
import { alertSuccess } from 'shared/js/alertUtils';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

type CompleteProfileProps = {
  phoneNumber: string;
  checkingOut?: boolean;
}

type FormInputs = {
  email: string;
  firstName: string;
  lastName: string;
}

export const CompleteProfileForm: FC<CompleteProfileProps> = ({ phoneNumber, checkingOut = false }) => {
  const { completeSignup, fetchCustomer } = useCustomer();
  const { onClose } = useModalContext();
  const [submitting, setSubmitting] = useState(false);
  const formMethods = useForm<FormInputs>({ mode: 'onTouched' });
  const email = useWatch({ name: 'email', control: formMethods.control });
  const firstName = useWatch({ name: 'firstName', control: formMethods.control });
  const lastName = useWatch({ name: 'lastName', control: formMethods.control });
  const { handleSubmit, setValue } = formMethods;
  const [hasError, setHasError] = useState(false);
  const { customer } = useCustomer();

  const onSuccessfulAccountCreation = useCallback(() => {
    onClose();
    alertSuccess(customer?.firstName ? `Welcome, ${customer?.firstName}!` : 'Welcome!');
  },
  [customer, onClose]);

  const submit = useCallback(async (inputData: FormInputs) => {
    setSubmitting(true);
    try {
      const success = await completeSignup(inputData.email, inputData.firstName, inputData.lastName);
      if(success) {
        await fetchCustomer();
        onSuccessfulAccountCreation();
      } else {
        setHasError(true);
      }
    } catch(err) {
      setHasError(true);
    } finally {
      setSubmitting(false);
    }
  }, [completeSignup, fetchCustomer, onSuccessfulAccountCreation]);

  const onBlurInput = (emailValue: string) => () => {
    setValue('email', emailValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };
  return (
    <FormProvider {...formMethods}>
      <div className="headerContent">
        <h2 className="title" id="pwless-auth-modal-header">Complete your Toast account</h2>
      </div>
      {/* Stop propagation of the event so that submitting this form doesn't also trigger a submit of any parent forms, like the checkout form */}
      <form className="completeProfileForm" onSubmit={event => { event.stopPropagation(); handleSubmit(submit)(event); }}>
        <div className="formBody">
          {hasError ? <span className="formError">The information you entered was invalid. Please try again.</span> : null}
          <div className="phoneNumberBox">{parsePhoneNumber(phoneNumber, 'US').formatNational()}</div>
          <div className="inputs">
            <FormInput id="firstName" type="text" label="First name" required />
            <FormInput id="lastName" type="text" label="Last name" required />
            <FormInput id="email" type="email" label="Email" required
              validate={value => isEmail(value) || 'enter a valid email address'}
              onBlur={onBlurInput(email)} />
          </div>
          <div className="legal" role="contentinfo">
            Your email will be used to provide digital receipts, account communication, and marketing messages from both Toast and restaurants you visit unless you unsubscribe.
          </div>
        </div>
        <div className="footer">
          <Button className="submitBtn" type="submit" disabled={submitting || !(isEmail(email || '') && firstName && lastName)}>
            {checkingOut ? 'Create account and check out' : 'Create account'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
