import React, { useCallback, useMemo } from 'react';

import { DeliveryIcon, IconSize, PickUpIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import { MenuConfig } from 'src/apollo/sites';
import { FulfillmentBehaviorTooltip } from 'src/public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentBehaviorTooltip';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { getQuoteTime } from 'src/public/components/online_ordering/fulfillmentUtils';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { getDiningBehaviorToggleLabel, isToday, isTomorrow, toLocalTime } from 'src/shared/js/timeUtils';

import { formatAddressLabel } from 'shared/components/common/form_input/LocationInput';
import { LoadingSpinner } from 'shared/components/common/loading_spinner/LoadingSpinner';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import Toggle from 'shared/components/common/toggle/Toggle';

import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';
import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';
import { ExpandIcon, InfoIcon } from 'public/components/default_template/online_ordering/dining_behavior_toggle/Icons';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';

export const getSubLabel = (isASAP: boolean, minTime: number | null, time?: string) => {
  if(isASAP) {
    return minTime ? getDiningBehaviorToggleLabel(minTime) : undefined;
  }

  return time ? toLocalTime(time) : undefined;
};

const getScheduledFulfillmentString = (mustScheduleOrder: boolean, time?: string) => {
  if(time && !mustScheduleOrder) {
    const dateObj = new Date(time);
    const day = isToday(dateObj) ? 'Today' : isTomorrow(dateObj) ? 'Tomorrow' : dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    return `${day}, ${dateObj.toLocaleTimeString('en-US', { timeStyle: 'short' })}`;
  }
  return 'Schedule your order';
};

const getAsapFulfillmentString = (canOrderDelivery: boolean, isPickupAsapOnly: boolean, isDeliveryAsapOnly: boolean, minTime: number | null) => {
  if(isPickupAsapOnly && canOrderDelivery === isDeliveryAsapOnly) {
    return minTime ? `ASAP only, ${getDiningBehaviorToggleLabel(minTime)}` : 'ASAP only';
  }
  return minTime ? `Today, ${getDiningBehaviorToggleLabel(minTime)}` : 'ASAP';
};

const FulfillmentControl = ({ onClick, isMobile, testId, loading }: { onClick: () => void; isMobile?: boolean, testId?: string, loading?: boolean }) => {
  const { fulfillmentData, diningOptionBehaviorAvailability, canOrderDelivery, guestSelectedFulfillmentTime, closeFulfillmentTooltip } = useFulfillment();
  const { ooRestaurant, selectedLocation, orderingDisabled, restaurant: { config, meta } } = useRestaurant();
  const { cart } = useCart();
  const { validDeliveryAddress } = useDelivery();
  const templateOverrides = useMemo(() => overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config), [config]);
  const getColorFromTheme = useThemeColorScheme();

  const onClickToggle = () => {
    onClick();
    closeFulfillmentTooltip();
  };

  const loadingSpinnerColor = useMemo(() => {
    return getColorFromTheme(theme => theme.colorScheme.icon.action, meta.primaryColor);
  }, [getColorFromTheme, meta.primaryColor]);

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const fulfillmentBehavior = useMemo(
    () => fulfillmentData?.cartFulfillmentData.diningOptionBehavior ?? DiningOptionBehavior.TakeOut,
    [fulfillmentData?.cartFulfillmentData.diningOptionBehavior]
  );
  const isASAP = fulfillmentType === FulfillmentType.Asap;
  const isPickupAsapOnly = useMemo(() => {
    const pickup = diningOptionBehaviorAvailability['takeout'];
    return Boolean(pickup?.asapAvailable && !pickup?.scheduledAvailable);
  }, [diningOptionBehaviorAvailability]);

  const isDeliveryAsapOnly = useMemo(() => {
    const delivery = diningOptionBehaviorAvailability['delivery'];
    return Boolean(delivery?.asapAvailable && !delivery?.scheduledAvailable);
  }, [diningOptionBehaviorAvailability]);

  const fulfillmentTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime || undefined;
  const minimumTime = useMemo(
    () => ooRestaurant ? getQuoteTime(fulfillmentBehavior, ooRestaurant, cart) : null,
    [cart, ooRestaurant, fulfillmentBehavior]
  );
  const promptToScheduleOrder = useMemo(() => {
    const behavior = fulfillmentBehavior === DiningOptionBehavior.TakeOut ? diningOptionBehaviorAvailability.takeout : diningOptionBehaviorAvailability.delivery;
    const scheduledOrdersOnly = Boolean(behavior?.scheduledAvailable && !behavior.asapAvailable);
    return scheduledOrdersOnly && !guestSelectedFulfillmentTime;
  }, [fulfillmentBehavior, diningOptionBehaviorAvailability, guestSelectedFulfillmentTime]);

  if(orderingDisabled) {
    return (
      <button className={classnames('fulfillmentControl orderingUnavailable', templateOverrides.roundedCorner)} disabled aria-disabled data-testid={testId ?? 'fulfillment-control'}>
        <InfoIcon />
        <div>Not accepting orders</div>
      </button>
    );
  }

  return (
    <button
      disabled={isPickupAsapOnly && !canOrderDelivery}
      className={classnames('fulfillmentControl', templateOverrides.roundedCorner)}
      data-testid={testId ?? 'fulfillment-control'}
      onClick={onClickToggle}>
      <div className="fulfillmentControlContent">
        <div className="fulfillmentBehavior" data-testid="fulfillment-behavior" id="fulfillmentBehavior">
          {fulfillmentBehavior === DiningOptionBehavior.Delivery ? 'Delivery' : 'Pickup'}
        </div>
        <FulfillmentBehaviorTooltip canOrderDelivery={canOrderDelivery} />
        <div className="separator" />
        <div className="fulfillmentDetails" data-testid="fulfillment-time">
          {isASAP ? getAsapFulfillmentString(canOrderDelivery, isPickupAsapOnly, isDeliveryAsapOnly, minimumTime) : getScheduledFulfillmentString(promptToScheduleOrder, fulfillmentTime)}
          {isMobile &&
              <div data-testid="formattedAddress">
                {fulfillmentBehavior === DiningOptionBehavior.Delivery ?
                  formatDeliveryAddress(cart ? cart.order?.deliveryInfo : validDeliveryAddress, false) :
                  formatAddressLabel(selectedLocation)}
              </div>}
        </div>
      </div>
      {!(isPickupAsapOnly && !canOrderDelivery) &&
          <div className={classnames('icon', { loading })}>
            {loading ? <LoadingSpinner color={loadingSpinnerColor} size="16px" strokeWidth="5px" /> : <ExpandIcon />}
          </div>}
    </button>
  );
};

export const LegacyDiningBehaviorToggle = (props: {
  saveChange?: boolean;
  className?: string;
  withSpinner?: boolean;
  iconSize?: IconSize;
  themed?: boolean
  showQuoteTimes?: boolean
}) => {
  const { saveChange, className, withSpinner, iconSize, themed = true, showQuoteTimes = true } = props;
  const {
    takeoutEnabled,
    deliveryEnabled,
    canOrderTakeout,
    canOrderDelivery,
    fulfillmentData,
    selectedDiningOptionBehavior,
    setSelectedDiningOptionBehavior,
    diningOptionBehaviorAvailability,
    updateCartFulfillment,
    loadingFulfillment,
    setDeliveryAddressNeeded,
    guestSelectedFulfillmentTime
  } = useFulfillment();
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const isASAP = fulfillmentType === FulfillmentType.Asap;
  const fulfillmentTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime || undefined;
  const pickupMinimumTime = useMemo(() => ooRestaurant ? getQuoteTime(DiningOptionBehavior.TakeOut, ooRestaurant, cart) : null, [cart, ooRestaurant]);
  const deliveryMinimumTime = useMemo(() => ooRestaurant ? getQuoteTime(DiningOptionBehavior.Delivery, ooRestaurant, cart) : null, [cart, ooRestaurant]);
  const scheduledDeliveryOnly = diningOptionBehaviorAvailability?.delivery?.scheduledAvailable && !diningOptionBehaviorAvailability.delivery.asapAvailable;
  const scheduledPickupOnly = diningOptionBehaviorAvailability?.takeout?.scheduledAvailable && !diningOptionBehaviorAvailability.takeout.asapAvailable;

  const { clearDeliveryAddress } = useDelivery();
  const onToggle = useCallback((value: DiningOptionBehavior) => {
    setSelectedDiningOptionBehavior(value);

    if(saveChange) {
      // delivery requires an address before the cart can be updated
      if(value === DiningOptionBehavior.Delivery) {
        setDeliveryAddressNeeded(true);
      } else if(fulfillmentData?.cartFulfillmentData) {
        clearDeliveryAddress();
        updateCartFulfillment({
          fulfillmentType: fulfillmentData.cartFulfillmentData.fulfillmentType,
          fulfillmentDateTime: fulfillmentData.cartFulfillmentData.fulfillmentDateTime,
          diningOptionBehavior: value,
          deliveryInfo: null
        });
      }
    }
  }, [setDeliveryAddressNeeded, clearDeliveryAddress, setSelectedDiningOptionBehavior, saveChange, updateCartFulfillment, fulfillmentData]);

  const toggleValue = useMemo(() => selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'left' : 'right', [selectedDiningOptionBehavior]);
  const asapOnly = useMemo(() => {
    const pickup = diningOptionBehaviorAvailability['takeout'];
    const delivery = diningOptionBehaviorAvailability['delivery'];
    return !pickup?.scheduledAvailable && !delivery?.scheduledAvailable;
  }, [diningOptionBehaviorAvailability]);
  const showSubLabel = showQuoteTimes || asapOnly;

  // Hide the toggle if only one option is enabled, but show both if the Rx has both enabled, even if orders cannot be
  // created at this time (i.e. TakeOut is available now, but Delivery isnt)
  if(!takeoutEnabled || !deliveryEnabled) {
    return null;
  }

  return (
    <div className={className} data-testid="dining-behavior-toggle">
      <Toggle
        className="fulfillmentToggleRedesign"
        value={toggleValue}
        onChange={onToggle}
        themed={themed}
        left={{
          name: `${scheduledDeliveryOnly && !guestSelectedFulfillmentTime ? 'Schedule ' : ''}Delivery`,
          value: DiningOptionBehavior.Delivery,
          disabled: !canOrderDelivery,
          icon: <DeliveryIcon size={iconSize} accessibility="decorative" />,
          subLabel: showSubLabel && !(scheduledDeliveryOnly && !guestSelectedFulfillmentTime) ? getSubLabel(isASAP, deliveryMinimumTime, fulfillmentTime) : undefined,
          dataTestId: 'toggle-delivery'
        }}
        right={{
          name: `${scheduledPickupOnly && !guestSelectedFulfillmentTime ? 'Schedule ' : ''}Pickup`,
          value: DiningOptionBehavior.TakeOut,
          disabled: !canOrderTakeout,
          icon: <PickUpIcon size={iconSize} accessibility="decorative" />,
          subLabel: showSubLabel && !(scheduledPickupOnly && !guestSelectedFulfillmentTime) ? getSubLabel(isASAP, pickupMinimumTime, fulfillmentTime) : undefined,
          dataTestId: 'toggle-pickup'
        }} />
      {withSpinner && loadingFulfillment && <LoadingSpinnerOverlay withBorderRadius />}
    </div>
  );
};

export default FulfillmentControl;
