import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type Params = {
  menuGuid?: string;
  itemSlug?: string;
}

/**
  * A convenient hook for having your React Router based app scroll to the top of the page automatically on page navigation
  */
export const useScrollToTopOnNewLocation = () => {
  const { pathname, search, hash } = useLocation();
  const params = useParams<Params>();
  const lastLocationPath = useRef<string | null>(null);
  const lastSearchString = useRef<string | null>(null);

  useEffect(() => {
    if((lastLocationPath.current !== pathname || lastSearchString.current !== search) && !params.menuGuid && !params.itemSlug && !hash) {
      if(window) { window.scrollTo(0, 0); }
      lastLocationPath.current = pathname;
      lastSearchString.current = search;
    }
  }, [pathname, search, params, hash]);
};

export default useScrollToTopOnNewLocation;
