import { useEffect, useCallback, useState } from 'react';

import { PDFDocumentLoadingTask, PDFDocumentProxy } from 'pdfjs-dist';

export type PDFUtils = {
  zoom: (n: number) => void
  nextPage: () => void
  previousPage: () => void
  totalPages: number
  scale: number
  page: number
}

export const usePDF = (file: string, canvas: React.RefObject<HTMLCanvasElement>): PDFUtils => {
  const [scale, setScale] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pdf, setPdf] = useState<PDF>();

  const watchPdfLib = useCallback(
    () => {
      if(!file || !window || !canvas?.current) return;

      // @ts-ignore
      const pdfLib = globalThis?.pdfjsLib;

      if(!pdfLib) {
        let timeout: NodeJS.Timeout = setTimeout(watchPdfLib, 100);
        return () => clearTimeout(timeout);
      }

      const p = new PDF(pdfLib, canvas.current);
      p.loadFile(file).then(doc => {
        setTotalPages(doc?._pdfInfo?.numPages || 1);
        setPdf(p);
      });

      return () => {
        p.cancel();
      };
    },
    [file, canvas]
  );

  useEffect(() => {
    return watchPdfLib();
  }, [file, watchPdfLib]);

  const zoom = async (dir: number) => {
    await pdf?.zoom(dir);
    setScale(pdf?.scale || 1);
  };

  const nextPage = async () => {
    await pdf?.nextPage();
    setPage(pdf?.currentPage || 1);
  };

  const previousPage = async () => {
    await pdf?.previousPage();
    setPage(pdf?.currentPage || 1);
  };

  return {
    zoom,
    nextPage,
    previousPage,
    totalPages,
    scale,
    page
  };
};

class PDF {
  currentPage: number = 1;
  scale: number = 1;
  pdfjsLib: any;
  url: string;
  loadingTask: PDFDocumentLoadingTask;
  totalPages: number = 0;
  loaded: boolean = false;
  mounted: boolean = true;
  pdf: PDFDocumentProxy;
  canvas: HTMLCanvasElement | null;
  renderTask: any;
  dispatch: (v: PDF) => void;

  constructor(pdfjsLib: any, canvas: HTMLCanvasElement | null) {
    this.pdfjsLib = pdfjsLib;
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.worker.mjs';
    this.canvas = canvas;
  }

  receivedPDF(pdf: PDFDocumentProxy) {
    if(!this.mounted) return;
    this.pdf = pdf;
    this.loaded = true;
    this.totalPages = pdf._pdfInfo.numPages;
    this.getPage(1);
    return pdf;
  }

  receivedError(e: Error) {
    this.loaded = false;
    console.error(e);
  }

  nextPage() {
    if(this.currentPage >= this.totalPages) {
      return Promise.resolve();
    }
    return this.getPage(this.currentPage + 1);
  }

  previousPage() {
    if(this.currentPage <= 1) {
      return Promise.resolve();
    }
    return this.getPage(this.currentPage - 1);
  }

  zoom(dir: number) {
    if(dir > 0) {
      if(this.scale >= 2) return Promise.resolve();
      this.scale += 0.5;
    } else {
      if(this.scale <= 0.5) return Promise.resolve();
      this.scale -= 0.5;
    }
    return this.getPage(this.currentPage);
  }

  getPage(n: number) {
    this.currentPage = n;
    return this.getCurrentPage();
  }

  getCurrentPage() {
    if(!this.loaded) {
      return Promise.reject();
    }
    return this.pdf.getPage(this.currentPage).then(this.displayPage.bind(this));
  }

  displayPage(page: any) {
    if(!this.canvas || !window || !this.mounted) {
      console.error('No canvas to display PDF');
      return;
    }
    const viewport = page.getViewport({ scale: this.scale });
    const context = this.canvas.getContext('2d');
    this.canvas.height = viewport.height;
    this.canvas.width = viewport.width;
    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    this.renderTask = page.render(renderContext);
  }

  loadFile(url: string) {
    this.url = url;
    this.loadingTask = this.pdfjsLib.getDocument(url);
    return this.loadingTask.promise.then(
      this.receivedPDF.bind(this),
      this.receivedError.bind(this)
    );
  }

  cancel() {
    this.mounted = false;
    this.loadingTask?.destroy();
  }
}

export default PDF;
