import * as React from 'react'
import { FetchResult } from '@apollo/client'
import { AuthHeader } from '@toasttab/guest-authentication-js'

import { GuestContact } from '.'
import { ToastEnvironment } from '@toasttab/authentication-utils'

import config from 'config'

export type InitGuestAuthenticationFunc = (
  gatewayOrigin: string,
  guestSessionResumed: () => void,
  toastEnv?: ToastEnvironment
) => void

type StartPasswordlessFunc = (
  phoneNumber: string,
  source: string
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>

type ConfirmVerificationCodeFunc = (
  verificationCode: string,
  source: string
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>

type ConfirmPasswordlessFunc = (
  source: string,
  contact?: GuestContact
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>

type LogOutFunc = () => Promise<void>

type GetAuthenticationHeaderFunc = () => Promise<AuthHeader | null>

interface AuthContext {
  isAuthenticated: boolean
  setIsAuthenticated: (isAuthenticated: boolean) => void
  getAuthenticationHeader: GetAuthenticationHeaderFunc
  startPasswordless: StartPasswordlessFunc
  confirmVerificationCode: ConfirmVerificationCodeFunc
  confirmPasswordless: ConfirmPasswordlessFunc
  refreshAuthToken: () => void
  logOut: LogOutFunc
}

const Context = React.createContext<AuthContext>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  getAuthenticationHeader: async () => null,
  startPasswordless: (async () => {
    throw new Error('Not set')
  }) as StartPasswordlessFunc,
  confirmVerificationCode: (async () => {
    throw new Error('Not set')
  }) as ConfirmVerificationCodeFunc,
  confirmPasswordless: (async () => {
    throw new Error('Not set')
  }) as ConfirmPasswordlessFunc,
  refreshAuthToken() {
    throw new Error('Not set')
  },
  logOut: async () => {
    return
  }
})

/**
 * React Context that provides various authentication related functionality for guest accounts.
 * Wrap around the highest level component, under which authentication functionality is required.
 *
 * Upon mounting, attempt to refresh the guest's session. If successful, setIsAuthenticated will be
 * called with `true`, setting the authentication state to be authenticated.
 *
 * It's up to the consuming application to decide when to call setIsAuthenticated after successful
 * calls to confirmVerificationCode, confirmPasswordless, and logOut.
 */
export function AuthProviderCommon({
  gatewayOrigin,
  initGuestAuthentication,
  confirmVerificationCode,
  confirmPasswordless,
  getAuthenticationHeader,
  logOut,
  startPasswordless,
  setIsAuthenticated,
  isAuthenticated,
  refreshAuthToken,
  children
}: {
  gatewayOrigin: string
  initGuestAuthentication: InitGuestAuthenticationFunc
  confirmVerificationCode: ConfirmVerificationCodeFunc
  confirmPasswordless: ConfirmPasswordlessFunc
  getAuthenticationHeader: GetAuthenticationHeaderFunc
  logOut: LogOutFunc
  startPasswordless: StartPasswordlessFunc
  setIsAuthenticated: (value: boolean) => void
  refreshAuthToken: () => void
  isAuthenticated: boolean
  children: React.ReactNode
}) {
  React.useEffect(() => {
    initGuestAuthentication(
      gatewayOrigin,
      () => {
        setIsAuthenticated(true)
      },
      envToToastEnv(config.environment)
    )
  }, [gatewayOrigin, initGuestAuthentication, setIsAuthenticated])

  const context = {
    isAuthenticated,
    setIsAuthenticated,
    getAuthenticationHeader,
    startPasswordless,
    confirmVerificationCode,
    confirmPasswordless,
    refreshAuthToken,
    logOut
  }
  return <Context.Provider value={context}>{children}</Context.Provider>
}

export function useAuth() {
  return React.useContext(Context)
}

function envToToastEnv(env: String): ToastEnvironment {
  switch(env) {
    case 'production':
      return ToastEnvironment.PROD
    case 'staging':
    // development is used for devpreprod also so set to preprod
    case 'development':
      return ToastEnvironment.PREPROD
    default:
      return ToastEnvironment.DEV
  }
}