import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import urljoin from 'url-join';

import UserNavWrapper from 'src/public/components/default_template/nav/UserNav';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';

import { TOAST_LOCAL_PATH } from 'public/js/siteUtilities';

import { resources, server } from 'config';


const Nav = () => {
  return (
    <>
      <div className="toastLocalNav navWrapper">
        <div className="nav">
          <div className="left">
            <div data-testid="logoLink" nav-role="logo">
              <Link href={urljoin(`${server.protocol}://${resources.toastLocalHost}`, TOAST_LOCAL_PATH)} sameTab>
                <div className="logoWrapper">
                  <Image alt="Toast logo" src="/icons/toast-logo-orange.svg" className="logo" eagerLoad />
                </div>
              </Link>
            </div>
          </div>
          <div className="middle"></div>
          <div className="right">
            <div className="loginBtn ">
              <UserNavWrapper />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" autoClose={3000} transition={Slide} hideProgressBar newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover theme="dark" limit={2} />
    </>
  );
};

export default Nav;
