import React from 'react';

import { Restaurant } from 'schema-dts';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { RestaurantDataFragment } from 'src/apollo/sites';
import { mergeSchedules, schemaOrgOpeninghours } from 'src/lib/js/schedule';

import { getImageUrl } from 'shared/components/common/Image';
import { OORestaurant, RestaurantLocation } from 'shared/components/common/restaurant_context/RestaurantContext';

import { ScheduleType } from 'public/components/online_ordering/types';
import { Menus } from 'public/components/online_ordering/useOOMenu';
import SchemaThing from 'public/components/seo/SchemaThing';

type Props = {
  restaurant: Pick<OORestaurant, 'name' | 'location' | 'i18n'> | undefined
  site: Pick<RestaurantDataFragment, 'meta' | 'config'>
  selectedLocation: RestaurantLocation
  locationSchedule: ScheduleType | undefined
  menus: Menus | undefined
}

const OrderPageSchema = ({ restaurant, site, selectedLocation, locationSchedule, menus }: Props) => {
  if(!restaurant) {
    return null;
  }
  return <SchemaThing<Restaurant> json={routes => ({
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    '@id': routes.location,
    sameAs: site.meta?.urls || undefined,
    name: restaurant?.name,
    servesCuisine: site.meta?.cuisine,
    paymentAccepted: site.meta?.acceptedPaymentTypes,
    priceRange: site.meta?.priceRange,
    image: site.meta.icon ? getImageUrl(site.meta.icon) : undefined,
    address: {
      '@type': 'PostalAddress',
      addressCountry: restaurant.i18n.country || undefined,
      postalCode: selectedLocation?.zipcode,
      addressRegion: selectedLocation?.state,
      addressLocality: selectedLocation?.city,
      streetAddress: selectedLocation?.address2 ? `${selectedLocation?.address1}, ${selectedLocation?.address2}` : selectedLocation?.address1
    },
    ...selectedLocation?.lat && selectedLocation?.long
      ? {
        geo: {
          '@type': 'GeoCoordinates',
          longitude: `${selectedLocation?.long}`,
          latitude: `${selectedLocation?.lat}`
        }
      }
      : {},
    acceptsReservations: !!selectedLocation.reservationsUrl,
    telephone: selectedLocation.phoneNumber || restaurant.location.phone,
    openingHoursSpecification: schemaOrgOpeninghours(mergeSchedules(selectedLocation, locationSchedule, !!site.config.isOnlineOrderingOnly, DiningOptionBehavior.TakeOut)),
    hasMenu: {
      '@type': 'Menu',
      '@id': `${routes.location}`,
      hasMenuSection: menus?.map(menu => ({
        '@type': 'MenuSection',
        '@id': `${routes.location}#${menu?.guid}`,
        name: menu?.name,
        hasMenuSection: menu.groups?.filter(group => group != null).map(menuGroup => ({
          '@type': 'MenuSection',
          '@id': `${routes.location}#${menuGroup?.guid}`,
          name: menuGroup?.name,
          description: menuGroup?.description,
          hasMenuItem: menuGroup?.items?.map(menuItem => ({
            '@type': 'MenuItem',
            sameAs: menuItem?.guid ? routes.menuItemUrl(selectedLocation.externalId, menuItem.name || '', menuItem.guid) : undefined
          }))
        }))
      }))
    }
  })} />;
};

export default OrderPageSchema;
