import React from 'react';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';

interface ShowForUSProps {
  testId?: string
  children: React.ReactNode
}
export const ShowForUS = ({ testId, children }: ShowForUSProps) => {
  if(useIsIntlRestaurant()) {
    return null;
  }
  return <div data-testid={testId}>{children}</div>;
};
