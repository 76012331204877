import 'intersection-observer';
import React, { useEffect, useState } from 'react';

export type Config = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
}

const config: Config = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: [0, 1]
};

const useInView = (
  node?: React.RefObject<HTMLElement> | null,
  options: Config = config
): boolean => {
  const { root = null, rootMargin, threshold } = options;

  const [inView, setInView] = useState<boolean>(false);

  useEffect(() => {
    // Create an observer instance linked to the callback function
    if(node?.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(({ isIntersecting }) => setInView(isIntersecting));
      }, options);

      // Start observing the target node for configured mutations
      observer.observe(node.current);

      return () => {
        observer.disconnect();
      };
    }

    return () => { };
  }, [node, root, rootMargin, threshold, options]);

  return inView;
};

export default useInView;
