import queryString from 'query-string';

import { toBase64 } from 'src/lib/js/base64';

import { resources } from 'config';

type SearchableLocation = {
  latitude?: number | null,
  longitude?: number | null,
  city: string,
  state?: string
};

export const rxLocationSearchUrl = (location?: SearchableLocation) =>
  location?.latitude && location?.longitude
    ? `https://${resources.toastLocalHost}/local/search?${queryString.stringify({
      filters: toBase64(JSON.stringify({
        userLat: location.latitude,
        userLong: location.longitude,
        userCity: location.city,
        userState: location.state
      }))
    })}`
    : undefined;
