import React, { useState } from 'react';

type Props = {
  title: string;
  defaultState: 'open' | 'close';
}

const TitleCard = ({ title, defaultState, children } : React.PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(defaultState == 'open');

  return (
    <div className={`title-card ${ isOpen ? 'open' : 'close'}`}>
      <div className="title txt-body-lg" onClick={() => setIsOpen(!isOpen)}>
        {title}
      </div>
      <div className="children">
        {children}
      </div>
    </div>
  );
};

export default TitleCard;
