import React, { useEffect } from 'react';

import useInView, { Config } from './useInView';

const useOnVisible = (ref: React.RefObject<HTMLElement>, cb?: () => void, config?: Config) => {
  const isVisible = useInView(ref, config);

  useEffect(() => {
    if(isVisible && cb) {
      cb();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};

export default useOnVisible;
