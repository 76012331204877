import React, { useCallback, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { generatePath } from 'react-router';

import { WithContext } from 'schema-dts';
import urljoin from 'url-join';
import { v4 as uuid } from 'uuid';

import EditorAwareExport from 'src/editor/build-tools/exporter';
import { RequestContextProps } from 'src/lib/js/context';
import { getHost } from 'src/lib/js/utilities';
import { AllowedSchemaThings } from 'src/public/components/seo/SchemaThing';
import { applyURLDomainOverride, usePageDomains } from 'src/public/js/PageDomainContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { ensureHTTPPrefix } from 'public/components/seo/protocolPrefix';


import StructuredDataContext, { SchemaId } from './StructuredDataContext';

type SchemaStore = { schemaId: SchemaId, schema: WithContext<AllowedSchemaThings> | AllowedSchemaThings } // JSON+LD scripts produced by the SchemaThing.tsx component
type SchemaStack = SchemaStore[]

type Props = React.PropsWithChildren<Pick<RequestContextProps, 'staticContext'>>

const StructuredDataProvider = ({ children, staticContext }: Props) => {
  const location = useLocation();
  const { locations } = useRestaurant();
  const { pageDomainOverrides } = usePageDomains();
  const { homePath, orderPathPattern } = useRestaurantRoutes();
  const [schemaStack, setSchemaStack] = useState<SchemaStack>([]);
  const hostname = getHost(staticContext);

  const orderUrl = useCallback((locationGuid: string) => {
    const loc = locations?.find(loc => loc.externalId === locationGuid);
    const url = urljoin(ensureHTTPPrefix(hostname), loc?.shortUrl ? generatePath(orderPathPattern, { slug: loc.shortUrl }) : '');
    // Resolve and substitue any overriding domains for the given URL's path.
    const resolvedUrl = applyURLDomainOverride(url, pageDomainOverrides);
    return resolvedUrl;
  }, [locations, hostname, pageDomainOverrides, orderPathPattern]);

  /** The main schema object for this page. There should only be one schema object (the latest mounted) in focus per-page. */
  const activeSchema = useMemo(() => {
    return schemaStack[schemaStack.length - 1]?.schema ?? null;
  }, [schemaStack]);

  const mountSchema = useCallback(<T extends AllowedSchemaThings>(schema: WithContext<T> | T): SchemaId => {
    // Add the schema to the stack and store.
    const id: SchemaId = uuid();
    setSchemaStack(schemas => [...schemas, { schemaId: id, schema }]);
    return id;
  }, [setSchemaStack]);

  const dismountSchema = useCallback((id: SchemaId) => {
    setSchemaStack(schemaEntries => schemaEntries.filter(entry => entry.schemaId !== id));
  }, [setSchemaStack]);


  return (
    <StructuredDataContext.Provider
      value={{ mountSchema, dismountSchema, primarySchema: activeSchema, homePath, location, hostname, orderUrl: orderUrl, staticContext }}>{children}
    </StructuredDataContext.Provider>);
};

const EditorStructuredDataProvider = ({ children }: Props) => {
  return <>{children}</>;
};

export default EditorAwareExport(StructuredDataProvider, EditorStructuredDataProvider);
