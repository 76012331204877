
import useCookie from 'react-use-cookie';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useStaticSiteContext } from 'src/public/components/app/StaticSiteContext';
import { isToastLocalRequest, isToastOrderRequest } from 'src/public/js/siteUtilities';
import { GIA_ACCESS, PWLESS_REFRESH } from 'src/shared/components/common/authentication/constants';

import config from 'config';


/**
 * The GIA authentication library currently depends upon the `localStorage` value "hasActiveSession" in order to determine
whether to use the new vs. legacy authentication mechanism.
 * - [localStorage key definition is "hasActiveSession"](https://github.toasttab.com/toasttab/library-authentication/blob/87169123bda61886489c9518b9c342963958d45e/packages/guest-authentication-js/src/AuthClient/AuthClient.ts#L31-L37)
 * - If the value is not set to "true", it [bypasses session initialization when not present](https://github.toasttab.com/toasttab/library-authentication/blob/87169123bda61886489c9518b9c342963958d45e/packages/authentication-utils/src/business/PasswordlessAuthClient.ts#L57)
 */
function setActiveSession() {
  if(typeof window === 'undefined') return;
  localStorage.setItem('hasActiveSession', 'true');
}

/**
 * Determines whether "GIA" authentication should be used.
 * This cookie is expected to have been set by this or another application on the toasttab.com domain,
 * and as a side-effect this application will "respect" that preference, as to allow cross-application
 * login state across toasttab.com.
 *
 * GIA-based authentication currently depends upon setting
 * the credentials as a cookie on:
 * - domain: .ws-preprod-api.eng.toasttab.com
 * - path: /authentication
 *
 * Due to restrictions on third-party cookies present and forthcoming on most browsers ([see more](https://developers.google.com/privacy-sandbox/cookies)),
 * GIA-based authentication will fail to work on custom domain providers of sites-web, as they would be incapable of setting/getting
 * a cookie registered to a third-party domain, even if it was set on that site.
 *
 * Therefore, sites-web should *never* serve this cookie as a response on custom domains,
 * as this would break authorization from working at all!
 *
 * In order to expand the usage of GIA auth at a future time, sites-web may consider pursuing an incremental
 * rollout of the woo-gia-direct-auth when serving to toasttab.com domains. For example, if toast-customer-access and
 * toast-customer-refresh have expired, set woo-gia-direct-auth to move a user to the new authentication mechanism.
*/
const useGiaAuthCookie = () => {
  return useCookie(GIA_ACCESS);
};

export const useGiaAuth = () => {
  const { ooGiaAuthKillSwitch, stpOoGiaAuthRollout } = useFlags();
  const [giaAuthOverride] = useGiaAuthCookie();
  // A refresh token's presence means that it is unexpired, and therefore we should persist using it
  const [passwordlessRefreshToken] = useCookie(PWLESS_REFRESH);

  const staticRequestContext = useStaticSiteContext();
  const isToastTabDomain = isToastLocalRequest(staticRequestContext) || isToastOrderRequest(staticRequestContext);

  if(!isToastTabDomain) return false;
  if(ooGiaAuthKillSwitch) return false;

  if(giaAuthOverride === 'true') {
    setActiveSession();
    return true;
  }

  if(!stpOoGiaAuthRollout) return false;

  if(!passwordlessRefreshToken || config.giaAuth) {
    setActiveSession();
    return true;
  }

  return false;
};
