import React, { useMemo } from 'react';

import classNames from 'classnames';

type Props = {
  children: React.ReactNode | string;
  type?: 'primary' | 'secondary';
  maxWidth?: string; // When set, text in the pill will be truncated with an ellipis (...) to not exceed maxWidth.
};

const Pill = ({ children, type = 'primary', maxWidth }: Props) => {
  const tooltip = useMemo(() => maxWidth && typeof children === 'string' ? { title: children } : {}, [maxWidth, children]);

  return (
    <div className={classNames('pill', { type, truncated: maxWidth })} style={{ maxWidth: maxWidth }} {...tooltip}>
      {children}
    </div>
  );
};

export default Pill;
