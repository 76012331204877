import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import { OORestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { Cart } from 'public/components/online_ordering/types';

export const getQuoteTime = (diningOptionBehavior: DiningOptionBehavior, restaurant: OORestaurant, cart?: Cart | null) =>
  diningOptionBehavior === DiningOptionBehavior.Delivery
    ? cart?.deliveryQuoteTime || restaurant.minimumDeliveryTime
    : cart?.takeoutQuoteTime || restaurant.minimumTakeoutTime;
