import React from 'react';

import { ToggleInput } from 'shared/components/common/forms';

type MarketingSubscriptionCheckboxProps = {
  name: string;
  isActive: boolean;
  setActive: (arg: boolean) => void;
  disabled?: boolean;
  legalText: string;
};

export enum MarketingSubscriptionCheckboxLegalText {
  SMS = 'Text me recurring exclusive offers from this restaurant or restaurant group.',
  EMAIL = 'Email me news and offers from this restaurant or restaurant group.',
}

export const MarketingSubscriptionCheckbox = ({ isActive, setActive, disabled = false, name, legalText }: MarketingSubscriptionCheckboxProps) => {
  return ( //subscribeToSmsMarketing
    <div className="marketingCheckbox">
      <ToggleInput
        type="checkbox"
        name={name}
        id={name}
        dataTestId={name}
        disabled={disabled}
        checked={isActive}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => setActive(event.target.checked)}
        onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
          if(event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            setActive(!isActive);
          }
        }}>
        <div className="subtitle" role="contentinfo">
          {legalText}
        </div>
      </ToggleInput>
    </div>
  );
};
