import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { getMenuItemSrcSet } from 'public/components/default_template/menu_section/MenuItemCard';
import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';

const DeeplinkItemModal = ({ item }: {item: MenuItem}) => {
  const { ooRestaurant, selectedLocation } = useRestaurant();
  const { orderPath } = useRestaurantRoutes();
  const { isOpen, onClose } = useModal({ defaultOpen: true });

  const displayedRestaurantName = selectedLocation.name || ooRestaurant?.name;
  const metaTitle = `${displayedRestaurantName} - ${item.name}`;
  const metaDescription = `Order ${item.name} online from ${displayedRestaurantName}. ${item.description ?? ''}`.trimEnd();
  const imageSet = useMemo(() => item.imageUrls ? getMenuItemSrcSet(item.imageUrls) : {}, [item.imageUrls]);
  const openGraphImage = item.image ?? imageSet['600w'] ?? imageSet['900w'];

  return (
    <div data-testid="deeplink-item-modal">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="og:title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
        {openGraphImage && <meta name="og:image" content={openGraphImage}></meta>}
      </Helmet>
      <ItemModal
        withTransitions={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          window.history.replaceState(null, '', orderPath);
        }}
        restaurantGuid={selectedLocation.externalId}
        shortUrl={ooRestaurant?.shortUrl}
        specialRequestsEnabled={ooRestaurant?.specialRequestsConfig.enabled}
        specialRequestsPlaceholder={ooRestaurant?.specialRequestsConfig.placeholderMessage}
        itemGroupGuid={item.itemGroupGuid}
        itemGuid={item.guid}
        itemTags={item.itemTags}
        shouldShowHighlights />
    </div>
  );
};

export default DeeplinkItemModal;
