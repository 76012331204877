import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch, UseFormReturn } from 'react-hook-form';

import classnames from 'classnames';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import useTracker from 'src/lib/js/hooks/useTracker';
import Image from 'src/shared/components/common/Image';
import PhoneInput from 'src/shared/components/common/form_input/PhoneInput';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { ModalContent, useModalContext, ModalCloseButton } from 'shared/components/common/modal';
import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { alertSuccess } from 'shared/js/alertUtils';
import { asValidPhoneCountryCode } from 'shared/js/phoneUtils';

import { CompleteProfileForm } from 'public/components/default_template/online_ordering/account/completeProfileForm/CompleteProfileForm';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { ConfirmCodeForm } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';


type FormInputs = {
  yourInfoPhone: string;
}

enum LoginStage {
  EnterPhone = 'EnterPhone',
  EnterVerificationCode = 'EnterVerificationCode',
  CompleteProfile = 'CompleteProfile'
}

export const PwlessAuth = ({ source }: {source: AuthenticationSource}) => {
  const [loginStage, setLoginStage] = useState(LoginStage.EnterPhone);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: { yourInfoPhone: '' }
  });
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });

  return (
    <ModalContent contentClassName="pwlessModalContent fullScreenMobile" data-testid="PwlessAuth" ariaLabelledBy="pwless-auth-modal-header">
      <div className={classnames('modalHeader', { withBackArrow: loginStage !== LoginStage.EnterPhone })} data-testid="PwlessAuth">
        {loginStage !== LoginStage.EnterPhone &&
          <button aria-label="Return to phone input" className="backArrow" onClick={() => {
            setLoginStage(LoginStage.EnterPhone);
            formMethods.reset({ yourInfoPhone: '' });
          }}>
            <Image alt="Back" src="icons/arrow-left-gray.svg" />
          </button>}
        <ModalCloseButton />
      </div>
      <div className="body">
        {loginStage === LoginStage.CompleteProfile
          ? <CompleteProfileForm phoneNumber={phoneNumber} />
          : <LoginForm formMethods={formMethods} loginStage={loginStage} setLoginStage={setLoginStage} source={source} />}
      </div>
    </ModalContent>
  );
};


const LoginForm = ({
  formMethods,
  loginStage,
  setLoginStage,
  source
}: {
  formMethods: UseFormReturn<FormInputs>,
  loginStage: LoginStage,
  setLoginStage: (loginStage: LoginStage) => void,
  source: AuthenticationSource,
}) => {
  const { onClose } = useModalContext();
  const { customer, passwordlessLogin, fetchCustomer, completeSignup } = useCustomer();
  const [error, setError] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);
  const tracker = useTracker();
  const ooRestaurant = useOptionalRestaurant()?.ooRestaurant;

  const country = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });
  const parsedPhoneNumber = useMemo(() =>
    isValidPhoneNumber(phoneNumber, country) ? parsePhoneNumber(phoneNumber, country) : null
  , [country, phoneNumber]);

  const validatePhone = useCallback((value: string) =>
  // Don't use the memoized parsedPhoneNumber declared above because this is a callback to be passed for form validation
    isValidPhoneNumber(value, country) || 'Enter a valid local phone number'
  , [country]);

  const handlePhoneInputKeydown = async (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      await onSubmitPhone();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onSubmitPhone = useCallback(async () => {
    if(!parsedPhoneNumber) return;

    // If it is a valid local phone number in the country of the rx, send the code
    tracker.track('Clicked Send code', {
      source,
      countryCode: parsedPhoneNumber.country
    });

    setVerificationLoading(true);
    const success = await passwordlessLogin(parsedPhoneNumber.number);
    setVerificationLoading(false);

    if(success) {
      setError('');
      setLoginStage(LoginStage.EnterVerificationCode);
    } else {
      setError('Error sending confirmation code');
    }
  }, [setVerificationLoading, setLoginStage, passwordlessLogin, parsedPhoneNumber, tracker, source]);

  const onLogin = useCallback(async () => {
    const fetchedCustomer = await fetchCustomer();
    if(!fetchedCustomer) {
      setLoginStage(LoginStage.CompleteProfile);
    } else {
      onClose();
      alertSuccess(fetchedCustomer?.firstName ? `Welcome back, ${fetchedCustomer?.firstName}!` : 'Welcome back!');
      tracker.track('Completed 2FA', { source, newOrExistingAccount: 'existing account' });
      await completeSignup(fetchedCustomer?.email || '', fetchedCustomer?.firstName || '', fetchedCustomer?.lastName || '');
    }
  }, [setLoginStage, fetchCustomer, onClose, tracker, source, completeSignup]);

  return (
    <FormProvider {...formMethods}>
      {verificationLoading && <LoadingSpinnerOverlay />}
      <div className="headerContent">
        <h2 className="title" id="pwless-auth-modal-header">{loginStage === LoginStage.EnterVerificationCode ? 'Enter verification' : 'Enter your phone number'}</h2>
        <p className="subtitle">
          {loginStage === LoginStage.EnterVerificationCode ? `Code sent to ${parsedPhoneNumber ? parsedPhoneNumber.formatNational() : phoneNumber}` : 'We\'ll send you a code to log in or sign up.'}
        </p>
      </div>
      <div id="pwlessauthcontent">
        {loginStage === LoginStage.EnterVerificationCode && parsedPhoneNumber ?
          <ConfirmCodeForm phoneNumber={parsedPhoneNumber.number} newAccount={false} onComplete={onLogin} sendCode={onSubmitPhone} /> :
          <div className="phoneForm">
            <PhoneInput
              id="yourInfoPhone"
              aria-labelledby="pwlessauthcontent"
              required
              label="Phone number"
              validate={validatePhone}
              autoComplete="tel"
              defaultValue={customer?.phone || ''}
              warning={error}
              filled={Boolean(customer?.phone)}
              onKeyDown={handlePhoneInputKeydown}
              unregisterOnUnmount={false}
              shouldUnFocus={false}
              initialFocus={true}
              isSingletonField={true} />
            <button type="button" className="submitPhoneButton" onClick={onSubmitPhone} disabled={!parsedPhoneNumber}>
              <div className="text"><span>Continue with</span><Image alt="Toast" src="icons/toast-logo-white-with-text.svg" /></div>
            </button>
          </div>}
      </div>
      {loginStage === LoginStage.EnterPhone &&
        <p className="legal" role="contentinfo">
          By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.
        </p>}
    </FormProvider>
  );
};
