import { createContext } from 'react';

import { WithContext } from 'schema-dts';

import { RequestContextProps } from 'src/lib/js/context';
import { AllowedSchemaThings } from 'src/public/components/seo/SchemaThing';

export type SchemaId = string // Generated by uuid

type ContextValue = {
  mountSchema: (SchemaThing: WithContext<AllowedSchemaThings> | AllowedSchemaThings) => SchemaId,
  dismountSchema: (id: SchemaId) => void
  primarySchema: WithContext<AllowedSchemaThings> | AllowedSchemaThings | null
  homePath: string
  hostname: string
  orderUrl: (guid: string) => string
} & Pick<RequestContextProps, 'location' | 'staticContext'>

const StructuredDataContext = createContext<ContextValue | undefined>(undefined);

export default StructuredDataContext;
