import { useEffect, useMemo, useState } from 'react';

import { useEditor } from '@toasttab/sites-components';
import { throttle } from 'lodash';

import { useStaticSiteContext } from 'src/public/components/app/StaticSiteContext';

// Maximum screen width in pixels, from `src/shared/styles/responsive.scss`
export enum ScreenWidth {
  EXTRA_SMALL = 567,
  SMALL = 767,
  MEDIUM = 1023,
  LARGE = 1279
}

// @ts-ignore
const DEFAULT_IS_MOBILE = typeof __IS_MOBILE__ !== 'undefined' ? __IS_MOBILE__ : false;

export const useIsMobile = (maxMobileWidth: number): boolean => {
  const { isEditor, screenType } = useEditor();
  const staticSiteContext = useStaticSiteContext();
  const [isMobile, setIsMobile] = useState<boolean>(Boolean(staticSiteContext?.isMobile ?? DEFAULT_IS_MOBILE));

  useEffect(() => {
    if(isEditor) {
      setIsMobile(screenType === 'mobile');
    } else if(typeof window !== 'undefined') {
      const mediaQuery = `(max-width: ${maxMobileWidth}px)`;
      const mediaQueryList = window.matchMedia(mediaQuery);
      setIsMobile(mediaQueryList.matches);

      const updateIsMobile = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      mediaQueryList.addEventListener('change', updateIsMobile);
      return () => window.removeEventListener('change', updateIsMobile);
    }
    return;
  }, [setIsMobile, maxMobileWidth, isEditor, screenType]);

  return isMobile;
};

export const useScreenWidth = (): number => {
  const _window = useMemo(() => typeof window !== 'undefined' ? window : {} as Window, []);
  const [width, setWidth] = useState(_window.innerWidth);
  useEffect(() => {
    const updateWidth = throttle(() => setWidth(_window.innerWidth), 500);
    _window.addEventListener('resize', updateWidth);
    return () => {
      _window.removeEventListener('resize', updateWidth);
    };
  }, [_window]);

  return width;
};
