import React from 'react';
import Highlighter from 'react-highlight-words';

import { useMenuSearchContext } from './MenuSearchContext';
import { removeAccents } from './searchFilterUtils';

export const SearchHighlightedText = ({ text, className }: { text: string, className?: string }) => {
  const { searchRegExp } = useMenuSearchContext();

  return (
    <Highlighter
      className={className}
      sanitize={removeAccents}
      searchWords={[searchRegExp]}
      textToHighlight={text} />
  );
};
