import React, { createContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

export type SiftContextType = {
  sessionId: string | null;
};

export const SiftContext = createContext<SiftContextType | null>(null);

// Create the context provider
export const SiftContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  // Generate a UUID when the component mounts
  const sessionId = React.useMemo(() => {
    return uuidv4();
  }, []);

  return (
    <SiftContext.Provider value={{ sessionId }}>
      {children}
    </SiftContext.Provider>
  );
};

export const useSift = (): SiftContextType => {
  const siftContext = React.useContext(SiftContext);
  if(siftContext === null) {
    throw new Error('useSift must be used within a SiftContextProvider');
  }
  return siftContext;
};
