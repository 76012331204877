import React, { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';

import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import PageWrapper from 'public/components/default_template/PageWrapper';
import CheckoutForm from 'public/components/default_template/online_ordering/checkout/CheckoutForm';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { CheckoutContextProvider, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { DeliveryContextProvider } from 'public/components/online_ordering/DeliveryContext';
import { FulfillmentContextProvider } from 'public/components/online_ordering/FulfillmentContext';
import { GiftCardContextProvider } from 'public/components/online_ordering/GiftCardContext';
import { LoyaltyContextProvider, useLoyalty } from 'public/components/online_ordering/LoyaltyContext';
import { MarketingContextProvider } from 'public/components/online_ordering/MarketingContext';
import OffersContextProvider, { useOffers } from 'public/components/online_ordering/OffersContext';
import { PaymentContextProvider } from 'public/components/online_ordering/PaymentContext';
import { TimeBasedRuleContextProvider } from 'public/components/online_ordering/TimeBasedRuleContext';
import { PayPalProvider, usePaypal } from 'public/components/online_ordering/paypalUtils';

import { DebugPanel } from './debug/DebugPanel';
import { getFirstOrThirdParty } from './online_ordering/checkout/loyalty/LoyaltyUtils';


export const CheckoutPageContent = ({ nav, footer }: { nav: React.ReactNode, footer?: React.ReactNode }) => {
  const { track } = useThrottledTracker();
  const { pointsBalance, redemptions } = useLoyalty();
  const { ooRestaurant } = useRestaurant();
  const { enabledPaymentOptions } = useCheckout();
  const { isPaypalFeatureFlagLoaded, isPaypalScriptLoading } = usePaypal();
  const { achievedOffers } = useOffers();

  useEffect(() => {
    if(
      typeof window !== 'undefined'
      && isPaypalFeatureFlagLoaded
      && !isPaypalScriptLoading
      && ooRestaurant
    ) {
      track(
        'Checkout page view',
        {
          firstOrThirdParty: getFirstOrThirdParty(ooRestaurant.loyaltyConfig?.programName),
          pointsBalance: pointsBalance,
          numOfRedemptions: redemptions.length,
          numOffersAchieved: achievedOffers?.length ?? 0,
          enabledPaymentOptions: Array.from(enabledPaymentOptions.values())
        }
      );
    }
  // run once when all the data has finished loading
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, ooRestaurant, pointsBalance, redemptions.length, isPaypalScriptLoading, isPaypalFeatureFlagLoaded]);

  return (
    <div className="defaultTemplate whiteBackground" data-testid="checkoutPage">
      {nav}
      <div className="paddedSection">
        <div className="checkout" role="main" id="main" aria-label="checkout page">
          <CheckoutForm />
          <DebugPanel />
        </div>
      </div>
      {footer}
    </div>
  );
};

const RestaurantCheckoutPage = () => <PageWrapper Component={CheckoutPageContent} shouldShowPreviewBanner={false} />;

export const ContextualCheckoutPage = ({ children }: React.PropsWithChildren<{}>) => {
  const ooClient = useOOClient();

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider>
        <TimeBasedRuleContextProvider>
          <DeliveryContextProvider>
            <FulfillmentContextProvider>
              <GiftCardContextProvider>
                <PaymentContextProvider>
                  <PayPalProvider>
                    <MarketingContextProvider>
                      <CheckoutContextProvider>
                        <LoyaltyContextProvider>
                          <OffersContextProvider>
                            {children}
                          </OffersContextProvider>
                        </LoyaltyContextProvider>
                      </CheckoutContextProvider>
                    </MarketingContextProvider>
                  </PayPalProvider>
                </PaymentContextProvider>
              </GiftCardContextProvider>
            </FulfillmentContextProvider>
          </DeliveryContextProvider>
        </TimeBasedRuleContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};

const CheckoutPage = () => <ContextualCheckoutPage><RestaurantCheckoutPage /></ContextualCheckoutPage>;
export default CheckoutPage;
