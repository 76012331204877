import React, { useMemo } from 'react';

import { Marker as GoogleMapsMarker, InfoWindow } from '@react-google-maps/api';

import { UpcomingSchedules, DiningOptionBehavior, RestaurantLocationsQuery } from 'src/apollo/onlineOrdering';
import { getTakingOrdersTillString } from 'src/lib/js/schedule';

type OORestaurant = NonNullable<NonNullable<RestaurantLocationsQuery['restaurants']>[0]> & { __typename: 'Restaurant' };

type InfoProps = {
  restaurant: OORestaurant;
  selectedDiningOption?: DiningOptionBehavior;
}

type Props = {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  restaurant: OORestaurant;
  onClick: () => void;
  isInfoOpen?: boolean;
  isSelected?: boolean;
  selectedDiningOption?: DiningOptionBehavior;
}

const DetailMarker = ({ restaurant, selectedDiningOption }: InfoProps) => {
  const isOpen = useMemo(() =>
    (selectedDiningOption === DiningOptionBehavior.Delivery ? restaurant.schedule.asapAvailableForDelivery : restaurant.schedule.asapAvailableForTakeout) ?? false, [selectedDiningOption, restaurant]);
  const diningBehaviorSchedule: UpcomingSchedules | undefined = restaurant.schedule.upcomingSchedules?.filter(schedule => schedule.behavior == selectedDiningOption)[0];
  const timeZoneId = useMemo(() => restaurant.timeZoneId || Intl.DateTimeFormat().resolvedOptions().timeZone, [restaurant]);

  return (
    <div className="mapInfoWindow detail">
      <h3>{restaurant.whiteLabelName || restaurant.name}</h3>
      <hr />
      <h4>Address</h4>
      <div>
        <div>{restaurant.location.address1}{restaurant.location.address2 ? `, ${restaurant.location.address2}` : null}</div>
        <div>{restaurant.location.city}, {restaurant.location.state} {restaurant.location.zip}</div>
      </div>
      <div className="schedule">
        {getTakingOrdersTillString(isOpen, diningBehaviorSchedule, timeZoneId)}
      </div>
    </div>
  );
};

const Marker = (props: Props) => {
  return (
    <GoogleMapsMarker
      label={props.isSelected ? undefined : ' '}
      key={props.restaurant.guid}
      position={props.position}
      onClick={props.onClick}>
      {props.isInfoOpen ?
        <InfoWindow>
          <DetailMarker
            restaurant={props.restaurant}
            selectedDiningOption={ props.selectedDiningOption } />
        </InfoWindow>
        : null}
    </GoogleMapsMarker>
  );
};

export default Marker;
