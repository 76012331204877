import React from 'react';

const DemoButton = ({ buttonLocation }: { buttonLocation: string }) => {
  return (
    <div className="demoButton button blue">
      <div className="buttonChildrenWrapper">
        <a href={`https://pos.toasttab.com/request-demo?utm_source=toast_local&utm_medium=internal_referral&utm_content=${buttonLocation}&utm_term=toast_local`}>Get a demo</a>
      </div>
    </div>
  );
};

export default DemoButton;
