import { IS_EDITOR } from './is-editor';

/**
* Returns either the original component or editor component
* depending on the build `WEBPACK_IS_EDITOR` webpack build variable. This allows
* us to substitute components when in the editor without
* affecting the client build.
*
* @param originalComponent - The default component to use.
* @param editorComponent - The component to use when building for the sites-admin-spa editor.
  This component will be exported when publishing to `@toasttab/toast-sites-web`.
  This component must have a matching type signature to the originalComponent.
* @returns The component to use based on editor context
*
* @example Using the exporter
  type Props = {
    text: string
  }

  export const DefaultQuoteComponent = (props: Props) => (<blockquote>{props.text}</blockquote>);

  const EditorOnlyQuoteComponent = (props: Props) => {
    const [value, setValue] = React.useState(props.text);
    // return an alternative, editable version of the component.
    return (
      <input type="text" onChange={e => setValue(e.target.value)} value={value} />
    );
  }

  export default EditorAwareExport(DefaultQuoteComponent, EditorOnlyQuoteComponent);

  // To import the component, use the standard esmodule import syntax
  import QuoteComponent from "path/to/components/Quote.tsx";

  // You can still import the readonly version of the component by using named exports and imports.
  import { DefaultQuoteComponent } from "path/to/components/Quote.tsx";
*/

const EditorAwareExport = <T extends {}>(originalComponent: T, editorComponent: T): T => {
  let ComponentToExport: T;

  if(IS_EDITOR) {
    ComponentToExport = editorComponent;
  } else {
    ComponentToExport = originalComponent;
  }
  return ComponentToExport;
};

export default EditorAwareExport;
