import { CompletedOrderModifierSelection } from 'src/apollo/onlineOrdering';
import { CartSelection } from 'src/public/components/online_ordering/types';

// This type allows flattenModifiers to return a quantity with CompletedOrderModifierSelection
export type WrappedCompletedOrderModifierSelection = Omit<CompletedOrderModifierSelection, 'modifiers'> & {
  quantity?: number;
  modifiers?: Array<WrappedCompletedOrderModifierSelection>;
};

export type FlattenModifiersType = WrappedCompletedOrderModifierSelection | CartSelection;

const combineDuplicateModifiersOnLevel = (modifiers: Partial<FlattenModifiersType>[]): Array<Partial<FlattenModifiersType>> => {
  const modifierMap = new Map<string, Partial<FlattenModifiersType>>();

  modifiers.forEach(modifier => {
    if(modifier && modifier.name) {
      if(modifierMap.has(modifier.name)) {
        const existingModifier = modifierMap.get(modifier.name);
        modifierMap.set(modifier.name, {
          ...existingModifier,
          // Add the quantity of the existing modifier to the new modifier
          quantity: (existingModifier?.quantity || 1) + (modifier.quantity || 1)
        });
      } else {
        modifierMap.set(modifier.name, modifier);
      }
    }
  });

  return Array.from(modifierMap.values());
};

const assignLevelsAndCombineDupes = (modifiers: Partial<FlattenModifiersType>[], level: number | undefined = 0): Array<Partial<FlattenModifiersType> & {level: number}> => {
  if(!modifiers.length) {
    return [];
  }

  const combinedModifiers = combineDuplicateModifiersOnLevel(modifiers);

  // Goes through each modifier and assigns a level to them
  const assignedModifiers: Array<Partial<FlattenModifiersType> & {level: number}> = combinedModifiers.map(m => {
    let mods: any[] = [];
    if(m?.modifiers) {
      // Move to the next level
      mods = assignLevelsAndCombineDupes(m.modifiers, level + 1);
    }
    return {
      ...m,
      modifiers: mods,
      level,
      quantity: m?.quantity || 1
    };
  });
  return assignedModifiers;
};

// Flattens the modifiers into a single array and assigns levels to them
export const flattenModifiers = (modifiers: Partial<FlattenModifiersType>[], level: number | undefined = 0): Array<Partial<FlattenModifiersType> & {level: number}> => {
  if(!modifiers.length) {
    return [];
  }

  const nonNullModifiers: Partial<FlattenModifiersType>[] = modifiers
    .filter((m: Partial<FlattenModifiersType>): boolean => !!m) as Partial<FlattenModifiersType>[];

  const cleanedModifiers = assignLevelsAndCombineDupes(nonNullModifiers);

  const flattenedModifiers =
    cleanedModifiers.flatMap(m => {
      const nestedFlattenedModifiers = m?.modifiers ? flattenModifiers(m.modifiers, level + 1) : [];
      return [{ ...m, modifiers: undefined, level }, ...nestedFlattenedModifiers];
    });
  return flattenedModifiers;
};
