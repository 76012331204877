import React, { ReactNode } from 'react';

import { SearchHighlightedText } from 'public/components/default_template/search';

export const MenuSectionHeader = ({ header, badge }: { header: string | null | undefined; badge?: ReactNode } ) => {
  return header ?
    <h3>
      {<SearchHighlightedText text={header} />}
      {badge ? <div className="headerBadge">{badge}</div> : null}
    </h3>
    : null;
};
