import React, { useCallback, useEffect } from 'react';

import { Modal, ModalContent, ModalOverlay, ModalCloseButton, useModal } from 'shared/components/common/modal';

import CreditCardForm from 'public/components/default_template/online_ordering/checkout/payment/CreditCardForm';
import { usePayment, SelectedCreditCard } from 'public/components/online_ordering/PaymentContext';

const CreditCardModal = (props : React.PropsWithChildren<{ cardGuid?: string }>) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { newCreditCard, setSelectedCreditCard } = usePayment();

  useEffect(() => {
    setSelectedCreditCard((selectedCard: SelectedCreditCard) =>
      ({
        savedCardGuid: newCreditCard ? null : selectedCard?.savedCardGuid,
        newCardSelected: Boolean(newCreditCard)
      }));
  }, [setSelectedCreditCard, newCreditCard]);

  const submit = useCallback(() => onClose(), [onClose]);

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} preventOverlayClose>
        <ModalOverlay />
        <ModalContent>
          <div className="creditCardModalContent">
            <div className="header">
              <h3>{props.cardGuid ? 'Edit' : 'Add'} card</h3>
              <ModalCloseButton />
            </div>
            <div className="body">
              <CreditCardForm showCheckbox />
            </div>
          </div>
          <button type="button" className="creditCardModalButton primaryColorBackground primaryColorHover" disabled={!newCreditCard} onClick={submit}>Add</button>
        </ModalContent>
      </Modal>
      <span onClick={() => onOpen()}>
        {props.children}
      </span>
    </>
  );
};

export default CreditCardModal;
