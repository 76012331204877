import React, { useContext, useState } from 'react';

type SpotlightContextType = {
  isHidden: boolean;
  hide: () => void;
}

const SpotlightContext = React.createContext<SpotlightContextType>({ isHidden: false, hide: () => { } });

export const SpotlightContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isHidden, setHidden] = useState(false);

  return (
    <SpotlightContext.Provider value={{ isHidden, hide: () => setHidden(true) }}>
      {children}
    </SpotlightContext.Provider>
  );
};

export const useSpotlightContext = () => {
  const context = useContext(SpotlightContext);

  if(!context) {
    throw new Error('useSpotlightContext must be used within an instance of SpotlightContextProvider');
  }

  return context;
};
