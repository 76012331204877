import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import queryString from 'query-string';


import { toBase64 } from 'src/lib/js/base64';
import { Query } from 'src/lib/js/hooks/useQueryString';

import Image from 'shared/components/common/Image';
import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'shared/components/common/location_search/PlacesAutocomplete';


import { fetchUserLocation } from 'public/components/toast_local/rx_search/locationUtils';


import { gMapsAPIKey } from 'config';

class LocationInput extends Component<Props & { history: any }, { editMode: boolean; locationError: string}> {
  static propTypes = {
    history: PropTypes.object,
    parsedQS: PropTypes.object,
    street: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    onLocationSelected: PropTypes.func
  };

  constructor(props: Props & { history: any }) {
    super(props);
    this.state = { editMode: false, locationError: '' };
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.onClickCurrentLocation = this.onClickCurrentLocation.bind(this);
  }

  handleClickOutside() {
    this.setState(state => ({ ...state, editMode: false }));
  }

  onAddressSelected(location: any) {
    // fields used here must be specified in GMAPS_PLACERESULT_FIELDS
    if(location && location.geometry && location.geometry.location) {
      let street = '';
      let neighborhood = '';
      let city = '';
      let state = '';
      for(let component of location.address_components) {
        for(let componentType of component.types) {
          if(componentType === 'street_number') {
            street = street ? component.long_name + ' ' + street : component.long_name;
            break;
          } else if(componentType === 'route') {
            street = street ? street + ' ' + component.short_name : component.short_name;
            break;
          } else if(componentType === 'neighborhood') {
            neighborhood = component.long_name;
            break;
          } else if(componentType === 'locality') {
            city = component.long_name;
            break;
          } else if(componentType === 'administrative_area_level_1') {
            state = component.short_name;
            break;
          }
        }
      }
      const lat = location.geometry.location.lat();
      const long = location.geometry.location.lng();

      this.setState({ editMode: false, locationError: '' });
      this.props.onLocationSelected(street, neighborhood, city, state, lat, long);
    }
  }

  onClickCurrentLocation() {
    fetchUserLocation()
      .then(userLocation => {
        const qs = {
          ...this.props.parsedQS,
          filters: toBase64(JSON.stringify({
            ...this.props.parsedQS.filters,
            ...userLocation
          }))
        };
        this.props.history.push(`/local/search?${queryString.stringify(qs)}`);
      })
      .catch(() => {
        this.setState({ editMode: false, locationError: 'Sorry, there was an error finding you. Please enter your location.' });
      });
  }

  render() {
    const dropDownLabel = this.props.street || this.props.neighborhood || (this.props.city && this.props.state ? `${this.props.city}, ${this.props.state}` : this.props.city);

    return (
      <>
        <div className="location-dropdown-input-wrapper">
          {this.state.editMode ?
            <div className="address-input">
              <PlacesAutocomplete
                id="rx-search-location-dropdown"
                placeholder="Enter a city or address"
                showGenericSpinner
                apiKey={gMapsAPIKey}
                placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
                onPlaceSelected={this.onAddressSelected}
                options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }} />
            </div> :
            <div className="selected-location" onClick={() => this.setState({ editMode: true })}>
              <Image className="map-pin" src="toastLocal/icons/location-blue.svg" alt="Update location" />
              <div className="address">{dropDownLabel || 'Enter a city or address'}</div>
              <Image className="caret" src="toastLocal/splash/caret-down-blue.svg" alt="Caret" />
            </div>}
          <div className="current-location" onClick={this.onClickCurrentLocation}>
            <Image className="" src="toastLocal/icons/current-location-icon.svg" alt="Use my location" />
          </div>
        </div>
        {this.state.locationError || !dropDownLabel ?
          <p className="txt-body-sm error location-error">
            {this.state.locationError || 'Enter a city or address to begin your search'}
          </p> :
          null}
      </>
    );
  }
}

const LocationInputWithClickOutside = enhanceWithClickOutside(LocationInput);

type Props = {
  label?: string;
  parsedQS: Query
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  onLocationSelected: (street: string, neighborhood: string, city: string, state: string, lat: number, long: number) => void
}

const LocationDropDown = ({ label, parsedQS, street, neighborhood, city, state, onLocationSelected }: Props) => {
  const history = useHistory();
  return (
    <div className="location-dropdown">
      <div className="location-dropdown-label">{label}</div>
      <div className="location-dropdown-input">
        <LocationInputWithClickOutside history={history} parsedQS={parsedQS} street={street} neighborhood={neighborhood} city={city} state={state} onLocationSelected={onLocationSelected} />
      </div>
    </div>
  );
};
export default LocationDropDown;
