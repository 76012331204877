import React, { useState, useRef } from 'react';

import { useMenuSearchContext } from './MenuSearchContext';

export const SearchInput = () => {
  const { setSearchString, clearSearchString } = useMenuSearchContext();
  const textEl = useRef<HTMLInputElement>(null);

  const [searchInputValue, setSearchInputValue] = useState('');

  // searchString is used outside of this search box to control what items are filtered. This value gets updated with a debounce
  // searchInputValue is used internally to the search box e.g. to decide if we need to show the clear button
  // we need to make sure to update both of these values when the input changes
  const onUpdateValue = (newValue: string): void => {
    setSearchInputValue(newValue);

    if(!newValue) {
      clearSearchString();
    } else {
      setSearchString(newValue);
    }
  };

  return (
    <div className="ooSearch">
      <div className="searchIcon">
        <i className="inline-block leading-none" aria-hidden="true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="inline-block align-middle h-6 w-6">
            <path
              d="M15.15 15.15l5.6 5.6m-3.9-10.7a6.8 6.8 0 11-13.6 0 6.8 6.8 0 0113.6 0z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round">
            </path>
          </svg>
        </i>
      </div>

      <input
        ref={textEl}
        className="searchBox"
        type="text"
        value={searchInputValue}
        onChange={e => {
          onUpdateValue(e.target.value);
        }}
        placeholder="Search"
        maxLength={100}
        data-testid="oo-search-input" />

      <ClearIcon searchInputValue={searchInputValue} onClick={() => {
        onUpdateValue('');
        if(textEl.current) {
          textEl.current.click();
          textEl.current.focus();
        }
      }} />
    </div>
  );
};

const ClearIcon = ({ onClick, searchInputValue }: {onClick: () => void, searchInputValue: string }) => {
  if(!searchInputValue) {
    return <div className="searchClearIconPlaceHolder"></div>;
  }

  return (
    <div className="searchClearIcon">
      <button type="button" onClick={onClick}>
        <i role="img" aria-label="Clear the search input">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="inline-block align-middle h-6 w-6">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              // eslint-disable-next-line max-len
              d="M21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25s9.75 4.365 9.75 9.75zM9.78 8.72a.75.75 0 00-1.06 1.06L10.94 12l-2.22 2.22a.75.75 0 101.06 1.06L12 13.06l2.22 2.22a.75.75 0 101.06-1.06L13.06 12l2.22-2.22a.75.75 0 00-1.06-1.06L12 10.94 9.78 8.72z"
              fill="currentColor">
            </path>
          </svg>
        </i>
      </button>
    </div>
  );
};
