import { addDays, parseISO, subDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import {
  DailySchedule,
  DayOfWeek,
  DiningOptionBehavior,
  PickupWindowRule,
  ServicePeriod,
  UpcomingSchedules
} from 'src/apollo/onlineOrdering';
import { intervalContainsCurrentTime } from 'src/lib/js/schedule';

// DayOfWeek values in order based on how new Date().getDay() returns days as numbers (0 = Sunday, 1 = Monday, etc)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay#return_value
export const dayStrs = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday
];
export const getPickupWindowRuleDuration = (pickupWindowRule: PickupWindowRule, dayOfWeek: DayOfWeek) => {
  const pickupDay = pickupWindowRule.days.find(day => day.dayOfWeek === dayOfWeek);
  const duration = pickupDay?.pickupWindows[pickupDay.pickupWindows.length - 1]?.duration; // Get the last pickup window duration
  return duration || 0;
};

export const getRestaurantClosingDateTime = (servicePeriods: ServicePeriod[], scheduleDate: Date, timeZone: string) => {
  if(!servicePeriods || servicePeriods.length < 1) return null;
  const lastServicePeriod = servicePeriods[servicePeriods.length - 1]!;

  const closingTimeDate = lastServicePeriod.endTime < lastServicePeriod.startTime ?
    addDays(scheduleDate, 1)
    : scheduleDate;

  return zonedTimeToUtc(new Date(`${closingTimeDate.toISOString().split('T')[0]} ${lastServicePeriod.endTime}`), timeZone);
};

export const getEarliestPickupTime = (formattedRestaurantClosingTime: Date, pickUpWindowDuration: number) => {
  const earliestPickupTime = new Date(formattedRestaurantClosingTime.getTime());
  return new Date(earliestPickupTime.setHours(formattedRestaurantClosingTime.getHours() - pickUpWindowDuration));
};


// NOTE: Pickup window logic assumes that guest's device's time zone is the same as the time zone of the restaurant.
// If the guest's device is in a different time zone, the pickup window logic will typically work, but there may be edge cases where it does not
// due to the way utcToZonedTime and zonedTimeToUtc handle time zone conversions. If we are concerned about this in the
// future, we should switch over to using moment-timezone for dates and time zone conversions.

/**
 * Finds the business day schedule to use for pickup window validation. This will be yesterday's schedule (if yesterday
 * has an interval stretching into today), today's schedule, or null if no schedule is deemed valid based on the current date/time.
 *
 * To avoid subtracting from the wrong day's stock, we limit items with pickup windows to being scheduled for pickup in the
 * same business day only. We know a date's business day is over (i.e. closeout hour has passed) when that date
 * is no longer included in the restaurant's upcoming schedules.
 * If the restaurant is closed for the rest of the current business day, we return null.
 *
 * @param schedules the current and upcoming business day schedules, by date and in time local to the rx
 * @param timeZoneId the time zone id of the restaurant ex. 'America/New_York'
 * */
export const findPickupWindowSchedule = (schedules: DailySchedule[], timeZoneId: string):
{ dateSchedule: DailySchedule; day: DayOfWeek | undefined } | null => {
  if(schedules.length < 1) return null;

  const nowRx: Date = utcToZonedTime(new Date(), timeZoneId);
  const yesterdayRx = subDays(nowRx, 1);
  const todayDate = nowRx.getDate();

  const todaysDateSchedule = schedules.find(schedule => new Date(schedule.date).getUTCDate() === todayDate);
  const yesterdaysDateSchedule = schedules.find(schedule => new Date(schedule.date).getUTCDate() === todayDate - 1);
  const lastPeriodToday = todaysDateSchedule?.servicePeriods[todaysDateSchedule?.servicePeriods.length - 1] || null;
  const lastPeriodYesterday = yesterdaysDateSchedule?.servicePeriods[yesterdaysDateSchedule?.servicePeriods.length - 1] || null;

  // The schedules arg will only include yesterday's schedule if yesterday's closeout hour has not yet passed, so we
  // should return yesterday's schedule if the rx is still open.
  if(yesterdaysDateSchedule) {
    const yesterdayReturnSchedule = { dateSchedule: yesterdaysDateSchedule, day: dayStrs[yesterdayRx.getDay()] };
    return lastPeriodYesterday && intervalContainsCurrentTime(lastPeriodYesterday.startTime, lastPeriodYesterday.endTime, true, timeZoneId, true) ?
      yesterdayReturnSchedule
      : null;
  }

  if(todaysDateSchedule && lastPeriodToday) {
    const todayReturnSchedule = { dateSchedule: todaysDateSchedule, day: dayStrs[nowRx.getDay()] };
    // Today's last interval crosses into tomorrow, so rx is still open.
    if(lastPeriodToday.endTime < lastPeriodToday.startTime) {
      return todayReturnSchedule;
    }
    // Since we don't know exactly when yesterday's closeout hour was, we use midnight today as a placeholder for when we can
    // start placing scheduled orders for pickup today.
    return intervalContainsCurrentTime('00:00', lastPeriodToday.endTime, false, timeZoneId, false) ?
      todayReturnSchedule
      : null;
  }
  return null;
};

export const isInPickupWindow = (
  pickupWindowConstraints: PickupWindowConstraints,
  fulfillmentDateTime: Date // UTC
) => {
  return fulfillmentDateTime >= pickupWindowConstraints.earliestPickupDate && fulfillmentDateTime <= pickupWindowConstraints.latestPickupDate;
};

type PickupWindowConstraints = {
  earliestPickupDate: Date;
  latestPickupDate: Date;
};

/**
 * Gets the UTC datetime of the first and last pickup window date times based on the current time and dining option.
 *
 * @param pickupWindowRule pickup window time-based menu rule
 * @param behavior dining option behavior
 * @param upcomingSchedules the rx's upcoming daily schedules, by dining behavior. Includes a date's schedule until the rx has passed that day's closeout hour.
 *  For more context on upcomingSchedules, see https://github.toasttab.com/toasttab/toast-consumer-app-bff/blob/6c2639c09a968016fa8e65c4bdd9466115eeea5e/src/schedule/upcoming_schedules_model.js#L51
 * @param timeZoneId the time zone id of the restaurant ex. 'America/New_York'
 * */
export const getPickupWindowConstraints = (
  pickupWindowRule: PickupWindowRule,
  behavior: DiningOptionBehavior,
  upcomingSchedules: UpcomingSchedules[],
  timeZoneId: string
): PickupWindowConstraints | null => {
  let schedules = upcomingSchedules.find(upcomingSchedule => upcomingSchedule.behavior === behavior)?.dailySchedules;

  if(behavior === DiningOptionBehavior.Delivery && !schedules) { // If delivery is not available, use takeout schedules
    schedules = upcomingSchedules.find(upcomingSchedule => upcomingSchedule.behavior === DiningOptionBehavior.TakeOut)?.dailySchedules;
  }

  if(!schedules) return null;

  const scheduleInfo = findPickupWindowSchedule(schedules, timeZoneId);
  if(!scheduleInfo || !scheduleInfo.day) return null;
  const scheduleToUse = scheduleInfo.dateSchedule;

  const pickupWindowDuration = getPickupWindowRuleDuration(pickupWindowRule, scheduleInfo.day);
  const restaurantPeriods = scheduleToUse.servicePeriods;
  const restaurantClosingTime = getRestaurantClosingDateTime(restaurantPeriods, new Date(scheduleToUse.date), timeZoneId);
  if(!restaurantClosingTime) return null;
  const earliestPickupTime = getEarliestPickupTime(restaurantClosingTime, pickupWindowDuration);
  if(!earliestPickupTime) return null;

  return { earliestPickupDate: earliestPickupTime, latestPickupDate: restaurantClosingTime };
};

/**
 * Validates if the item can be fulfilled within the pickup window based on the current time and dining option.
 *
 * @param pickupWindowRule pickup window time-based menu rule
 * @param fulfillmentDateTimeISO fulfillment date time to validate. In UTC, ISO format
 * @param behavior dining option behavior
 * @param upcomingSchedules the rx's upcoming daily schedules, by dining behavior. Includes a date's schedule until the rx has passed that day's closeout hour.
 *  For more context on upcomingSchedules, see https://github.toasttab.com/toasttab/toast-consumer-app-bff/blob/6c2639c09a968016fa8e65c4bdd9466115eeea5e/src/schedule/upcoming_schedules_model.js#L51
 * @param timeZoneId the time zone id of the restaurant ex. 'America/New_York'
 */
export const validateItemWithPickupWindowFulfillmentTime = (
  pickupWindowRule: PickupWindowRule,
  fulfillmentDateTimeISO: string | null, // UTC, ISO format
  behavior: DiningOptionBehavior,
  upcomingSchedules: UpcomingSchedules[],
  timeZoneId: string
) => {
  // A null fulfillment time indicates an ASAP order, so we'll just set it to 'now'
  const fulfillmentDateTime = fulfillmentDateTimeISO ? parseISO(fulfillmentDateTimeISO) : new Date();

  const pickupWindowConstraints = getPickupWindowConstraints(pickupWindowRule, behavior, upcomingSchedules, timeZoneId);
  return pickupWindowConstraints ? isInPickupWindow(pickupWindowConstraints, fulfillmentDateTime) : false;
};
