import React, { createContext, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

import { useEditor } from '@toasttab/sites-components';

import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

export type FulfillmentBehaviorTooltipContextType = {
  openTooltip: () => void;
  closeTooltip: () => void;
  tooltipIsOpen: boolean;
}

export type FulfillmentBehaviorTooltipProps = {
  canOrderDelivery: boolean;
}
const toolTipText = 'Pickup and delivery available!';

export const FulfillmentBehaviorTooltipContext = createContext<FulfillmentBehaviorTooltipContextType>({ openTooltip: () => {}, closeTooltip: () => {}, tooltipIsOpen: false });

export const FulfillmentBehaviorTooltip = ({ canOrderDelivery: deliveryEnabled } : FulfillmentBehaviorTooltipProps) => {
  const { isEditor } = useEditor();
  const { fulfillmentTooltipIsOpen, openFulfillmentTooltip, closeFulfillmentTooltip } = useFulfillment();
  const { loadingCart, cart } = useCart();
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  useEffect(() => {
    if(deliveryEnabled && !isEditor && !loadingCart && (cart?.order?.selections?.length || 0) === 0) {
      openFulfillmentTooltip();
    }
  }, [deliveryEnabled, isEditor, openFulfillmentTooltip, loadingCart, cart?.order?.selections?.length]);

  if(isEditor || isMobile) {
    return null;
  }

  return (
    <Tooltip
      clickable
      data-testid="fulfillmentTooltip"
      isOpen={fulfillmentTooltipIsOpen}
      anchorSelect="#fulfillmentBehavior">
      <div
        onClick={e => {
          closeFulfillmentTooltip();
          e.stopPropagation();
        }} className="fulfillmentTooltip"
        tabIndex={0}>
        {toolTipText}
        <TooltipCloseIcon />
      </div>
    </Tooltip>
  );
};

export const TooltipCloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.10059 4.1001L11.9006 11.9001M11.9006 4.1001L4.10059 11.9001" stroke="white" strokeWidth="0.9" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
