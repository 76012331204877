import React from 'react';

export const ChefsHatIcon = ({ color }: {color: string}) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none" aria-label="Minimum lead time">
    {/* eslint-disable-next-line max-len */}
    <path d="M3.0392 2.96078C3.0392 1.88235 3.92156 1 4.99999 1C6.07842 1 6.96077 1.88235 6.96077 2.96078M3.62744 3.54902C3.37254 3.07843 2.88234 2.76471 2.33332 2.76471C1.5294 2.76471 0.862732 3.43137 0.862732 4.23529C0.862732 5.03922 1.5294 5.70588 2.33332 5.70588L2.25489 11H7.74508L7.66665 5.70588C8.47057 5.70588 9.13724 5.03922 9.13724 4.23529C9.13724 3.43137 8.47057 2.76471 7.66665 2.76471C7.09803 2.76471 6.60783 3.07843 6.37254 3.54902M4.01959 8.45098V5.90196M5.98038 8.45098V5.90196M2.45097 9.62745H7.35293" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const CalendarIcon = ({ color }: {color: string}) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" aria-label="Preorder">
    {/* eslint-disable-next-line max-len */}
    <path d="M3 2.5249V0.524902M7 2.5249V0.524902M0.5 4.2249H9.5M8.5 9.4749H1.5C0.95 9.4749 0.5 9.0249 0.5 8.4749V2.4749C0.5 1.9249 0.95 1.4749 1.5 1.4749H8.5C9.05 1.4749 9.5 1.9249 9.5 2.4749V8.4749C9.5 9.0749 9.05 9.4749 8.5 9.4749Z" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
