import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import IFramedCodeBase, { calculateScale } from './IFramedCodeBase';

type Props = {
  code?: string;
  parentHeight?: number | null;
  parentWidth?: number | null;
  onIframeChange?: () => void
};

const EditableIFramedCode = ({ code, parentHeight, parentWidth, onIframeChange }: Props) => {
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null);
  const [scaleRatio, setScaleRatio] = useState(0.0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);

  const resizeContent = useCallback(() => {
    if(parentHeight && parentWidth && ref?.contentDocument?.body?.clientHeight && ref?.contentDocument?.body?.clientWidth) {
      const scale = calculateScale(parentHeight, parentWidth, ref.contentDocument.body.clientHeight, ref.contentDocument.body.clientWidth);
      if(scale) {
        setScaleRatio(scale);
        setOffset((parentWidth - scale * ref.contentDocument.body.clientWidth) / 2);
      }
      setLoading(false);
    }
  }, [ref, parentHeight, parentWidth]);

  useEffect(() => {
    if(ref && parentHeight && parentWidth) {
      setLoading(true);
      ref.height = `${parentHeight}px`;
      ref.width = `${parentWidth}px`;

      resizeContent();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentHeight, parentWidth]);

  useEffect(() => {
    if(ref && onIframeChange) {
      onIframeChange();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  // Dont allow interaction with the content when rendered in the editor
  return <IFramedCodeBase className={classNames('readOnly', { loading })} code={code} setRef={setRef} iframeOnLoad={resizeContent} scaleRatio={scaleRatio} offset={offset} />;
};

export default EditableIFramedCode;
