import * as React from 'react';

import { PackagingConfig, PackagingInfo } from 'src/apollo/onlineOrdering';
import { ToggleInput } from 'src/shared/components/common/forms';

interface PackagingOptionsProps {
  packagingConfig: PackagingConfig,
  selectedPackagingOptions: PackagingInfo,
  onPackagingOptionsUpdate: (packagingItems: PackagingInfo) => void
}

export const PackagingOptions = ({ packagingConfig, selectedPackagingOptions, onPackagingOptionsUpdate }: PackagingOptionsProps) => {
  React.useEffect(() => {
    const initialSelectedPackagingOptions = [...packagingConfig.items].map(item => {
      return {
        itemConfigId: item.id,
        included: item.guestInclusionType === 'OPT_OUT'
      };
    });

    onPackagingOptionsUpdate({ packagingItems: initialSelectedPackagingOptions });
  }, [onPackagingOptionsUpdate, packagingConfig.items]);

  const onChange = (itemId:string) => {
    const newPackagingOptions = selectedPackagingOptions.packagingItems.map(item => {
      if(itemId === item.itemConfigId) {
        item.included = !item.included;
      }
      return item;
    });

    onPackagingOptionsUpdate({ packagingItems: newPackagingOptions });
  };

  return (
    <div className="packagingOptionsSelector" data-testid="packagingOptionsSelector">
      <p className="packagingOptionsGuestMessage">{packagingConfig.guestMessage}</p>
      <ul>
        {
          packagingConfig.items.map(item => {
            // see if item is selected from the selectedPackagingOptions.packagingItems array
            const selected = selectedPackagingOptions.packagingItems.filter(packagingItem => {
              if(item.id === packagingItem.itemConfigId) return true;
              return false;
            })[0];

            return (
              <li key={item.id} data-testid="packagingOptionsItem">
                <ToggleInput
                  type="checkbox"
                  data-testid="test"
                  name={item.id}
                  id={item.id}
                  checked={selected?.included}
                  onChange={() => onChange(item.id)}>
                  <p>
                    {item.guestDisplayName}
                  </p>
                  {
                    item.guestDescription && <p className="packagingItemDescription">{item.guestDescription}</p>
                  }
                </ToggleInput>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
