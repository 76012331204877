import { PlaceholderImageProps, PlaceholderImageWindowCache, generatePlaceholderImage } from './generatePlaceholderImage';

const serializeParams = (params: {[k:string]: any}) => {
  const ks = Object.keys(params);
  ks.sort();
  const vs = ks.map(k => params[k].toString());
  return vs.join('|');
};

export const getPlaceholderImage = (props: PlaceholderImageProps) => {
  if(typeof window === 'undefined') return null;
  const key = serializeParams(props);

  const cache = window as unknown as PlaceholderImageWindowCache;
  if(cache.generatedImages?.[key]) {
    return cache.generatedImages[key];
  } else {
    if(!cache.generatedImages) {
      cache.generatedImages = {};
    }
    // generate placeholder with props. Make sure to call exports.generatePlaceholderImage so mocks work in jest
    cache.generatedImages[key] = generatePlaceholderImage(props);
    return cache.generatedImages[key];
  }
};
