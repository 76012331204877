import { useContext } from 'react';

import StructuredDataContext from './StructuredDataContext';


const useStructuredData = () => {
  const context = useContext(StructuredDataContext);
  if(!context) {
    return undefined;
  } else {
    return context;
  }
};

export default useStructuredData;
