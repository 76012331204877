import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { Cart } from 'public/components/online_ordering/types';

// Copied from https://github.com/toasttab/takeout-web/blob/main/client/components/Upsells/upsell-rules.js

interface AmendedMenuItem extends MenuItem {
  groupName: string;
  menuName: string;
}

export interface RuleContext {
  cart: Cart;
  popularItems: any[];
}

type SortFunctionWithContext = (context: RuleContext, itemA: AmendedMenuItem, itemB: AmendedMenuItem) => number;

type FilterFunctionWithContext = (context: RuleContext, item: AmendedMenuItem) => boolean;


export const isNotBannedWordsFilter = (words: string[]): FilterFunctionWithContext => (_, item) => {
  const text = [item.name, item.description].join(' ').toLowerCase();
  const pattern = words.map(str => str.toLowerCase()).join('|');
  const matcher = new RegExp(`^.*${pattern}.*$`);
  return !matcher.test(text);
};

export const notInCartFilter: FilterFunctionWithContext = ({ cart }: { cart: Cart }, item: any) => {
  if(!cart) return true;
  const selections = cart && cart.order && cart.order.selections || [];
  const selectionGuids = selections.map((item: any) => item.itemGuid);
  return !selectionGuids.includes(item.guid);
};

export const menuGroupsInCartFilter = ({ cart }: { cart?: Cart | null }, item: { menuGroupGuids: string[] }) => {
  if(!cart) return false;
  const selections = cart && cart.order && cart.order.selections || [];
  const selectionGroupGuids = new Set(selections.map(item => item?.itemGroupGuid));
  return item.menuGroupGuids.some(guid => selectionGroupGuids.has(guid));
};

export const isInStockFilter: FilterFunctionWithContext = (_: any, item: any) => !item.outOfStock;

export const isNotPopularItems: FilterFunctionWithContext = ({ popularItems }: any, item: any) =>
  popularItems.every((popItem: any) => popItem.guid !== item.guid);

export const prioritizeWordsSort = (words: string[]): SortFunctionWithContext => (_, a, b) => {
  const textA = [a.name, a.description].join(' ').toLowerCase();
  const textB = [b.name, b.description].join(' ').toLowerCase();

  const aHasWord = words.some(word => textA.includes(word)) ? 1 : 0;
  const bHasWord = words.some(word => textB.includes(word)) ? 1 : 0;

  return bHasWord - aHasWord;
};

export const prioritizeHasImage: SortFunctionWithContext = (_, a, b) => {
  const aHasImage = a.imageUrls ? 1 : 0;
  const bHasImage = b.imageUrls ? 1 : 0;

  return bHasImage - aHasImage;
};

export const intelligentUpsellFilterRules: FilterFunctionWithContext[] = [
  isNotBannedWordsFilter(['alcohol', 'beer', 'wine', 'cocktail', 'abv']),
  notInCartFilter,
  isInStockFilter,
  isNotPopularItems
];

export const upsellSortRules: SortFunctionWithContext[] = [
  prioritizeWordsSort([
    'fries',
    'shake',
    'beverage',
    'dessert',
    'soda',
    'soup',
    'salad'
  ]),
  prioritizeHasImage
];
