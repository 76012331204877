import React from 'react';

import { fromUnixTime } from 'date-fns';

import { DiningOptionBehavior, OrderStatusData, OrderStatusType, ThirdPartyDeliveryStatus } from 'src/apollo/onlineOrdering';
import { CompletedOrder } from 'src/public/components/online_ordering/types';
import { toLocalDateTime } from 'src/shared/js/timeUtils';

import { StageDivider } from 'public/components/default_template/online_ordering/order_tracker/order_status_bar/OrderStatusBar';
import {
  AlertIcon,
  CurbsideIcon,
  DriverIcon,
  HomeIcon,
  OrderConfirmedIcon,
  OrderReadyIcon,
  PickupIcon,
  PreparingIcon,
  RestaurantIcon,
  ScheduledIcon
} from 'public/components/default_template/online_ordering/order_tracker/order_status_bar/OrderStatusIcons';

export enum UnifiedOrderStatus {
  SCHEDULED = 'SCHEDULED',
  RECEIVED = 'RECEIVED',
  CONFIRMED = 'CONFIRMED',
  PREPARING = 'PREPARING',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  DRIVER_EN_ROUTE_TO_RESTAURANT = 'DRIVER_EN_ROUTE_TO_RESTAURANT',
  DRIVER_EN_ROUTE_TO_DROPOFF = 'DRIVER_EN_ROUTE_TO_DROPOFF',
  DRIVER_AT_DROPOFF = 'DRIVER_AT_DROPOFF',
  DRIVER_AT_RESTAURANT = 'DRIVER_AT_RESTAURANT',
  COMPLETE = 'COMPLETE',
  UNKNOWN = 'UNKNOWN',
  CANCELLED = 'CANCELLED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  UNDELIVERABLE = 'UNDELIVERABLE',
  FAILURE_AT_RESTAURANT = 'FAILURE_AT_RESTAURANT'
}


const orderStatuses = Object.values(OrderStatusType);
const deliveryStatuses : Array<ThirdPartyDeliveryStatus | null> = [...Object.values(ThirdPartyDeliveryStatus), null];

/**
 * Create a map of combinations of OrderStatusType <-> ThirdPartyDeliveryStatus statuses to their UnifiedOrderStatus.
 * ThirdPartyDeliveryStatuses are mapped after OrderStatusTypes since they usually "override" the latter. For example,
 * if the orderStatus is Preparing but the deliveryStatus is DriverEnRouteToDropoff, we can assume the order is much
 * further along in the process than "Preparing"
 *
 */
const combinationMapping = () : MapType => {
  let mapping : MapType = {};

  deliveryStatuses.forEach(deliveryStatus => {
    mapping[`${OrderStatusType.Received}-${deliveryStatus}`] = UnifiedOrderStatus.RECEIVED;
    mapping[`${OrderStatusType.Future}-${deliveryStatus}`] = UnifiedOrderStatus.SCHEDULED;
    mapping[`${OrderStatusType.Preparing}-${deliveryStatus}`] = UnifiedOrderStatus.PREPARING;
    mapping[`${OrderStatusType.Approved}-${deliveryStatus}`] = UnifiedOrderStatus.CONFIRMED;
    mapping[`${OrderStatusType.InPreparation}-${deliveryStatus}`] = UnifiedOrderStatus.PREPARING;
    mapping[`${OrderStatusType.AlmostReady}-${deliveryStatus}`] = UnifiedOrderStatus.PREPARING;
    mapping[`${OrderStatusType.ReadyForPickup}-${deliveryStatus}`] = UnifiedOrderStatus.READY_FOR_PICKUP;
    mapping[`${OrderStatusType.AutoClosed}-${deliveryStatus}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
    mapping[`${OrderStatusType.Closed}-${deliveryStatus}`] = UnifiedOrderStatus.COMPLETE;
    mapping[`${OrderStatusType.Unfulfilled}-${deliveryStatus}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
    mapping[`${OrderStatusType.Unknown}-${deliveryStatus}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
    mapping[`${OrderStatusType.Voided}-${deliveryStatus}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
  });

  orderStatuses.forEach(orderStatus => {
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverAtRestaurant}`] = UnifiedOrderStatus.DRIVER_AT_RESTAURANT;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.Undeliverable}`] = UnifiedOrderStatus.UNDELIVERABLE;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DispatchFailure}`] = UnifiedOrderStatus.DELIVERY_FAILED;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.OrderDelivered}`] = UnifiedOrderStatus.COMPLETE;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverEnRouteToDropoff}`] = UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_DROPOFF;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverAtDropoff}`] = UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_DROPOFF;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverReturnedOrder}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DeliveryCancelled}`] = UnifiedOrderStatus.CANCELLED;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverAtDropoff}`] = UnifiedOrderStatus.DRIVER_AT_DROPOFF;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DeliveryCancelledNotApproved}`] = UnifiedOrderStatus.FAILURE_AT_RESTAURANT;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverEnRouteToRestaurant}`] = UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_RESTAURANT;
    mapping[`${orderStatus}-${ThirdPartyDeliveryStatus.DriverReturnedOrder}`] = UnifiedOrderStatus.DELIVERY_FAILED;
  });
  return mapping;
};

const statusMapping = combinationMapping();

/**
 * Unifies the different statuses into one status enum
 *
 * @param orderStatus
 * @param deliveryStatus
 */
const getUnifiedOrderStatus = (orderStatus: OrderStatusType, deliveryStatus: ThirdPartyDeliveryStatus | null) => {
  const key = `${orderStatus}-${deliveryStatus}`;
  return statusMapping[key] || UnifiedOrderStatus.UNKNOWN;
};

type MapType = { [key:string]: UnifiedOrderStatus }

export const statusToIcons = (orderStatus: UnifiedOrderStatus, diningOptionBeahvior: DiningOptionBehavior, curbside: boolean) : Array<JSX.Element> => {
  switch(diningOptionBeahvior) {
    case DiningOptionBehavior.Delivery:
      return deliveryStatusToIcons(orderStatus);
    case DiningOptionBehavior.TakeOut:
      return curbside ? curbsideStatusToIcons(orderStatus) : takeoutStatusToIcons(orderStatus);
    default:
      return [];
  }
};

export const statusToCopy = (
  orderStatus: UnifiedOrderStatus,
  diningOptionBehavior: DiningOptionBehavior,
  restaurantName: string,
  pickupInstructions?: string | null,
  driverName?: string,
  completedDate?: Date,
  curbside?: boolean
)
: StatusBarComponentCopy | null => {
  switch(diningOptionBehavior) {
    case DiningOptionBehavior.Delivery:
      return deliveryStatusToCopy(orderStatus, restaurantName, driverName);
    case DiningOptionBehavior.TakeOut:
      return takeoutStatusToCopy(orderStatus, restaurantName, pickupInstructions, completedDate, curbside);
    default:
      return null;
  }
};


const takeoutStatusToIcons = (orderStatus: UnifiedOrderStatus) : Array<JSX.Element> => {
  switch(orderStatus) {
    case UnifiedOrderStatus.SCHEDULED:
      // eslint-disable-next-line max-len
      return [<ScheduledIcon key="scheduledIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <PickupIcon key="pickupIcon" inProgress />];
    case UnifiedOrderStatus.UNKNOWN:
    case UnifiedOrderStatus.RECEIVED:
      // eslint-disable-next-line max-len
      return [<OrderConfirmedIcon key="orderConfirmedIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <PickupIcon key="pickupIcon" inProgress />];
    case UnifiedOrderStatus.CONFIRMED:
      // eslint-disable-next-line max-len
      return [<OrderConfirmedIcon key="orderConfirmedIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <PickupIcon key="pickupIcon" inProgress />];
    case UnifiedOrderStatus.PREPARING:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <PickupIcon key="pickupIcon" inProgress />];
    case UnifiedOrderStatus.READY_FOR_PICKUP:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" inProgress />, <PickupIcon key="pickupIcon" inProgress />];
    case UnifiedOrderStatus.CANCELLED:
      return []; // no icons when order is cancelled
    case UnifiedOrderStatus.COMPLETE:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" />, <OrderReadyIcon key="orderReadyIcon" />];
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
      // eslint-disable-next-line max-len
      return [<AlertIcon key="alertIcon" />, <StageDivider key="divider-1" upcoming />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <PickupIcon key="pickupIcon" inProgress />];
    default:
      return [];
  }
};

const takeoutStatusToCopy = (orderStatus: UnifiedOrderStatus, restaurantName: string, pickupInstructions?: string | null, completedDate?: Date, curbside?: boolean): StatusBarComponentCopy | null => {
  const orderCompletedDate = typeof completedDate === 'number' ? new Date(completedDate) : completedDate;
  switch(orderStatus) {
    case UnifiedOrderStatus.SCHEDULED:
      return {
        title: 'Order scheduled',
        subtitle: 'Your order has been scheduled for pickup.'
      };
    case UnifiedOrderStatus.UNKNOWN:
    case UnifiedOrderStatus.RECEIVED:
      return {
        title: 'Confirming your order',
        subtitle: `${restaurantName} is confirming your order.`
      };
    case UnifiedOrderStatus.CONFIRMED:
    case UnifiedOrderStatus.PREPARING:
      return {
        title: 'Preparing your order',
        subtitle: `${restaurantName} is preparing your order.`
      };
    case UnifiedOrderStatus.READY_FOR_PICKUP:
      return {
        title: 'Ready for pickup!',
        subtitle: pickupInstructions ?? `Your order is ready for ${curbside ? 'curbside ' : ''}pickup at ${restaurantName}`
      };
    case UnifiedOrderStatus.CANCELLED:
      return {
        title: 'Your order has been canceled',
        subtitle: 'Your order was canceled.'
      };
    case UnifiedOrderStatus.COMPLETE:
      return {
        title: 'Order completed',
        subtitle: `Your order was completed${formatOrderCompletedDate(orderCompletedDate)}.`
      };
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
      return {
        title: 'Order pending confirmation',
        subtitle: `Please contact ${restaurantName} about your order status.`
      };
    default:
      return null;
  }
};

const formatOrderCompletedDate = (date?: Date) => {
  if(date) {
    return ` at ${Intl.DateTimeFormat('en-US', {
      timeStyle: 'short',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(date)}`;
  }
  return '';
};

const deliveryStatusToCopy = (orderStatus: UnifiedOrderStatus, restaurantName: string, driverName?: string): StatusBarComponentCopy | null => {
  switch(orderStatus) {
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
      return {
        title: 'Order pending confirmation',
        subtitle: `Please contact ${restaurantName} about your order status`
      };
    case UnifiedOrderStatus.DELIVERY_FAILED:
      return {
        title: 'Sorry, your order failed',
        subtitle: `There was an issue processing your delivery order. Please contact ${restaurantName} to cancel your order and place a pickup order.`
      };
    case UnifiedOrderStatus.SCHEDULED:
      return {
        title: 'Order scheduled',
        subtitle: 'Your order has been scheduled for delivery.'
      };
    case UnifiedOrderStatus.UNKNOWN:
    case UnifiedOrderStatus.RECEIVED:
      return {
        title: 'Confirming your order',
        subtitle: `${restaurantName} is confirming your order.`
      };
    case UnifiedOrderStatus.CONFIRMED:
    case UnifiedOrderStatus.READY_FOR_PICKUP:
    case UnifiedOrderStatus.PREPARING: {
      const driverCopy = driverName ? ` ${driverName} will be delivering your order.` : '';
      // if the driver is assigned, the copy changes to not include the Rx name
      const rxCopy = driverName ? 'Your order is being prepared' : `${restaurantName} is preparing your order`;
      return {
        title: 'Preparing your order',
        subtitle: `${rxCopy}.${driverCopy}`
      };
    }
    case UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_RESTAURANT:
      return {
        title: 'Picking up your order',
        subtitle: 'Your driver is on their way to pick up your order.'
      };
    case UnifiedOrderStatus.DRIVER_AT_RESTAURANT:
      return {
        title: 'Picking up your order',
        subtitle: `Your driver is picking up your order at ${restaurantName}.`
      };
    case UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_DROPOFF:
      return {
        title: 'Delivering your order',
        subtitle: 'Your driver has your order and is on their way to you!'
      };
    case UnifiedOrderStatus.DRIVER_AT_DROPOFF:
      return {
        title: 'Delivering your order',
        subtitle: `${driverName ?? 'Your driver'} is approaching with your order!`
      };
    case UnifiedOrderStatus.CANCELLED:
      return {
        title: 'Your order was canceled',
        subtitle: `Your order was canceled. Please contact ${restaurantName} for more information.`
      };
    case UnifiedOrderStatus.UNDELIVERABLE:
      return {
        title: 'Unable to deliver your order',
        subtitle: `${driverName ?? 'Your driver'} is having difficulty delivering your order. Please contact your driver.`
      };
    case UnifiedOrderStatus.COMPLETE:
      return {
        title: 'Order completed',
        subtitle: 'Your order has been completed. Enjoy!'
      };
    default:
      return null;
  }
};

const curbsideStatusToIcons = (orderStatus: UnifiedOrderStatus) => {
  // curbside matches takeout's flows except for the last icons being different
  let icons = takeoutStatusToIcons(orderStatus);
  icons[icons.length - 1] = <CurbsideIcon inProgress={orderStatus !== UnifiedOrderStatus.COMPLETE} />;

  return icons;
};

const deliveryStatusToIcons = (orderStatus: UnifiedOrderStatus) : Array<JSX.Element> => {
  switch(orderStatus) {
    case UnifiedOrderStatus.SCHEDULED:
      // eslint-disable-next-line max-len
      return [<ScheduledIcon key="scheduledIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.UNKNOWN:
    case UnifiedOrderStatus.RECEIVED:
      // eslint-disable-next-line max-len
      return [<OrderConfirmedIcon key="orderConfirmedIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.CONFIRMED:
      // eslint-disable-next-line max-len
      return [<OrderConfirmedIcon key="orderConfirmedIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.PREPARING:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" inProgress />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.READY_FOR_PICKUP:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" inProgress />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_RESTAURANT:
    case UnifiedOrderStatus.DRIVER_AT_RESTAURANT:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" inProgress />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.DRIVER_EN_ROUTE_TO_DROPOFF:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" />, <DriverIcon key="driverIcon" />, <StageDivider key="divider-3" inProgress />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.DRIVER_AT_DROPOFF:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" />, <DriverIcon key="driverIcon" />, <StageDivider key="divider-3" inProgress />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.COMPLETE:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" />, <DriverIcon key="driverIcon" />, <StageDivider key="divider-3" />, <HomeIcon key="home-icon" />];
    case UnifiedOrderStatus.CANCELLED:
      return []; // no icons when order is cancelled
    case UnifiedOrderStatus.UNDELIVERABLE:
      // eslint-disable-next-line max-len
      return [<PreparingIcon key="preparingIcon" />, <StageDivider key="divider-1" />, <RestaurantIcon key="restaurantIcon" />, <StageDivider key="divider-2" />, <AlertIcon key="alertIcon" />, <StageDivider key="divider-3" inProgress />, <HomeIcon key="home-icon" inProgress />];
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
      // eslint-disable-next-line max-len
      return [<AlertIcon key="alertIcon" />, <StageDivider key="divider-1" upcoming />, <RestaurantIcon key="restaurantIcon" inProgress />, <StageDivider key="divider-2" upcoming />, <DriverIcon key="driverIcon" inProgress />, <StageDivider key="divider-3" upcoming />, <HomeIcon key="home-icon" inProgress />];
    default:
      return [];
  }
};

export const deliveryStatusToFulfillmentTime = (orderStatus: UnifiedOrderStatus, orderStatusData: OrderStatusData | undefined, order: CompletedOrder) : string | null => {
  const estimatedDelivery = orderStatusData?.trackingDetails?.estimatedDeliveryTime ? parseInt(orderStatusData.trackingDetails.estimatedDeliveryTime) : null;
  const fulfillmentTime = estimatedDelivery ?? order.estimatedFulfillmentDate ?? null;
  const formattedFulfillment = fulfillmentTime ? formatFulfillmentTime(fulfillmentTime) : null;
  switch(orderStatus) {
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
    case UnifiedOrderStatus.UNKNOWN:
      return 'Estimated arrival is pending';
    case UnifiedOrderStatus.COMPLETE: {
      const deliveryDateCopy = orderStatusData?.lastUpdated ? ` ${formatFulfillmentTime(Date.parse(orderStatusData.lastUpdated))}` : '';
      return `Your order was delivered${deliveryDateCopy}.`;
    }
    default:
      return formattedFulfillment ? `Estimated arrival ${formattedFulfillment}` : null;
  }
};

export const takeoutStatusToFulfillmentTime = (orderStatus: UnifiedOrderStatus, order: CompletedOrder) => {
  const formattedFulfillment = order.estimatedFulfillmentDate ? formatFulfillmentTime(order.estimatedFulfillmentDate) : null;
  switch(orderStatus) {
    case UnifiedOrderStatus.UNKNOWN:
    case UnifiedOrderStatus.FAILURE_AT_RESTAURANT:
      return 'Estimated pickup time is pending';
    case UnifiedOrderStatus.COMPLETE:
    case UnifiedOrderStatus.READY_FOR_PICKUP:
      return null;
    default:
      return formattedFulfillment ? `Your order will be ready ${formattedFulfillment}` : null;
  }
};

const formatFulfillmentTime = (fulfillmentTime: number) => {
  return toLocalDateTime(fromUnixTime(fulfillmentTime / 1000)).replace('Today', 'today')
    .replace('Tomorrow', 'tommorrow');
};


type StatusBarComponentCopy = {
  title: string
  subtitle: string
}

export default getUnifiedOrderStatus;
