import React, { useCallback, useEffect, useMemo } from 'react';

import { Cart, LoyaltyRedemption, RewardTypeData } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';

import ContextualLoadingSpinner from 'shared/components/common/loading_spinner/LoadingSpinner';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import DiscountPill, { DiscountPillState, DiscountPillType } from 'public/components/default_template/online_ordering/checkout/payment/Discounts/DiscountPill';
import { useCart } from 'public/components/online_ordering/CartContext';

import { getFirstOrThirdParty, isRedemptionInCart } from './LoyaltyUtils';
import { ThirdPartyLoyaltyGuestConsentForm } from './ThirdPartyLoyaltyGuestConsentForm';


export const getRedemptionDescription = (redemption: LoyaltyRedemption): string => {
  let messages = redemption.currentState?.messages;
  if(!messages || !messages.rewardValueMessage) {
    // 3PL redemptions from the cart do not have currentState, use name instead
    if(redemption.name) {
      messages = { rewardValueMessage: redemption.name, rewardLabelMessage: null };
    } else return '';
  }
  const { rewardValueMessage, rewardLabelMessage } = messages;

  // 3rd party doesn't support rewardLabelMessage
  return rewardLabelMessage === null ? rewardValueMessage as string : `${rewardValueMessage} (${rewardLabelMessage})`;
};

const LoyaltyRedemptionSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart, refetchCart } = useCart();
  const {
    hasLoyaltyAccount,
    redemptions,
    removeRedemption,
    addRedemption,
    loadingRedemptions,
    loadingLoyaltyAccount,
    redemptionErrorRef,
    pointsBalance,
    setAllowLookup
  } = useLoyalty();
  const tracker = useTracker();
  const { customer } = useCustomer();
  const loadingLoyalty = useMemo(() => loadingRedemptions || loadingLoyaltyAccount, [loadingRedemptions, loadingLoyaltyAccount]);
  const requireLookupAuthorization = useMemo(() => getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === '3PL', [ooRestaurant?.loyaltyConfig?.programName]);

  useEffect(() => {
    // auto allow 1st party
    if(getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === 'ToastLoyalty') {
      setAllowLookup(true, false);
    }
  }, [ooRestaurant?.loyaltyConfig?.programName, setAllowLookup]);

  const onClickRedemption = useCallback((redemption: LoyaltyRedemption) => async () => {
    let action = 'Removed';
    if(isRedemptionInCart(redemption, cart as Cart)) {
      await removeRedemption(redemption);
    } else {
      action = 'Added';
      await addRedemption(redemption);
    }
    tracker.track('Loyalty Reward Tapped', {
      redemptionName: redemption.name,
      action: action,
      firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName),
      error: redemptionErrorRef?.current
    });
    await refetchCart();
  }, [cart, tracker, ooRestaurant?.loyaltyConfig?.programName, redemptionErrorRef, refetchCart, removeRedemption, addRedemption]);

  const availableRedemptions = useMemo(() => {
    return redemptions.filter(it => !!it.available);
  }, [redemptions]);

  const nonAvailableRedemptions = useMemo(() => {
    return redemptions.filter(it => !it.available && it.referenceId !== 'LOYALTY_POINTS_AMOUNT' && it.rewardType != RewardTypeData.Birthday);
  }, [redemptions]);

  const headerMsg = useMemo(() => {
    return `Rewards ${pointsBalance ? `(${pointsBalance} pts available)` : ''}`;
  }, [pointsBalance]);

  if(requireLookupAuthorization && customer && !hasLoyaltyAccount) return (
    <ThirdPartyLoyaltyGuestConsentForm restaurantName={ ooRestaurant?.name } />
  );

  if(!ooRestaurant?.loyaltyConfig || !cart || !hasLoyaltyAccount || !customer) {
    return null;
  }
  return (
    <div className="loyaltyRedemption" data-testid="loyalty-redemption">
      <div className="header">{headerMsg} {loadingLoyalty && <ContextualLoadingSpinner size="16px" strokeWidth="3px" />}</div>
      <div className="redemptions" data-testid="redemptions-list">
        {availableRedemptions.map((r, i) =>
          <DiscountPill
            onClick={onClickRedemption(r)}
            text={getRedemptionDescription(r)}
            state={isRedemptionInCart(r, cart as Cart) ? DiscountPillState.ACTIVE : DiscountPillState.INACTIVE}
            type={DiscountPillType.REWARD}
            key={`redemption-${r.redemptionGuid}-${r.referenceId}`}
            testId={`reward-pill-${i}`}
            loadingOverride={loadingLoyalty} />)}
        {nonAvailableRedemptions.map((redemption, i) =>
          <DiscountPill
            text={getRedemptionDescription(redemption)}
            state={DiscountPillState.LOCKED}
            type={DiscountPillType.REWARD}
            key={`locked-redemption-${redemption.redemptionGuid}-${redemption.referenceId}`}
            testId={`locked-reward-pill-${i}`} />)}
      </div>
      {redemptionErrorRef?.current && <p className="error">{redemptionErrorRef.current}</p>}
    </div>
  );
};

export default LoyaltyRedemptionSection;
