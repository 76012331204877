// utility type to support stopgap rich text
export type RichTextField = {
  text?: string
  color?: string
  fontFamily?: string
}

export const toRichTextField = (fieldValue?: string): RichTextField => {
  if(!fieldValue) {
    return {
      color: undefined,
      text: undefined,
      fontFamily: undefined
    };
  }
  const rawData = JSON.parse(fieldValue).data[0].children[0];

  return {
    color: rawData.color,
    text: rawData.text,
    fontFamily: rawData.fontFamily
  };
};

const POPUP_KEY = 'toast-sites-interstitial';
const getPopupKey = (popupGuid: string) => `${POPUP_KEY}|${popupGuid}`;

export const setHasShown = (popupGuid: string) => {
  sessionStorage.setItem(getPopupKey(popupGuid), 'true');
};

export const getHasShown = (popupGuid: string) => {
  return !!sessionStorage.getItem(getPopupKey(popupGuid));
};
