import React from 'react';

import { emptySchedule, getDay, Day } from 'src/lib/js/schedule';

import { RestaurantProfileScheduleType } from 'public/components/default_template/restaurant_profile/profileUtils';

import { daysOfWeek, compactTimes } from './timeUtilities';

type Props = {
  schedule: RestaurantProfileScheduleType;
}

const Hours = ({ schedule } : Props) => {
  const hours = emptySchedule();
  if(schedule?.upcomingSchedules) {
    schedule.upcomingSchedules.forEach(upcomingSchedule => {
      upcomingSchedule.dailySchedules.forEach(schedule => {
        const scheduleDate = new Date(schedule.date);
        const day = getDay(scheduleDate, true);
        hours[day].intervals = schedule.servicePeriods;
      });
    });
  }

  for(const [day, daySchedule] of Object.entries(hours)) {
    hours[day as Day].intervals = compactTimes(daySchedule.intervals, true);
  }

  const today = new Date().getDay();

  return (
    <div className="hours-container" id="rx-hours">
      {schedule ?
        <table>
          <tbody>
            {daysOfWeek.map((dayOfWeek, i) =>
              <tr key={dayOfWeek} className={`${i == today ? 'bold' : '' }`}>
                <td>{dayOfWeek}</td>
                <td className="hours">
                  {hours[dayOfWeek.toLowerCase() as Day]?.intervals?.length ? hours[dayOfWeek.toLowerCase() as Day]?.intervals?.map(day => `${day.startTime} - ${day.endTime}`).join(', ') : 'Closed'}
                </td>
              </tr>)}
          </tbody>
        </table> :
        <p>Call for Open Hours</p>}
    </div>
  );
};
export default Hours;
