import React, { FC } from 'react';

import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';
import { UpsellItem } from 'public/components/default_template/online_ordering/upsell/UpsellItem';
import { getUpsellItemPrice } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';
import { useCart } from 'public/components/online_ordering/CartContext';


interface UpsellBasicCardProps {
  item?: UpsellItem
}

export const UpsellBasicCard: FC<UpsellBasicCardProps> = (props: UpsellBasicCardProps) => {
  const { item } = props;
  const { isOpen, onClose, onOpen } = useModal();
  const { cartGuid, cart } = useCart();
  const { selectedLocation } = useRestaurant();
  const restaurantGuid = selectedLocation.externalId;

  if(!item) {
    return <></>;
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if(e.code === 'Enter' || e.code === 'Space') {
      onOpen();
    }
  };

  return (
    <>
      {cart?.restaurant && <ItemModal
        isOpen={isOpen}
        onClose={onClose}
        restaurantGuid={restaurantGuid}
        shortUrl={cart.restaurant?.shortUrl}
        specialRequestsEnabled={cart.restaurant?.specialRequestsConfig.enabled}
        specialRequestsPlaceholder={cart.restaurant?.specialRequestsConfig.placeholderMessage}
        itemGuid={item.guid}
        itemGroupGuid={item.itemGroupGuid}
        cartGuid={cartGuid as string} />}
      <div role="button" className="basicUpsellCardGrid" data-testid="UpsellBasicCard" onClick={onOpen} onKeyDown={onKeyDown} tabIndex={0}>
        <p className="title">{item.name}</p>
        <div className="price">
          {getUpsellItemPrice(item)}
          <div className="upsellQuickAddBtn" aria-hidden>+</div>
        </div>
      </div>
    </>

  );
};
