import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'

export const setupGuestDocument= gql`
  mutation setupGuest($input: SetupGuestInput!) {
    setupGuest(input: $input) {
      ... on SetupGuestSuccess {
        unverified {
          phone
        }
      }
      ... on Error {
        message
      }
    }
  }
`

/**
 * Starts the passwordless authentication process by initializing a profile in Auth0 using
 * the input phone number and requests a verification code to be sent to that phone number.
 *
 * See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L120
 * for response details.
 *
 * GraphQL playground can also be spun up to view response details.
 * See https://github.com/toasttab/toast-do-federated-gateway#introspection-on-preproduction
 * or https://github.com/toasttab/toast-guest-profiles#graphql for detail.
 *
 * @param phoneNumber: Used to initialize a profile in Auth0 and receive the verification code.
 * @param source: See https://github.com/toasttab/toast-guest-profiles/blob/main/toast-guest-profiles-application/src/main/graphql/auth.graphql#L207.
 * @param requestPasswordless: Callback into the auth client for requesting a password be sent to the phone number.
 * @param apolloClient: The client to use for this mutation
 */
export async function startPasswordlessMutation(
  phoneNumber: string,
  source: string,
  requestPasswordless: (phoneNumber: string) => Promise<void>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) {
  const response = await apolloClient.mutate({
    mutation: setupGuestDocument,
    variables: {
      input: {
        identity: {
          phone: phoneNumber
        },
        source
      }
    }
  })

  const responseType = response.data?.setupGuest?.__typename

  if (
    responseType !== 'SetupGuestSuccess' &&
    responseType !== 'UnexpectedAuthError'
  ) {
    return response
  }

  await requestPasswordless(phoneNumber)

  return response
}
