import React, { useState, useCallback } from 'react';

import classnames from 'classnames';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';

type Props = {
  label: string;
}

const ExpandableSection = (props: React.PropsWithChildren<Props>) => {
  const { label, children } = props;
  const [expanded, setExpanded] = useState(false);

  const expandOnClick = useCallback((event: React.SyntheticEvent) => {
    setExpanded(old => !old);
    event.stopPropagation();
  }, [setExpanded]);

  // to make the expandable section more accessible for keyboard access
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if(e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
      e.preventDefault(); // Prevent the default space key action (page scroll)
      expandOnClick(e);
    }
  }, [expandOnClick]);

  return (
    <>
      <div className="expandableSectionLabel" onKeyDown={handleKeyDown} onClick={expandOnClick} tabIndex={0}>
        {label}
        <span className={classnames('controlIcon', expanded && 'rotated')} />
      </div>
      <AnimatedSection className="expandableSectionContent" expanded={expanded}>
        {children}
      </AnimatedSection>
    </>
  );
};

export default ExpandableSection;
