import React from 'react';

import { formatImageURL } from 'src/lib/js/utilities';

import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';
import { UpsellItem } from 'public/components/default_template/online_ordering/upsell/UpsellItem';
import { getUpsellItemPrice } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';
import { useCart } from 'public/components/online_ordering/CartContext';


interface UpsellImageCardProps {
  item?: UpsellItem;
}

export const UpsellImageCard = (props: UpsellImageCardProps) => {
  const { item } = props;
  const { isOpen, onClose, onOpen } = useModal();
  const { cartGuid, cart } = useCart();
  const { selectedLocation } = useRestaurant();
  const restaurantGuid = selectedLocation.externalId;

  if(!item) {
    return <></>;
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if(e.code === 'Enter' || e.code === 'Space') {
      onOpen();
    }
  };

  return (
    <>
      {cart?.restaurant && <ItemModal
        isOpen={isOpen}
        onClose={onClose}
        restaurantGuid={restaurantGuid}
        shortUrl={cart.restaurant?.shortUrl}
        specialRequestsEnabled={cart.restaurant?.specialRequestsConfig.enabled}
        specialRequestsPlaceholder={cart.restaurant?.specialRequestsConfig.placeholderMessage}
        itemGuid={item.guid}
        itemGroupGuid={item.itemGroupGuid}
        cartGuid={cartGuid as string} />}
      <div role="button" className="imageUpsellItemGrid" data-testid={`upsell-image-card-${item.guid}`} onClick={onOpen} onKeyDown={onKeyDown} tabIndex={0}>
        <div className="image">
          <img className="imgWrapper" src={formatImageURL(item.imageUrls.xs)} alt="" data-testid={item.name + '-image'} />
          <div className="quickAdd"><div className="upsellQuickAddBtn" aria-hidden>+</div></div>
        </div>
        <p className="title">{item.name}</p>
        <p className="price">{getUpsellItemPrice(item)}</p>
      </div>
    </>
  );
};
