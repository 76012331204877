import { useCallback } from 'react';

import { GuestInput } from 'src/apollo/onlineOrdering';
import { reportErrorMessageWithData } from 'src/lib/js/clientError';

import { useTempEventTracker } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';

export const useIsCartValid = (withLogging?: boolean) => {
  const { track } = useTempEventTracker();
  const { validateCart, refetchCart } = useCart();
  const { setOrderError } = useCheckout();

  const isCartValid = useCallback(async (errorRefetchesCartModifications?: boolean, guestInfo?: GuestInput): Promise<boolean> => {
    const { validateCartPreCheckout } = await validateCart(guestInfo);
    const { __typename } = validateCartPreCheckout;

    if(__typename === 'CartResponse' && validateCartPreCheckout.info.length === 0 && validateCartPreCheckout.warnings.length === 0) {
      return true;
    }

    if(__typename === 'CartResponse') {
      if(validateCartPreCheckout.warnings.length !== 0) {
        setOrderError({
          type: validateCartPreCheckout.warnings[0].code,
          message: validateCartPreCheckout.warnings[0].message
        });
      } else if(validateCartPreCheckout.info.length !== 0) {
        setOrderError({
          type: validateCartPreCheckout.info[0].code,
          message: validateCartPreCheckout.info[0].message
        });
      }
    } else if(__typename === 'CartModificationError') {
      setOrderError({
        type: validateCartPreCheckout.code,
        message: validateCartPreCheckout.message,
        refetchCart: errorRefetchesCartModifications
      });
    } else if(__typename === 'CartOutOfStockError') {
      setOrderError({
        type: 'OUT_OF_STOCK',
        message: validateCartPreCheckout.message,
        refetchCart: errorRefetchesCartModifications
      });
    } else if(__typename === 'CartValidationError') {
      setOrderError({
        type: validateCartPreCheckout.key,
        message: validateCartPreCheckout.message
      });
    } else {
      setOrderError({
        type: 'unknown',
        message: 'Sorry, your order could not be sent.'
      });
    }

    if(withLogging) {
      track('temp_SPI_precheckout_cart_validation_failed', validateCartPreCheckout);
      reportErrorMessageWithData('Invalid cart', validateCartPreCheckout);
    }

    // If configured, don't refetch the cart until after the error modal is closed for errors that modify the cart.
    // This helps prevent page re-renders that could prevent the error modal from displaying.
    if(!errorRefetchesCartModifications || !['CartModificationError', 'CartOutOfStockError'].includes(__typename)) {
      refetchCart();
    }

    return false;
  }, [refetchCart, setOrderError, validateCart, withLogging, track]);

  return { isCartValid };
};
