import { ExternalModule } from 'src/apollo/sites';
import { createScriptTagsFromString } from 'src/public/components/default_template/meta/PixelsMeta';

export const USER_WAY_MODULE_NAME = 'UserWay';

type UserWayConfig = {
  key: string;
};

const allowedKeyChars = /[a-zA-Z0-9]+/;

// Exported for testing
export const validateConfig = (config: UserWayConfig) => {
  if(config?.key?.replace(allowedKeyChars, '').length) return false;

  return true;
};

export const userWayHeader = (module: ExternalModule) => {
  if(!module.enabled) return;
  const config = JSON.parse(module.config) as UserWayConfig;
  if(!validateConfig(config)) {
    return null;
  }
  return createScriptTagsFromString(`<script src="https://cdn.userway.org/widget.js" data-account="${config.key}"></script>`)[0];
};
