import React from 'react';

import EditorAwareExport from './exporter';

type EditorOnlyType = (props: React.PropsWithChildren<{}>) => JSX.Element;


const EditorOnlyComponent: EditorOnlyType = ({ children }) => {
  return <>{children}</>;
};

const EmptyComponent: EditorOnlyType = _ => {
  /**
   * Because the children passed to `EditorBuildOnly` are defined outside
   * this component, simply not rendering the children in this
   * EmptyComponent doesn't tree shake the children.
   *
   * To tree shake the children when building for non-editor environments,
   * we must discard the children provided to `<EditorBuildOnly>` during
   * the JSX parsing step.
   * `tools/babel/shake-editor-only-react.js` contains code that looks
   * for components with the name "EditorBuildOnly" during the JSX
   * parsing step and skips over the parsing of the children
   * when WEBPACK_IS_EDITOR is true.
   *
   * Shedding children provided to <EditorBuildOnly> during JSX lets
   * webpack tree shake those children.
  */
  return <></>;
};


/**
 * A component that only renders its children when
 * viewed inside the page editor, otherwise displaying an empty component.
 */
const EditorBuildOnly = EditorAwareExport(EmptyComponent, EditorOnlyComponent);

export default EditorBuildOnly;
