import { Source, RegistrationSource } from 'src/apollo/onlineOrdering';

export const getRegistrationSource = () => {
  if(typeof window !== 'undefined' && window.location && window.location.href.includes('toasttab.com/local')) {
    return RegistrationSource.Local;
  }

  return RegistrationSource.Sites;
};

export const getSource = () => {
  if(typeof window !== 'undefined' && window.location && window.location.href.includes('toasttab.com/local')) {
    return Source.ToastLocal;
  }

  return Source.ToastSites;
};
