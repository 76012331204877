import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { getImageUrl } from 'shared/components/common/Image';

import { resources } from 'config';

const ToastLocalHelmet = () => {
  const location = useLocation();
  const url = `https://${resources.toastLocalHost}${location.pathname}`;
  const title = 'Toast | The best food at your fingertips';
  const description = 'Best place to order takeout and delivery. Great selection with lowest prices and no commissions for restaurants. Search by location, cuisine, and more.';
  const logoSrc = 'icons/favicon-32x32.png';
  const noIndex = process.env.NODE_ENV !== 'production';

  return (
    <Helmet>
      {noIndex ? <meta name="robots" content="noindex, nofollow" /> : null}
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <link rel="canonical" href={url} />
      <meta name="og:url" content={url} />
      <title>{title}</title>
      <meta name="og:title" content={title} />
      <link rel="icon" id="favicon" href={getImageUrl(logoSrc)} />
      <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap" rel="stylesheet" />
    </Helmet>
  );
};

export default ToastLocalHelmet;
