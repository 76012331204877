import React from 'react';

import { NearbyRestaurantSearchResultRestaurantType } from 'src/public/components/default_template/restaurant_profile/profileUtils';

import Image from 'shared/components/common/Image';


type Props = {
  config?: NearbyRestaurantSearchResultRestaurantType
}

const RXConfig = ({ config }: Props) =>
  <div className="rx-config">
    {config && config.hasOnlineOrderingModule ? <Image src="toastLocal/icons/takeout-gray.svg" alt="Takeout" /> : null}
    {config && config.deliveryServiceAvailability.available ? <Image src="toastLocal/icons/delivery-gray.svg" alt="Delivery" /> : null}
  </div>;

export default RXConfig;
