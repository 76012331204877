import React, { FC, useCallback, useEffect } from 'react';


import { DeliveryInfo, SavedAddress } from 'src/apollo/onlineOrdering';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import DropDown from 'shared/components/common/dropdown';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import DeliveryAddressInput from 'public/components/default_template/online_ordering/dining_options/DeliveryAddressInput';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';


interface DeliveryAddressSelectionProps {
}

export const DeliveryAddressSelection: FC<DeliveryAddressSelectionProps> = () => {
  const { selectedLocation } = useRestaurant();
  const { validateDeliveryAddress, validateAllDeliveryAddresses, validDeliveryAddress, clearDeliveryAddress, isValidating, setSavedAddressUsed } = useDelivery();
  const { customer } = useCustomer();

  const onSelectDeliveryAddress = useCallback(async (address: SavedAddress) => {
    const deliveryInfoInput = { ...address.deliveryInfo, __typename: undefined };
    await validateDeliveryAddress(deliveryInfoInput, selectedLocation.externalId);
    setSavedAddressUsed(validDeliveryAddress ? true : false);
  }, [selectedLocation?.externalId, validateDeliveryAddress, validDeliveryAddress, setSavedAddressUsed]);

  useEffect(() => {
    // only use one of the saved addresses if a valid address isn't already set
    if(customer && customer.addresses && customer.addresses.length > 0 && !validDeliveryAddress) {
      validateAllDeliveryAddresses(customer.addresses, selectedLocation.externalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!customer || !customer.addresses || customer.addresses.length === 0) {
    return <DeliveryAddressInput selectedLocation={selectedLocation} />;
  }

  const addressOptions: SavedAddress[] = [
    ...customer.addresses,
    // this option allows guests to enter a new address
    {
      guid: 'none',
      deliveryInfo: undefined as any
    }
  ];

  return (
    <>
      <div className="deliveryAddressSelection">
        <DropDown label={formattedDeliveryInfo(validDeliveryAddress, customer.addresses)} withBorder>
          {({ close }) => addressOptions.map(address => {
            return (
              <DropDownOption
                className="option"
                key={address.guid}
                onSelect={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(address.deliveryInfo) {
                    await onSelectDeliveryAddress(address);
                  } else {
                    clearDeliveryAddress();
                  }
                  close();
                }}>
                {formattedDeliveryInfo(address.deliveryInfo, customer?.addresses) }
              </DropDownOption>
            );
          })}
        </DropDown>
      </div>
      <br />
      {!isValidating && !validDeliveryAddress && <DeliveryAddressInput selectedLocation={selectedLocation} />}
      {isValidating && <LoadingSpinnerOverlay withBorderRadius />}
    </>
  );
};

const formattedDeliveryInfo = (deliveryInfo: DeliveryInfo | undefined | null, addresses: SavedAddress[]) => {
  if(!deliveryInfo) {
    return 'Enter a new address...';
  }

  const addressName = addresses
    .filter(address => formatDeliveryAddress(address.deliveryInfo, true) === formatDeliveryAddress(deliveryInfo, true))
    .map(address => address.name)[0];
  return (
    <>
      {addressName && <strong>{`${addressName} `}</strong>}{`${deliveryInfo.address1}${deliveryInfo.address2 ? `, ${deliveryInfo.address2}` : ''}`}
    </>
  );
};
