import React, { useEffect } from 'react';

import { ButtonType } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';

import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

export const ItemResolutionModal = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const { addToCart, deleteFromCart } = useCart();
  const { fulfillmentData } = useFulfillment();
  const { itemToAdd, setItemToAdd, conflictingItemInCart, setConflictingItemInCart } = useTimeBasedRules();
  const { track } = useThrottledTracker();
  const cartContext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);

  useEffect(() => {
    if(itemToAdd) {
      onOpen();
    }
  }, [onOpen, itemToAdd]);

  // This really shouldn't happen
  if(!itemToAdd || !conflictingItemInCart) {
    return null;
  }

  const closeModal = () => {
    setItemToAdd(undefined);
    setConflictingItemInCart(undefined);
    onClose();
  };

  const handleAddItem = async () => {
    if(conflictingItemInCart.selectionGuid) {
      await deleteFromCart(conflictingItemInCart.selectionGuid);
    }
    try {
      await addToCart(itemToAdd.modifier, itemToAdd.quantity, fulfillmentData?.cartFulfillmentData);
    } catch(error) {
      reportError('Error adding cart item.', error);
      return;
    }

    cartContext?.open();
    track('Added item to cart', {
      quantity: itemToAdd.quantity,
      price: itemToAdd.modifier?.price,
      unitOfMeasure: itemToAdd.modifier?.unitOfMeasure,
      timeBasedRule: 'Preorder'
    });
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} testId="item-resolution-modal">
      <ModalOverlay fadeIn />
      <ModalContent contentClassName="itemResolutionModal" fadeIn ariaLabelledBy="dining-options-modal-header">
        <div className="header">
          <h3 id="dining-options-modal-header">Oops!</h3>
          <ModalCloseButton />
        </div>
        <div className="info">
          <Image src="icons/warning-orange.svg" alt="" />
          <div>
            {/* {Item 1} and {Item 2} are not available to order at the same time. */}
            {conflictingItemInCart.name ? <b>{conflictingItemInCart.name}</b> : <span>An item in your cart</span>}
            <span>{' '}and{' '}</span>
            {itemToAdd.name ? <b>{itemToAdd.name}</b> : <span>the item you just added</span>}
            <span>{' '}are not available to order at the same time.</span>
          </div>
        </div>
        <div className="actions">
          <div>
            {/* Do you want to add {Item 2} to your cart?  If so, we will remove {Item 1} from your cart. */}
            <span>Do you want to add{' '}</span>
            {itemToAdd.name ? <b>{itemToAdd.name}</b> : <span>the new item</span>}
            <span>{' '}to your cart?  If so, we will remove{' '}</span>
            {conflictingItemInCart.name ? <b>{conflictingItemInCart.name}</b> : <span>the conflicting item</span>}
            <span>{' '}from your cart.</span>
          </div>
          <Button variant={ButtonType.Primary} onClick={handleAddItem}>Add to cart</Button>
          <Button variant={ButtonType.Secondary} onClick={onClose}>Cancel</Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
