import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import IFramedCodeBase, { calculateScale } from './IFramedCodeBase';

type Props = {
  code?: string;
  parentHeight: number;
  parentWidth: number;
};

const IFramedCode = ({ code, parentHeight, parentWidth }: Props) => {
  const [loading, setLoading] = useState(true);
  const [scaleRatio, setScaleRatio] = useState(0.0);
  const [offset, setOffset] = useState(0);

  // When the iframe has rendered, set its dimensions based on the parent (which can be resized in the editor)
  // and scale the content to fit
  const setRef = useCallback((ref: HTMLIFrameElement) => {
    ref.height = `${parentHeight}px`;
    ref.width = `${parentWidth}px`;

    const scale = calculateScale(parentHeight, parentWidth, ref?.contentDocument?.body?.clientHeight, ref?.contentDocument?.body?.clientWidth);
    if(scale) {
      setScaleRatio(scale);
      if(ref?.contentDocument?.body?.clientWidth) {
        setOffset((parentWidth - scale * ref.contentDocument.body.clientWidth) / 2);
      }
    }

    setLoading(false);
  }, [parentHeight, parentWidth]);

  // Hide the iframe until the contents have been scaled
  return <IFramedCodeBase className={classNames({ loading })} code={code} setRef={setRef} scaleRatio={scaleRatio} offset={offset} />;
};

export default IFramedCode;
