// To prevent other files from using the global WEBPACK_IS_EDITOR, only define the type in this file.
declare const WEBPACK_IS_EDITOR: boolean;

/**
 * If `true`, the environment is being built for the editor bundle.
 * If `false`, the environment is being built for the client/server bundle.
 *
 * Redefine the WEBPACK_IS_EDITOR arg and re-export it as IS_EDITOR.
 * This allows developers to mock the exported `IS_EDITOR` using
 * `jest.mock('src/editor/build-tools/is-editor', jest.fn({ IS_EDITOR: false })).
 * This is necessary because you cannot mock `WEBPACK_IS_EDITOR` in jest tests.
 */
export const IS_EDITOR = WEBPACK_IS_EDITOR;
