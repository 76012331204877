import React, { useMemo } from 'react';

import classnames from 'classnames';

import { CateringFormPage, EmailMarketingPage, Image, LogoSizeOption, LoyaltySignUpPage, PageConfig } from 'src/apollo/sites';
import { getImageUrl } from 'src/shared/components/common/Image';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';

import Footer from 'public/components/default_template/footer';
import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';

type BetterTogetherWrapperProps = {
  content?: LoyaltySignUpPage | EmailMarketingPage | CateringFormPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
  classNames?: string;
}

const BetterTogetherWrapper = (props: React.PropsWithChildren<BetterTogetherWrapperProps>) => {
  const { restaurant } = useRestaurant();

  const backgroundColorStyle = useMemo(() => {
    return props.config?.backgroundColor
      ? { backgroundColor: props.config?.backgroundColor }
      : {};
  }, [props.config?.backgroundColor]);
  const image: Image | null = useMemo(() => props.content?.image ? props.content?.image : null, [props.content]);
  const imagePosition: string = useMemo(() => {
    if(props.content?.imagePosition) return props.content?.imagePosition.toString();
    return 'backgroundColor';
  }, [props.content?.imagePosition]);

  const { meta, content, theme } = restaurant;
  if(!content) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  // Use the standard logoSize when the logo is in the middle of the nav
  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;
  return (
    <div className="defaultTemplate betterTogetherTemplate" data-testid="better-together-page" style={backgroundColorStyle} id="main">
      <Nav
        withShadow
        logoSrc={meta.icon}
        logoObject={meta.iconObject}
        logoSize={
          ['header-layout-4', 'header-layout-5', 'header-layout-6', 'header-layout-7'].includes(content.navConfig?.navLayout || '') ? LogoSizeOption.Standard : null
        }
        primaryCta={primaryCta}
        nonPrimaryCtas={nonPrimaryCtas}
        shouldShowPreviewBanner={true} />
      <main className={classnames('betterTogetherPage', imagePosition, props.classNames)}>
        {image && props.content?.imagePosition &&
          <_Image alt={image.altText ?? ''} imageObject={image} imageObjectPath={`${props.editPathRoot}.content.image`}
            className={classnames('image', imagePosition)} />}
        <div className={classnames('background', imagePosition)}
          style={{ backgroundImage: image?.src ? `url("${getImageUrl(image.src)}")` : '' }} />
        {props.children}
      </main>
      <div className="footerWrapper"><Footer /></div>
    </div>
  );
};

export default BetterTogetherWrapper;
