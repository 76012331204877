import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getCookie, removeCookie } from 'react-use-cookie';

import * as Sentry from '@sentry/react';
import { useEditor } from '@toasttab/sites-components';

import { refreshAuthToken } from 'src/apollo/createClient';
import {
  CustomerDocument,
  CustomerQueryResult,
  PasswordlessLogoutInput,
  useCompleteIdentityProfileMutation,
  useConfirmCodeMutation,
  useCustomerQuery,
  useLogoutMutation,
  usePasswordlessLoginMutation
} from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getRegistrationSource, getSource } from 'src/shared/components/common/authentication';
import { formatPhoneNumber } from 'src/shared/components/common/form_input/PhoneInput';

import { PWLESS_ACCESS, PWLESS_REFRESH } from 'shared/components/common/authentication/constants';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { isGuestProfileCreated, setTokens } from 'shared/js/authUtils';

import { AuthenticationStatus, getAuthenticationStatus } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';

import { Customer, CustomerContextCommonProvider } from './CustomerContextCommon';

/**
 * Do not use this directly. Use CustomerContextProviderWrapper instead.
 */
export const CustomerContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { isEditor } = useEditor();
  const [profileCreated, setProfileCreated] = useState(isGuestProfileCreated(getCookie(PWLESS_ACCESS)));

  // when setting the access and refresh tokens, update whether or not the profile has been created
  const onTokensSet = useCallback((accessToken: string) => {
    setProfileCreated(isGuestProfileCreated(accessToken));
  }, []);

  const setTokensWrapper = useCallback((accessToken: string, accessTokenExpiration: string, refreshToken: string) => {
    setTokens(accessToken, accessTokenExpiration, refreshToken, onTokensSet);
  }, [onTokensSet]);

  const refreshAuthTokenWrapper = useCallback(async () => {
    await refreshAuthToken(onTokensSet);
  }, [onTokensSet]);

  const deleteTokens = useCallback(() => {
    removeCookie(PWLESS_ACCESS);
    removeCookie(PWLESS_REFRESH);
    setProfileCreated(false);
  }, []);

  const client = useOOClient();
  const [loading, setLoading] = useState(false);
  const tracker = useTracker();
  const { data, loading: loadingCustomer, refetch: refetchCustomer, error: customerError } = useCustomerQuery({
    ssr: false,
    fetchPolicy: 'cache-first',
    client,
    skip: !profileCreated || isEditor
  });

  useEffect(() => {
    if(profileCreated) {
      refetchCustomer();
    }
  }, [profileCreated, refetchCustomer]);

  // a null value indicates that the auth token is bad
  useEffect(() => {
    if(profileCreated === null) {
      refreshAuthTokenWrapper();
    }
  }, [profileCreated, refreshAuthTokenWrapper]);

  const customer = profileCreated ? data?.customer as Customer : null;

  // We add the user ID to the tracker so that we can use it on events
  // Note, if the customer is not logged in, it will properly be set to undefined
  useEffect(() => {
    tracker.setUser(customer?.id);
  }, [customer, tracker]);

  const [pwlessLogin] = usePasswordlessLoginMutation({ client });
  const passwordlessLogin = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    let hasError = false;

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

      const { data } = await pwlessLogin({
        variables: {
          input: {
            phone: formattedPhoneNumber,
            source: getSource()
          }
        }
      });

      if(!(data?.passwordlessLoginUnified.__typename === 'PasswordlessLoginUnifiedResponse' && data.passwordlessLoginUnified.success)) {
        hasError = true;
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [pwlessLogin, setLoading]);

  const [completeIdentityProfile] = useCompleteIdentityProfileMutation({ client });
  const completeSignup = useCallback(async (email: string, firstName: string, lastName: string) => {
    setLoading(true);
    let hasError = false;
    // The ApolloClient's auth link adds the auth header to requests only if the guest profile has been
    // completed, but the call to complete the profile requires the auth header,
    // so it is added here.
    const headers: { [key: string]: string } = {};
    const accessToken = getCookie(PWLESS_ACCESS);
    if(accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    try {
      const { data } = await completeIdentityProfile({
        variables: {
          input: {
            email: email,
            source: getRegistrationSource(),
            firstName,
            lastName
          }
        },
        context: { headers }
      });

      if(data?.completeIdentityProfile.__typename !== 'CompleteIdentityProfileResponse') {
        hasError = true;
      } else {
        await refreshAuthTokenWrapper();
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [completeIdentityProfile, setLoading, refreshAuthTokenWrapper]);

  const [confirmCode] = useConfirmCodeMutation({ client });
  const passwordlessConfirmCode = useCallback(async (phoneNumber: string, code: string) => {
    setLoading(true);
    let customerGuid = null;

    try {
      const { data } = await confirmCode({
        variables: {
          input: {
            code,
            phone: phoneNumber,
            source: getSource()
          }
        }
      });

      if(data?.passwordlessConfirmCodeUnified.__typename === 'PasswordlessTokenUnifiedResponse') {
        customerGuid = data.passwordlessConfirmCodeUnified.guestGuid;
        setTokensWrapper(
          data.passwordlessConfirmCodeUnified.accessToken,
          data.passwordlessConfirmCodeUnified.expiresAtIso8601,
          data.passwordlessConfirmCodeUnified.refreshToken
        );
      }
    } catch(err) {
      customerGuid = null;
    }

    setLoading(false);
    return customerGuid;
  }, [confirmCode, setLoading, setTokensWrapper]);

  // For use in instances where operations are contingent upon the existence of an account
  const fetchCustomer = useCallback(async () => {
    const accessToken = getCookie(PWLESS_ACCESS);
    if(!isGuestProfileCreated(accessToken)) return null;
    try {
      const { data } = await client.query<CustomerQueryResult['data']>({ query: CustomerDocument, fetchPolicy: 'network-only' });

      return (data?.customer || null) as Customer | null;
    } catch(err) {
      // Error expected, BFF throws when customer doesn't exist
      return null;
    }
  }, [client]);

  const [logoutMutation] = useLogoutMutation({ client });
  const pwlessLogout = useCallback(async () => {
    const refreshToken = getCookie(PWLESS_REFRESH);
    const input: PasswordlessLogoutInput = {
      source: getSource(),
      refreshToken
    };
    try {
      const { data: _data } = await logoutMutation({ variables: { input } });
      if(_data?.passwordlessLogout?.__typename === 'PasswordlessAuthenticationError') {
        Sentry.captureException(`ERROR: passwordless logout error code: ${_data.passwordlessLogout.code}, message ${_data.passwordlessLogout.message}`);
        return false;
      }

      deleteTokens();
      client.writeQuery({
        query: CustomerDocument,
        data: { customer: null }
      });
      return true;
    } catch(err) {
      Sentry.captureException(`ERROR: passwordless logout error ${err}`);
      return false;
    }
  }, [logoutMutation, deleteTokens, client]);

  const authenticationStatus = useRef<AuthenticationStatus>();
  useEffect(() => {
    const status = getAuthenticationStatus(customer);
    if(authenticationStatus.current !== status) {
      tracker.register({ authenticationStatus: status });
      authenticationStatus.current = status;
    }
  }, [customer, authenticationStatus, tracker]);

  useEffect(() => {
    if(getCookie(PWLESS_REFRESH) && !getCookie(PWLESS_ACCESS) && !customer && !isEditor) {
      refreshAuthTokenWrapper();
    }
  // Refresh the access token on the first render if a refresh token exists and the access token expired
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // The first customer call can trigger an auth error and refresh of the authToken cookie outside of the
    // customer context. This happens when the cookie is set to an expired token and apollo client catches this
    // and does the refresh. In this case just refetch the customer if the token has changed.
    const hasAuthErrors = customerError?.graphQLErrors && customerError.graphQLErrors.findIndex((err: any) => err?.extensions?.code === 'UNAUTHENTICATED') !== -1;
    if(hasAuthErrors) {
      // reread the access token which should be refreshed by apollo client
      setProfileCreated(isGuestProfileCreated(getCookie(PWLESS_ACCESS)));
    }
  }, [customerError, profileCreated, refetchCustomer]);

  return (
    <CustomerContextCommonProvider context={{
      customerContextMode: 'PASSWORDLESS_AUTH',
      customer,
      loadingCustomer: loading || loadingCustomer,
      refetchCustomer,
      refreshAuthToken: refreshAuthTokenWrapper,
      passwordlessLogin,
      passwordlessConfirmCode,
      completeSignup,
      fetchCustomer,
      pwlessLogout
    }}>
      {props.children}
    </CustomerContextCommonProvider>
  );
};
