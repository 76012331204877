export type MsaType = {
  label?: string,
  city: string,
  state: string,
  description: string,
  latitude: number,
  longitude: number
};

export const MSAS: MsaType[] = [
  {
    city: 'Atlanta',
    state: 'GA',
    description: 'Visit this underrated foodie city for Southern fare and much more.',
    latitude: 33.748997,
    longitude: -84.387985
  },
  {
    city: 'Austin',
    state: 'TX',
    description: 'The Tex Mex might be great – but there&apos;s even more to love here.',
    latitude: 30.264980,
    longitude: -97.746597
  },
  {
    city: 'Baltimore',
    state: 'MD',
    description: 'Local produce shines in this coastal city&apos;s emerging food scene.',
    latitude: 39.290386,
    longitude: -76.612190
  },
  {
    city: 'Boston',
    state: 'MA',
    description: 'Explore the rich culinary history of one of America&apos;s oldest cities.',
    latitude: 42.360081,
    longitude: -71.058884
  },
  {
    city: 'Chicago',
    state: 'IL',
    description: 'A must visit with a diverse selection of incredible restaurants.',
    latitude: 41.881832,
    longitude: -87.623177
  },
  {
    city: 'Cleveland',
    state: 'OH',
    description: 'Come to Cleveland hungry! This growing city&apos;s food scene is top notch.',
    latitude: 41.503201,
    longitude: -81.621277
  },
  {
    city: 'Dallas',
    state: 'TX',
    description: 'Enjoy a wide range of delicious cuisine in the Big D.',
    latitude: 32.779167,
    longitude: 96.808891
  },
  {
    city: 'Denver',
    state: 'CO',
    description: 'Explore a little bit of everything in the Mile High City.',
    latitude: 39.742043,
    longitude: -104.991531
  },
  {
    city: 'Detroit',
    state: 'MI',
    description: 'Discover what makes this city a hot food destination.',
    latitude: 42.331429,
    longitude: -83.045753
  },
  {
    city: 'Houston',
    state: 'TX',
    description: 'Barbeque and Tex-Mex are must-try cuisines in this fast growing city.',
    latitude: 29.637964,
    longitude: -95.395935
  },
  {
    city: 'Los Angeles',
    state: 'CA',
    description: 'Check out one of the top foodie destinations in America.',
    latitude: 34.052235,
    longitude: -118.243683
  },
  {
    city: 'Miami',
    state: 'FL',
    description: 'An eclectic culinary hotspot influenced by Pan-Latin flavors.',
    latitude: 25.793449,
    longitude: -80.139198
  },
  {
    city: 'Minneapolis',
    state: 'MN',
    description: 'Explore this vibrant restaurant scene in the Land of 10,000 Lakes.',
    latitude: 46.392410,
    longitude: -94.636230
  },
  {
    city: 'Nashville',
    state: 'TN',
    description: 'Hot chicken and barbecue are a must in Music City.',
    latitude: 36.196167,
    longitude: -86.622498
  },
  {
    city: 'New York',
    state: 'NY',
    description: 'Find out for yourself why this is the culinary capital of the world.',
    latitude: 40.730610,
    longitude: -73.935242
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    description: 'Come for the cheesesteak, stay for everything else Philly has to offer.',
    latitude: 39.952583,
    longitude: -75.165222
  },
  {
    city: 'Phoenix',
    state: 'AZ',
    description: 'Southwestern cuisine at its finest.',
    latitude: 33.448376,
    longitude: -112.074036
  },
  {
    city: 'Providence',
    state: 'RI',
    description: 'Discover chef-driven restaurants in lively, friendly neighborhoods.',
    latitude: 41.825226,
    longitude: -71.418884
  },
  {
    city: 'Richmond',
    state: 'VA',
    description: 'Home to more than Virginia&apos;s capital, Richmond is a foodie&apos;s dream.',
    latitude: 37.541290,
    longitude: -77.434769
  },
  {
    city: 'San Diego',
    state: 'CA',
    description: 'This town is more than its sunshine. Find fresh oysters, tacos & more.',
    latitude: 32.715736,
    longitude: -117.161087
  },
  {
    city: 'San Francisco',
    state: 'CA',
    description: 'Its reputation for innovation extends to its restaurant scene.',
    latitude: 37.773972,
    longitude: -122.431297
  },
  {
    city: 'Seattle',
    state: 'WA',
    description: 'Amazing seafood just scratches the surface in the heart of the Pacific Northwest.',
    latitude: 47.608013,
    longitude: -122.335167
  },
  {
    city: 'Tampa',
    state: 'FL',
    description: 'Enjoy cuban sandwiches, deviled crab, and more in this seaside city.',
    latitude: 27.964157,
    longitude: -82.452606
  },
  {
    label: 'Washington, D.C.',
    city: 'Washington',
    state: 'DC',
    description: 'Push beyond politics when you explore DC&apos;s thriving restaurant scene.',
    latitude: 38.889805,
    longitude: -77.009056
  }
];
