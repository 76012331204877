export const CUISINES = [
  {
    name: 'American',
    value: 'american',
    icon: 'toastLocal/cuisines/american.png',
    image: 'toastLocal/splash/cuisines/hotdog-with-onions-and-mustard-176.jpg'
  },
  {
    name: 'Bars & Lounges',
    value: 'bar',
    icon: 'toastLocal/cuisines/bars.png',
    image: 'toastLocal/splash/cuisines/cocktail-and-snacks-176.jpg'
  },
  {
    name: 'Barbecue',
    value: 'barbecue',
    icon: 'toastLocal/cuisines/barbecue.png',
    image: 'toastLocal/splash/cuisines/ribs-with-mac-and-cheese-176.jpg'
  },
  {
    name: 'Breakfast & Brunch',
    value: 'breakfast',
    icon: 'toastLocal/cuisines/breakfast.png',
    image: 'toastLocal/splash/cuisines/poached-egg-on-toast-176.jpg'
  },
  {
    name: 'Burgers',
    value: 'burgers',
    icon: 'toastLocal/cuisines/burgers.png',
    image: 'toastLocal/splash/cuisines/fat-juicy-burgers-and-fries-176.jpg'
  },
  {
    name: 'Cafes, Coffee & Tea',
    value: 'cafe',
    icon: 'toastLocal/cuisines/cafe.png',
    image: 'toastLocal/splash/cuisines/espresso-drink-and-almond-croissant-176.jpg'
  },
  {
    name: 'Chinese',
    value: 'chinese',
    icon: 'toastLocal/cuisines/chinese.png',
    image: 'toastLocal/splash/cuisines/chicken-and-vegetable-stir-fry-176.jpg'
  },
  {
    name: 'Desserts',
    value: 'desserts',
    icon: 'toastLocal/cuisines/desserts.png',
    image: 'toastLocal/splash/cuisines/assorted-gelato-176.jpg'
  },
  {
    name: 'Greek',
    value: 'greek',
    icon: 'toastLocal/cuisines/greek.png',
    image: 'toastLocal/splash/cuisines/lamb-gyro-with-fries-176.jpg'
  },
  {
    name: 'Indian',
    value: 'indian',
    icon: 'toastLocal/cuisines/indian.png',
    image: 'toastLocal/splash/cuisines/garlic-naan-and-various-indian-cuisine-176.jpg'
  },
  {
    name: 'Italian',
    value: 'italian',
    icon: 'toastLocal/cuisines/italian.png',
    image: 'toastLocal/splash/cuisines/seafood-salad-on-spaghetti-176.jpg'
  },
  {
    name: 'Japanese',
    value: 'japanese',
    icon: 'toastLocal/cuisines/japanese.png',
    image: 'toastLocal/splash/cuisines/bowl-of-ramen-with-chicken-and-egg-176.jpg'
  },
  {
    name: 'Korean',
    value: 'korean',
    icon: 'toastLocal/cuisines/korean.png',
    image: 'toastLocal/splash/cuisines/korean-pork-dish-with-egg-176.jpg'
  },
  {
    name: 'Mediterranean',
    value: 'mediterranean',
    icon: 'toastLocal/cuisines/mediterranean.png',
    image: 'toastLocal/splash/cuisines/dolmas-with-tzatziki-sauce-176.jpg'
  },
  {
    name: 'Mexican & Tex-Mex',
    value: 'mexican',
    icon: 'toastLocal/cuisines/mexican.png',
    image: 'toastLocal/splash/cuisines/mexican-street-corn-176.jpg'
  },
  {
    name: 'Middle Eastern',
    value: 'middle-eastern',
    icon: 'toastLocal/cuisines/middle-eastern.png',
    image: 'toastLocal/splash/cuisines/couscous-salad-on-pita-with-falafel-176.jpg'
  },
  {
    name: 'Pizza',
    value: 'pizza',
    icon: 'toastLocal/cuisines/pizza.png',
    image: 'toastLocal/splash/cuisines/vegetable-pizza-176.jpg'
  },
  {
    name: 'Salad',
    value: 'salad',
    icon: 'toastLocal/cuisines/salad.png',
    image: 'toastLocal/splash/cuisines/deconstructed-salad-with-assorted-vegetables-176.jpg'
  },
  {
    name: 'Sandwiches',
    value: 'sandwiches',
    icon: 'toastLocal/cuisines/sandwiches.png',
    image: 'toastLocal/splash/cuisines/steak-and-cheese-with-onions-176.jpg'
  },
  {
    name: 'Seafood',
    value: 'seafood',
    icon: 'toastLocal/cuisines/seafood.png',
    image: 'toastLocal/splash/cuisines/scallops-on-bed-of-vegetables-176.jpg'
  },
  {
    name: 'Southern',
    value: 'southern',
    icon: 'toastLocal/cuisines/southern.png',
    image: 'toastLocal/splash/cuisines/collard-greens-and-sandwich-176.jpg'
  },
  {
    name: 'Sushi',
    value: 'sushi',
    icon: 'toastLocal/cuisines/sushi.png',
    image: 'toastLocal/splash/cuisines/assorted-salmon-eel-and-tuna-sushi-176.jpg'
  },
  {
    name: 'Thai',
    value: 'thai',
    icon: 'toastLocal/cuisines/thai.png',
    image: 'toastLocal/splash/cuisines/seafood-soup-and-rice-176.jpg'
  }
];
