import { useCallback } from 'react';
import useScript from 'react-script-hook';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { reportErrorMessageWithData } from 'src/lib/js/clientError';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { useTracker } from 'src/lib/js/hooks/useTracker';

import {
  resources,
  spiEnabled
} from 'config';

declare global {
  interface Window {
    Toast: any;
  }
}

export const useSpi = () => {
  const { ooSpiEnabled, ooSpiSurchargingFe } = useFlags();
  const isIntlRestaurant = useIsIntlRestaurant();

  return {
    spiEnabled: ooSpiEnabled && !isIntlRestaurant && spiEnabled,
    spiSurchargingEnabled: ooSpiEnabled && !isIntlRestaurant && spiEnabled && ooSpiSurchargingFe
  };
};

// only log the events if the FF is enabled
export const useTempEventTracker = () => {
  const { ooSpiTempEventsEnabled } = useFlags();
  const tracker = useTracker();

  const track = useCallback((name: any, data: any) => {
    if(ooSpiTempEventsEnabled) {
      tracker.track(name, data);
    }
  }, [tracker, ooSpiTempEventsEnabled]);

  return { track };
};

export const useSpiSdk = (skip?: boolean, forceEnable?: boolean) => {
  const { spiEnabled } = useSpi();
  const { track } = useTempEventTracker();
  const [loading, error] = useScript({ src: !skip && (spiEnabled || forceEnable) ? resources.spiURL : null });
  if(error) {
    reportErrorMessageWithData('Failed to load SPI SDK', { src: resources.spiURL, error });
    track('temp_SPI_sdk_failed', {});
  }
  return !loading && !error && typeof window !== 'undefined' ? window.Toast : undefined;
};
