import React from 'react';

import { ScheduleIcon, HomeIcon, DeliveryIcon, LocationIcon, PickUpIcon } from '@toasttab/buffet-pui-icons';

import { DiningOptionBehavior, OrderStatusData } from 'src/apollo/onlineOrdering';
import { CompletedOrder } from 'src/public/components/online_ordering/types';
import Link from 'src/shared/components/common/link';

// eslint-disable-next-line no-restricted-imports
import { UnifiedOrderStatus, deliveryStatusToFulfillmentTime, takeoutStatusToFulfillmentTime } from '../order_status_bar/statusUtil';


type TrackerFulfillmentInfoProps = {
  order: CompletedOrder
  orderStatus: UnifiedOrderStatus
  orderStatusData?: OrderStatusData
  loggedIn: boolean
}

export const TrackerFulfillmentInfo = ( { order, orderStatus, orderStatusData, loggedIn } : TrackerFulfillmentInfoProps ) => {
  const destination = encodeURIComponent([
    order.restaurant.location.address1,
    order.restaurant.location.address2 || '',
    order.restaurant.location.city,
    order.restaurant.location.state,
    order.restaurant.location.zip
  ].join(' '));
  const directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
  const fulfillmentDateInfo = order.diningOptionBehavior === DiningOptionBehavior.TakeOut ?
    takeoutStatusToFulfillmentTime(orderStatus, order) :
    deliveryStatusToFulfillmentTime(orderStatus, orderStatusData, order);

  return (
    <div>
      {order.diningOptionBehavior === DiningOptionBehavior.Delivery
        ?
        <div>
          {fulfillmentDateInfo &&
            <div className="fulfillmentRow">
              <div className="icon"><ScheduleIcon /></div>
              <div className="header">{fulfillmentDateInfo}</div>
            </div>}
          {loggedIn &&
          <div className="fulfillmentRow">
            <div className="icon"><HomeIcon /></div>
            <div>
              <div className="header">Delivery Location</div>
              {order.deliveryInfo &&
              <div className="deliveryAddress">
                <div>{order.deliveryInfo.address1}</div>
                <div>{order.deliveryInfo.address2}</div>
                <div>{order.deliveryInfo?.city}, {order.deliveryInfo.state} {order.deliveryInfo.zipCode}</div>
              </div>}
            </div>
          </div>}
          {order.deliveryInfo?.notes &&
          <div data-testid="delivery-notes" className="fulfillmentRow">
            <div className="icon"><DeliveryIcon /></div>
            <div>
              <div className="header">Delivery</div>
              <div className="deliveryInstructions">
                Instructions: {order.deliveryInfo?.notes}
              </div>
            </div>
          </div>}
        </div>
        :
        <div>
          {fulfillmentDateInfo &&
            <div className="fulfillmentRow">
              <div className="icon"><ScheduleIcon /></div>
              <div className="header">{fulfillmentDateInfo}</div>
            </div>}
          <div className="fulfillmentRow">
            <div className="icon"><LocationIcon /></div>
            <div>
              <div className="header">{order.restaurant.location.address1}</div>
              <Link className="directions" href={directionsLink}>
                Get directions
              </Link>
            </div>
          </div>
          <div className="fulfillmentRow">
            <div className="icon"><PickUpIcon /></div>
            <div>
              <div className="header">Pickup</div>
            </div>
          </div>
        </div>}
    </div>
  );
};
