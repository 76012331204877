import {
  ImageRatio,
  MenuConfig,
  MenuTemplate,
  OnlineOrderingConfig,
  PlaceHolderImageGenerationEnum,
  PlaceholderImageConfig,
  RestaurantConfig,
  StyleIntensity
} from 'src/apollo/sites';
import { lightOrDark } from 'src/shared/js/colorUtils';

export enum BackgroundShade {
  Light = 'bgShadeLight',
  Dark = 'bgShadeDark'
}

/**
 * Templates now dictates some behaviors/styles implicitly
 */
export type TemplateOverrides = {
  menuTemplateOverride?: MenuTemplate | null;
  cardOrientation?: string;
  placeholderImageConfig: PlaceholderImageConfig;
  borderStroke: string;
  borderColor?: string;
  backgroundShade: BackgroundShade;
  roundedCorner: string;
  dropShadow: string;
  imageRatio?: string;
  enableMobileOptimization: boolean;
  v2TagPositioning: boolean;
  hideMenuItemDescription: boolean;
  hideImages?: boolean;
}

const calcImageRatio = (
  imgRatioStr: string | null | undefined,
  ooConfig: OnlineOrderingConfig | null | undefined,
  menuTemplate: MenuTemplate | null | undefined,
  isOoBasic: boolean
): ImageRatio | undefined => {
  let imgRatio: ImageRatio | undefined;

  if(isOoBasic) {
    return ImageRatio.Smaller;
  }

  switch(menuTemplate) {
    case MenuTemplate.Classic:
    case MenuTemplate.LeftImageV2:
      imgRatio = ImageRatio[imgRatioStr as keyof typeof ImageRatio]
        || (ooConfig?.format?.squareImages ? ImageRatio.Smaller : ImageRatio.Larger);
      break;
    case MenuTemplate.TopImageV2:
      imgRatio = ImageRatio[imgRatioStr as keyof typeof ImageRatio] || ImageRatio.Shorter;
      break;
  }

  return imgRatio;
};

/**
 * We are moving to controlling some card behaviors such as image layout based on the template as opposed to separate configs.
 */
export const overrideConfigBasedOnTemplate = (menuConfig?: MenuConfig | null, config?: RestaurantConfig | null): TemplateOverrides => {
  const menuFormatConfig = menuConfig?.format;
  const template = menuFormatConfig?.template;
  const ooConfig = config?.ooConfig;
  const isOoBasic = Boolean(!config || config.isOnlineOrderingOnly && config.hasFullCustomization === false);
  const searchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : new Map<string, string>();
  const menuTemplateOverrideStr = searchParams.get('menuTemplate');
  const menuTemplateOverride = MenuTemplate[menuTemplateOverrideStr as keyof typeof MenuTemplate] || template;

  const forcePlaceholder = [MenuTemplate.LeftImageV2, MenuTemplate.TopImageV2].includes(menuTemplateOverride) ? PlaceHolderImageGenerationEnum.RepeatedText : null;
  const generatePlaceholderImageStr = searchParams.get('menuPlaceholderImage');
  const generatePlaceholderImage = PlaceHolderImageGenerationEnum[generatePlaceholderImageStr as keyof typeof PlaceHolderImageGenerationEnum]
    || forcePlaceholder || menuFormatConfig?.placeholderImage?.generatePlaceholderImage || PlaceHolderImageGenerationEnum.None;

  const placeholderImageConfig: PlaceholderImageConfig = {
    generatePlaceholderImage,
    // don't allow override of text due to security issues with freeform user input text rendering
    placeholderImageText: menuFormatConfig?.placeholderImage?.placeholderImageText
  };

  const borderStrokeStr = searchParams.get('menuBorderStroke');
  const borderStrokeIntensity = StyleIntensity[borderStrokeStr as keyof typeof StyleIntensity] || menuFormatConfig?.borderStroke || StyleIntensity.None;
  const borderStroke = `borderStroke${borderStrokeIntensity}`;
  const borderColor = searchParams.get('menuBorderColor') || menuFormatConfig?.borderColor || undefined;

  const roundedCornerStr = searchParams.get('menuRoundedCorner');
  const roundedCornerIntensity = StyleIntensity[roundedCornerStr as keyof typeof StyleIntensity] || menuFormatConfig?.roundedCorner || StyleIntensity.None;
  const roundedCorner = `roundedCorner${roundedCornerIntensity}`;

  const dropShadowStr = searchParams.get('menuDropShadow');
  const dropShadowIntensity = StyleIntensity[dropShadowStr as keyof typeof StyleIntensity] || menuFormatConfig?.dropShadow || StyleIntensity.None;
  const dropShadow = `dropShadow${dropShadowIntensity}`;

  const lightOrDarkBg = lightOrDark(ooConfig?.colors?.background);
  const backgroundShade = lightOrDarkBg < 0.5 ? BackgroundShade.Dark : BackgroundShade.Light;

  const imageRatioStr = searchParams.get('menuImageRatio');
  const imageRatioCalc = calcImageRatio(imageRatioStr || menuFormatConfig?.imageRatio, ooConfig, menuTemplateOverride, isOoBasic);
  const imageRatio = imageRatioCalc && `imageRatio${imageRatioCalc}`;

  const enableMobileOptimizationStr = searchParams.get('mobileOptimized');
  const enableMobileOptimization = enableMobileOptimizationStr?.toLowerCase() === 'true' || (isOoBasic ? false : menuFormatConfig?.mobileOptimization) || false;

  const hideMenuItemDescriptionStr = searchParams.get('hideMenuItemDescription');
  const hideMenuItemDescription = hideMenuItemDescriptionStr?.toLowerCase() === 'true' || menuFormatConfig?.hideMenuItemDescription || false;


  const templateOverrides: TemplateOverrides = {
    menuTemplateOverride,
    placeholderImageConfig,
    borderStroke,
    borderColor,
    backgroundShade,
    roundedCorner,
    dropShadow,
    imageRatio,
    enableMobileOptimization,
    v2TagPositioning: false,
    hideMenuItemDescription
  };

  switch(menuTemplateOverride) {
    case MenuTemplate.Classic:
      templateOverrides.cardOrientation = 'right';
      templateOverrides.v2TagPositioning = true;
      break;
    case MenuTemplate.LeftImageV2:
    case MenuTemplate.TopImageV2:
      templateOverrides.cardOrientation = 'left';
      templateOverrides.v2TagPositioning = true;
      break;
    default:
      templateOverrides.cardOrientation = ooConfig?.cardOrientation || 'left';
  }

  return templateOverrides;
};
