import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router';

import { useEditor } from '@toasttab/sites-components';

import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export type AttributionContextType = {
  // Attribution fields to be applied as utm_ search params within this context.
  utm_medium?: string, // defaults to 'toast_sites'
  utm_source?: string, // defaults to site domain name
  utm_campaign?: string,
  utm_content?: string, // defaults to page name (extracted from URL path).
  utm_term?: string,
}

export const AttributionContext = createContext<AttributionContextType>({});

export const useAttribution = () => {
  const context = useContext(AttributionContext);
  const { isEditor } = useEditor();
  if(isEditor) {
    return {};
  }
  if(!context) {
    throw new Error('useAttribution must be used within an AttributionContextProvider');
  }
  return context;
};

const pathToContent = (path: string): string => {
  // Extracts a valid utm_content string from the page path.
  // '--' replaces '/' as a path delimiter.
  if(path.startsWith('/')) {
    // strip leading slash
    path = path.slice(1);
  }
  if(path === '') {
    return 'home';
  }
  return path.split('/').join('--');
};

/**
 * Use AttributionContextProvider to nest and override UTM params throughout a page.
 * You can nest multiple AttributionContextProviders, each overriding a different value.
 * Each AttributionContextProvider will inherit or override its parent's context state.
 * Values passed directly to the AttributionContextProvider will always override the parent's context.
 *
 * medium: default="toast_sites",
 * source: default=<domain name>
 * content: defaults to the page name, retrieved from URL path.
 * campaign: no default
 * term: no default
 */
const AttributionContextProvider = ({ children, ...props }: React.PropsWithChildren<AttributionContextType>) => {
  const { isEditor } = useEditor();
  return isEditor ?
    <EmptyAttributionContextProvider>{children}</EmptyAttributionContextProvider>
    : <SitesAttributionContextProvider {...props}>{children}</SitesAttributionContextProvider>;
};

/** EmptyAttributionContextProvider sets attribution context to {} on all of its children. This should be used inside Editor DOM tree only. */
const EmptyAttributionContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  return <AttributionContext.Provider value={{}}>{children}</AttributionContext.Provider>;
};

const SitesAttributionContextProvider = ({ children, ...attributionProps }: React.PropsWithChildren<AttributionContextType>) => {
  let newContext: AttributionContextType = { utm_medium: 'toast_sites' };

  // Prevent `router` components from being forwarded with context props
  const parentContext = useAttribution();
  const pageLocation = useLocation();

  if(parentContext) {
    // if a parentContext exists, inherit its values.
    newContext = { ...newContext, ...parentContext };
  }

  // hostname used for utm_source
  const restaurant = useOptionalRestaurant();
  const host = restaurant?.host;
  // path used for utm_content,
  const utmContent = pathToContent(pageLocation.pathname);

  // UTM values handed through props are highest priority.
  // Override inherited or fetched values with the prop values.
  newContext = { utm_source: host, utm_content: utmContent, ...newContext, ...attributionProps };
  return <AttributionContext.Provider value={newContext}>{ children }</AttributionContext.Provider>;
};


export default AttributionContextProvider;
