import React, { useContext } from 'react';

import * as ld from '@launchdarkly/node-server-sdk';
import { Request } from 'express';


const FlagContext = React.createContext<ld.LDFlagSet>({});

export const FlagContextProvider = ({ children, req }: React.PropsWithChildren<{ req: Request }>) => {
  return (
    <FlagContext.Provider value={{ ...req?.flags }}>{children}</FlagContext.Provider>
  );
};

export const useFlagContext = () => {
  return useContext(FlagContext);
};
