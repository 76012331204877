import React, { useMemo } from 'react';

import classNames from 'classnames';


import { RankedPromoOfferDiscount } from 'src/apollo/onlineOrdering';
import { ButtonType, MenuConfig } from 'src/apollo/sites';
import { formatImageURL, formatPrice } from 'src/lib/js/utilities';
import { MenuItem } from 'src/public/components/default_template/menu_section/MenuSection';
import { PlaceHolderMenuImage } from 'src/public/components/default_template/menu_section/PlaceHolderMenuImage';
import { CART_POPOVER_CONTEXT_KEY } from 'src/public/components/default_template/online_ordering/cart/CartModal';
import { OutOfStockTag } from 'src/public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useOOMenus } from 'src/public/components/online_ordering/useOOMenu';
import Button from 'src/shared/components/common/button';
import { useModal } from 'src/shared/components/common/modal';
import { usePopoverContext } from 'src/shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';
import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';

import { getMenuItemFromGuid, getOfferPrice, getGroupNameFromGuid, getMenuNameFromGuid, scrollToMenu } from './OfferUtils';


export type OfferItemsProps = { offer: RankedPromoOfferDiscount, closeOfferItems: () => void};

export const OfferItemsGrid = ({ offer, closeOfferItems }: OfferItemsProps) => {
  const { menus } = useOOMenus({});

  if(!menus || !offer?.discountEntities?.items) {
    return null;
  }

  const itemList: MenuItem[] = [];
  let noImages = true;
  offer.discountEntities.items.forEach((guid : string) => {
    const item = getMenuItemFromGuid(guid, menus);
    if(item) {
      itemList.push(item);
      noImages = noImages && !item.imageUrls?.xs;
    }
  });

  return (
    <div className="offerItemsGrid" data-testid="offer-items-grid">
      {offer.discountEntities.items.map((guid : string) => {
        const item = getMenuItemFromGuid(guid, menus);
        return <OfferItem key={guid} item={item} offer={offer} closeOfferItems={closeOfferItems} showImages={!noImages} />;
      })}
    </div>
  );
};

type OfferItemProps = {
  item?: MenuItem | null,
  offer: RankedPromoOfferDiscount,
  closeOfferItems: () => void,
  showImages: boolean
};
const OfferItem = ({ item, offer, closeOfferItems, showImages }: OfferItemProps) => {
  const { cartGuid, cart } = useCart();
  const { isOpen, onClose, onOpen } = useModal();
  const { selectedLocation, restaurant: { config, name, meta: { fontFamily } } } = useRestaurant();
  const templateOverrides = useMemo(() => overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config), [config]);
  const restaurantGuid = selectedLocation.externalId;
  if(!item) {
    return null;
  }
  const outOfStock = item.outOfStock;
  const price = item.prices?.[0] || item.price || 0;
  const newPrice = getOfferPrice(offer, price);
  let image = null;
  if(showImages) {
    if(item?.imageUrls?.xs) {
      image = <><img className="imgWrapper" src={formatImageURL(item.imageUrls.xs)} alt={item.name || ''} data-testid="offer-item-image" /><div className="imgGradient" /></>;
    } else {
      const text = templateOverrides?.placeholderImageConfig.placeholderImageText || name;
      const imageSize = 200;
      image =
        <PlaceHolderMenuImage
          text={text}
          textColor={'rgba(0, 0, 0, .15)'}
          fontFamily={fontFamily?.name}
          fontSize={'16px'}
          rotation={-29}
          height={imageSize}
          width={imageSize}
          alpha={0.2}
          className="imgWrapper" />;
    }
  }
  return (
    <>
      {cart?.restaurant && <ItemModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          closeOfferItems();
        }}
        restaurantGuid={restaurantGuid}
        shortUrl={cart.restaurant?.shortUrl}
        specialRequestsEnabled={cart.restaurant?.specialRequestsConfig.enabled}
        specialRequestsPlaceholder={cart.restaurant?.specialRequestsConfig.placeholderMessage}
        itemGuid={item.guid}
        itemGroupGuid={item.itemGroupGuid}
        cartGuid={cartGuid as string}
        shouldShowHighlights={true}
        offer={offer} />}
      <button
        className={classNames('offerItem',
          templateOverrides?.borderStroke,
          templateOverrides?.roundedCorner,
          templateOverrides?.dropShadow,
          outOfStock && 'outOfStock')}
        style={{ borderColor: templateOverrides?.borderColor }}
        onClick={onOpen}
        disabled={outOfStock ?? false}
        tabIndex={0}>
        <div className="image">
          {image}
        </div>
        <div className="textContent">
          <div className="titleContainer">
            <p className="title">{item.name}</p>
          </div>
          <div className="priceContainer">
            <p className="price">{formatPrice(price)}</p>
            {item.outOfStock
              ? <OutOfStockTag /> :
              <p className="price free">{newPrice === 0 ? 'Free' : formatPrice(newPrice)}</p>}
          </div>
        </div>
      </button>
    </>
  );
};

export const FullMenuOffer = ({ onClose }: { onClose: (openCart: boolean) => void}) => {
  const popovercontext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);

  return (
    <div className="fullMenuOffer">
      <Button
        onClick={() => {
          popovercontext?.close();
          onClose(false);
        }}
        variant={ButtonType.Primary}>
        Back to menu
      </Button>
    </div>
  );
};

type MenuGroupsListProps = {
  menuGroupGuids: string[];
  menuGuids: string[];
  onClose: (openCart: boolean) => void;
};

export const MenuGroupsList = ({ menuGroupGuids, menuGuids, onClose }: MenuGroupsListProps ) => {
  const { menus } = useOOMenus({});
  const popovercontext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);

  if(!menus) {
    return null;
  }

  // For menu groups: identifier is the GUID, for menus: identifier is the name
  const onMenuClick = (identifier: string, isGroup: boolean = false) => {
    popovercontext?.close();
    onClose(false);
    scrollToMenu(identifier, isGroup);
  };

  return (
    <div>
      <div className="menuGroupHeader">Eligible Menu Categories</div>
      <ul className="menuGroupList">
        {menuGuids.map(menuGuid => {
          const menuName = getMenuNameFromGuid(menuGuid, menus);
          if(!menuName) {
            return null;
          }
          return (
            <li key={menuGuid}>
              {menuName}
              <button onClick={() => onMenuClick(menuGuid, false)}>
                View
              </button>
            </li>
          );
        })}
        {menuGroupGuids.map(groupGuid => {
          const groupName = getGroupNameFromGuid(groupGuid, menus);
          if(!groupName) {
            return null;
          }
          return (
            <li key={groupGuid}>
              {groupName}
              <button onClick={() => onMenuClick(groupGuid, true)}>
                View
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OfferItemsGrid;
