import { useEffect } from 'react';

/**
  * an "on scroll" helper hook that listens for scroll position updates and automatically unlistens when the component unmounts.
  * @param {function} onScroll - The callback function called during a scroll change. It is passed directly to the `window.addEventListener(...)`
  * call and thus receives its parameters (`event`).
  */

/* BUG: WHEN THE COMPONENT UNMOUNTS, ACTIONS IN `onScroll` MAY STILL BE CALLED - TRY NAVIGATING AWAY FROM THE PAGE AND COMING BACK - YOU'll SEE A MESSAGE IN THE CONSOLE */
export const useOnScroll = (onScroll: () => void) => {
  useEffect(() => window.addEventListener('scroll', onScroll), [onScroll]);

  return () => window.removeEventListener('scroll', onScroll);
};

export default useOnScroll;
