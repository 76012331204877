import React, { useCallback, useEffect, useState } from 'react';

import { AddIcon, SubtractIcon, DeleteIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';

type Props = {
  onQuantityChange: (quantity: number, change: number, defaultValueChange?: boolean) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
  allowDelete?: boolean;
  onDelete?: () => void;
  className?: string;
  canIncrease?: boolean;
}

type Quantity = {
  current: number;
  previous: number;
}

const QuantitySelector = ({ min, max, defaultValue, className, onQuantityChange, allowDelete, onDelete, canIncrease }: Props) => {
  const [quantity, setQuantity] = useState<Quantity>({
    previous: 0,
    current: defaultValue || min || 0
  });

  useEffect(() => {
    const previous = defaultValue || min || 0;
    const current = defaultValue || min || 0;
    setQuantity({
      previous,
      current
    });
    onQuantityChange(current, current - previous, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((quantity: Quantity) => {
    onQuantityChange(quantity.current, quantity.current - quantity.previous);
    setQuantity(quantity);
  }, [onQuantityChange]);

  const incQuantity = useCallback(() => {
    onChange({
      current: quantity.current + 1,
      previous: quantity.current
    });
  }, [onChange, quantity]);
  const decQuantity = useCallback(() => {
    onChange({
      current: quantity.current - 1,
      previous: quantity.current
    });
  }, [onChange, quantity]);

  /**
   * These actions are defined by WAI-ARIA roles for spinbuttons.
   * @see https://www.w3.org/WAI/ARIA/apg/patterns/spinbutton/
   */
  const handleArrowKeys = useCallback((e: any) => {
    switch(e.key) {
      case 'ArrowUp':
        e.stopPropagation();
        e.preventDefault();
        if(!canIncrease || max && quantity.current === max) {
          return;
        }
        incQuantity();
        break;
      case 'ArrowDown':
        e.stopPropagation();
        e.preventDefault();
        if(quantity.current === min) {
          return;
        }
        decQuantity();
        break;
      case 'Home':
        e.stopPropagation();
        e.preventDefault();
        if(min && quantity.current !== min) {
          onChange({
            current: min,
            previous: quantity.current
          });
        }
        break;
      case 'End':
        e.stopPropagation();
        e.preventDefault();
        if(max && quantity.current !== max) {
          onChange({
            current: max,
            previous: quantity.current
          });
        }
        break;
      default:
        // no-op
    }
  }, [canIncrease, decQuantity, onChange, incQuantity, max, min, quantity]);

  return (
    <div className={classnames('quantitySelector', className)}>
      {quantity.current !== 1 || !allowDelete ?
        <button
          type="button"
          tabIndex={0}
          className="button"
          aria-label="Decrease quantity"
          disabled={min ? quantity.current <= min : false}
          onClick={e => {
            e.stopPropagation();
            decQuantity();
          }}>
          <SubtractIcon />
        </button> :
        <button
          type="button"
          tabIndex={0}
          className="button"
          aria-label="Remove modifier"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if(onDelete) {
              onDelete();
            }
          }}>
          <DeleteIcon />
        </button>}
      <span
        onKeyDown={handleArrowKeys}
        data-testid="spinner"
        className="quantity"
        tabIndex={0}
        aria-label="Quantity" aria-valuemin={min || 0} aria-valuemax={max || Infinity} aria-valuenow={quantity.current}>
        {quantity.current}
      </span>
      <button
        type="button"
        tabIndex={0}
        className="button"
        aria-label="Increase quantity"
        disabled={canIncrease === false || (max ? quantity.current >= max : false)}
        onClick={e => {
          e.stopPropagation();
          incQuantity();
        }}>
        <AddIcon />
      </button>
    </div>
  );
};

export default QuantitySelector;
