import React, { createContext, useContext, useMemo } from 'react';

// eslint-disable-next-line camelcase
import { Menus_VisibilityEnum, useDoGetMenuItemsQuery, DoMenus_GetMenuItemsItem } from 'src/apollo/onlineOrdering';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

type BulkMenuItemsContextType = {
  // eslint-disable-next-line camelcase
  menuItems: DoMenus_GetMenuItemsItem[]
}

type BulkMenuItemsContextProps = {
  bulkMenuItemIds?: any[] | null
}

export const BulkMenuItemsContext = createContext<BulkMenuItemsContextType | undefined>(undefined);

// Provides the menu item details for the menu items listed in the bulk calls
// section for this page.
export const BulkMenuItemsContextProvider = ({ bulkMenuItemIds, children }: React.PropsWithChildren<BulkMenuItemsContextProps>) => {
  const itemsToRequestList = useMemo(() => {
    return bulkMenuItemIds?.map(item => {
      return {
        menuGroupGuid: item?.itemGroupGuid,
        menuItemGuid: item?.itemGuid,
        restaurantGuid: item?.rxGuid,
        respectAvailability: false,
        // eslint-disable-next-line camelcase
        visibility: Menus_VisibilityEnum.ToastOnlineOrdering
      };
    }) || [];
  }, [bulkMenuItemIds]);

  const ooClient = useOOClient();

  const { data, error } = useDoGetMenuItemsQuery({
    variables: { input: { items: itemsToRequestList } },
    skip: itemsToRequestList.length === 0,
    client: ooClient
  });

  const menuItemsData: any[] = useMemo(() => {
    if(error) {
      console.error('Error fetching bulk menu items', error);
    }
    if(data && data.doMenus_getMenuItems.__typename === 'DoMenus_GetMenuItemsResponse') {
      return data.doMenus_getMenuItems.items;
    }
    return [];
  }, [error, data]);

  const context = { menuItems: menuItemsData };
  return (
    <BulkMenuItemsContext.Provider value={context}>
      {children}
    </BulkMenuItemsContext.Provider>);
};

export const useMenuItems = () => {
  const context = useContext(BulkMenuItemsContext);
  if(!context) {
    throw new Error('useMenuItems must be used within a MenuItemsContextProvider');
  }

  return context;
};
