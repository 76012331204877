import { setCookie } from 'react-use-cookie';

import { getGuestUserInfoFromToken, BaseGuestUserInfoFromToken } from '@toasttab/authentication-utils';
import jwtDecode from 'jwt-decode';

import { PWLESS_ACCESS, PWLESS_REFRESH, REFRESH_COOKIE_EXPIRY_DAYS, DEFAULT_AUTH_COOKIE_EXPIRY_DAYS } from 'src/shared/components/common/authentication/constants';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const isTokenExpired = (accessToken: string): boolean => {
  const decodedToken = jwtDecode<BaseGuestUserInfoFromToken>(accessToken);
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  if(decodedToken.exp < secondsSinceEpoch) return true;
  return false;
};

// Returns true if the guest has completed their profile.
// Returns null if given a bad token.
export const isGuestProfileCreated = (accessToken?: string): boolean | null => {
  try {
    if(!accessToken || isTokenExpired(accessToken)) return null;
    // isProfileCreated can be undefined for guests who created profiles before the completeIdentityProfile endpoint existed.
    // these are assumed to have a profile.
    const guestInfo = getGuestUserInfoFromToken(accessToken);
    return guestInfo.isProfileCreated === true || guestInfo.isProfileCreated === undefined;
  } catch(e) {
    // getGuestUserInfoFromToken may throw an exception if given a bad token
    return null;
  }
};

const setAccessToken = (token: string, accessTokenExpiration: string) => {
  const expirationMs = Date.parse(accessTokenExpiration);
  const expirationInDays = !isNaN(expirationMs) ? (expirationMs - Date.now()) / MS_PER_DAY : DEFAULT_AUTH_COOKIE_EXPIRY_DAYS;
  setCookie(PWLESS_ACCESS, token, { days: expirationInDays });
};
const setRefreshToken = (token: string) => setCookie(PWLESS_REFRESH, token, { days: REFRESH_COOKIE_EXPIRY_DAYS });

export const setTokens = (accessToken: string, accessTokenExpiration: string, refreshToken: string, onSetTokens?: (accessToken: string) => void) => {
  setAccessToken(accessToken, accessTokenExpiration);
  setRefreshToken(refreshToken);
  if(onSetTokens) {
    onSetTokens(accessToken);
  }
};

