import React, { useContext, useState, createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { GiftCardConfig, RestaurantCreditCardConfig, SpecialRequestsConfig, SpotlightConfig } from 'src/apollo/onlineOrdering';
import { MenuConfig, OnlineOrderingConfig } from 'src/apollo/sites';

type DebugContextType = {
  debugModeEnabled: boolean
  ooRestaurantOverride: any
  setOORestaurantOverride: (newOverride: any) => void,
  metaOverride: MetaOverrideType,
  setMetaOverride: (newOverride: MetaOverrideType) => void,
  menuPageConfigOverride: MenuConfig,
  setMenuPageConfigOverride: (newOverride: MenuConfig) => void,
  orderPageConfigOverride: OnlineOrderingConfig
  setOrderPageConfigOverride: (newOverride: OnlineOrderingConfig) => void,
}
export const DebugContext = createContext<DebugContextType | undefined>(undefined);

type OORestaurantOverrideType = {
  onlineOrderingEnabled?: boolean,
  minimumDeliveryTime?: number,
  minimumTakeoutTime?: number,
  creditCardConfig?: RestaurantCreditCardConfig,
  giftCardConfig?: GiftCardConfig,
  specialRequestsConfig?: SpecialRequestsConfig,
  spotlightconfig?: SpotlightConfig
}

export type MetaOverrideType = {
  backgroundColor?: 'string',
  primaryColor?: 'string',
  secondaryTextColor?: 'string',
  textColor?: 'string',
  title?: 'string'
}
export const DebugContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [menuPageConfigOverride, setMenuPageConfigOverride] = useState<MenuConfig>({});
  const [orderPageConfigOverride, setOrderPageConfigOverride] = useState<OnlineOrderingConfig>({});
  const [ooRestaurantOverride, setOORestaurantOverride] = useState<OORestaurantOverrideType>({});
  const [metaOverride, setMetaOverride] = useState<MetaOverrideType>({});
  const { search } = useLocation();
  const [debugModeEnabled, setDebugModeEnabled] = useState(false);
  useEffect(() => {
    setDebugModeEnabled( search ? queryString.parse(search).debug === 'true' : false);
  }, [search]);
  return (
    <DebugContext.Provider value={{
      debugModeEnabled,
      ooRestaurantOverride,
      setOORestaurantOverride,
      metaOverride,
      setMetaOverride,
      menuPageConfigOverride,
      setMenuPageConfigOverride,
      orderPageConfigOverride,
      setOrderPageConfigOverride
    }}>{children}
    </DebugContext.Provider>
  );
};

export const useDebug = () => {
  const context = useContext(DebugContext);

  if(!context) {
    throw new Error('debugContext must be used within a DebugContextProvider');
  }
  return context;
};
