import { useCallback } from 'react';

import { Theme } from 'src/apollo/sites';

import { useOptionalRestaurant, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

type ThemeConsumerType = (theme: Theme) => any

const getThemeColorScheme = (theme: Theme, themeConsumer: ThemeConsumerType, defaultColor: any) => {
  try {
    if(theme?.enabled && theme?.colorScheme) {
      return themeConsumer(theme);
    } else {
      return defaultColor;
    }
  } catch(_) {
    return defaultColor;
  }
};

export const useThemeColorScheme = () => {
  const { restaurant: { theme } } = useRestaurant();

  return useCallback((themeConsumer: ThemeConsumerType, defaultColor: any) => {
    return getThemeColorScheme(theme as Theme, themeConsumer, defaultColor);
  }, [theme]);
};

export const useOptionalThemeColorScheme = () => {
  const theme = useOptionalRestaurant()?.restaurant?.theme;

  return useCallback((themeConsumer: ThemeConsumerType, defaultColor: any) => {
    return getThemeColorScheme(theme as Theme, themeConsumer, defaultColor);
  }, [theme]);
};


