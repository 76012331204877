import { RestaurantConfig, RestaurantPage } from 'src/apollo/sites';


type Props = {
  config?: RestaurantConfig;
  sitePages?: RestaurantPage[] | null;
}

export const getLinkEnums = ({ config, sitePages }: Props ) => {
  return (
    [
      { displayName: 'Homepage', value: '/' },
      { displayName: 'Menu', value: '/menu' },
      ...config?.isOnlineOrderingEnabled ? [{ displayName: 'Online Ordering', value: '/order' }] : [],
      ...sitePages?.map(p => ({ displayName: p.route, value: p.route })) || []
    ]
  );
};
