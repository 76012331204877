import React from 'react';

export const RightArrow = ({ color }: { color: string }) => {
  return (
    <svg width="6" height="12" fill="none" stroke={color} viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" >
      <path d="M1.05 8.99994L4.95 4.99994L1.05 0.999939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const LeftArrow = ({ color }: { color: string }) => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 10" fill="none" stroke={color} xmlns="http://www.w3.org/2000/svg" >
      <path d="M4.95 1.00006L1.05 5.00006L4.95 9.00006" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const UpArrow = ({ color }: { color: string }) => {
  return (
    <svg width="28" height="16" viewBox="0 0 28 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M0.499902 14.5547C0.498496 14.2734 0.608182 14.0035 0.80787 13.8052L14.2179 0.410644L27.1933 13.3861L27.1919 13.3847C27.6068 13.7897 27.6152 14.452 27.2116 14.8669C26.8066 15.2803 26.1443 15.2888 25.7294 14.8852L14.2177 3.34287L2.27004 15.2906C2.07457 15.4959 1.80316 15.6126 1.51912 15.6126C1.23646 15.6126 0.965041 15.4959 0.769597 15.2906C0.588189 15.0895 0.491159 14.8265 0.499597 14.5551L0.499902 14.5547Z" />
    </svg>
  );
};

export const DownArrow = ({ color }: { color: string }) => {
  return (
    <svg width="28" height="16" viewBox="0 0 28 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M27.5 1.4455C27.5014 1.72675 27.3917 1.99673 27.192 2.19502L13.782 15.5895L0.806528 2.61406L0.807934 2.61547C0.393106 2.21047 0.384646 1.54814 0.788246 1.13327C1.19325 0.719849 1.85557 0.711389 2.27044 1.11499L13.7822 12.6573L25.7298 0.709632C25.9253 0.50432 26.1967 0.387601 26.4808 0.387601C26.7634 0.387601 27.0348 0.50432 27.2303 0.709632C27.4117 0.910724 27.5087 1.17371 27.5003 1.44511L27.5 1.4455Z" />
    </svg>
  );
};

export const DropdownTriangle = ({ color }: {color: string}) => {
  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill={color} xmlns="http://www.w3.org/2000/svg" >
      {/* eslint-disable-next-line max-len */}
      <path d="M7.51814 0.75H1.54693C0.452213 0.75 0.153653 1.35 0.949813 2.15L3.73637 4.95C4.03493 5.25 4.23397 5.25 4.53253 5.25C4.83109 5.25 5.03013 5.15 5.22917 4.95L8.01574 2.15C8.8119 1.35 8.61286 0.75 7.51814 0.75Z" />
    </svg>
  );
};

export const NavControl = ({ color }: {color: string}) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M6 6.55005H18.5M1.5 6.55005H2M6 16.4501H18.5M1.5 16.4501H2M6 11.4501H18.5M1.5 11.4501H2M6 1.55005H18.5M1.5 1.55005H2" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
