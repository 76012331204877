import { EnumItem } from '@toasttab/sites-components';

import { SectionImagePosition, RelativePosition } from 'src/apollo/sites';

export const InsetTextPositionEnum: any[] = [
  { displayName: 'Top', value: SectionImagePosition.Bottom },
  { displayName: 'Top Right', value: SectionImagePosition.BottomLeft },
  { displayName: 'Top Left', value: SectionImagePosition.BottomRight },
  { displayName: 'Center', value: SectionImagePosition.Center },
  { displayName: 'Right', value: SectionImagePosition.Left },
  { displayName: 'Left', value: SectionImagePosition.Right },
  { displayName: 'Bottom', value: SectionImagePosition.Top },
  { displayName: 'Bottom Right', value: SectionImagePosition.TopLeft },
  { displayName: 'Bottom Left', value: SectionImagePosition.TopRight }
];

export const RelativePositionEnum: EnumItem[] = [
  { displayName: 'Top', value: RelativePosition.Top },
  { displayName: 'Top Right', value: RelativePosition.TopRight },
  { displayName: 'Top Left', value: RelativePosition.TopLeft },
  { displayName: 'Center', value: RelativePosition.Center },
  { displayName: 'Right', value: RelativePosition.Right },
  { displayName: 'Left', value: RelativePosition.Left },
  { displayName: 'Bottom', value: RelativePosition.Bottom },
  { displayName: 'Bottom Right', value: RelativePosition.BottomRight },
  { displayName: 'Bottom Left', value: RelativePosition.BottomLeft }
];
