import React, { useMemo, useRef } from 'react';

import { _heading, ModuleField, useEditor, ContainsEditableProps, FieldType } from '@toasttab/sites-components';
import classnames from 'classnames';

import { ButtonType, CardType, ThemeTypeEnum, SectionImagePosition, CardImage, PaddingEnum, BackgroundImage } from 'src/apollo/sites';

import Carousel, { CarouselTypes } from 'shared/components/common/carousel/Carousel';
import { getBackgroundColorOrImageModule, getImageModules, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';

import { InsetTextPositionEnum } from 'public/components/default_template/DefaultTemplateUtils';

import { EditableCard } from './Card';
import PositionedCard from './PositionedCard';

type Props = {
  id: string;
  header?: string | null;
  wrapCards?: boolean | null;
  cards?: Array<CardType> | null;
  theme?: ThemeTypeEnum | null;
  isFirstSection?: boolean;
  primaryColor?: string;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

const SingleCard = ({ editPath, header, card, theme, isFirstSection, backgroundColor, backgroundImage, padding = PaddingEnum.Medium }: Props & { card: CardType }) => {
  const { useEditableRef } = useEditor();
  const pathToCard = `${editPath}.cards[0]`;
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'hero section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
        {
          displayName: 'Button',
          type: FieldType.Boolean,
          path: `${pathToCard}.button`,
          value: !!card.button,
          activeValue: { text: 'Button', type: 'primary', link: '/' }
        },
        {
          path: `${editPath}.theme`,
          displayName: 'Style',
          type: FieldType.Enum,
          value: theme,
          enums: [
            { displayName: 'Default', value: null },
            { displayName: 'Square', value: ThemeTypeEnum.None },
            { displayName: 'Rounded', value: ThemeTypeEnum.Rounded },
            { displayName: 'Outlined', value: ThemeTypeEnum.Outlined },
            { displayName: 'Wide', value: ThemeTypeEnum.Wide },
            // without an image, the half-leaf theme has no effect
            ...card.image ? [{ displayName: 'Leaf', value: ThemeTypeEnum.HalfLeaf }] : []
          ]
        },
        ...card.image ?
          [
            {
              displayName: 'Text Inset',
              type: FieldType.Boolean,
              path: `${pathToCard}.image.textInset`,
              value: !!card.image?.textInset
            },
            {
              path: `${pathToCard}.image.position`,
              displayName: 'Text Position',
              type: FieldType.Enum,
              value: card.image.position,
              enums: InsetTextPositionEnum
            } as ModuleField,
            ...card.image.textInset || card.image.position === SectionImagePosition.Center ?
              getImageModules(`${pathToCard}.image`, card.image)
              : []
          ] as ModuleField[] :
          []
      ]
    }
  });

  const pathToCardHeader = `${editPath}.cardsHeader`;
  const imagePosition = useMemo(() => mapImagePosition(card.image), [card.image]);

  let contentWrapperClassName = '';
  let contentPadding = '';
  if(imagePosition !== SectionImagePosition.Center) {
    if([SectionImagePosition.Top, SectionImagePosition.Bottom].includes(imagePosition)) {
      contentPadding = theme === ThemeTypeEnum.Wide ? 'paddedSection' : '';
    } else {
      contentWrapperClassName = [SectionImagePosition.BottomRight, SectionImagePosition.Right, SectionImagePosition.TopRight].includes(imagePosition) ? 'justifyEnd' : '';
      contentPadding =
        theme === ThemeTypeEnum.Wide
          ? [SectionImagePosition.BottomRight, SectionImagePosition.Right, SectionImagePosition.TopRight].includes(imagePosition)
            ? 'leftPaddedSection'
            : 'rightPaddedSection'
          : '';
    }
  }

  return (
    <div className={classnames({ paddedSection: theme !== ThemeTypeEnum.Wide })} ref={editableRef}>
      <div>
        {header && <_heading styleLevel={2} className="sectionHeader" html={header} editPath={pathToCardHeader} />}
        <PositionedCard
          editPath={pathToCard}
          parentEditPath={editPath}
          contentWrapperClassName={contentWrapperClassName}
          contentClassName={contentPadding}
          theme={theme}
          card={card}
          imgPosition={imagePosition}
          isFirstSection={isFirstSection} />
      </div>
    </div>
  );
};

const MultipleCards = ({ editPath, id, header, wrapCards, cards, theme, primaryColor, backgroundColor, backgroundImage, padding = PaddingEnum.Medium }: Props & { cards: Array<CardType> }) => {
  const { useEditableRef, isEditor } = useEditor();
  const carouselRef = useRef<HTMLDivElement>(null);
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'cards section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    skipRef: carouselRef,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
        {
          path: `${editPath}.theme`,
          displayName: 'Style',
          type: FieldType.Enum,
          value: theme,
          enums: [
            { displayName: 'Default', value: null },
            { displayName: 'Square', value: ThemeTypeEnum.None },
            { displayName: 'Rounded', value: ThemeTypeEnum.Rounded },
            { displayName: 'Outlined', value: ThemeTypeEnum.Outlined },
            { displayName: 'Wide', value: ThemeTypeEnum.Wide },
            // without an image, the half-leaf theme has no effect
            ...cards?.find(c => c.image) ? [{ displayName: 'Half Leaf', value: ThemeTypeEnum.HalfLeaf }] : []
          ]
        },
        // cards start wrapping after the second one
        ...(cards?.length || 0) > 2 ?
          [{
            path: `${editPath}.wrapCards`,
            displayName: 'Wrap Items',
            type: FieldType.Boolean,
            value: wrapCards
          }] as ModuleField[] :
          []
      ]
    }
  });

  const pathToCard = (idx: number) => `${editPath}.cards[${idx}]`;

  if(!cards?.length) {
    if(isEditor) {
      return <div className={classnames('cards', 'emptySection', theme, { wrapCards })} ref={editableRef}></div>;
    }
    return null;
  }

  return (
    <div className={classnames('cards', theme, { wrapCards })} ref={editableRef}>
      {header &&
        <div className="paddedSection">
          <_heading
            styleLevel={2}
            id={`${editPath}.cardsHeader`}
            className="sectionHeader"
            html={header}
            editPath={`${editPath}.cardsHeader`} />
        </div>}
      <Carousel
        id={id}
        theme={theme}
        hideArrows={wrapCards}
        wrapItems={wrapCards}
        primaryColor={primaryColor}
        carouselRef={carouselRef}
        carouselType={CarouselTypes.Cards}
        ariaLabel={header ?? undefined}
        items={cards.map((card, i) =>
          <EditableCard key={`card-${i}-${card.guid || ''}`}
            editPath={pathToCard(i)}
            className="carouselItem"
            theme={theme}
            image={card.image}
            header={card.header}
            subheader={card.subheader}
            title={card.title}
            body={card.body}
            footer={card.footer}
            button={
              card.button ?
                {
                  ...card.button,
                  type: card.button.type || ButtonType.Secondary
                } :
                null
            }
            hasShadow={card.hasShadow}
            alignment={card.alignment} />)} />
    </div>
  );
};

export const mapImagePosition = (image: CardImage | null | undefined) => {
  if(image && image.textInset && image.position) {
    // when image.textInset is true, we should flip the image.position to its horizontal inverse.
    const positionMapping: Record<SectionImagePosition, SectionImagePosition> = {
      [SectionImagePosition.Bottom]: SectionImagePosition.Bottom,
      [SectionImagePosition.BottomLeft]: SectionImagePosition.BottomRight,
      [SectionImagePosition.BottomRight]: SectionImagePosition.BottomLeft,
      [SectionImagePosition.Center]: SectionImagePosition.Center,
      [SectionImagePosition.Left]: SectionImagePosition.Right,
      [SectionImagePosition.Right]: SectionImagePosition.Left,
      [SectionImagePosition.Top]: SectionImagePosition.Top,
      [SectionImagePosition.TopLeft]: SectionImagePosition.TopRight,
      [SectionImagePosition.TopRight]: SectionImagePosition.TopLeft
    };
    return positionMapping[image.position];
  }
  return image?.position || SectionImagePosition.BottomLeft;
};

const Cards = (props: Props) =>
  props.cards && (props.cards.length || 0) === 1 && props.cards[0]?.image
    ? <SingleCard {...props} card={props.cards[0]} />
    : <MultipleCards {...props} cards={props.cards || []} />;

export default Cards;
