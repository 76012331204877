import React from 'react';
import { withRouter } from 'react-router';

import { RequestContextProps } from 'src/lib/js/context';
import { isPreviewSite } from 'src/lib/js/previewModeUtils';

import Button from 'shared/components/common/button';
import Link from 'shared/components/common/link';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { NavType } from 'shared/components/common/nav/Nav';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

type Props = RequestContextProps & {
  navType: NavType;
  shouldShowPreviewBanner: boolean;
  className?: string;
};

const PreviewModeBanner = (props: React.PropsWithChildren<Props>) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { restaurant, toastProduct } = useRestaurant();
  const isPreviewMode = isPreviewSite(restaurant?.config, toastProduct);

  return (
    <>
      {!props.className?.includes('secondaryNav') &&
        props.shouldShowPreviewBanner && isPreviewMode &&
          <>
            <div className="previewModeBanner">
              <p>This website is in preview mode.</p>
              <Button color={'#E7EAF6'} onClick={() => onOpen()}>More info</Button>
            </div>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay fadeIn fadeOut />
              <ModalContent contentClassName="previewModeModalContent" slideIn slideOut data-testid="PreviewModeModal" ariaLabelledBy={'preview-mode-modal'}>
                <div className="modalHeader">
                  <h3 className="header">This website is currently in preview mode.</h3>
                  <ModalCloseButton />
                </div>
                <div>
                  <p>While in preview mode, this site cannot be indexed by search engines, and certain features may be disabled.</p>
                  <br />
                  <p>You can bring your website live via the <Link href="https://www.toasttab.com/restaurants/admin/sites/dashboard/">Websites Dashboard</Link>.</p>
                </div>
              </ModalContent>
            </Modal>
          </>}
    </>
  );
};

export default withRouter(PreviewModeBanner);
