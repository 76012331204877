import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ThemeTypeEnum } from 'src/apollo/sites';

const DEFAULT_ITEMS_PER_PAGE = 3;
export const MOBILE_MIN_CAROUSEL_ITEMS = 2;

type Props = {
  containerId: string;
  containerRef: React.RefObject<HTMLUListElement | HTMLDivElement>;
  theme?: ThemeTypeEnum | null;
  numItems: number;
  itemsPerPage?: number;
  autoScrollMS?: number;
  autoScrollActive?: boolean;
};

export type CarouselDirection = 'forwards' | 'backwards'

export const useCarousel = (props: Props) => {
  const [currentItem, setCurrentItem] = useState(0);

  const itemsPerPage = useMemo(() => props.itemsPerPage || DEFAULT_ITEMS_PER_PAGE, [props.itemsPerPage]);

  const showOneItem = useMemo(() => props.theme === ThemeTypeEnum.Wide && props.numItems > itemsPerPage, [props.theme, props.numItems, itemsPerPage]);

  const itemNamesMap = useMemo(() => {
    const subset = new Map<number, string>();
    [...Array(props.numItems)].forEach((_, i) => showOneItem || i % itemsPerPage === 0 ? subset.set(i, `scrollElement-${props.containerId}-${i}`) : null);
    return subset;
  }, [props.containerId, props.numItems, showOneItem, itemsPerPage]);

  const itemNames = useMemo(() => Array.from(itemNamesMap.values()), [itemNamesMap]);


  useEffect(() => {
    if(typeof window !== 'undefined') {
      require('smoothscroll-polyfill').polyfill();
    }
  }, []);


  const scroll = useCallback((direction: CarouselDirection, autoScrollTrigger: boolean = false) => {
    if(autoScrollTrigger && !props.autoScrollActive) {
      return;
    }
    if(direction == 'backwards') {
      setCurrentItem(curr => curr <= 0 ? itemNames.length - 1 : curr - 1);
    } else {
      setCurrentItem(curr => curr < itemNames.length - 1 ? curr + 1 : 0);
    }
  }, [itemNames, setCurrentItem, props.autoScrollActive]);

  const scrollTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    // Every time the currentItem changes, reset the autoscroll timer.
    // This prevents auto-scroll events from stacking atop user scrolls.
    if(props.autoScrollMS) {
      const currTimer = scrollTimer.current;
      if(currTimer) {
        // Reset the timer.
        clearTimeout(currTimer);
      }
      // store the new timeout
      scrollTimer.current = setTimeout(() => scroll('forwards', true), props.autoScrollMS);
    }

    // teardown
    return () => {
      if(scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
    };
  }, [props.autoScrollMS, scroll, itemNames.length, currentItem]);


  useEffect(() => {
    const scrollName = itemNames[currentItem];
    // scrolling not needed if there arent enough items
    if(scrollName && props.containerRef.current && props.numItems > itemsPerPage) {
      let scrollableContainer: HTMLElement = props.containerRef.current;
      let contentToScrollTo: HTMLElement | null | undefined = document.getElementsByName(scrollName)[0] || document.getElementById(scrollName);
      if(!contentToScrollTo) {
        const editor = document.querySelector('#editorContainer');
        contentToScrollTo = editor?.querySelector(`[name="${scrollName}"]`);
      }
      if(currentItem === 0) {
        scrollableContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return;
      }
      if(contentToScrollTo) {
        let left = contentToScrollTo.offsetLeft;
        const parent = scrollableContainer.parentElement;
        if(parent) {
          let padding = window.getComputedStyle(parent).getPropertyValue('padding-left');
          padding = padding.substring(0, padding.length - 2); // removes `px` from `##px`
          left = contentToScrollTo.offsetLeft - parseInt(padding);
        }
        scrollableContainer.scrollTo({ top: 0, left: left, behavior: 'smooth' });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerId, currentItem, props.containerRef]);

  return {
    showOneItem,
    itemNamesMap,
    scroll,
    currentItem,
    totalScrollItems: itemNames.length,
    itemsPerPage
  };
};
