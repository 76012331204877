import React from 'react';

import { RestaurantProfileRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';

import Pill from './Pill';

type Props = {
  restaurant: RestaurantProfileRestaurantType;
};

const CuisinePills = ({ restaurant }: Props) =>
  restaurant.cuisineType ?
    <div className="cuisine-pills">
      <Pill>{restaurant.cuisineType}</Pill>
    </div> :
    null;

export default CuisinePills;
