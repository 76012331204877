interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

const colorToRgbComponents = (color: string): RGBA => {
  const rgba: RGBA = {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  };

  if(color.match(/^rgb/)) {
    const colorArray = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    rgba.r = parseInt(colorArray?.[1] || '255');
    rgba.g = parseInt(colorArray?.[2] || '255');
    rgba.b = parseInt(colorArray?.[3] || '255');
    rgba.a = parseFloat(colorArray?.[4] || '1.0');
  } else {
    if(color && /^#([A-Fa-f0-9]){3,8}$/.test(color)) {
      let hexArray = color.substring(1).split('');
      if(hexArray.length === 3) {
        hexArray = [hexArray[0]!, hexArray[0]!, hexArray[1]!, hexArray[1]!, hexArray[2]!, hexArray[2]!];
      }

      if(hexArray.length === 8) {
        rgba.a = (Number('0x' + hexArray[6]! + hexArray[7]!) & 255) / 255;
      }
      const normalizedHex: number = Number(`0x${hexArray.slice(0, 6).join('')}`);
      rgba.r = normalizedHex >> 16 & 255;
      rgba.g = normalizedHex >> 8 & 255;
      rgba.b = normalizedHex & 255;

      return rgba;
    }
  }

  return rgba;
};

export const hexToRgbA = (hex?: string | null, aValue?: number | null) => {
  let alphaChannel = aValue;
  if(hex) {
    hex = hex.trim();
  }
  if(hex && /^rgb/.test(hex)) {
    if(hex.indexOf('rgba') === 0) {
      return alphaChannel != null && alphaChannel != undefined ? hex.replace(/,[0-9.]+?\)$/, `,${alphaChannel})`) : hex;
    } else {
      return hex.replace('rgb', 'rgba').replace(')', `,${alphaChannel ?? 1})`);
    }
  }
  if(hex && /^#([A-Fa-f0-9]){3,8}$/.test(hex)) {
    let hexArray = hex.substring(1).split('');
    if(hexArray.length === 3) {
      hexArray = [hexArray[0]!, hexArray[0]!, hexArray[1]!, hexArray[1]!, hexArray[2]!, hexArray[2]!];
    }
    // Use the hex alpha channel if no alpha channel is provided
    if(hexArray.length === 8) {
      if(alphaChannel === null || alphaChannel === undefined) {
        alphaChannel = (Number('0x' + hexArray[6]! + hexArray[7]!) & 255) / 255;
      }
    }
    const normalizedHex = '0x' + hexArray.slice(0, 6).join('');
    // @ts-ignore
    return 'rgba(' + [normalizedHex >> 16 & 255, normalizedHex >> 8 & 255, normalizedHex & 255].join(',') + ',' + (alphaChannel ?? 1) + ')';
  }
  return null;
};

const LIGHT_DARK_HSP_THRESHOLD = 127.5;
export const lightOrDark = (color?: string | null): number => {
  if(!color) {
    // assume light as default
    return 1.0;
  }

  try {
    const rgba = colorToRgbComponents(color);
    // scale all values by the alpha
    const norm: RGBA = {
      r: rgba.a * rgba.r,
      g: rgba.a * rgba.g,
      b: rgba.a * rgba.b,
      a: 1.0
    };

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
      0.299 * (norm.r * norm.r) +
      0.587 * (norm.g * norm.g) +
      0.114 * (norm.b * norm.b)
    );

    // normalize the hsp value from 0-1 around the threshold
    if(hsp < LIGHT_DARK_HSP_THRESHOLD) {
      // dark is < 0.5
      return hsp / LIGHT_DARK_HSP_THRESHOLD / 2;
    }

    // light >= 0.5
    return (hsp - LIGHT_DARK_HSP_THRESHOLD) / (255 - LIGHT_DARK_HSP_THRESHOLD) / 2 + 0.5;
  } catch(e) {
    // input errors just return light value
    console.error(e);

    return 1.0;
  }
};
