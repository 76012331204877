import React from 'react';

import {
  CheckCircleSelectedIcon,
  RestaurantIcon as RxIcon,
  OrderReadyIcon as ReadyIcon,
  HomeIcon as Home,
  ScheduledOrdersIcon,
  ForkKnifeIcon,
  PickUpIcon,
  PickUpCurbsideIcon,
  WarningOutlineIcon,
  WarningFilledIcon
} from '@toasttab/buffet-pui-icons';
import classNames from 'classnames';
export type StatusIconProps = {
  inProgress?: boolean
}

const inProgressColor = '#C0C0C0';
const completedColor = '#252525';

export const OrderConfirmedIcon = ({ inProgress }: StatusIconProps) =>
  <CheckCircleSelectedIcon testId="status-confirmed-icon" className={classNames('statusIcon', { inProgress: inProgress })} />;


export const RestaurantIcon = ({ inProgress }: StatusIconProps) =>
  <RxIcon className={classNames('statusIcon', { inProgress: inProgress })} />;

export const OrderReadyIcon = ({ inProgress }: StatusIconProps) =>
  <ReadyIcon testId="status-pickup-icon-complete" className={classNames('statusIcon', { inProgress: inProgress })} />;

export const DriverIcon = ({ inProgress }: StatusIconProps) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    {/* eslint-disable-next-line max-len */}
    <path d="M20.3089 16.7298L23.1076 15.7575C23.1603 15.7392 23.2162 15.7321 23.2713 15.7227C23.4962 15.6845 23.667 15.4956 23.667 15.2683V14.3452C23.667 11.7606 22.8674 11.1144 21.7481 11.1144H18.8697M20.3089 16.7298C20.3089 18.2592 19.0202 19.499 17.4305 19.499C15.8408 19.499 14.5521 18.2592 14.5521 16.7298M20.3089 16.7298C20.3089 15.2004 19.0202 13.9606 17.4305 13.9606C15.8408 13.9606 14.5521 15.2004 14.5521 16.7298M3.51823 15.7298C2.39233 15.3688 1.8538 12.7494 1.67256 11.625C1.62874 11.3531 1.84933 11.1144 2.13524 11.1144H3.51823M3.51823 11.1144H18.8697M3.51823 11.1144C4.95742 9.72979 5.62905 4.5 8.31555 4.5H14.5521C16.471 4.5 17.4305 9.72979 18.8697 11.1144M9.75475 16.7298C9.75475 18.2592 8.46605 19.499 6.87635 19.499C5.28666 19.499 3.99796 18.2592 3.99796 16.7298C3.99796 15.2004 5.28666 13.9606 6.87635 13.9606C8.46605 13.9606 9.75475 15.2004 9.75475 16.7298ZM9.75475 16.7298H14.5521M11.1939 5V10.6529" stroke={inProgress ? inProgressColor : completedColor} strokeWidth="1.5" />
  </svg>;

export const HomeIcon = ({ inProgress }: StatusIconProps) =>
  <Home className={classNames('statusIcon', { inProgress: inProgress })} />;

export const ScheduledIcon = ({ inProgress }: StatusIconProps) =>
  <ScheduledOrdersIcon testId="status-scheduled-icon" className={classNames('statusIcon', { inProgress: inProgress })} />;

export const PreparingIcon = ({ inProgress }: StatusIconProps) =>
  <ForkKnifeIcon testId="status-preparing-icon" className={classNames('statusIcon', { inProgress: inProgress })} />;

export const PickupIcon = ({ inProgress }: StatusIconProps) =>
  <PickUpIcon testId="status-pickup-icon" className={classNames('statusIcon', { inProgress: inProgress })} />;

export const CurbsideIcon = ({ inProgress }: StatusIconProps) =>
  <PickUpCurbsideIcon testId="status-curbside-icon" className={classNames('statusIcon', { inProgress: inProgress })} />;

type AlertIconProps = {
  full?: boolean
}
export const AlertIcon = ({ full }: AlertIconProps) =>
  full ? <WarningFilledIcon data-testid="status-warning-icon-filled" className="warningStatusIcon" /> : <WarningOutlineIcon data-testid="status-warning-icon" className="warningStatusIcon" />;


