import React from 'react';

import { useFormatPrice } from 'src/lib/js/hooks/useFormatPrice';

type PriceProps = {
  value: number;
};

const FormattedPrice = ({ value } : PriceProps) => {
  return <>{useFormatPrice(value)}</>;
};

export default FormattedPrice;
