import React, { useCallback } from 'react';

import useTracker from 'src/lib/js/hooks/useTracker';

import Image from 'shared/components/common/Image';
import { Modal, ModalOverlay, useModal } from 'shared/components/common/modal';

import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { CheckoutMode, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

export const ToastCheckoutButton = () => {
  const { setCheckoutMode } = useCheckout();
  const tracker = useTracker();
  const { isOpen, onClose, onOpen } = useModal();

  const handleClick = () => {
    onOpen();
    setCheckoutMode(CheckoutMode.Toast);
    tracker.track('Started Authentication', { source: AuthenticationSource.ExpressCheckout });
  };

  return (
    <div className="checkoutMode" data-testid="expressCheckoutButton">
      <button type="button" className="toastCheckout" onClick={handleClick} aria-expanded="false" aria-controls="checkoutSection">
        <div className="text"><Image alt="Toast logo" aria-hidden="true" src="icons/toast-logo-filled.svg" /> Express checkout with Toast</div>
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay fadeIn fadeOut />
        <PwlessAuth source={AuthenticationSource.ExpressCheckout} />
      </Modal>
    </div>
  );
};

export const GuestCheckoutButton = ({ onClick }: { onClick?: () => void }) => {
  const { setCheckoutMode, setCreateAccount } = useCheckout();
  const { setPaymentOption } = usePayment();
  const onButtonClick = useCallback(() => {
    setCheckoutMode(CheckoutMode.Guest);
    setPaymentOption(null);
    setCreateAccount(false);
    onClick?.();
  }, [setCheckoutMode, setCreateAccount, setPaymentOption, onClick]);

  return (
    <div className="checkoutMode">
      <button type="button" data-testid="guestCheckoutButton" className="borderedGuestCheckout" onClick={onButtonClick} aria-expanded="false" aria-controls="checkoutSection">
        <div className="text">Checkout as a guest</div>
      </button>
    </div>
  );
};
