import React from 'react';

import classnames from 'classnames';
import { startCase } from 'lodash';

import { formatTimeString, FullSchedule, getToday } from 'src/lib/js/schedule';

import Image from 'shared/components/common/Image';
import { Modal, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import Popover from 'shared/components/common/popover/Popover';

import OpenStatus from 'public/components/default_template/footer/location/OpenStatus';

type ModalProps = {
  children?: (openModal: () => void) => React.ReactNode;
} & AllHoursProps;

type AllHoursProps = {
  isLocationOpen: boolean;
  schedule: FullSchedule;
  restaurantName: string;
  timezoneId: string;
}

const AllHoursModal = ({ children, ...allHoursProps }: ModalProps) => {
  const { isOpen, onClose, onOpen } = useModal();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay fadeIn />
        <ModalContent fadeIn ariaLabelledBy={'allHoursModalRxName'}>
          <AllHours {...allHoursProps} />
        </ModalContent>
      </Modal>
      {children && children(onOpen)}
    </>
  );
};

const AllHours = ({ restaurantName, isLocationOpen, schedule, timezoneId }: AllHoursProps) =>
  <div className="allHoursModal" tabIndex={0}>
    <h3 id="allHoursModalRxName" className="rxName">{restaurantName}</h3>
    <div className="openStatus">
      <OpenStatus
        isOpen={isLocationOpen}
        schedule={schedule}
        timezoneId={timezoneId} />
    </div>
    <div className="schedule">
      {Object.entries(schedule).map(([day, daySchedule]) => {
        const isToday = day === getToday(timezoneId);
        return (
          <div key={day}>
            <span className={classnames('day', { bold: isLocationOpen && isToday })}>{startCase(day)}</span>
            <div className="hoursAndTooltip">
              <div className="hoursWrapper">
                {daySchedule.intervals.length ?
                  daySchedule.intervals.map(servicePeriod => {
                    return (
                      <div className={classnames('scheduleHours', { bold: isLocationOpen && isToday })} key={formatTimeString(servicePeriod.startTime, true)}>
                        {formatTimeString(servicePeriod.startTime, true)} - {formatTimeString(servicePeriod.endTime, true)}
                      </div>);
                  })
                  : 'Closed'}
              </div>
              {daySchedule.overrideDescription &&
                <Popover
                  contextKey="scheduleOverrideTooltip"
                  arrowClassName="tooltipArrow"
                  target={({ open }) => <button onClick={open} aria-label="View schedule overrides"><Image src="icons/info.svg" alt="" className="overrideTooltip" /></button>} >
                  <div className="tooltipContent">{daySchedule?.overrideDescription}</div>
                </Popover>}
            </div>
          </div>);
      })}
    </div>
  </div>;
export default AllHoursModal;
