import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useEditor } from '@toasttab/sites-components';

import { ExternalModule } from 'src/apollo/sites';
import { userWayHeader, USER_WAY_MODULE_NAME } from 'src/shared/js/external/userway';

export type UserWayMetaProps = {
  externalModules?: ExternalModule[] | null;
};

const UserWayMeta = ({ externalModules }: UserWayMetaProps) => {
  const { isEditor } = useEditor();

  if(isEditor) return null;

  const userWayModule = externalModules?.filter(n => n && n.enabled && n.name === USER_WAY_MODULE_NAME);
  const header = userWayModule?.[0] && userWayHeader(userWayModule[0]);
  if(!header) return null;
  return <Helmet>{header}</Helmet>;
};

export default UserWayMeta;
