import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { fromBase64 } from 'src/lib/js/base64';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Query = {[k: string]: any };

/**
  * A convenient hook for getting the query string for the current route.
  *
  * @param {string} base64Decode - An optional array of keys that should be base64 decoded.
  * @param {string} parseJSON - An optional array of keys that should be parsed as JSON objects.
  * @return {object} The parsed query string as an object of key-value pairs.
  */
export const useQueryString = (base64Decode?: string[], parseJSON?: string[]): Query => {
  const { search } = useLocation();

  if(search) {
    const parsed = queryString.parse(search);

    if(base64Decode) {
      for(const key of base64Decode) {
        const val = parsed[key];
        if(val) {
          if(val.constructor == Array) {
            parsed[key] = val.map(fromBase64);
          } else if(typeof val === 'string') {
            parsed[key] = fromBase64(val);
          }
        }
      }
    }
    if(parseJSON) {
      for(const key of parseJSON) {
        const val = parsed[key];
        if(val) {
          if(val.constructor == Array) {
            const obj = val.map(v => v ? JSON.parse(v) : null);
            parsed[key] = obj;
          } else if(typeof val === 'string') {
            parsed[key] = JSON.parse(val);
          }
        }
      }
    }

    return parsed;
  }

  return {};
};

export default useQueryString;
