import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import LocationDescription from 'public/components/default_template/footer/location/LocationDescription';
import { useLocationSchedules } from 'public/components/online_ordering/FulfillmentContext';

export const Locations = () => {
  const { restaurant, updateLocation } = useRestaurant();
  const [collapseLocations, setCollapseLocations] = useState(Boolean(restaurant?.content?.footerConfig?.collapseLocations));

  useEffect(() => {
    if(restaurant?.content?.footerConfig?.collapseLocations) {
      setCollapseLocations(true);
    }
  }, [restaurant?.content?.footerConfig?.collapseLocations]);

  const locationSchedules = useLocationSchedules();
  const onLocationClick = useCallback(
    (locationId: string) => () => {
      updateLocation(locationId);
    },
    [updateLocation]
  );
  const isBOO = restaurant.config.isOnlineOrderingOnly;
  if(restaurant?.content?.footerConfig?.hideLocations) return null;

  const locations = restaurant.locations?.map((location, index) =>
    <LocationDescription
      key={location.externalId}
      editPath={`locations[${index}]`}
      restaurant={restaurant}
      location={location}
      locationSchedule={locationSchedules && locationSchedules[location.externalId]}
      onLocationClick={onLocationClick}
      config={restaurant.content?.footerConfig} />);

  return (
    <>
      <h2 className="name">{`${restaurant.name} Location${(restaurant.locations?.length || 0) > 1 ? 's' : ''} and ${isBOO ? 'Ordering ' : ''}Hours`}</h2>
      {(restaurant.locations || []).length > 1 && restaurant.content?.footerConfig?.collapseLocations
        ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
          <a onClick={() => setCollapseLocations(v => !v)} className={classNames('locationsToggle', { collapsed: collapseLocations })} data-testid="expand-locations">
            {collapseLocations ? 'Show' : 'Hide'} Locations
          </a>
          <div className="locations" style={{ display: collapseLocations ? 'none' : 'flex' }}>
            {locations}
          </div>
        </div>
        :
        <div className="locations">{locations}</div>}
    </>
  );
};
