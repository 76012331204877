import React, { Component } from 'react';

import BaseErrorComponent from 'shared/components/base_error/BaseErrorComponent';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Sentry loaded globally in <head> tag
    if(typeof Sentry !== 'undefined') {
      // eslint-disable-next-line
      Sentry.withScope(scope => {
        for(const key in info) {
          scope.setExtra(key, info[key]);
        }
        // eslint-disable-next-line
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if(this.state.hasError) {
      return <div><BaseErrorComponent errorCode={'500'} errorMessage={'Something went wrong, please try again.'} /></div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
