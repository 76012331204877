import React from 'react';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import PrimaryCTA from 'shared/components/primary_cta/PrimaryCTA';

const EmptyCart = () => {
  const { orderPath } = useRestaurantRoutes();

  return (
    <div className="checkoutForm emptyCart">
      <section className="checkoutSection currentSection">
        <h2 className="subHeader">Your cart is empty.</h2>
        <div className="fixedSection">
          <div>Add items to start your order.</div>
        </div>
        <div className="fixedSection">
          <PrimaryCTA href={orderPath} text="Order Online" />
        </div>
      </section>
    </div>
  );
};

export default EmptyCart;
