import React, { useCallback } from 'react';

import { GuestIcon, CopyIcon } from '@toasttab/buffet-pui-icons';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

import useTracker from 'src/lib/js/hooks/useTracker';


type DeliveryDriverInfoType = {
  driverName: string,
  driverPhotoUrl: string | null | undefined,
  driverNumber: string | null | undefined
}

export type DeliveryDriverInfoProps = {
  driverInfo?: DeliveryDriverInfoType,
  countryCode?: CountryCode,
}

const DeliveryDriverInfo = ({ driverInfo, countryCode } : DeliveryDriverInfoProps) => {
  const tracker = useTracker();

  const onPhoneClick = useCallback(() => {
    if(driverInfo?.driverNumber) {
      navigator.clipboard.writeText(driverInfo?.driverNumber);
    }
    tracker.track('Driver Phone Copied');
  }, [driverInfo?.driverNumber, tracker]);

  if(!driverInfo) return <NoDriver />;

  const { driverName, driverNumber, driverPhotoUrl } = driverInfo;

  return (
    <div data-testid="driver-info">
      <div className="driverProfile">
        {driverPhotoUrl ? <img className="driverPhoto" alt="Driver Photo" src={driverPhotoUrl} /> : <GuestIcon />}
        <div className="driverName">{driverName}</div>
      </div>
      <div className="copy">You can call or text your driver to contact them if you have any questions or requests about your delivery.</div>
      {/* phone */}
      {driverNumber &&
       <button aria-label="Copy driver phone" data-testid="driver-phone" className="driverPhone" onClick={onPhoneClick}>
         {parsePhoneNumber(driverNumber, countryCode ?? 'US').formatNational()}
         <CopyIcon className="copyToClipboardIcon" />
       </button>}
    </div>
  );
};


const NoDriver = () =>
  <div data-testid="no-driver" className="noDriver">
    {/* icon */}
    <div className="icon">
      <GuestIcon />
    </div>
    {/* copy */}
    <div className="info">
      <div className="driverName" style={{ fontWeight: 'bold' }}>Assigning Driver</div>
      <div className="copy">Looking for the closest available driver for your order</div>
    </div>
  </div>;

export default DeliveryDriverInfo;
