import * as React from 'react';

import classnames from 'classnames';
import sanitize from 'sanitize-html';

import { FundraisingConfig } from 'src/apollo/onlineOrdering';
import Image from 'src/shared/components/common/Image';
import { ToggleInput } from 'src/shared/components/common/forms';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

interface FundraisingProps {
  fundraisingCampaign: FundraisingConfig | undefined;
  onChange: (amount: number) => void;
  orderTotal: number;
  fundraisingEnabled: boolean;
  fundraisingAmount?: number;
  guestLegalDisclosure?: string;
  causeParentName?: string;
}

enum FundraisingDonationType {
  RoundUp = 'ROUND_UP',
  FlatRate = 'FLAT_RATE'
}

export const getRoundUpAmount = (total: number) => {
  return parseFloat(
    (
      1.0 -
      total * 100 % 100 / 100
    ).toFixed(2)
  );
};

const Fundraising = ({
  fundraisingCampaign = {},
  onChange = () => { },
  orderTotal = 0.00,
  fundraisingEnabled = false,
  fundraisingAmount = 0.00
}: FundraisingProps) => {
  const [isRoundUpTotal, setRoundUpTotal] = React.useState(false);
  const [flatRateAmount, setFlatRateAmount] = React.useState(0.00);
  const roundUpAmount = getRoundUpAmount(orderTotal);

  const {
    campaignName = '',
    campaignDescription = '',
    campaignLogoURL = '',
    donationType = FundraisingDonationType.RoundUp,
    donationAmounts = [],
    guestLegalDisclosure = '',
    causeParentName = ''
  } = fundraisingCampaign;

  React.useEffect(() => {
    if(isRoundUpTotal && fundraisingEnabled && donationType === FundraisingDonationType.RoundUp) {
      onChange(roundUpAmount);
    } else if(fundraisingEnabled && donationType === FundraisingDonationType.FlatRate) {
      onChange(flatRateAmount);
    } else {
      onChange(0.00);
    }
  }, [onChange, orderTotal, isRoundUpTotal, roundUpAmount, fundraisingEnabled, flatRateAmount, donationType]);

  return (
    <div className="fundraisingSelector" data-testid="fundraising-selector">
      <h3 className="checkoutSectionHeader" data-testid="fundraising-title">Support & Give</h3>
      <div className="fundraisingInfo padded">
        <div>
          <p className="campaignName" data-testid="fundraising-name">{campaignName}</p>
          {causeParentName && <p className="causeParentName" data-testid="fundraising-parent-cause-name">{causeParentName}</p>}
          <p className="campaignDescription" data-testid="fundraising-description">{campaignDescription}</p>
          {
            donationType === FundraisingDonationType.RoundUp &&
              <div data-testid="round-up">
                <ToggleInput
                  type="checkbox"
                  name="fundraisingRoundUpCheckbox"
                  id="fundraisingRoundUpCheckbox"
                  onChange={e => setRoundUpTotal(e.target.checked)}>
                  Round up and give ${roundUpAmount.toFixed(2)}
                </ToggleInput>
              </div>
          }
        </div>
        {campaignLogoURL &&
          <div data-testid="fundraising-logo">
            <Image
              src={campaignLogoURL}
              alt={`${campaignName} logo`}
              height={100} />
          </div>}
      </div>
      {
        donationType === FundraisingDonationType.FlatRate &&
        <div className="flat-rate-amounts" data-testid="flat-rate">
          {donationAmounts && donationAmounts.map((amount:number) => {
            return (
              <button
                key={`flat-rate-amount-${amount}`}
                data-testid={`flat-rate-amount-${amount}`}
                type="button"
                className={classnames('flat-rate-amount', fundraisingAmount === amount && 'selected')}
                onClick={() => {
                  if(fundraisingAmount === amount) {
                    setFlatRateAmount(0.00);
                  } else {
                    setFlatRateAmount(amount);
                  }
                }}>
                <FormattedPrice value={amount} />
              </button>
            );
          })}
        </div>
      }
      {
        Boolean(fundraisingAmount) &&
          <p className="campaignDisclosure" data-testid="fundraising-disclosure" dangerouslySetInnerHTML={{ __html: sanitize(guestLegalDisclosure || '') }} />
      }
    </div>
  );
};

export default Fundraising;
