import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';
import InputMessage from 'shared/components/common/form_input/InputMessage';

type Props = {
  id: string;
  label?: string;
  button?: React.ReactElement;
  buttonOnClick?: () => void;
  filled?: boolean;
  required?: boolean;
  validate?: (value: string) => boolean | string | undefined;
  warn?: (value: string) => string | undefined;
  warning?: string;
  children?: React.ReactElement;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const TextArea = (props: Props) => {
  const { id, label, button, buttonOnClick, filled, required, validate, warn, warning, ...rest } = props;
  const { register, watch, formState: { errors } } = useFormContext();
  const [warningMessage, setWarningMessage] = useState('');
  const inputValue = watch(id);

  useEffect(() => {
    if(warning) {
      setWarningMessage(warning);
    } else if(warn && inputValue) {
      setWarningMessage(warn(inputValue) || '');
    } else {
      setWarningMessage('');
    }
  }, [inputValue, warn, warning, setWarningMessage]);

  return (
    <div className={classnames('formInput', errors[id] && 'error', (filled || inputValue) && 'filled')}>
      <div className="inputWrapper">
        <div className="inputElementWrapper">
          <label htmlFor={id}>
            {label}
            {required ? <span aria-hidden="true">*</span> : ''}
          </label>
          {props.children ? props.children : <textarea aria-describedby={`${id}-input-message`} id={id} {...register(id, { required: required ? 'required' : false, validate })} {...rest} />}
        </div>
        {button}
        {errors[id] && <Image alt="Warning icon" src="icons/warning-red.svg" />}
      </div>
      <InputMessage id={id} warning={warningMessage} />
    </div>
  );
};

export default TextArea;
