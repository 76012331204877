import React, { createContext, useContext } from 'react';

import { CustomerQuery } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

export interface CustomerContextCommonType {
  customer?: Customer | null;
  loadingCustomer: boolean;
  refetchCustomer: () => void;
  refreshAuthToken: () => void;
  fetchCustomer: () => Promise<Customer | null>;
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>;
  passwordlessConfirmCode: (phoneNumber: string, code: string) => Promise<string | null>;
  completeSignup: (email: string, firstName: string, lastName: string) => Promise<boolean>;
  pwlessLogout: () => Promise<boolean>;
  customerContextMode: 'GIA_AUTH' | 'PASSWORDLESS_AUTH',
}

export interface CustomerContextType extends CustomerContextCommonType {
  savedAddressUsed: boolean;
  setSavedAddressUsed: (savedAddressUsed: boolean) => void;
}

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

function isPromise<T>(v: unknown): v is Promise<T> {
  return !!v && typeof v === 'object' && 'then' in v && typeof v.then === 'function' && 'catch' in v && typeof v.catch === 'function';
}

function useWrapCustomerActionsWithTracker<TFn extends(...args: any[]) => ReturnType<TFn>>(fn: TFn, trackingParams: {
  eventSuccessName: string,
  eventErrorName: string,
  customerContextMode: CustomerContextCommonType['customerContextMode']
}): (...args: Parameters<TFn>) => ReturnType<TFn> {
  const { track } = useTracker();
  return React.useCallback((...args) => {
    try {
      const res = fn(...args);
      // handle promises
      if(isPromise(res)) {
        return res.then(r => {
          track(trackingParams.eventSuccessName, { customerContextMode: trackingParams.customerContextMode });
          return r;
        }).catch(e => {
          track(trackingParams.eventErrorName, { customerContextMode: trackingParams.customerContextMode });
          throw e;
        }) as ReturnType<TFn>; // this is a typescript kludge that guarantees we have not modified the underlying value
      } else {
        track(trackingParams.eventSuccessName, { customerContextMode: trackingParams.customerContextMode });
        return res;
      }
    } catch(e) {
      track(trackingParams.eventErrorName, { customerContextMode: trackingParams.customerContextMode });
      throw e;
    }
  }, [fn, track, trackingParams.eventSuccessName, trackingParams.eventErrorName, trackingParams.customerContextMode]);
}

export const CustomerContextCommonProvider = (props: React.PropsWithChildren<{context: CustomerContextCommonType}>) => {
  const [savedAddressUsed, setSavedAddressUsed] = React.useState(false);

  const customerContextMode = props.context.customerContextMode;
  const passwordlessLogin = useWrapCustomerActionsWithTracker(props.context.passwordlessLogin, {
    eventSuccessName: 'passwordlessLogin_success',
    eventErrorName: 'passwordlessLogin_error',
    customerContextMode
  });

  const pwlessLogout = useWrapCustomerActionsWithTracker(props.context.pwlessLogout, {
    eventSuccessName: 'pwlessLogout_success',
    eventErrorName: 'pwlessLogout_error',
    customerContextMode
  });

  const completeSignup = useWrapCustomerActionsWithTracker(props.context.completeSignup, {
    eventSuccessName: 'completeSignup_success',
    eventErrorName: 'completeSignup_error',
    customerContextMode
  });

  const refetchCustomer = useWrapCustomerActionsWithTracker(props.context.refetchCustomer, {
    eventSuccessName: 'refetchCustomer_success',
    eventErrorName: 'refetchCustomer_error',
    customerContextMode
  });

  const fetchCustomer = useWrapCustomerActionsWithTracker(props.context.fetchCustomer, {
    eventSuccessName: 'fetchCustomer_success',
    eventErrorName: 'fetchCustomer_error',
    customerContextMode
  });

  const refreshAuthToken = useWrapCustomerActionsWithTracker(props.context.refreshAuthToken, {
    eventSuccessName: 'refreshAuthToken_success',
    eventErrorName: 'refreshAuthToken_error',
    customerContextMode
  });

  const context = {
    ...props.context,
    passwordlessLogin,
    pwlessLogout,
    completeSignup,
    refetchCustomer,
    fetchCustomer,
    refreshAuthToken,
    savedAddressUsed,
    setSavedAddressUsed
  };

  return (
    <CustomerContext.Provider value={context}>
      {props.children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if(!context) {
    throw new Error('useCustomer must be used within a CustomerContextProvider');
  }

  return context;
};
