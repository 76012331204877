import React, { useCallback, useState } from 'react';
import { OtpInput } from 'reactjs-otp-input';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { Modal, ModalContent, ModalOverlay } from 'shared/components/common/modal';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

const CONFIRMATION_CODE_LENGTH = 6;

type ConfirmCodeProps = {
  phoneNumber: string;
  newAccount?: boolean;
  onComplete: (customerGuid?: string) => Promise<any>;
  sendCode?: () => void;
};

export const ConfirmCodeForm = ({ phoneNumber, onComplete, sendCode }: ConfirmCodeProps) => {
  const { loadingCustomer } = useCustomer();

  return (
    <div className="codeForm" role="form">
      {loadingCustomer && <LoadingSpinnerOverlay />}
      <ConfirmationCodeInputs phoneNumber={phoneNumber} onComplete={onComplete} />
      {sendCode && <button className="resendCodeButton" onClick={sendCode}>Resend code</button>}
    </div>
  );
};

type ModalProps = {
  onComplete: (customerGuid?: string) => Promise<any>;
  phoneNumber: string;
  newAccount?: boolean;
  isCheckout?: boolean;
  sendCode?: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmationCodeModal = ({ onComplete, phoneNumber, newAccount, sendCode, isOpen, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <div className="userModal">
          <h2>Confirm it&apos;s you</h2>
          <div className="form">
            <ConfirmCodeForm phoneNumber={phoneNumber} newAccount={newAccount} onComplete={onComplete} sendCode={sendCode} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export function ConfirmationCodeInputs({
  phoneNumber,
  onComplete
}: {
  phoneNumber: string;
  onComplete: (customerGuid?: string) => Promise<any>;
}) {
  const { passwordlessConfirmCode, loadingCustomer } = useCustomer();
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);

  const onChangeCode = useCallback(async (code: string) => {
    setCode(code);

    if(code.length === CONFIRMATION_CODE_LENGTH) {
      const customerGuid = await passwordlessConfirmCode(phoneNumber, code);
      if(customerGuid) {
        setHasError(false);
        await onComplete(customerGuid);
      } else {
        setHasError(true);
        setCode('');
      }
    }
  }, [passwordlessConfirmCode, phoneNumber, onComplete, setCode]);
  return (
    <>
      <OtpInput
        shouldAutoFocus={true}
        isInputNum={true}
        separator={<span className="separator" />}
        isDisabled={loadingCustomer}
        containerStyle="otpInput"
        inputStyle="textBox otpTextBox"
        placeholder="000000"
        value={code}
        numInputs={CONFIRMATION_CODE_LENGTH}
        onChange={onChangeCode} />
      {hasError && <span className="formError">The code you entered was incorrect. Please try again.</span>}
    </>
  );
}
