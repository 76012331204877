export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export type OpenHours = {
  startTime: string;
  endTime: string;
}

const pad = (value: number) => ('0' + value).slice(-2);

const dateToMilitaryTimeString = (dateTime: Date) => `${pad(dateTime.getHours())}:${pad(dateTime.getMinutes())}:${pad(dateTime.getSeconds())}`;

const dateToTwelveHourTimeString = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`;
};

const dateToTimeString = (time: Date, toTwelveHourTime?: boolean) => toTwelveHourTime ? dateToTwelveHourTimeString(time) : dateToMilitaryTimeString(time);

export const compactTimes = (times?: OpenHours[], toTwelveHourTime?: boolean) => {
  const parsedTimes = times ?
    times.map(time => {
      const parsedTime = { startTime: new Date(`01 Jan 1970 ${time.startTime}`), endTime: new Date(`01 Jan 1970 ${time.endTime}`) };
      // If the window spills into the next day (e.g. 1AM "that evening" - then bump it forward one day)
      if(parsedTime.endTime < parsedTime.startTime) {
        parsedTime.endTime = new Date(`02 Jan 1970 ${time.endTime}`);
      }
      return parsedTime;
    }) :
    [];
  parsedTimes.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  const compactedTimes = [];
  for(const currentTime of parsedTimes) {
    const length = compactedTimes.length;
    if(!length) {
      compactedTimes.push(currentTime);
    } else {
      const lastTime = compactedTimes[length - 1];
      // Handle case where the times overlap
      if(lastTime && lastTime.endTime >= currentTime.startTime) {
        if(currentTime.endTime > lastTime.endTime) {
          lastTime.endTime = currentTime.endTime;
        } // No else case here, want a no-op
      } else {
        // In this case, times don't overlap
        compactedTimes.push(currentTime);
      }
    }
  }
  return compactedTimes.map(time => ({
    startTime: dateToTimeString(time.startTime, toTwelveHourTime),
    endTime: dateToTimeString(time.endTime, toTwelveHourTime)
  }));
};
