import React, { useState, useRef } from 'react';

import useOnScroll from './useOnScroll';

type Bounds = {
  minScrollY?: number;
  maxScrollY?: number;
}

const useElementInBounds = <T extends HTMLElement>(bounds: Bounds): [React.RefObject<T>, boolean] => {
  const ref = useRef<T>(null);
  const [inBounds, setInBounds] = useState(false);

  useOnScroll(() => {
    if(ref.current) {
      const boundRect = ref.current.getBoundingClientRect();

      const aboveMin = !bounds.minScrollY || boundRect.top >= bounds.minScrollY;
      const belowMax = !bounds.maxScrollY || boundRect.top <= bounds.maxScrollY;

      const newInBounds = aboveMin && belowMax;
      if(newInBounds !== inBounds) {
        setInBounds(newInBounds);
      }
    }
  });

  return [ref, inBounds];
};

export default useElementInBounds;
