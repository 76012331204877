import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import classnames from 'classnames';

import Portal from 'shared/components/common/modal/Portal';

import { usePopoverContext } from './PopoverContext';

type ConsumerProps = { open: () => void, close: (e?: React.SyntheticEvent) => void, isOpenRef: React.MutableRefObject<boolean>};

type Props = {
  target: (args: ConsumerProps) => React.ReactElement;
  children: React.ReactNode | ((args: ConsumerProps) => React.ReactNode);
  onClose?: () => void;
  disabled?: boolean;
  contextKey: string;
  arrowClassName?: string;
  firstFocusClassName?: string;
  contentClassName?: string;
  customOffset?: [number, number]
};

const Popover = (props: Props) => {
  const context = usePopoverContext(props.contextKey);
  const [isOpen, setIsOpen] = useState(false);
  const [refEl, setRefEl] = useState<HTMLElement | null>();
  const [popEl, setPopEl] = useState<HTMLElement | null>();
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>();
  const isOpenRef = useRef<boolean>(false);
  isOpenRef.current = isOpen || context?.isOpen || false;

  const { styles, attributes, update } = usePopper(refEl, popEl, {
    modifiers: [
      { name: 'arrow', options: { element: arrowEl } },
      { name: 'offset', options: { offset: props.customOffset ?? [0, 8] } },
      { name: 'eventListeners', options: { scroll: true, resize: true } }
    ]
  });

  const close = useCallback((e?: React.SyntheticEvent) => {
    if(props.onClose) {
      props.onClose();
    }
    if(context?.close) {
      context.close();
    } else {
      setIsOpen(false);
    }

    // only give focus back to the refEl when a child was not clicked on i.e. in MenuGroupPills
    if(!popEl?.contains(e?.target as Node)) {
      refEl?.focus();
    }
  }, [props, context, refEl, popEl]);

  const focusIndexRef = useRef(0);

  useEffect(() => {
    if(!props.disabled && popEl && (isOpen || context?.isOpen)) {
      if(update) {
        update();
      }

      focusIndexRef.current = 0;

      const focusableElements = popEl.querySelectorAll(
        'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
      ) as NodeListOf<HTMLElement>;

      if(props.firstFocusClassName) {
        focusIndexRef.current = Array.from(focusableElements).findIndex(el => el.classList.contains(props.firstFocusClassName!));
      }
      focusableElements[focusIndexRef.current]?.focus();

      const lastIndex = focusableElements.length - 1;

      const handleKeyDown = (e: KeyboardEvent) => {
        if(!['Tab', 'Escape'].includes(e.key)) return;
        e.preventDefault();

        switch(e.key) {
          case 'Tab':
            if(e.shiftKey) {
              e.preventDefault();

              focusIndexRef.current = focusIndexRef.current === 0
                ? lastIndex
                : focusIndexRef.current - 1;
            } else {
              focusIndexRef.current = focusIndexRef.current === lastIndex
                ? 0
                : focusIndexRef.current + 1;
            }

            focusableElements[focusIndexRef.current]?.focus();
            break;
          case 'Escape':
            close();
            break;
        }
      };

      popEl.addEventListener('keydown', handleKeyDown);

      return () => {
        popEl.removeEventListener('keydown', handleKeyDown);
      };
    }

    // satisfy linter that all code paths explicitly return.
    return;
  }, [props, isOpen, popEl, context?.isOpen, update, close]);

  const open = useCallback(() => {
    if(update) {
      update();
    }
    if(!props.disabled && context?.open) {
      context.open();
    } else if(!props.disabled) {
      setIsOpen(true);
    }
  }, [props.disabled, update, context]);

  const onBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if(!event.currentTarget.contains(event.relatedTarget as Node) && !event.currentTarget.contains(event.target)) {
      close();
    }
  }, [close]);

  const targetEl = props.target({ open, close, isOpenRef });
  const refTargetEl = React.cloneElement(targetEl, { ref: setRefEl });


  return (
    <div className="popover">
      {isOpenRef.current ?
        <Portal allowScroll>
          <div className="popoverOverlay" onClick={close} onTouchStart={close} />
        </Portal>
        : null}
      {refTargetEl}
      <div
        role="dialog"
        aria-modal="true"
        aria-hidden={!isOpenRef.current}
        style={{ ...styles.popper, visibility: isOpenRef.current ? 'visible' : 'hidden' }}
        ref={setPopEl}
        className={classnames('popoverContent', props.contentClassName, { popoverVisible: isOpenRef.current })}
        onBlur={onBlur}
        {...attributes.popper}>
        <div className={classnames('arrow', props.arrowClassName)} style={styles.arrow} ref={setArrowEl}>
          <div />
        </div>

        {typeof props.children === 'function' ? props.children({ open, close, isOpenRef }) : props.children}
      </div>
    </div>);
};

export default Popover;
