import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import classnames from 'classnames';
import queryString from 'query-string';

import { toBase64 } from 'src/lib/js/base64';
import { useQueryString } from 'src/lib/js/hooks/useQueryString';

import Image from 'shared/components/common/Image';
import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'shared/components/common/location_search/PlacesAutocomplete';


import { fetchUserLocation } from 'public/components/toast_local/rx_search/locationUtils';


import { gMapsAPIKey } from 'config';

export type Props = {
  placeholder?: string;
  className?: string;
  city?: City;
  cuisineId?: number;
  resetString?: boolean;
  onStringReset?: () => void;
  searchMode?: 'text' | // "location" mode uses google places autocomplete to search by location.
  'location'; // "text" mode searches for food and cuisine types with plaintext
}


export type City = {
  parentCity?: City;
  city: string;
  state: string;
  lat: string;
  long: string;
};

type Filters = { [filter: string]: string | number };


const SearchBar = ({ placeholder = 'Restaurant or cuisine near me', className, city, cuisineId, resetString, onStringReset, searchMode = 'text' }: Props) => {
  const parsedQS = useQueryString(['filters'], ['filters']);
  const history = useHistory();

  const [searchString, setSearchString] = useState(parsedQS.filters ? parsedQS.filters.search : '');

  const search = useCallback((filters: Filters) => {
    history.push(`/local/search${Object.keys(filters).length ? `?${queryString.stringify({ filters: toBase64(JSON.stringify(filters)), pg: 1 })}` : ''}`);
  }, [history]);

  const newFilters = useMemo(() => {
    const filters: Filters = {};
    if(city) {
      if(city.parentCity) {
        filters['userCity'] = city.parentCity.city;
        filters['userLat'] = city.parentCity.lat;
        filters['userLong'] = city.parentCity.long;
      } else {
        filters['userCity'] = city.city;
        filters['userLat'] = city.lat;
        filters['userLong'] = city.long;
      }
      filters['userState'] = city.state;
    }
    if(cuisineId) { filters['cuisineId'] = cuisineId; }
    if(searchString) { filters['search'] = searchString; }

    return filters;
  }, [searchString, city, cuisineId]);

  const buildPlaceFilters = (place: Omit<google.maps.places.PlaceResult, 'name'>) => {
    // prepare place filters;
    let street: string | undefined = undefined;
    let neighborhood: string | undefined = undefined;
    let city: string | undefined = undefined;
    let state: string | undefined = undefined;
    let lat: number | undefined = undefined;
    let long: number | undefined = undefined;
    const selectedPlaceFilters: Filters = {};
    if(place && place.geometry?.location && place.address_components) {
      lat = place.geometry.location.lat();
      long = place.geometry.location.lng();
      if(lat && long) {
        selectedPlaceFilters['userLat'] = lat;
        selectedPlaceFilters['userLong'] = long;
      }
      for(const component of place.address_components) {
        for(const componentType of component.types) {
          if(componentType === 'street_number') {
            street = street ? component.long_name + ' ' + street : component.long_name;
            selectedPlaceFilters['userStreet'] = street;
            break;
          } else if(componentType === 'route') {
            street = street ? street + ' ' + component.short_name : component.short_name;
            selectedPlaceFilters['userStreet'] = street;
            break;
          } else if(componentType === 'neighborhood') {
            neighborhood = component.long_name;
            selectedPlaceFilters['userNeighborhood'] = neighborhood || '';
            break;
          } else if(componentType === 'locality') {
            city = component.long_name;
            selectedPlaceFilters['userCity'] = city;
            break;
          } else if(componentType === 'administrative_area_level_1') {
            state = component.short_name;
            selectedPlaceFilters['userState'] = state;
            break;
          }
        }
      }
    }
    return selectedPlaceFilters;
  };

  const fetchAndSearchUserLocation = useCallback(() => {
    fetchUserLocation()
      .then(userLocation => {
        search({ ...parsedQS.filters, ...userLocation, ...newFilters });
      })
      .catch(() => {
        // if we fail to lookup the user's location, try searching without it
        search({ ...parsedQS.filters, ...newFilters });
      });
  }, [newFilters, parsedQS, search]);

  const launchTextSearch = useCallback(() => {
    const filters = { ...parsedQS.filters, ...newFilters };
    if(filters.userCity) {
      search(filters);
    } else {
      // searching without a place defaults to using the user's location.
      fetchAndSearchUserLocation();
    }
  }, [fetchAndSearchUserLocation, parsedQS, newFilters, search]);

  const launchPlaceSearch = useCallback((place: Omit<google.maps.places.PlaceResult, 'name'>) => {
    const selectedPlaceFilters = buildPlaceFilters(place);
    search({ ...parsedQS.filters, ...newFilters, ...selectedPlaceFilters });
  }, [newFilters, parsedQS, search]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value);
  const onSearchKeyDown = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      launchTextSearch();
    }
  };

  useEffect(() => {
    if(resetString) {
      setSearchString('');
      if(onStringReset) {
        onStringReset();
      }
    }
  }, [resetString, onStringReset]);

  return (
    <div className={classnames('search-bar', className)}>
      {searchMode === 'text' ?
        <input placeholder={placeholder} value={searchString} onChange={onSearchChange} onKeyDown={onSearchKeyDown} aria-label="Search" /> :
        <PlacesAutocomplete
          id="tl-splash-search-bar"
          placeholder="Enter a city or address"
          showGenericSpinner
          apiKey={gMapsAPIKey}
          useCurrentLocation
          placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
          onPlaceSelected={place => launchPlaceSearch(place)}
          options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }} />}
      <div className={'submit-button'} onClick={() => launchTextSearch()}>
        <Image src="/icons/search-white-tl.svg" alt="Go" className="search-icon" />
      </div>
    </div>
  );
};


export default SearchBar;
