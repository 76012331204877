import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from 'shared/components/common/modal';

type AlertModalContextType = {
  openAlertModal: (message: string) => void;
  closeAlertModal: () => void;
}

export const AlertModalContext = createContext<AlertModalContextType>({
  openAlertModal: () => {},
  closeAlertModal: () => {}
});

export const useAlertModalContext = () => {
  return useContext(AlertModalContext);
};

/**
  * Note that this implementation may seem a little over-engineered on the surface (i.e why not hoist the state for the modal into
  * AlertModalProvider, rather than passing a function down to AlertModal and using useEvent to register the open/close functions
  * on component mount). The problem is that if you manage the state in the same component that you render props.children, then
  * props.children will re-render every time you change the modal state. As such, given that AlertModalProvider is intended to wrap
  * all page content at the App level, you might encounter infinite loops like when popping up a modal on page load to indicate an
  * error - Dismissing the modal would trigger re-render and potentially the same page load error and thus the modal would show again.
  */
export const AlertModalProvider = (props: React.PropsWithChildren<{}>) => {
  const [modalController, setModalController] = useState<AlertModalContextType>({ openAlertModal: () => {}, closeAlertModal: () => {} });

  const registerModalController = useCallback((openAlertModal: (message: string) => void, closeAlertModal: () => void) => {
    setModalController({ openAlertModal, closeAlertModal });
  }, []);

  return (
    <AlertModalContext.Provider value={modalController}>
      {props.children}
      <AlertModal registerModalController={registerModalController} />
    </AlertModalContext.Provider>
  );
};

type Props = {
  registerModalController: (openAlertModal: (message: string) => void, closeAlertModal: () => void) => void;
}

const AlertModal = ({ registerModalController } : Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openAlertModal = (newMessage: string) => {
    setMessage(newMessage);
    setIsOpen(true);
  };

  const closeAlertModal = () => {
    setMessage('');
    setIsOpen(false);
  };

  // When the component mounts, we want to set the open/close funcs... And then unset them on dismount
  useEffect(() => {
    registerModalController(openAlertModal, closeAlertModal);

    return () => {
      registerModalController(() => {}, () => {});
    };
  }, [registerModalController]);

  return (
    <Modal isOpen={isOpen} onClose={closeAlertModal} className="alertModal">
      <ModalOverlay fadeIn fadeOut />
      <ModalContent fadeIn>
        <ModalCloseButton />

        <div className="alertMessage">{message}</div>
      </ModalContent>
    </Modal>
  );
};

export default AlertModalProvider;
