import React from 'react';

import { useEditor, ContainsEditableProps, FieldType } from '@toasttab/sites-components';
import classnames from 'classnames';

const MAX_STARS = 5;

type StarProps = {
  fillColor: string;
  size: number;
}

type Props = {
  fillColor: string;
  count: number;
  size?: number;
  className?: string;
} & Partial<ContainsEditableProps>;

const FullStar = ({ fillColor, size }: StarProps) =>
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    {/* eslint-disable-next-line max-len */}
    <path fill={fillColor} d="M9.11473 0.680427L10.6308 3.80168C10.8091 4.15839 11.1658 4.42593 11.5226 4.42593L15.0005 4.961C15.9815 5.13935 16.3382 6.29868 15.6248 7.0121L13.1278 9.41993C12.8602 9.68746 12.6819 10.0442 12.7711 10.4901L13.3953 13.8789C13.5737 14.8598 12.5035 15.5732 11.6117 15.1274L8.49048 13.5221C8.13377 13.3438 7.68788 13.3438 7.33116 13.5221L4.20991 15.1274C3.31813 15.5732 2.33716 14.8598 2.42634 13.8789L3.05059 10.4901C3.22895 10.0442 3.13977 9.59828 2.87223 9.33075L0.375235 6.92293C-0.338193 6.2095 0.018521 5.05018 0.999485 4.87182L4.47745 4.33675C4.83416 4.24757 5.19088 4.06921 5.36923 3.7125L6.88527 0.591248C7.33116 -0.211359 8.66884 -0.211359 9.11473 0.680427Z" />
  </svg>;

const HalfStar = ({ fillColor, size }: StarProps) =>
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path fill={fillColor} d="M8 12.5V1L5.5 5L1 6L4 9.5L3.5 14.5L8 12.5Z" />
    {/* eslint-disable-next-line max-len */}
    <path stroke={fillColor} d="M9.95614 4.12935L9.95609 4.12938L9.95995 4.13709C10.22 4.6572 10.7716 5.14439 11.4557 5.17446L14.8743 5.7004C15.2394 5.77228 15.3862 6.18505 15.0983 6.47786L12.6072 8.88004L12.6071 8.87995L12.5974 8.8896C12.2087 9.27833 11.8855 9.87447 12.0344 10.6309L12.6574 14.013C12.6575 14.0133 12.6575 14.0136 12.6576 14.0139C12.6875 14.1802 12.6223 14.3223 12.4799 14.4201C12.3315 14.5222 12.1327 14.5479 11.9501 14.458L8.83349 12.8552L8.83352 12.8551L8.82589 12.8513C8.25803 12.5674 7.56361 12.5674 6.99575 12.8513L6.99573 12.8513L6.98815 12.8552L3.87153 14.458C3.54653 14.6179 3.15151 14.3891 3.17133 13.9746L3.77618 10.6911C4.01105 10.0287 3.8937 9.29155 3.40256 8.80042L3.40265 8.80033L3.39284 8.79086L0.901683 6.38868C0.613838 6.09587 0.760611 5.68311 1.12573 5.61122L4.59149 5.07803C4.61431 5.07452 4.63695 5.06995 4.65935 5.06435C5.10412 4.95316 5.71819 4.69164 6.04005 4.04791L6.0401 4.04793L6.04386 4.04018L7.54807 0.943288C7.61266 0.839558 7.76211 0.744727 7.98126 0.750206C8.19868 0.755641 8.36387 0.858572 8.44246 1.01295L9.95614 4.12935Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

const HollowStar = ({ fillColor, size }: StarProps) =>
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    {/* eslint-disable-next-line max-len */}
    <path stroke={fillColor} d="M9.95614 4.12935L9.95609 4.12938L9.95995 4.13709C10.22 4.6572 10.7716 5.14439 11.4557 5.17446L14.8743 5.7004C15.2394 5.77228 15.3862 6.18505 15.0983 6.47786L12.6072 8.88004L12.6071 8.87995L12.5974 8.8896C12.2087 9.27833 11.8855 9.87447 12.0344 10.6309L12.6574 14.013C12.6575 14.0133 12.6575 14.0136 12.6576 14.0139C12.6875 14.1802 12.6223 14.3223 12.4799 14.4201C12.3315 14.5222 12.1327 14.5479 11.9501 14.458L8.83349 12.8552L8.83352 12.8551L8.82589 12.8513C8.25803 12.5674 7.56361 12.5674 6.99575 12.8513L6.99573 12.8513L6.98815 12.8552L3.87153 14.458C3.54653 14.6179 3.15151 14.3891 3.17133 13.9746L3.77618 10.6911C4.01105 10.0287 3.8937 9.29155 3.40256 8.80042L3.40265 8.80033L3.39284 8.79086L0.901683 6.38868C0.613838 6.09587 0.760611 5.68311 1.12573 5.61122L4.59149 5.07803C4.61431 5.07452 4.63695 5.06995 4.65935 5.06435C5.10412 4.95316 5.71819 4.69164 6.04005 4.04791L6.0401 4.04793L6.04386 4.04018L7.54807 0.943288C7.61266 0.839558 7.76211 0.744727 7.98126 0.750206C8.19868 0.755641 8.36387 0.858572 8.44246 1.01295L9.95614 4.12935Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

const Stars = ({ count, size = 24, className, fillColor, editPath }: Props) => {
  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'stars',
    displayName: 'Stars',
    actions: [],
    schema: {
      fields: [
        {
          displayName: 'Count',
          type: FieldType.Number,
          path: editPath || '',
          value: count,
          validation: {
            min: 0.5,
            max: 5,
            step: 0.5
          }
        }
      ]
    }, path: editPath
  });
  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(count);
    const emptyStars = MAX_STARS - Math.ceil(count);
    for(let i = 0; i < fullStars; i++) {
      stars.push(<FullStar size={size} fillColor={fillColor} key={`full${i}`} />);
    }
    if(fullStars < count) {
      stars.push(<HalfStar size={size} fillColor={fillColor} key="halfStar" />);
    }
    for(let i = 0; i < emptyStars; i++) {
      stars.push(<HollowStar size={size} fillColor={fillColor} key={`hollow${i}`} />);
    }
    return stars;
  };

  return <div ref={editableRef} className={classnames('stars', className)} data-testid="stars" role="img" aria-label={`Rated ${count} stars`}>{renderStars()}</div>;
};

export default Stars;
