import { Block as BlockType } from 'src/apollo/sites';

export const GRID_GAP = 8;

export const DESKTOP = {
  CELL_LENGTH: 36 + GRID_GAP,
  NUM_COLS: 24
};

export const MOBILE = {
  CELL_LENGTH: 24 + GRID_GAP,
  NUM_COLS: DESKTOP.NUM_COLS / 2
};

// Returns true if any part of the block exists in the right half of the screen in desktop view.
export const isInRightHalf = (block: BlockType): boolean => {
  return block.endX > MOBILE.NUM_COLS + 1;
};

export const hasValue = (value: number | null | undefined): boolean => {
  return value !== undefined && value !== null;
};

// Returns true if the mobile location has been set.
export const hasMobilePosition = (block: BlockType): boolean => {
  return hasValue(block.mobileStartX) && hasValue(block.mobileStartY) && hasValue(block.mobileEndX) && hasValue(block.mobileEndY);
};

export const isTextBlock = (block: BlockType) => {
  return block.kind.startsWith('heading') || block.kind === 'paragraph';
};

export const getNumRowsFromBlock = (
  block: BlockType,
  isMobile: boolean
) => {
  if(isMobile) {
    const getBackupMobileNumRows = (block: BlockType): number => {
      const kind = block.kind;
      const blockHeightOnDesktop =
        (block.endY - block.startY) * DESKTOP.CELL_LENGTH;

      if(isTextBlock(block)) {
        const blockWidthOnDesktop =
          (block.endX - block.startX) * DESKTOP.CELL_LENGTH;
        return Math.ceil(
          blockWidthOnDesktop * blockHeightOnDesktop /
          (MOBILE.NUM_COLS * Math.pow(MOBILE.CELL_LENGTH, 2))
        );
      } else if(kind === 'shape' || kind === 'button') {
        return Math.ceil(blockHeightOnDesktop / MOBILE.CELL_LENGTH);
      } else {
        return block.endY - block.startY;
      }
    };

    const getBackupNumRows = () => block.mobileNumRows ?? getBackupMobileNumRows(block);

    return hasMobilePosition(block)
      ? block.mobileEndY! - block.mobileStartY!
      : getBackupNumRows();
  } else {
    return block.endY - block.startY;
  }
};
