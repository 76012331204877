import React, { useCallback, useMemo, useState } from 'react';

import { LoadScript, GoogleMap } from '@react-google-maps/api';

import { DiningOptionBehavior, useRestaurantLocationsQuery, RestaurantLocationsQuery } from 'src/apollo/onlineOrdering';
import useWindowMessage from 'src/shared/js/hooks/useWindowMessage';

import { LoadingSpinner } from 'shared/components/common/loading_spinner/LoadingSpinner';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import config from 'config';

import DetailMarker from './DetailMarker';
import FulfillmentMarker from './FulfillmentMarker';
import { MapMessage } from './messages';

export type MarkerStyle = 'default' | 'locationDetails';

type OORestaurant = NonNullable<NonNullable<RestaurantLocationsQuery['restaurants']>[0]> & { __typename: 'Restaurant' };

type Props = {
  sourceId?: string | null;
  useOrderFulfillment?: boolean;
  locationGuids: string[]
  selectedGuid?: string | null;
  color?: string;
  targetOrigin: string;
  selectedDiningOption?: DiningOptionBehavior;
  markerStyle?: string | null;
}

const Map = (props: Props) => {
  const client = useOOClient();
  const { data: locationsData } = useRestaurantLocationsQuery({
    variables: { restaurantGuids: props.locationGuids },
    client
  });
  const [openGuid, setOpenGuid] = useState<string>();

  useWindowMessage({
    origin: props.targetOrigin,
    sourceId: props.sourceId,
    messageHandlers: {
      [MapMessage.CLOSED]: () => setOpenGuid(undefined),
      [MapMessage.SELECTED_GUID]: (data: { guid: string }) => setOpenGuid(data.guid)
    }
  });

  const interact = useCallback(() => {
    setOpenGuid(undefined);
    window.parent.postMessage(JSON.stringify({
      name: MapMessage.INTERACT,
      data: { sourceId: props.sourceId }
    }), props.targetOrigin);
  }, [props]);

  const updateOpenGuid = useCallback((guid: string) => {
    setOpenGuid(guid);
    window.parent.postMessage(JSON.stringify({
      name: MapMessage.SELECTED_PIN,
      data: {
        sourceId: props.sourceId,
        guid
      }
    }), props.targetOrigin);
  }, [props]);

  const updateSelectedData = useCallback((guid: string, diningOptionBehavior?: DiningOptionBehavior) => {
    setOpenGuid(undefined);
    window.parent.postMessage(JSON.stringify({
      name: MapMessage.SELECTED_GUID,
      data: {
        guid,
        diningOptionBehavior,
        sourceId: props.sourceId
      }
    }), props.targetOrigin);
  }, [props]);


  const bounds = useMemo(() => new google.maps.LatLngBounds(), []);
  const onLoad = useCallback((map: google.maps.Map) => {
    if((locationsData?.restaurants?.length || 0) > 1) {
      map.fitBounds(bounds);
    }
  }, [bounds, locationsData]);

  if(!locationsData?.restaurants?.length) {
    return null;
  }

  const markers = locationsData?.restaurants?.map((rx: OORestaurant) => {
    if(rx?.__typename === 'Restaurant' && rx?.location?.latitude && rx?.location?.longitude) {
      bounds.extend(new google.maps.LatLng(rx.location.latitude, rx.location.longitude));
      return (
        props.markerStyle === 'locationDetails' || !rx.onlineOrderingEnabled || !rx.shortUrl ?
          <DetailMarker
            onClick={() => updateOpenGuid(rx.guid)}
            isSelected={props.selectedGuid === rx.guid}
            isInfoOpen={openGuid === rx.guid}
            position={{ lat: rx.location.latitude, lng: rx.location.longitude }}
            key={rx.guid}
            restaurant={rx as OORestaurant}
            selectedDiningOption={props.selectedDiningOption} /> :
          <FulfillmentMarker
            useOrderFulfillment={props.useOrderFulfillment}
            onClick={() => updateOpenGuid(rx.guid)}
            onSelect={(guid, diningOptionBehavior) => updateSelectedData(guid, diningOptionBehavior)}
            isInfoOpen={openGuid === rx.guid}
            key={rx.guid}
            position={{ lat: rx.location.latitude, lng: rx.location.longitude }}
            isSelected={props.selectedGuid === rx.guid}
            selectedDiningOption={props.selectedDiningOption}
            restaurant={rx as OORestaurant} />
      );
    }

    return null;
  });

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
      onClick={interact}
      onDragStart={interact}
      options={{ disableDefaultUI: true }}
      zoom={8}
      onLoad={onLoad}
      center={bounds.getCenter()}>
      {markers}
    </GoogleMap>
  );
};

const WrappedMap = (props: Props) =>
  <LoadScript googleMapsApiKey={config.gMapsAPIKey} loadingElement={<LoadingSpinner color={props.color} />}>
    <Map {...props} />
  </LoadScript>;
export default WrappedMap;
