import React, { useMemo, useReducer } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';

// eslint-disable-next-line camelcase
import { Menus_MenuItemTag, TimeBasedRules, UnitOfMeasure } from 'src/apollo/onlineOrdering';
import { CardOrientation, MenuConfig, MenuTemplate, PlaceHolderImageGenerationEnum, Theme } from 'src/apollo/sites';
import useOnVisible from 'src/lib/js/hooks/useOnVisible';

import Image from 'shared/components/common/Image';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { OfferBadge } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { useOffers } from 'public/components/online_ordering/OffersContext';

import MenuItemCard, { getTemplateClass } from './MenuItemCard';
import { MenuSectionHeader } from './MenuSectionHeader';
import { overrideConfigBasedOnTemplate, TemplateOverrides } from './menuStylingOverrides';

const TOP_IMAGE_ITEMS_PER_ROW = 4;

const ContrastChecker = new ColorContrastChecker();

export type MenuItem = {
  name?: string | null;
  image?: string | null;
  imageUrls?: { [key: string]: string } | null;
  price?: number | null;
  prices?: number[] | null;
  description?: string | null;
  guid: string | null;
  outOfStock?: boolean | null;
  itemGroupGuid: string;
  timeBasedRules?: TimeBasedRules | null
  // eslint-disable-next-line camelcase
  itemTags?: Menus_MenuItemTag[]
  masterId?: string | null;
  unitOfMeasure?: UnitOfMeasure | null;
  usesFractionalQuantity?: boolean | null;
}

export type Props = {
  header?: string | null;
  description?: string | null;
  menuItems?: (MenuItem | null)[] | null;
  onVisible?: () => void;
  restaurantGuid?: string;
  shortUrl?: string;
  specialRequestsEnabled?: boolean;
  specialRequestsPlaceholder?: string;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  isMapOrModalOpen?: boolean;
  orientation?: CardOrientation | null;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  wrapItems?: boolean;
  menuItemIdMap?: Map<number, string>;
  groupGuid?: string;
  menuGuid?: string;
}

type MenuItemCardsProps = {
  menuItems?: (MenuItem | null)[] | null;
  menuItemIdMap?: Map<number, string>;
  menuGuid?: string | null;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  isMapOrModalOpen?: boolean;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription: Array<boolean>;
  templateOverrides?: TemplateOverrides;
};

export const MenuItemCards = ({ menuItems, menuGuid, menuItemIdMap, setIsMapOrModalOpen, isMapOrModalOpen, isReadOnly, menuConfig, rowHasDescription, templateOverrides }: MenuItemCardsProps) =>
  <>
    {menuItems
      ? menuItems.map((item, i) => item ?
        <MenuItemCard
          key={item.guid || item.name}
          id={menuItemIdMap?.get(i)}
          item={item}
          menuGuid={menuGuid}
          setIsMapOrModalOpen={setIsMapOrModalOpen}
          isMapOrModalOpen={isMapOrModalOpen}
          isReadOnly={isReadOnly}
          menuConfig={menuConfig}
          rowHasDescription={rowHasDescription[Math.floor(i / TOP_IMAGE_ITEMS_PER_ROW)]}
          templateOverrides={templateOverrides} />
        : null)
      : new Array(8).fill(0)
        .map((_, index) =>
          <li className="item" key={`skeleton${index}`}>
            <Skeleton width="100%" height="100%" />
          </li>)}
  </>;

export const getRowDescriptionArray = (menuItems?: (MenuItem | null)[] | null) => {
  const arr = Array.from({ length: Math.ceil((menuItems?.length || 0) / 4) }, () => false);
  menuItems?.forEach((item, i) => arr[Math.floor(i / TOP_IMAGE_ITEMS_PER_ROW)] ||= Boolean(item?.description));
  return arr;
};

const getMenuBackgroundStyle = (theme: Theme | undefined | null, menuConfig: MenuConfig | null | undefined) => {
  if(theme?.enabled) {
    const menuColorOverrides = theme.colorOverrides?.viewOnlyMenu;
    return menuColorOverrides?.cardBackground ? { backgroundColor: menuColorOverrides.cardBackground } : {};
  } else {
    return menuConfig?.colors?.background ? { backgroundColor: menuConfig.colors.background } : {};
  }
};

const MenuSection = (props: Props, ref: React.RefObject<HTMLDivElement>) => {
  const { restaurant: { meta, config, theme } } = useRestaurant();
  const { groupOffers, getOffersTextForEntity } = useOffers();

  const [sectionExpanded, toggleSectionExpanded] = useReducer(state => !state, true);
  const templateOverrides = useMemo(
    () => overrideConfigBasedOnTemplate(props.menuConfig, config),
    [config, props.menuConfig]
  );

  useOnVisible(ref, props.onVisible, { rootMargin: '-49% 0px -49% 0px' });

  // Create an array representing rows of menu items. Set the indexed value to true if one of the items in that row has a description.
  const rowHasDescription = useMemo(() => getRowDescriptionArray(props.menuItems), [props.menuItems]);


  if(!props.menuItems) {
    return null;
  }

  const menuFormatConfig = props.menuConfig?.format;

  const hasPlaceholderImages = menuFormatConfig?.placeholderImage && menuFormatConfig.placeholderImage.generatePlaceholderImage !== PlaceHolderImageGenerationEnum.None;
  const noOriginalImages = props.menuItems?.every(item => !item?.image && !item?.imageUrls?.medium);
  const noImages = noOriginalImages && !hasPlaceholderImages;
  templateOverrides.hideImages = noOriginalImages && menuFormatConfig?.template === MenuTemplate.TopImageV2;

  const hasLowContrast = !ContrastChecker.isLevelAA(meta.secondaryTextColor || DEFAULT_COLORS.secondaryText, meta.backgroundColor || DEFAULT_COLORS.background);

  if(menuFormatConfig?.template === MenuTemplate.Condensed || menuFormatConfig?.template === MenuTemplate.ExtraCondensed || menuFormatConfig?.template === MenuTemplate.Stacked) {
    const sectionStyle = getMenuBackgroundStyle(theme, props.menuConfig);

    return (
      <div className="menuGroup">
        <section
          role="tabpanel"
          {...props.groupGuid && { 'aria-describedby': `menu-pill-${props.groupGuid}` }}
          className={classnames('menuSection', getTemplateClass(menuFormatConfig?.template), { card: menuFormatConfig?.expandableSections })}
          ref={ref}
          id={`menuGroup-${props.groupGuid}`}>
          <div className="menuSectionHeaderWrapper">
            {menuFormatConfig?.expandableSections ?
              <button
                className={classnames('menuSectionHeader', 'button')}
                onClick={toggleSectionExpanded}
                aria-expanded={Boolean(sectionExpanded)}
                aria-controls={`menuGroup-${props.groupGuid}-section`}>
                <MenuSectionHeader
                  header={props.header} />
                <Image src="icons/caret-down-27px.svg" alt="" className={sectionExpanded ? 'expanded' : ''} />
              </button>
              :
              <div className={classnames('menuSectionHeader')}>
                <MenuSectionHeader
                  header={props.header} />
              </div>}
            {props.description && <div className={classnames('description', { primaryTextColor: hasLowContrast })}>{props.description}</div>}
            {menuFormatConfig?.columns && <div className="sectionSeparatorWrapper"><hr className="sectionSeparator" /></div>}
          </div>
          {props.menuItems &&
            <div style={sectionStyle} id={`menuGroup-${props.groupGuid}-section`} className={classnames('itemSection',
              { expanded: !menuFormatConfig?.expandableSections || sectionExpanded, squaredCorners: menuFormatConfig?.squaredCorners })}>
              {!menuFormatConfig?.columns && <div className="sectionSeparatorWrapper" aria-hidden={true}><hr className="sectionSeparator" /></div>}
              <ul style={sectionStyle} className={classnames('itemSection',
                { columns: menuFormatConfig?.columns, expanded: !menuFormatConfig?.expandableSections || sectionExpanded })}>
                {props.menuItems.map((item: MenuItem) => item ?
                  <MenuItemCard
                    key={item.guid}
                    item={item}
                    setIsMapOrModalOpen={props.setIsMapOrModalOpen}
                    isMapOrModalOpen={props.isMapOrModalOpen}
                    isReadOnly={props.isReadOnly}
                    menuConfig={props.menuConfig}
                    templateOverrides={templateOverrides} />
                  : null)}
              </ul>
            </div>}
        </section>
      </div>
    );
  }

  const condenseGroups = menuFormatConfig?.condenseImagelessGroups && noImages;
  const templateClass = condenseGroups ? '' : getTemplateClass(menuFormatConfig?.template);
  const isNewTemplate = menuFormatConfig?.template ? [MenuTemplate.Classic, MenuTemplate.LeftImageV2, MenuTemplate.TopImageV2].includes(menuFormatConfig.template) : false;
  const sectionStyle = condenseGroups && !isNewTemplate ? getMenuBackgroundStyle(theme, props.menuConfig) : {};
  const numGroupOffers = (props.groupGuid ? groupOffers[props.groupGuid] ?? [] : []).length;
  const numTotalOffers = getOffersTextForEntity(undefined, props.groupGuid, props.menuGuid).length;

  return (
    <div className="menuGroup">
      <section
        role="tabpanel"
        {...props.groupGuid && { 'aria-describedby': `menu-pill-${props.groupGuid}` }}
        className={classnames('menuSection', templateClass, { card: menuFormatConfig?.expandableSections })}
        ref={ref}
        id={`menuGroup-${props.groupGuid}`}>
        <div className="headerWrapper">
          <MenuSectionHeader header={props.header} badge={numGroupOffers > 0 ? <OfferBadge text={numTotalOffers > 1 ? 'OFFERS' : 'OFFER'} /> : null} />
          {props.description && <div className={classnames('description', { primaryTextColor: hasLowContrast })}>{props.description}</div>}
        </div>
        <ul style={sectionStyle} className={classnames(
          'itemSection',
          {
            modalOpen: props.isMapOrModalOpen,
            compact: noImages && !isNewTemplate,
            listView: menuFormatConfig?.condenseImagelessGroups && !isNewTemplate,
            squaredCorners: menuFormatConfig?.squaredCorners,
            mobileOptimizedContainer: templateOverrides.enableMobileOptimization,
            topImageV2: menuFormatConfig?.template === MenuTemplate.TopImageV2
          }
        )} aria-busy={props.menuItems ? 'false' : 'true'}>
          <MenuItemCards
            menuItems={props.menuItems}
            menuGuid={props.menuGuid}
            menuItemIdMap={props.menuItemIdMap}
            setIsMapOrModalOpen={props.setIsMapOrModalOpen}
            isMapOrModalOpen={props.isMapOrModalOpen}
            isReadOnly={props.isReadOnly}
            menuConfig={props.menuConfig}
            rowHasDescription={rowHasDescription}
            templateOverrides={templateOverrides} />
        </ul>
      </section>
    </div>
  );
};

export default React.forwardRef(MenuSection);
