import { RestaurantSchedulesQuery, RestaurantScheduleFieldsFragment } from 'src/apollo/onlineOrdering';
import { toDayString } from 'src/shared/js/timeUtils';

import { ScheduleType } from 'public/components/online_ordering/types';

export const schedulesByGuid = (scheduleData?: RestaurantSchedulesQuery) => scheduleData?.restaurants?.reduce((map, maybeRestaurant) => {
  if(maybeRestaurant.__typename === 'Restaurant') {
    return {
      ...map,
      [maybeRestaurant.guid]: maybeRestaurant
    };
  }
  return map;
}, {} as { [key: string]: ScheduleType }) || {};


const transformScheduleToDay = (schedule: RestaurantScheduleFieldsFragment) => {
  return schedule.schedule.upcomingSchedules.map(s => s.dailySchedules.reduce((map, dailySchedule) => {
    const day = toDayString(dailySchedule.date);
    return {
      ...map,
      [day]: dailySchedule
    };
  }));
};

export const daySchedulesByGuid = (scheduleData?: RestaurantSchedulesQuery) => scheduleData?.restaurants?.reduce((map, maybeRestaurant) => {
  if(maybeRestaurant.__typename === 'Restaurant') {
    return {
      ...map,
      [maybeRestaurant.guid]: transformScheduleToDay(maybeRestaurant)
    };
  }
  return map;
});
