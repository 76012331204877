import React, { useCallback, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { noop } from 'lodash';
import { WithContext, Restaurant, Person, MenuItem, Menu, Organization } from 'schema-dts';
import urljoin from 'url-join';

import EditorAwareExport from 'src/editor/build-tools/exporter';
import { menuItemPath } from 'src/public/components/default_template/menu_section/MenuItemCard';
import useStructuredData from 'src/public/components/seo/context/hook';

import { ensureHTTPPrefix } from 'public/components/seo/protocolPrefix';

import { resources } from 'config';


// Type checking `Thing` is memory intensive during development, so its nice to restrict the scope of allowed Things.
export type AllowedSchemaThings = Restaurant | Person | MenuItem | Menu | Organization

type RouteUtils = {
  location: string
  homeUrl: string
  orderUrl: (guid: string) => string
  menuItemUrl: (rxGuid: string, itemName: string, itemGuid: string) => string
}

type Props<T extends AllowedSchemaThings> = {
  json: (routes: RouteUtils) => WithContext<T> | T
}


/**
 * Renders JSON+LD markup for given Thing schema object. See https://schema.org/Thing
 * @param json - The Thing schema object to render.
 */
const SchemaThing = <T extends AllowedSchemaThings>({ json: jsonFunc }: Props<T>) => {
  const ctx = useStructuredData();
  const menuItemUrl = useCallback((rxGuid: string, itemName: string, itemGuid: string) => {
    if(ctx?.orderUrl) {
      return ensureHTTPPrefix(menuItemPath(ctx.orderUrl(rxGuid), itemName, itemGuid));
    }
    return '';
  }, [ctx]);


  useEffect(() => {
    if(ctx && resources.schemaHostBlocklist?.includes(ctx.hostname)) {
      return;
    }
    if(ctx && ctx.mountSchema && ctx.dismountSchema) {
      const routes: RouteUtils = {
        location: urljoin(ensureHTTPPrefix(ctx.hostname), ctx.location?.pathname || window.location.pathname),
        homeUrl: ctx.homePath?.startsWith('/') ? ensureHTTPPrefix(urljoin(ctx.hostname, ctx.homePath)) : ctx.homePath,
        menuItemUrl: menuItemUrl,
        orderUrl: (guid: string) => ensureHTTPPrefix(ctx.orderUrl(guid))
      };

      let schema = jsonFunc(routes);
      const id = ctx.mountSchema(schema);
      return () => {
        ctx.dismountSchema(id);
      };
    }
    return noop;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!ctx) {
    console.warn('structured data cannot be mounted from outside of the StructuredDataProvider.');
    return null;
  }
  return <></>;
};

const EditorSchemaThing: typeof SchemaThing = <_T extends AllowedSchemaThings>() => {
  return <></>;
};

export default EditorAwareExport(Sentry.withErrorBoundary(SchemaThing, { fallback: <></> }) as typeof SchemaThing, EditorSchemaThing);
