import React from 'react';

import { ButtonType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';

type Props = {
  errorMessage: string;
  restart: () => void;
};

const ReservationError = ({ errorMessage, restart }: Props) => {
  return (
    <div className="errorSection" data-testid="reservation-error">
      <div className="error"><Image alt="Warning icon" src="icons/warning-red.svg" />{errorMessage}</div>
      <Button variant={ButtonType.Secondary} className="secondary-btn" onClick={restart}><div>Restart</div></Button>
    </div>
  );
};

export default ReservationError;
