import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router';

import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons';
import { FieldType, useEditor } from '@toasttab/sites-components';
import classNames from 'classnames';

import { PageConfig, SiteContentDataFragment, useSiteContentByDomainQuery, useSiteContentByMgmtGuidQuery, useSiteContentByShortUrlQuery } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';

import { getImageUrl } from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import UhOh from 'shared/components/uh_oh/UhOh';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';

import Footer from 'public/components/default_template/footer';
import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';

import { usePDF } from './pdfUtils';

type SiteContent = NonNullable<SiteContentDataFragment>['content'] & { __typename: 'SiteContent' };

type BaseProps = {
  file: string;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
}

export const PDFNav = ({ file, canvas } : { file : string, canvas: React.RefObject<HTMLCanvasElement> }) => {
  const pdf = usePDF(file, canvas as React.RefObject<HTMLCanvasElement>);

  const navListener = useCallback((e: KeyboardEvent) => {
    if(e.key === 'ArrowLeft' || e.key === 'Left') {
      pdf.previousPage();
    } else if(e.key === 'ArrowRight' || e.key === 'Right') {
      pdf.nextPage();
    }
  }, [pdf]);

  useEffect(() => {
    if(!window || !pdf) return;
    window.addEventListener('keydown', navListener);
    return () => {
      if(window) window.removeEventListener('keydown', navListener);
    };
  }, [pdf, navListener]);

  if(!pdf) return null;

  return (
    <div className="pdfNav" data-testid="pdfNav">
      <div className="pdfToolbar">
        <Button testId="pdfZoomIn" className="iconButton" style={{ paddingTop: 0 }} as="div" onClick={() => pdf.zoom(1)} >+</Button>
        ZOOM
        <Button testId="pdfZoomOut" className="iconButton" style={{ paddingTop: 0 }} as="div" onClick={() => pdf.zoom(-1)} >-</Button>
        {pdf.totalPages >= 2 &&
        <>
          <Button
            testId="pdfPreviousPage"
            className="iconButton"
            as="div"
            icon={<ChevronLeftIcon />}
            disabled={pdf.page < 2}
            onClick={() => {
              pdf.previousPage();
            }} />
            PAGE {pdf.page} OF {pdf.totalPages}
          <Button
            testId="pdfNextPage"
            className="iconButton"
            as="div"
            icon={<ChevronRightIcon />}
            disabled={pdf.page >= pdf.totalPages}
            onClick={() => {
              pdf.nextPage();
            }} />
        </>}
      </div>
    </div>
  );
};

export const PdfPage = ({ file, editPathRoot, config }: BaseProps) => {
  const { isEditor, useEditableRef } = useEditor();

  const backgroundColorStyle = config?.backgroundColor ? { backgroundColor: config?.backgroundColor } : {};

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'pdf',
    displayName: 'PDF',
    path: editPathRoot,
    actions: [],
    schema: {
      fields: [
        {
          type: FieldType.Pdf,
          displayName: 'File',
          value: file,
          path: `${editPathRoot}.content.filePath`
        }
      ]
    }
  });

  const canvasRef = createRef<HTMLCanvasElement>();

  return (
    <>
      <Helmet script={[
        { type: 'module', src: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.min.mjs' }
      ]} />
      <div className="pdfWrapper" data-testid="pdfWrapper" style={backgroundColorStyle}
        ref={editableRef} role="main" id="main" tabIndex={0}>
        {isEditor ? <div className="pdfEditorOverlay" /> : null}
        <div className={classNames('pdfCanvasWrapper', { inEditor: isEditor })}>
          <canvas id="pdf-canvas" ref={canvasRef} className="pdfCanvas" data-testid="pdfCanvas"></canvas>
        </div>
        <PDFNav file={file} canvas={canvasRef} />
      </div>
    </>
  );
};

export const WrappedPdfPage = React.memo(
  function WrappedPdfPage({ staticContext, file, editPathRoot, config }: Partial<RequestContextProps> & BaseProps) {
    const { isEditor } = useEditor();
    const { restaurant: defaultRestaurant, locations } = useRestaurant();
    const { meta, theme } = defaultRestaurant;
    const { siteRestaurant: dataRestaurant, error, loading } = useRestaurantSiteData({
      staticContext,
      domainQueryHook: useSiteContentByDomainQuery,
      shortUrlQueryHook: useSiteContentByShortUrlQuery,
      mgmtGuidQueryHook: useSiteContentByMgmtGuidQuery
    });

    const fileUrl = useMemo(() => getImageUrl(file), [file]);

    const siteRestaurant = isEditor ? defaultRestaurant : dataRestaurant;

    if(error) {
      return <UhOh meta={meta} siteTheme={theme} />;
    }

    if(loading) {
      return null;
    }

    if(!siteRestaurant) {
      return <NoMatch404 />;
    }

    const { content } = siteRestaurant;

    if(!locations || !content || !content.sections || !fileUrl) {
      return <NoMatch404 meta={meta} siteTheme={theme} />;
    }

    const { primaryCta, nonPrimaryCtas } = content as SiteContent;

    return (
      <div className="defaultTemplate">
        <Nav
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          navType="normal"
          primaryCta={primaryCta}
          nonPrimaryCtas={nonPrimaryCtas}
          shouldShowPreviewBanner={true} />
        <PdfPage file={fileUrl} editPathRoot={editPathRoot} config={config} />
        <Footer />
      </div>
    );
  }
);

export default withRouter<RequestContextProps & BaseProps, React.ComponentType<RequestContextProps & BaseProps>>(WrappedPdfPage);
