import * as React from 'react';

import { PackagingSelection } from 'src/apollo/onlineOrdering';

interface PackagingSelectionsProps {
  packaging: PackagingSelection[]
}

export const PackagingSelections = ({ packaging }: PackagingSelectionsProps) => {
  return (
    <div className="packagingSelections">
      <ul>
        {
          packaging.map(item => {
            return (
              <li key={item.guid} className="packagingItem" data-testid="packaging-item">
                <p>{item.guestDisplayName}</p>
                <p>{item.inclusion}</p>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
