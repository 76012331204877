import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import * as Sentry from '@sentry/react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useMarketing } from 'src/public/components/online_ordering/MarketingContext';
import { ukiDummyAddressValue } from 'src/public/components/online_ordering/addressUtils';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import Image from 'shared/components/common/Image';
import FormInput from 'shared/components/common/form_input';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import { ToggleInput } from 'shared/components/common/forms';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { alertSuccess } from 'shared/js/alertUtils';
import { asValidPhoneCountryCode } from 'shared/js/phoneUtils';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { ConfirmationCodeInputs } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import { AuthenticationSource, customerHasAllValidInfo, getCustomerInfo } from './checkoutUtils';
import { MarketingSubscriptionCheckbox, MarketingSubscriptionCheckboxLegalText } from './marketing/MarketingSubscriptionCheck';


export const PHONE_FOCUS_MSG = 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.';

const useToastSignup = () => {
  const { completeSignup, fetchCustomer } = useCustomer();
  const { setSaveNewCreditCard } = usePayment();
  const tracker = useTracker();

  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });

  const onToastSignup = useCallback(async () => {
    setSaveNewCreditCard(true);

    try {
      // Fetch customer with new access token
      const customer = await fetchCustomer();
      const newOrExistingCustomer = customer ? 'existing account' : 'new account';
      if(!customer) {
        await completeSignup(email, firstName, lastName);
        alertSuccess('Account created!');
      } else {
        await completeSignup(customer.email, customer.firstName || firstName, customer.lastName || lastName);
        alertSuccess('Welcome back!');
      }
      tracker.track('Completed 2FA', { source: AuthenticationSource.AccountCreation, newOrExistingCustomer });
    } catch(err) {
      Sentry.captureException(`ERROR: error completing signup ${err}`);
    }
  }, [setSaveNewCreditCard, fetchCustomer, completeSignup, email, firstName, lastName, tracker]);

  return { onToastSignup };
};

export const CustomerInfoSection = () => {
  const { customer } = useCustomer();
  const [editing, setEditing] = useState(false);
  const { paymentOption, userInfoRequired } = usePayment();
  const { ooRestaurant } = useRestaurant();
  const restaurantCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);

  const required = useMemo(
    () =>
      paymentOption === PaymentOption.UponReceipt
      || paymentOption !== PaymentOption.ApplePay && userInfoRequired,
    [paymentOption, userInfoRequired]
  );

  // Check if the customer has all the required info and that info is valid in the restaurant country.
  // When we support the tourist use case, we should be able to remove this as all info should be in a format which is valid in all supported countries.
  const isCustomerInfoValid = useMemo(() => customerHasAllValidInfo(customer, restaurantCountryCode), [customer, restaurantCountryCode]);

  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    switch(e.key) {
      case ' ':
      case 'Enter':
        setEditing(!editing);
        break;
    }
  }, [editing, setEditing]);

  return (
    <CheckoutSection>
      <AnimatedSection expanded={Boolean(customer) || required} slowTransition>
        <div data-testid={customer ? 'toast-checkout-inputs' : 'guest-checkout-inputs'} role="form">
          <div className="yourInfoContainer">
            <div className="yourInfoHeader">
              <h2 className="checkoutSectionHeader">Your info</h2>
              {customer && !editing && <div tabIndex={0} className="editLink" aria-label="Edit your user info" role="button" onKeyDown={onKeyDown} onClick={() => setEditing(true)}>Edit</div>}
            </div>
            <AnimatedSection expanded={isCustomerInfoValid && !editing} slowTransition testid="completed-info-animated-section">
              {isCustomerInfoValid && !editing && <CompletedInfo />}
            </AnimatedSection>
            <AnimatedSection expanded={!isCustomerInfoValid || editing} slowTransition testid="customer-info-inputs-animated-section">
              { (!isCustomerInfoValid || editing) && <CustomerInfoInputs initialFocus={!!customer} required={required} /> }
            </AnimatedSection>
            <div className="updateLinkContainer">
              {
              // Logically structure update at the end for a11y tab ordering
                customer && editing && <div tabIndex={0} className="editLink" aria-label="Update your user info" role="button" onKeyDown={onKeyDown} onClick={() => setEditing(false)}>Update</div>
              }
            </div>
          </div>
        </div>
      </AnimatedSection>
    </CheckoutSection>
  );
};

interface CustomerInfoInputsProps {
  initialFocus?: boolean;
  required: boolean
}

const CustomerInfoInputs = ({ initialFocus = false, required }: CustomerInfoInputsProps) => {
  const { createAccount, setCreateAccount, saveNewAddress, setSaveNewAddress } = useCheckout();
  const { smsAccountEnabled, subscribeToSmsMarketing, setSubscribeToSmsMarketing, subscribeToEmailMarketing, setSubscribeToEmailMarketing } = useMarketing();
  const { onToastSignup } = useToastSignup();
  const { customer, passwordlessLogin } = useCustomer();
  const tracker = useTracker();
  const { formState: { errors }, setFocus, clearErrors, trigger } = useFormContext();
  const { cart } = useCart();
  const { savedAddressUsed } = useDelivery();
  const [deliveryAddressLine2, setDeliveryLineAddress2] = useState(cart?.order?.deliveryInfo?.address2);
  const [deliveryNotes, setDeliveryNotes] = useState(cart?.order?.deliveryInfo?.notes);
  const [zipcode, setZipCode] = useState(cart?.order?.deliveryInfo?.zipCode === ukiDummyAddressValue ? '' : cart?.order?.deliveryInfo?.zipCode);
  const { ooRestaurant } = useRestaurant();
  const { ooEmailSubscriptionCheckboxEnabled } = useFlags();

  useEffect(() => {
    if(cart?.diningOptionBehavior !== DiningOptionBehavior.Delivery) {
      setSaveNewAddress(false);
    }
    setDeliveryLineAddress2(cart?.order?.deliveryInfo?.address2);
    setDeliveryNotes(cart?.order?.deliveryInfo?.notes);
  }, [setSaveNewAddress, cart]);

  useEffect(() => {
    if(saveNewAddress) {
      // forcing the focus here makes sure the validation for this field gets run
      setFocus('addressLabel');
    } else {
      // clear any errors for this required field when it isnt visible
      clearErrors('addressLabel');
    }
  }, [saveNewAddress, setFocus, clearErrors]);

  const handleCreateAccountCheckboxChange = async (checked: boolean) => {
    setCreateAccount(checked);
    if(checked) {
      tracker.track('Started Authentication', { source: AuthenticationSource.AccountCreation });
      await sendPwlessLoginCode();
      tracker.track('Clicked Send code', { source: AuthenticationSource.AccountCreation });
    }
  };

  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const addressLabel = useWatch({ name: 'addressLabel' });

  const sendPwlessLoginCode = useCallback( async () => {
    await passwordlessLogin(phoneNumber);
  }, [phoneNumber, passwordlessLogin]);

  const rxCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n.country);
  const validatePhone = useCallback(
    (value: string) => required && !isValidPhoneNumber(value, rxCountryCode) ? 'enter a valid local phone number' : true,
    [required, rxCountryCode]
  );

  const validateEmail = useCallback(
    (value: string) => required && !isEmail(value) ? 'enter a valid email address' : true,
    [required]
  );

  useEffect(() => {
    if(!required) {
      // clear errors and trigger validation so that the form can be submitted when these fields are no longer required
      clearErrors(['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName']);
      trigger(['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName']);
    }
  }, [required, clearErrors, trigger]);

  const defaultCustomerValues = getCustomerInfo(customer);
  const isIrishRx = cart?.order?.deliveryInfo?.country === 'IE';
  const isGbRx = cart?.order?.deliveryInfo?.country === 'GB';
  return (
    <>
      <PhoneInput
        id="yourInfoPhone"
        initialFocus={initialFocus}
        required={required}
        label="Phone number"
        validate={validatePhone}
        autoComplete="tel"
        defaultValue={defaultCustomerValues.yourInfoPhone || phoneNumber}
        filled={Boolean(customer?.phone)}
        unregisterOnUnmount={false} />
      <FormInput id="yourInfoEmail" type="email" autoComplete="email" label="Email" defaultValue={defaultCustomerValues.yourInfoEmail}
        filled={Boolean(customer?.email)} required={required} validate={validateEmail} className="fs-mask" />
      <FormInput id="yourInfoFirstName" type="text" autoComplete="given-name" label="First name" defaultValue={defaultCustomerValues.yourInfoFirstName}
        filled={Boolean(customer?.firstName)} required={required} className="fs-mask" />
      <FormInput id="yourInfoLastName" type="text" autoComplete="family-name" label="Last name" defaultValue={defaultCustomerValues.yourInfoLastName}
        filled={Boolean(customer?.lastName)} required={required} className="fs-mask" />
      {cart?.diningOptionBehavior === DiningOptionBehavior.Delivery &&
        <>
          {(isIrishRx || isGbRx) &&
          <FormInput id="deliveryZipCode" type="text" label={isIrishRx ? 'Eircode' : 'Postcode' } autoComplete="zipcode"
            filled={!!zipcode} value={zipcode || ''} required={required} className="fs-mask" onChange={e => { setZipCode(e.target.value ); }} />}
          <FormInput id="deliveryAddress2" type="text" label="Apt./Suite no." autoComplete="address-line2"
            filled={!!deliveryAddressLine2} value={deliveryAddressLine2 || ''} className="fs-mask" onChange={e => { setDeliveryLineAddress2(e.target.value ); }} />
          <FormInput id="deliveryInstructions" type="text" label="Delivery Instructions"
            filled={!!deliveryNotes} value={deliveryNotes || ''} className="fs-mask" onChange={e => { setDeliveryNotes(e.target.value ); }} />
          {customer && !savedAddressUsed &&
              <div className="formInput"
                data-testid="save-new-address">
                <ToggleInput
                  type="checkbox"
                  name="saveNewAddress"
                  id="saveNewAddress"
                  checked={saveNewAddress}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSaveNewAddress(event.target.checked)}>
                      Save address
                </ToggleInput>
              </div>}
          <AnimatedSection expanded={saveNewAddress}>
            <FormInput id="addressLabel" type="text" label="Address label" filled={Boolean(addressLabel)} defaultValue="" required={saveNewAddress} />
          </AnimatedSection>
        </>}
      {!customer &&
        <div className="createAccountCheckbox">
          <ShowForUS>
            <ToggleInput
              type="checkbox"
              name="createToastAccount"
              id="createToastAccount"
              dataTestId="createToastAccount"
              disabled={!(phoneNumber && email && firstName && lastName &&
                !Object.keys(errors).some(field => ['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName'].includes(field)))}
              checked={createAccount}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await handleCreateAccountCheckboxChange(event.target.checked)}
              onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
                if(event.key === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  await handleCreateAccountCheckboxChange(!createAccount);
                }
              }}>
              <div className="createAccountLabel">
                Create a Toast account
                <div className="imageContainer">
                  <Image alt="Toast logo" aria-hidden="true" src="icons/toast-logo-filled.svg" />
                </div>
              </div>
            </ToggleInput>
            <div className="note" role="contentinfo">
              Save your info for express checkout next time. Toast&apos;s{' '}
              <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Guest Terms of Service</a>
              {' '}and{' '}<a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>
              {' '}apply. Info for CA residents available{' '}
              <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">here</a>.
              Contact and payment information shared above may be stored, used, and shared by Toast for this and future orders from Toast restaurants.
            </div>
          </ShowForUS>
          {createAccount && <AccountCreation onComplete={onToastSignup} phoneNumber={`+1${phoneNumber}`} sendCode={sendPwlessLoginCode} />}
        </div>}
      {!customer && smsAccountEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToSmsMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.SMS}
          isActive={subscribeToSmsMarketing}
          setActive={setSubscribeToSmsMarketing}
          disabled={!(phoneNumber && !Object.keys(errors).includes('yourInfoPhone'))} />}
      {!customer && ooEmailSubscriptionCheckboxEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToEmailMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.EMAIL}
          isActive={subscribeToEmailMarketing}
          setActive={setSubscribeToEmailMarketing} />}
    </>
  );
};

/** Renders the customer's info in a compact format after it's been entered and validated */
const CompletedInfo = () => {
  const { smsAccountEnabled, subscribeToSmsMarketing, setSubscribeToSmsMarketing, subscribeToEmailMarketing, setSubscribeToEmailMarketing } = useMarketing();
  const { ooEmailSubscriptionCheckboxEnabled } = useFlags();
  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const addressLine2 = useWatch({ name: 'deliveryAddress2' });
  const deliveryInstructions = useWatch({ name: 'deliveryInstructions' });

  const { ooRestaurant } = useRestaurant();
  const rxCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n.country);
  const parsedPhoneNumber = useMemo(() =>
    isValidPhoneNumber(phoneNumber ?? '', rxCountryCode)
      ? parsePhoneNumber(phoneNumber, rxCountryCode)
      : null
  , [phoneNumber, rxCountryCode]);

  return (
    <div className="completedInfo">
      {firstName && lastName && <div className="sectionRow"><div className="icon"><Image alt="Customer icon" src="icons/user-gray.svg" /></div>{firstName} {lastName}</div>}
      {email && <div className="sectionRow"><div className="icon"><Image alt="Email icon" src="icons/email-gray.svg" /></div>{email}</div>}
      {parsedPhoneNumber?.isValid() &&
          <div className="sectionRow"><div className="icon"><Image alt="Phone icon" src="icons/phone-gray.svg" /></div>{parsedPhoneNumber.formatNational()}</div>}
      {addressLine2 && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{addressLine2}</div>}
      {deliveryInstructions && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{deliveryInstructions}</div>}
      {parsedPhoneNumber?.isValid() && smsAccountEnabled &&
        <MarketingSubscriptionCheckbox
          name="subscribeToSmsMarketing"
          legalText={MarketingSubscriptionCheckboxLegalText.SMS}
          isActive={subscribeToSmsMarketing}
          setActive={setSubscribeToSmsMarketing} />}
      {ooEmailSubscriptionCheckboxEnabled && <MarketingSubscriptionCheckbox
        name="subscribeToEmailMarketing"
        legalText={MarketingSubscriptionCheckboxLegalText.EMAIL}
        isActive={subscribeToEmailMarketing}
        setActive={setSubscribeToEmailMarketing} />}
    </div>
  );
};

/** Renders the passwordless login flow inline at the time of account creation. Exported for tests */
export const AccountCreation = ({
  phoneNumber,
  onComplete,
  sendCode
}: {
  phoneNumber: string;
  onComplete: (customerGuid?: string) => Promise<any>;
  sendCode: () => void;
}) => {
  return (
    <div className="confirmationSection">
      <div>{`Enter the code sent to ${parsePhoneNumber(phoneNumber, 'US').formatNational()} to confirm it's you.`}</div>
      <ConfirmationCodeInputs phoneNumber={phoneNumber} onComplete={onComplete} />
      <div className="helpText">
        <div>Didn&apos;t receive a code?</div>
        <button className="resend" type="button" onClick={sendCode}>Resend code</button>
      </div>
    </div>
  );
};

export default CustomerInfoSection;
