import React, { FC } from 'react';

import classnames from 'classnames';

import { MenuTemplate } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { UpsellItem } from 'public/components/default_template/online_ordering/upsell/UpsellItem';
import { UpsellBasicCard } from 'public/components/default_template/online_ordering/upsell/upsell_basic_card/UpsellBasicCard';
import { UpsellImageCard } from 'public/components/default_template/online_ordering/upsell/upsell_image_card/UpsellImageCard';
import { allHaveImage } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';
import { useUpsell } from 'public/components/online_ordering/UpsellContext';


interface UpsellProps {}

const Upsell: FC<UpsellProps> = () => {
  const { restaurant } = useRestaurant();
  const { upsells: upsellItems } = useUpsell();
  const template = restaurant?.config?.ooConfig?.format?.template || MenuTemplate.Classic;
  const withImage = allHaveImage(upsellItems, template);
  const UpsellCard = withImage ? UpsellImageCard : UpsellBasicCard;

  if(!upsellItems.length) {
    return <></>;
  }
  return (
    <>
      <div className="upsell" data-testid="Upsell">
        <p className="upsellHeader">
          Need anything else?
        </p>
        <div className={classnames('upsellGrid', { upsellLong: withImage, upsellShort: !withImage })}>
          {upsellItems.map((item: UpsellItem) => {
            return <UpsellCard key={`${item?.guid}_${item?.itemGroupGuid}`} item={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Upsell;
