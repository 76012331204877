import React from 'react';

import { useEditor, ContainsEditableProps } from '@toasttab/sites-components';
import classnames from 'classnames';

import { SectionBackgroundSize, Image as ImageObject, PaddingEnum, BackgroundImage } from 'src/apollo/sites';
import Image from 'src/shared/components/common/Image';

import { getBackgroundColorOrImageModule, getImageModules, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';


type Props = {
  imageObject?: ImageObject | null;
  imageObjectPath?: string | null;
  imageDescription?: string | null;
  backgroundSize?: SectionBackgroundSize | null;
  padding?: PaddingEnum | null;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
} & ContainsEditableProps;

const ImageHero = ({ editPath, imageObject, imageObjectPath, imageDescription, backgroundSize, backgroundColor, backgroundImage, padding = PaddingEnum.Medium }: Props) => {
  const { useEditableRef } = useEditor();

  const schemaFields = [
    getSectionPaddingModule(editPath, padding),
    getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
    ...imageObjectPath && imageObject ? getImageModules(imageObjectPath, imageObject) : []
  ];

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'hero section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    schema: { fields: schemaFields }
  });

  if(!imageObject?.src) return null;

  return (
    <div className="hero" ref={editableRef} data-testid="image-hero">
      <picture role="picture" >
        <Image className={classnames('image', { large: backgroundSize === 'large' })}
          imageObject={imageObject}
          alt={imageDescription || 'User-uploaded image'} eagerLoad aria-hidden={true} />
      </picture>
    </div>
  );
};

export default ImageHero;
