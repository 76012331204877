import React, { useCallback, useContext, createContext, useEffect, useMemo, useState } from 'react';

import {
  NewCardInput,
  OnlineOrderingSpiCreatePaymentIntentSuccessResponse,
  OnlineOrderingSpiUpdatePaymentIntentSuccessResponse,
  PlaceOrderErrorCode,
  useCreateGiftCardPaymentIntentMutation,
  useCreatePaymentIntentMutation,
  useUpdateGiftCardPaymentIntentMutation,
  useUpdatePaymentIntentMutation,
  PaymentType
} from 'src/apollo/onlineOrdering';
import { reportErrorMessageWithData } from 'src/lib/js/clientError';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { PaymentCompletedParams } from 'public/components/default_template/online_ordering/checkout/CheckoutForm';
import { useSpi, useSpiSdk, useTempEventTracker } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useApplePay } from 'public/components/online_ordering/applePayUtils';
import { BillingDetails, PaymentIntent, CreatePaymentMethodResultEvent, CreatePaymentMethodResultEventContent, ToastPaymentType } from 'public/components/online_ordering/types';

import { resources } from 'config';

type PaymentError = {
  message: string;
  type: string;
};

export enum PaymentOption {
  CreditCard = 'CreditCard',
  UponReceipt = 'UponReceipt',
  ApplePay = 'ApplePay',
  Paypal = 'PayPal',
  Venmo = 'Venmo',
  PayNow = 'PayNow'
}

export type SelectedCreditCard = {
  newCardSelected: boolean;
  savedCardGuid: string | null;
};

export type PaymentContextType = {
  createPaymentIntent: () => Promise<void>;
  paymentIntent?: OnlineOrderingSpiCreatePaymentIntentSuccessResponse;
  completePayment: (
    checkoutFormData: CheckoutFormData,
    giftCardAppliedAmount: number,
    toastCashAppliedAmount: number,
    validate: (() => Promise<boolean>) | null,
    onComplete: (paymentId?: string, firstName?: string, lastName?: string, phone?: string, email?: string, paymentCompletedParams?: PaymentCompletedParams,) => Promise<any>,
    onCancel: (needInput: boolean, firstName?: string, lastName?: string, phone?: string, email?: string) => void,
    onFail: (err: PaymentError | null) => void
  ) => Promise<void>;
  completeGiftCardPayment: (
    data: any,
    onComplete: (paymentId: string) => Promise<any>,
    onCancel: () => void,
    onFail: (err: PaymentError | null) => void
  ) => Promise<void>;
  confirmedPayment: PaymentIntent | null;
  billingDetails?: BillingDetails;
  userInfoRequired: boolean;
  setUserInfoRequired: (userInfoRequired: boolean) => void;
  isPaymentValid: boolean;
  setIsPaymentValid: (valid: boolean) => void;
  // the paymentType that was selected via SPI
  paymentType: string | null;
  setPaymentType: (paymentType: ToastPaymentType) => void;
  paymentOption: PaymentOption | null;
  setPaymentOption: (option: PaymentOption | null) => void;
  newCreditCard: NewCardInput | null;
  setNewCreditCard: (card: NewCardInput | null) => void;
  setSaveNewCreditCard: (saveCard: boolean) => void;
  selectedCreditCard: SelectedCreditCard | null;
  setSelectedCreditCard: (card: SelectedCreditCard | null | ((old: SelectedCreditCard) => SelectedCreditCard)) => void;
  tipEnabled: boolean;
  tipAmount: number;
  setTipAmount: (tip: number) => void;
  fundraisingEnabled: boolean;
  fundraisingAmount: number;
  setFundraisingAmount: (amount: number) => void;
  toastCashInfo: ToastCashInfo | null
  setToastCashInfo: (info: ToastCashInfo | null) => void,
  showRemoveRoundupTemporaryMessaging: boolean
}

const TIPPABLE_PAYMENT_OPTIONS = [PaymentOption.CreditCard, PaymentOption.ApplePay, PaymentOption.Paypal, PaymentOption.Venmo, PaymentOption.PayNow] as PaymentOption[];

export type ToastCashInfo = { toastCashAvailableAmount: number, toastCashAccountId: string }

export const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export const getCheckoutInfo = (checkoutFormData: CheckoutFormData, billingDetails?: BillingDetails | null) => {
  // The SPI SDK returns the user's name as a single string (partly because the GooglePay SDK does), so try to parse the name here
  const firstName = checkoutFormData.yourInfoFirstName || (billingDetails?.name || '').split(' ')?.[0];
  const lastName = checkoutFormData.yourInfoLastName || (billingDetails?.name || '').split(' ')?.[1];
  const phoneNumber = checkoutFormData.yourInfoPhone || billingDetails?.phoneNumber;
  const email = checkoutFormData.yourInfoEmail || billingDetails?.email;

  return { firstName, lastName, phoneNumber, email };
};

export const PaymentContextProvider = (props: React.PropsWithChildren<{ useGiftCardFlow?: boolean; giftCardAmount?: number }>) => {
  const ooClient = useOOClient();
  const { spiEnabled, spiSurchargingEnabled } = useSpi();
  const spiSdk = useSpiSdk(false, props.useGiftCardFlow);
  const { track } = useTempEventTracker();
  const { ooRestaurant, selectedLocation } = useRestaurant();
  const { cart, cartGuid, surcharges } = useCart();
  const { customer } = useCustomer();
  const [createPaymentIntentMutation] = useCreatePaymentIntentMutation({ client: ooClient });
  const [updatePaymentIntentMutation] = useUpdatePaymentIntentMutation({ client: ooClient });
  const [createGiftCardPaymentIntentMutation] = useCreateGiftCardPaymentIntentMutation({ client: ooClient });
  const [updateGiftCardPaymentIntentMutation] = useUpdateGiftCardPaymentIntentMutation({ client: ooClient });
  const { getConfig } = useApplePay();

  const [paymentIntent, setPaymentIntent] = useState<OnlineOrderingSpiCreatePaymentIntentSuccessResponse | undefined>();
  const [paymentOption, setPaymentOption] = useState<PaymentOption | null>(null);
  const [paymentType, setPaymentType] = useState<ToastPaymentType | null>(null);
  const [newCreditCard, setNewCreditCard] = useState<NewCardInput | null>(null);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState<SelectedCreditCard | null>(null);
  const [tipAmount, setTipAmount] = useState(0.00);
  const tipEnabled = useMemo(() => {
    return Boolean(ooRestaurant?.creditCardConfig.tipEnabled && paymentOption && TIPPABLE_PAYMENT_OPTIONS.includes(paymentOption));
  }, [ooRestaurant, paymentOption]);
  const [fundraisingAmount, setFundraisingAmount] = useState(0.00);
  const fundraisingEnabled = useMemo(() => {
    return Boolean(ooRestaurant?.fundraisingConfig?.active && (paymentOption === PaymentOption.CreditCard || paymentOption === PaymentOption.ApplePay || paymentOption === PaymentOption.PayNow));
  }, [ooRestaurant, paymentOption]);
  const [userInfoRequired, setUserInfoRequired] = useState(true);
  const [confirmPaymentError, setConfirmPaymentError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<CreatePaymentMethodResultEventContent | undefined>(undefined);
  const [confirmedPayment, setConfirmedPayment] = useState(null);

  // changing the payment type should cause a new payment method to be created when checking out
  const updatePaymentType = useCallback((newPaymentType: ToastPaymentType) => {
    setPaymentType(newPaymentType);
    setPaymentMethod(undefined);
  }, []);
  const [toastCashInfo, setToastCashInfo] = React.useState<ToastCashInfo | null>(null);

  useEffect(() => {
    if(cart) {
      const canPayAtCheckout = cart.paymentOptions.atCheckout.length > 0;
      const canPayUponReceipt = cart.paymentOptions.uponReceipt.length > 0;
      if(!canPayAtCheckout && !canPayUponReceipt) {
        setPaymentOption(null);
      } else {
        if(spiEnabled) {
          // This logic checks for a null paymentOption (i.e. the first render). However, the spiEnabled flag may not be initialized yet, so the logic
          // here also converts a CreditCard payment option to PayNow.
          if(canPayAtCheckout && (paymentOption === null || paymentOption === PaymentOption.CreditCard || paymentOption === PaymentOption.UponReceipt && !canPayUponReceipt)) {
            setPaymentOption(PaymentOption.PayNow);
          } else if(canPayUponReceipt && (paymentOption === null || paymentOption === PaymentOption.PayNow && !canPayAtCheckout)) {
            setPaymentOption(PaymentOption.UponReceipt);
          }
        } else {
          if(canPayAtCheckout && (paymentOption === null || paymentOption === PaymentOption.UponReceipt && !canPayUponReceipt)) {
            setPaymentOption(PaymentOption.CreditCard);
          } else if(canPayUponReceipt && (paymentOption === null || paymentOption === PaymentOption.CreditCard && !canPayAtCheckout)) {
            setPaymentOption(PaymentOption.UponReceipt);
          }
        }
      }
    }
  }, [cart, paymentOption, spiEnabled]);

  const createPaymentIntent = useCallback(async () => {
    if(props.useGiftCardFlow && props.giftCardAmount) {
      try {
        const res = await createGiftCardPaymentIntentMutation({ variables: { input: { amount: props.giftCardAmount, restaurantGuid: selectedLocation.externalId } } });
        if(res?.data?.oo?.spiCreateGiftCardPaymentIntent?.__typename === 'OnlineOrderingSpiCreatePaymentIntentSuccessResponse') {
          setPaymentIntent(res.data.oo.spiCreateGiftCardPaymentIntent);
        } else if(res?.data?.oo?.spiCreateGiftCardPaymentIntent?.__typename === 'OnlineOrderingPaymentIntentError') {
          reportErrorMessageWithData('Error creating payment intent', { message: res.data.oo.spiCreateGiftCardPaymentIntent.message });
        }
      } catch(e) {
        reportErrorMessageWithData('Error creating payment intent', { message: e.message });
      }
    } else if(spiEnabled && cartGuid) {
      try {
        const res = await createPaymentIntentMutation({ variables: { input: { cartGuid, paymentMethodConfigId: resources.paymentMethodConfigId } } });
        if(res?.data?.oo?.spiCreatePaymentIntent?.__typename === 'OnlineOrderingSpiCreatePaymentIntentSuccessResponse') {
          setPaymentIntent(res.data.oo.spiCreatePaymentIntent);
          setConfirmedPayment(null);
          track('temp_SPI_payment_intent_created', { paymentId: res.data.oo.spiCreatePaymentIntent.id });
        } else if(res?.data?.oo?.spiCreatePaymentIntent?.__typename === 'OnlineOrderingPaymentIntentError') {
          reportErrorMessageWithData('Error creating payment intent', { message: res.data.oo.spiCreatePaymentIntent.message });
          track('temp_SPI_payment_intent_create_failed', { type: 'OnlineOrderingPaymentIntentError', message: res.data.oo.spiCreatePaymentIntent.message });
        }
      } catch(e) {
        reportErrorMessageWithData('Error creating payment intent', { message: e.message });
        track('temp_SPI_payment_intent_create_failed', { type: 'UnknownException', message: e.message });
      }
    }
  }, [track, createPaymentIntentMutation, props, createGiftCardPaymentIntentMutation, selectedLocation, cartGuid, spiEnabled]);

  // re-create the payment intent if the user logged in or out
  useEffect(() => {
    createPaymentIntent();
  }, [spiEnabled, cartGuid, createPaymentIntent, customer]);

  const updatePaymentIntent = useCallback(async (
    email: string,
    restaurantGiftCardPaymentAmount: number,
    globalGiftCardPaymentAmount: number,
    toastCashPaymentAmount: number,
    paymentMethodId?: string
  ): Promise<OnlineOrderingSpiUpdatePaymentIntentSuccessResponse|void> => {
    if(cartGuid && email && paymentIntent && paymentOption === PaymentOption.PayNow) {
      const response = await updatePaymentIntentMutation({
        variables: {
          input: {
            cartGuid,
            email,
            globalGiftCardPaymentAmount: globalGiftCardPaymentAmount + toastCashPaymentAmount,
            paymentIntentId: paymentIntent.id,
            restaurantGiftCardPaymentAmount,
            tipAmount,
            fundraisingAmount,
            enableConfirmRetry: confirmPaymentError,
            paymentMethodId
          }
        }
      });
      if(response.data?.oo.spiUpdatePaymentIntent.__typename === 'OnlineOrderingPaymentIntentError') {
        track('temp_SPI_payment_intent_update_failed', { paymentIntentId: paymentIntent.id });
        reportErrorMessageWithData('Error placing order - failed to update payment intent', { paymentIntentId: paymentIntent?.id });
        throw {
          type: PlaceOrderErrorCode.PlaceOrderFailed,
          message: response.data.oo.spiUpdatePaymentIntent.message
        };
      } else {
        track('temp_SPI_payment_intent_updated', {});
      }

      if(response.data?.oo.spiUpdatePaymentIntent.__typename === 'OnlineOrderingSpiUpdatePaymentIntentSuccessResponse') {
        return response.data?.oo.spiUpdatePaymentIntent as OnlineOrderingSpiUpdatePaymentIntentSuccessResponse;
      }
    }
  }, [track, cartGuid, paymentIntent, paymentOption, tipAmount, fundraisingAmount, updatePaymentIntentMutation, confirmPaymentError]);

  const updateGiftCardPaymentIntent = useCallback(async (email: string, globalGiftCardPaymentAmount: number) => {
    if(props.giftCardAmount && email && paymentIntent) {
      const response = await updateGiftCardPaymentIntentMutation({
        variables: {
          input: {
            restaurantGuid: selectedLocation.externalId,
            email,
            globalGiftCardPaymentAmount,
            paymentIntentId: paymentIntent.id,
            giftCardAmount: props.giftCardAmount,
            enableConfirmRetry: confirmPaymentError
          }
        }
      });
      if(response.data?.oo.spiUpdateGiftCardPaymentIntent.__typename === 'OnlineOrderingPaymentIntentError') {
        throw {
          type: PlaceOrderErrorCode.PlaceOrderFailed,
          message: response.data.oo.spiUpdateGiftCardPaymentIntent.message
        };
      }
    }
  }, [selectedLocation, props, paymentIntent, updateGiftCardPaymentIntentMutation, confirmPaymentError]);

  const spiConfirmPaymentFailureCallback = useCallback((onFailed: any) => (ev: any) => {
    track('temp_SPI_confirmPayment_failed', { message: ev?.cause?.message, paymentIntentId: paymentIntent?.id });
    setConfirmPaymentError(true);
    reportErrorMessageWithData('Error placing order - failed to confirm payment', { message: ev?.cause?.message, error: ev, paymentIntentId: paymentIntent?.id });
    onFailed({
      type: PlaceOrderErrorCode.PlaceOrderFailed,
      message: 'We were unable to place your order.'
    });
    spiSdk.clearPaymentSelection();
  }, [track, spiSdk, paymentIntent]);

  const spiPaymentMethodSuccessCallback = useCallback(
    (checkoutFormData: any, giftCardAppliedAmount: number | null, toastCashAppliedAmount: number | null, validate: any, onConfirmPayment: any, onCancel: any, onFailed: any) =>
      async (createPaymentEvent: CreatePaymentMethodResultEvent) => {
        setPaymentMethod(createPaymentEvent.content);
        const paymentCompletedParams: PaymentCompletedParams = { paymentMethodId: null, surchargeAmount: null };

        const { firstName, lastName, phoneNumber, email } = getCheckoutInfo(checkoutFormData, createPaymentEvent.content.billingDetails);

        if(!props.useGiftCardFlow && (!firstName || !lastName || !phoneNumber || !email)) {
          // Couldn't get all the info from the checkout form or the digital wallet, so prompt the user to input it
          setUserInfoRequired(true);
          onCancel(true, firstName, lastName, phoneNumber, email);
        } else {
          try {
            let isValid = true;
            if(props.useGiftCardFlow) {
              await updateGiftCardPaymentIntent(checkoutFormData.email, cart?.order?.discounts?.globalReward?.amount || 0);
            } else {
              if(validate) {
                isValid = await validate();
              }

              if(isValid) {
                // email will never be undefined because of the condition above, but TS isn't smart enough to figure that out
                const updatedIntent = await updatePaymentIntent(
                  email || '',
                  giftCardAppliedAmount || 0,
                  cart?.order?.discounts?.globalReward?.amount || 0,
                  toastCashAppliedAmount || 0,
                  spiSurchargingEnabled ? createPaymentEvent.content.paymentMethodId : undefined
                );

                if(spiSurchargingEnabled) {
                  paymentCompletedParams.paymentMethodId = createPaymentEvent.content.paymentMethodId;
                  paymentCompletedParams.surchargeAmount = updatedIntent?.surchargeAmount;
                }
              }
            }
            // after the payment method is created, update the payment intent and confirm the payment

            if(isValid) {
              track('temp_SPI_confirming_payment', {});
              await spiSdk.confirmPayment(
                async (ev: any) => {
                  track('temp_SPI_confirmPayment_success', {});
                  setConfirmedPayment(ev.content.payment);
                  await onConfirmPayment(
                    ev.content.payment.externalReferenceId,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    paymentCompletedParams
                  );
                },
                spiConfirmPaymentFailureCallback(onFailed)
              );
            } else {
              // the validate function should set order errors, so don't pass back an error in this callback
              onFailed();
            }
          } catch(e) {
            track('temp_SPI_updateOrConfirmPayment_failed', { message: e.message, paymentIntentId: paymentIntent?.id });
            reportErrorMessageWithData('Error placing order - failed to update payment intent', { message: e.message, paymentIntentId: paymentIntent?.id });
            onFailed({
              type: PlaceOrderErrorCode.PlaceOrderFailed,
              message: 'We were unable to place your order.'
            });
            spiSdk.clearPaymentSelection();
          }
        }
      },
    [
      track,
      spiSdk,
      updatePaymentIntent,
      cart?.order?.discounts?.globalReward?.amount,
      spiConfirmPaymentFailureCallback,
      spiSurchargingEnabled,
      updateGiftCardPaymentIntent,
      props.useGiftCardFlow,
      paymentIntent
    ]
  );

  const spiPaymentMethodFailureCallback = useCallback((onCancel: any, onFailed: any) => (ev: any) => {
    setPaymentMethod(undefined);
    // ignore errors when the user cancelled the digital wallet payment (i.e. they closed the modal)
    if(ev?.cause?.message === 'User cancelled Google Pay flow.' || ev?.cause?.message === 'Apple Pay session cancelled.') {
      onCancel(false);
    } else {
      track('temp_SPI_createPaymentMethod_failed', { message: ev?.cause?.message || ev?.message || ev });
      reportErrorMessageWithData('Error placing order - failed to create payment method', { message: ev?.cause?.message || ev?.message, error: ev });
      onFailed({
        type: PlaceOrderErrorCode.PlaceOrderFailed,
        message: 'We were unable to place your order.'
      });
      spiSdk.clearPaymentSelection();
    }
  }, [track, spiSdk]);

  const completePayment = useCallback<PaymentContextType['completePayment']>(async (
    checkoutFormData,
    giftCardAppliedAmount,
    toastCashAppliedAmount,
    validate,
    onComplete,
    onCancel,
    onFailed
  ) => {
    // When using a payment from the SPI SDK, a payment method must be created and confirmed via the SDK
    if(paymentOption === PaymentOption.PayNow) {
      if(spiSdk) {
        track('temp_SPI_completing_payment', { paymentType });
        const config = getConfig(tipAmount, fundraisingAmount, giftCardAppliedAmount, toastCashAppliedAmount);
        if(paymentMethod) {
          // the payment method has already been created, so we don't need to create it again
          spiPaymentMethodSuccessCallback(checkoutFormData, giftCardAppliedAmount, toastCashAppliedAmount, validate, onComplete, onCancel, onFailed)(
            {
              content: {
                paymentMethodType: paymentMethod.paymentMethodType,
                billingDetails: paymentMethod.billingDetails,
                paymentMethodId: paymentMethod.paymentMethodId
              }
            }
          );
        } else {
          await spiSdk.createPaymentMethod(
            spiPaymentMethodSuccessCallback(checkoutFormData, giftCardAppliedAmount, toastCashAppliedAmount, validate, onComplete, onCancel, onFailed),
            spiPaymentMethodFailureCallback(onCancel, onFailed),
            config.lineItems, // line items to display on the digital wallet receipt
            surcharges && PaymentType.Credit in surcharges ? surcharges[PaymentType.Credit]?.surchargeAmount : undefined
          );
        }
      } else {
        track('temp_SPI_completing_payment_error_no_sdk', {});
        reportErrorMessageWithData('Error placing order - SPI SDK not loaded');
        onFailed({
          type: PlaceOrderErrorCode.PlaceOrderFailed,
          message: 'We were unable to place your order.'
        });
      }
    } else {
      try {
        await onComplete();
      } catch(e) {
        onFailed(e);
      }
    }
  }, [track,
    paymentOption,
    paymentType,
    spiSdk,
    tipAmount,
    fundraisingAmount,
    getConfig,
    spiPaymentMethodSuccessCallback,
    spiPaymentMethodFailureCallback,
    paymentMethod,
    surcharges]);

  const completeGiftCardPayment = useCallback(async ({ email }: { email: string}, onComplete: any, onCancel: any, onFailed: any) => {
    // When using a payment from the SPI SDK, a payment method must be created and confirmed via the SDK
    if(spiSdk) {
      await spiSdk.createPaymentMethod(
        spiPaymentMethodSuccessCallback({ email }, null, null, null, onComplete, onCancel, onFailed),
        spiPaymentMethodFailureCallback(onCancel, onFailed),
        [{ label: 'Gift card', amount: props.giftCardAmount, id: 'total-order-amount' }] // line items to display on the digital wallet receipt
      );
    } else {
      reportErrorMessageWithData('Error placing order - SPI SDK not loaded');
      onFailed({
        type: PlaceOrderErrorCode.PlaceOrderFailed,
        message: 'We were unable to place your order.'
      });
    }
  }, [spiSdk, spiPaymentMethodSuccessCallback, spiPaymentMethodFailureCallback, props]);

  // this is a temporary fix to show the user a message to remove roundup
  // when surcharging is enabled, roundup is applied, and tip is added
  // due to current bug in surcharging logic
  const showRemoveRoundupTemporaryMessaging = useMemo(() =>
    spiSurchargingEnabled && tipAmount > 0 && fundraisingEnabled && fundraisingAmount > 0,
  [spiSurchargingEnabled, tipAmount, fundraisingAmount, fundraisingEnabled]);

  return (
    <PaymentContext.Provider value={{
      // SPI SDK fields
      createPaymentIntent,
      paymentIntent,
      completePayment,
      completeGiftCardPayment,
      confirmedPayment,
      billingDetails: paymentMethod?.billingDetails,
      userInfoRequired,
      setUserInfoRequired,
      isPaymentValid,
      setIsPaymentValid,
      paymentType,
      setPaymentType: updatePaymentType,
      // Old CC form fields - should be able to clean up or remove eventually
      paymentOption,
      setPaymentOption,
      newCreditCard,
      setNewCreditCard,
      setSaveNewCreditCard: (saveCard: boolean) => setNewCreditCard(old => old ? { ...old, saveCard } : null),
      selectedCreditCard,
      setSelectedCreditCard,
      // Common fields
      tipEnabled,
      tipAmount,
      setTipAmount,
      fundraisingEnabled,
      fundraisingAmount,
      setFundraisingAmount,
      toastCashInfo,
      setToastCashInfo,
      showRemoveRoundupTemporaryMessaging
    }}>
      {props.children}
    </PaymentContext.Provider>);
};

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if(!context) {
    throw new Error('usePayment must be used within a PaymentContextProvider');
  }

  return context;
};

export const useOptionalPayment = () => {
  const context = useContext(PaymentContext);
  return context;
};
