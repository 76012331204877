import { useMemo } from 'react';

import qs from 'qs';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import config from 'config';


export const useTTODeepLink = (orderGuid: string): string | undefined => {
  const { selectedLocation, ooRestaurant } = useRestaurant();
  const restaurantGuid = selectedLocation.externalId;
  const toastOrderHost = config.resources.toastOrderHost;
  const toastwebBaseUri = config.resources.toastwebBaseUri;
  const environment = config.environment;
  const shortUrl = ooRestaurant?.shortUrl;

  return useMemo(() => {
    if(!toastwebBaseUri || !toastOrderHost || !restaurantGuid || !shortUrl) {
      return;
    }
    // Account for preproduction functionality

    const dynamicLinkSubdomain =
      environment === 'production'
        ? 'toasttakeout'
        : 'toasttakeoutpreprod';


    // Deep Link docs: https://firebase.google.com/docs/dynamic-links/create-manually
    const queryString = qs.stringify(
      {
        link: `${toastwebBaseUri}order/confirmation?order_guid=${orderGuid}&restaurant_guid=${restaurantGuid}&notification_type=oov4_cta`,
        apn: 'com.toasttab.consumer',
        utm_source: 'oov4',
        utm_medium: 'web',
        utm_campaign: 'oov4_cta_v1',
        isi: 1362180579,
        ibi: 'com.toasttab.consumer',
        at: '1001lQNI',
        ct: 'oov4_cta_v1',
        mt: 8,
        pt: 1364164,
        efr: 1,
        ofl: `https://${toastOrderHost}/online/${shortUrl}/confirm`
      },
      {
      // ensure proper URI encoding
        encode: true,
        // ensure consistent sort order of keys
        sort(a: string, b: string) {
          const sortOrder = [
            'link',
            'apn',
            'utm_source',
            'utm_medium',
            'utm_campaign'
          ];
          return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        },
        addQueryPrefix: false
      }
    );
    return `https://${dynamicLinkSubdomain}.page.link/?${queryString}`;
  }, [restaurantGuid, shortUrl, toastwebBaseUri, toastOrderHost, orderGuid, environment]);
};
