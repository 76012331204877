import { useCallback, useState } from 'react';

type Props = {
  defaultOpen?: boolean;
}

export const useModal = (props?: Props) => {
  const [isOpen, setIsOpen] = useState(props?.defaultOpen || false);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  return { isOpen, onOpen, onClose };
};
