export const isClient = () => typeof window !== 'undefined';

export const isTouchDevice = () => isClient() && document && 'ontouchstart' in document.documentElement;

export const hasCookies = () => {
  if(isClient()) {
    let cookieEnabled = navigator.cookieEnabled;
    if(!cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') != -1;
    }
    return cookieEnabled;
  }
  return false;
};
