import React, { useCallback, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { useCustomerLoyaltySignupMutation } from 'src/apollo/onlineOrdering';

import { useModal } from 'shared/components/common/modal';

import { ConfirmationCodeModal } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { CompletedOrder } from 'public/components/online_ordering/types';

type Props = {
  completedOrder: CompletedOrder;
  customerGuid?: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerPhone: string;
};

const LoyaltySignup = ({ completedOrder, customerGuid, customerFirstName, customerLastName, customerEmail, customerPhone }: Props) => {
  const { passwordlessLogin, completeSignup } = useCustomer();
  const [customerLoyaltySignupMutation] = useCustomerLoyaltySignupMutation();
  const [signupLoading, setSignupLoading] = useState(false);
  const [signupResult, setSignupResult] = useState('');
  const [signupError, setSignupError] = useState('');

  // confirmation code modal
  const { isOpen, onClose, onOpen } = useModal();

  const signup = useCallback(async (signUpCustomerGuid: string) => {
    setSignupLoading(true);
    setSignupResult('');
    setSignupError('');

    const restaurantGuid = completedOrder.restaurant.guid;
    const checkGuid = completedOrder.checkGuid;

    if(signUpCustomerGuid) {
      const { data } = await customerLoyaltySignupMutation({
        variables: {
          input: {
            loyaltyAccountCreationInput: {
              restaurantGuid,
              transactionGuid: uuid(),
              checkIdentifier: checkGuid
            },
            customerGuid: signUpCustomerGuid
          }
        }
      });

      if(data?.loyaltySignup.__typename === 'LoyaltySignupResponse') {
        setSignupResult('You\'re signed up!');
      } else {
        setSignupError('Sorry, there was an error creating your account');
      }
    }

    setSignupLoading(false);
    onClose();
  }, [completedOrder, customerLoyaltySignupMutation, setSignupLoading, onClose]);

  const onCompleteSignup = useCallback(async (cxGuid: string) => {
    await completeSignup(customerEmail, customerFirstName, customerLastName);
    signup(cxGuid);
  }, [completeSignup, customerEmail, customerFirstName, customerLastName, signup]);

  const pwlessLogin = useCallback(async () => {
    if(await passwordlessLogin(customerPhone)) {
      onOpen();
    } else {
      setSignupError('Sorry, there was an error creating your account');
    }
  }, [customerPhone, passwordlessLogin, onOpen]);

  const buttonOnClick = useCallback(() => customerGuid ? signup(customerGuid) : pwlessLogin(), [customerGuid, signup, pwlessLogin]);

  return (
    <>
      <ConfirmationCodeModal onComplete={onCompleteSignup} phoneNumber={`+1${customerPhone}`} isCheckout={false} onClose={onClose} isOpen={isOpen} sendCode={pwlessLogin} />
      <div className="loyaltySignup">
        {signupResult ?
          <div className="loyaltySuccess">{signupResult}</div> :
          <button className="loyaltyButton" disabled={signupLoading} onClick={buttonOnClick}>Join loyalty program</button>}
      </div>
      {signupError && <div className="loyaltyError">{signupError}</div>}
      <div className="loyaltyNote terms">
        You are agreeing to participate in the rewards program and to be
        contacted through the provided contact information as part of the program. Rewards
        program information is subject to{' '}
        <a
          href="https://pos.toasttab.com/terms-of-service"
          rel="noopener noreferrer"
          target="_blank">
          Toast&apos;s Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://pos.toasttab.com/privacy"
          rel="noopener noreferrer"
          target="_blank">
          Privacy Statement
        </a>
        . Rewards accounts created using phone number enrollment may receive SMS communications.
        SMS message and data rates may apply and message frequency varies. You may reply STOP to opt out.
      </div>
    </>
  );
};

export default LoyaltySignup;
