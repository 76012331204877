import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { RankedPromoOfferDiscount, RankedPromoOffersResponse, useRankedPromoOfferLazyQuery } from 'src/apollo/onlineOrdering';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from './CartContext';

export type OfferMap = {[guid: string]: string[]};

export type OffersContextType = {
  itemOffers: OfferMap,
  groupOffers: OfferMap,
  menuOffers: OfferMap,
  getOffersTextForEntity: (itemGuid?: string | null, groupGuid?: string | null, menuGuid?: string | null) => string[]
  rankedPromoOfferDiscounts?: RankedPromoOfferDiscount[],
  achievedOffers: RankedPromoOfferDiscount[],
  loading: boolean,
  hasAutoAppliedPromo: boolean
}

export const OffersContext = createContext<OffersContextType | undefined>(undefined);

const OffersContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant } = useRestaurant();
  const { applyPromoCode, cart, cartGuid, lastOrderSelectionChange, loadingCart } = useCart();
  const ooClient = useOOClient();
  const lastOrderSelectionChangeRef = useRef<Date | undefined>();
  const hasAttemptedAutoApply = useRef(false);
  const [loading, setLoading] = useState(true);
  const [hasAutoAppliedPromo, setHasAutoAppliedPromo] = useState(false);
  const autoAppliedKey = 'hasAutoAppliedPromo';
  const currentAppliedOffer = useMemo(() => cart?.order?.discounts?.restaurantDiscount?.promoCode, [cart?.order?.discounts?.restaurantDiscount?.promoCode]);


  useEffect(() => {
    if(typeof window !== 'undefined') {
      const lastAutoAppliedPromo = localStorage.getItem(autoAppliedKey);
      if(lastAutoAppliedPromo?.length ?? 0 > 0) {
        setHasAutoAppliedPromo(true);
      }
    }
  }, [autoAppliedKey]);

  useEffect(() => {
    if(hasAutoAppliedPromo) {
      localStorage.setItem(autoAppliedKey, currentAppliedOffer || '');
    }
  }, [currentAppliedOffer, hasAutoAppliedPromo]);

  const clearAutoAppliedPromo = useCallback(() => {
    if(currentAppliedOffer && currentAppliedOffer !== localStorage.getItem(autoAppliedKey)) {
      localStorage.removeItem(autoAppliedKey);
      setHasAutoAppliedPromo(false);
    }
  }, [currentAppliedOffer]);

  const [getPromoOffers, { data: rankedOffers, loading: loadingPromos }] = useRankedPromoOfferLazyQuery({
    fetchPolicy: 'cache-and-network',
    client: ooClient
  });
  useEffect(() => {
    // refresh every time the cart changes
    if(!loadingCart && ooRestaurant?.guid && lastOrderSelectionChange?.getTime() !== lastOrderSelectionChangeRef.current?.getTime()) {
      lastOrderSelectionChangeRef.current = lastOrderSelectionChange;
      hasAttemptedAutoApply.current = false;
      clearAutoAppliedPromo();
      getPromoOffers({ variables: { input: { restaurantGuid: ooRestaurant?.guid || '', requestSource: 'ONLINE_ORDERING', cartGuid: cartGuid } } });
    }
  }, [loadingCart, cart, cartGuid, getPromoOffers, ooRestaurant?.guid, lastOrderSelectionChange, clearAutoAppliedPromo]);

  useEffect(() => {
    if(hasAttemptedAutoApply.current && !loadingPromos) {
      setLoading(false);
      hasAttemptedAutoApply.current = false;
    }
  }, [loadingPromos]);

  const rankedPromoOffers = rankedOffers?.offers.rankedPromoOffers as RankedPromoOffersResponse;
  // a null discount field indicates that the offer pertains only to menu items/groups that are not visible in OO, so we filter them out here
  const rankedPromoOfferDiscounts = rankedPromoOffers?.rankedDiscounts?.filter(discount => discount.discount);

  const autoApplyTopPromo = useCallback(async () => {
    const topDiscount = rankedPromoOfferDiscounts[0];
    const singleUsePromoCode = topDiscount?.discount?.promoCodes && topDiscount.discount.promoCodes.length > 0 && topDiscount?.discount?.promoCodes[0]?.singleUse;
    const bogoAction = topDiscount?.discount?.bogoAction;
    const isItemDiscount = topDiscount?.discount?.selectionType === 'ITEM';
    const applicableItemDiscount = isItemDiscount && !bogoAction && topDiscount?.discountEntitiesClaimed;
    const isDiscountComplete = topDiscount?.percentCompleteInt === 100;
    if(isDiscountComplete && !currentAppliedOffer && !singleUsePromoCode && (isItemDiscount ? bogoAction || applicableItemDiscount : true)) {
      const applyError = await applyPromoCode(topDiscount.promoCode);
      if(!applyError) {
        setHasAutoAppliedPromo(true);
      }
    }
  }, [applyPromoCode, currentAppliedOffer, rankedPromoOfferDiscounts]);

  useEffect(() => {
    if(rankedPromoOfferDiscounts?.length > 0 && hasAttemptedAutoApply.current === false) {
      hasAttemptedAutoApply.current = true;
      autoApplyTopPromo();
    }
  }, [autoApplyTopPromo, rankedPromoOfferDiscounts]);

  useEffect(() => {
    setLoading(rankedPromoOfferDiscounts === undefined);
  }, [setLoading, rankedPromoOfferDiscounts]);

  const getOfferMapForEntity = (entityType: 'items' | 'groups' | 'menus', offers?: RankedPromoOfferDiscount[]) => {
    const offerMap: OfferMap = {};
    offers?.map(discount => {
      const { discount: offer, bannerGuid: offerGuid } = discount;
      const triggerEntityKey = entityType === 'items' ? 'triggerMenuItems' : entityType === 'groups' ? 'triggerMenuGroups' : 'triggerMenus';
      const entities = offer?.amountType === 'BOGO' ? offer?.bogoAction?.[entityType] : offer?.[triggerEntityKey];
      entities?.map(guid => offerMap[guid] = [...offerMap[guid] ?? [], offerGuid]);
    });
    return offerMap;
  };

  const itemOffers = useMemo(() => getOfferMapForEntity('items', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);
  const groupOffers = useMemo(() => getOfferMapForEntity('groups', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);
  const menuOffers = useMemo(() => getOfferMapForEntity('menus', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);

  const getOffersTextForEntity = useCallback((itemGuid?: string | null, groupGuid?: string | null, menuGuid?: string | null) => {
    const offerGuids = [
      ...itemGuid ? itemOffers[itemGuid] ?? [] : [],
      ...groupGuid ? groupOffers[groupGuid] ?? [] : [],
      ...menuGuid ? menuOffers[menuGuid] ?? [] : []
    ];
    return rankedPromoOfferDiscounts?.filter(offer => offerGuids.includes(offer.bannerGuid)).map(offer => offer.bannerText) ?? [];
  }, [itemOffers, groupOffers, menuOffers, rankedPromoOfferDiscounts]);

  const achievedOffers = useMemo(() => rankedPromoOfferDiscounts?.filter(offer => offer.percentCompleteInt >= 100 && offer.discountEntitiesClaimed), [rankedPromoOfferDiscounts]);

  return (
    <OffersContext.Provider value={{
      itemOffers,
      groupOffers,
      menuOffers,
      getOffersTextForEntity,
      rankedPromoOfferDiscounts,
      achievedOffers,
      loading,
      hasAutoAppliedPromo
    }}>
      {props.children}
    </OffersContext.Provider>);
};

export const useOffers = () => {
  const context = useContext(OffersContext);
  if(!context) {
    throw new Error('useOffers must be used within an OffersContextProvider');
  }

  return context;
};

export default OffersContextProvider;
