import React from 'react';

import { CakeIcon, WorkOutlineIcon, GroupIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';

import Image from 'src/shared/components/common/Image';

import DropDown from 'shared/components/common/dropdown';


type Props = {
  formState: object;
  occasion: string;
  setOccasion: (occasion: string) => void;
};

type Option = {
  label: string;
  icon: JSX.Element;
}

export const OPTIONS: Option[] = [
  { label: 'Birthday', icon: <CakeIcon /> },
  { label: 'Anniversary', icon: <Image alt="" src="/icons/anniversary.svg" /> },
  { label: 'Date', icon: <Image alt="" src="/icons/date.svg" /> },
  { label: 'Business', icon: <WorkOutlineIcon /> },
  { label: 'Reunion', icon: <GroupIcon /> },
  { label: 'Celebration', icon: <Image alt="" src="/icons/celebration.svg" /> }
];

const getIconForOccasion = (occasion: string) => {
  const option = OPTIONS.find(opt => opt.label === occasion);
  return option ? option.icon : null;
};

const OccasionSelector = ({ occasion, setOccasion }: Props) => {
  const dropDownLabel =
    <div className="selected">
      {getIconForOccasion(occasion) || <span className="placeholder">Occasion</span>} {occasion}
    </div>;

  return (
    <div className="occasionSelector">
      <div className="dropDownWrapper" >
        <DropDown label={dropDownLabel} withBorder leftAligned>
          {({ close }) =>
            OPTIONS.map(opt =>
              <div
                key={opt.label}
                onClick={() => {
                  close();
                  setOccasion(opt.label);
                }}
                className={classnames('option', { selected: occasion === opt.label })}
                data-testid={`occasion-option-${opt.label}`}>
                {opt.icon} {opt.label}
              </div>) }
        </DropDown>
      </div>
    </div>
  );
};

export default OccasionSelector;
