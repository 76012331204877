import React from 'react';

import { _div, _heading, FieldType, ModuleField, useEditor, ContainsEditableProps } from '@toasttab/sites-components';
import classnames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';

import { CardType, ThemeTypeEnum, Alignment, SectionImageHeight } from 'src/apollo/sites';
import { EditableButton } from 'src/shared/components/common/button';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { getImageModules } from 'shared/components/common/editor_context/editableUtils';

import { InsetTextPositionEnum } from 'public/components/default_template/DefaultTemplateUtils';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';

const ContrastChecker = new ColorContrastChecker();

type Props = {
  theme?: ThemeTypeEnum | null;
  className?: string;
  contentWrapperClassName?: string;
  contentClassName?: string;
  buttonName?: string;
  isHero?: boolean;
} & CardType & EditableProps;

type EditableProps = {
  pathToImageOverride?: string | null;
} & ContainsEditableProps;

export const Card = (props: Props & { editableRef?: React.Ref<HTMLDivElement>}) => {
  const { restaurant: { meta } } = useRestaurant();
  const { theme, className, contentWrapperClassName, contentClassName, image, button, hasShadow, alignment, editableRef } = props;

  const pathToImage = props.pathToImageOverride || `${props.editPath}.image`;
  const pathToImageSrc = `${pathToImage}.src`;
  const pathToImageAlt = props.pathToImageOverride ? undefined : `${pathToImage}.altText`;
  const pathToImageFit = props.pathToImageOverride ? undefined : `${pathToImage}.fit`;
  const pathToImageLink = props.pathToImageOverride ? undefined : `${pathToImage}.link`;

  const hasLowContrast = !ContrastChecker.isLevelAA(meta.textColor || DEFAULT_COLORS.text, meta.primaryColor);

  return (
    <div className={classnames('card', className, theme, { textInset: image?.textInset, hasShadow: hasShadow || theme === ThemeTypeEnum.Rounded && !image })}>
      <div className={classnames('cardWrapper themeElement', theme)} ref={editableRef}>
        {image &&
          <div className="cardImageWrapper">
            <_Image className={classnames('cardImage themeElement', theme, image.height)} src={image.src} alt={image.altText || ''} fit={image.fit} link={image.link}
              editPath={pathToImageSrc} altEditPath={pathToImageAlt} fitEditPath={pathToImageFit} linkEditPath={pathToImageLink} imageObject={image} imageObjectPath={pathToImage} />
          </div>}
        <div className={classnames('cardContentWrapper themeElement', theme, contentWrapperClassName, alignment)}>
          <div className={classnames('cardContent themeElement themeRowPaddedElement', contentClassName, theme, { secondaryTextColor: hasLowContrast })}>
            <CardContent header={props.header} subheader={props.subheader} title={props.title} body={props.body} editPath={props.editPath} isHero={props.isHero} />
            <div className={classnames('cardFooterWrapper', button?.alignment, { alignItems: button?.link && props.footer })}>
              <CardFooter editPath={props.editPath} buttonName={props.buttonName} button={props.button} image={props.image} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditableCard = (props: Props) => {
  const { useEditableRef } = useEditor();
  const { editPath, parentEditPath, image, button, alignment } = props;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'card',
    actions: ['delete'],
    path: editPath,
    parentPath: parentEditPath,
    schema: {
      fields: [
        {
          displayName: 'Button',
          type: FieldType.Boolean,
          path: `${editPath}.button`,
          value: !!button,
          activeValue: { text: 'Button', type: 'secondary', link: '/order' }
        },
        {
          path: `${editPath}.alignment`,
          displayName: 'Card Alignment',
          type: FieldType.Enum,
          value: alignment,
          enums: [
            { displayName: 'Center', value: Alignment.Center },
            { displayName: 'Left', value: Alignment.Left },
            { displayName: 'Right', value: Alignment.Right }
          ]
        },
        ...image ?
          [{
            path: `${editPath}.image.position`,
            displayName: 'Text Position',
            type: FieldType.Enum,
            value: image.position,
            enums: InsetTextPositionEnum
          } as ModuleField,
          {
            path: `${editPath}.image.height`,
            displayName: 'Image Height',
            type: FieldType.Enum,
            value: image.height,
            enums: [
              { displayName: 'Tall', value: SectionImageHeight.Tall },
              { displayName: 'Standard', value: SectionImageHeight.Short }
            ]
          } as ModuleField,
          {
            displayName: 'Text Inset',
            type: FieldType.Boolean,
            path: `${editPath}.image.textInset`,
            value: !!image?.textInset
          },
          ...image.textInset ?
            getImageModules(`${editPath}.image`, image)
            : []] :
          []
      ] as ModuleField[]
    }
  });

  return <Card {...props} editableRef={editableRef} />;
};


type ContentProps = {
  header: string | undefined | null
  subheader: string | undefined | null
  title: string | undefined | null
  body: string | undefined | null
  isHero: boolean | undefined | null
} & ContainsEditableProps

const CardContent = (props: ContentProps) => {
  let pathToHeader = `${props.editPath}.header`;
  const pathToSubheader = `${props.editPath}.subheader`;
  let pathToTitle = `${props.editPath}.title`;
  let pathToBody = `${props.editPath}.body`;
  if(props.isHero) {
    pathToBody = `${props.editPath}.heroBody`;
    pathToTitle = `${props.editPath}.heroTitle`;
    pathToHeader = `${props.editPath}.heroHeader`;
  }

  return props.header || props.subheader || props.title || props.body ?
    <div className="cardText">
      {props.header && <_heading styleLevel={2} className="cardHeader" html={props.header} editPath={pathToHeader} />}
      {props.subheader && <_heading styleLevel={3} className="cardSubheader" html={props.subheader} editPath={pathToSubheader} />}
      {props.title && <_heading styleLevel={2} html={props.title} editPath={pathToTitle} />}
      {props.body && <_div className="cardBody" html={props.body} editPath={pathToBody} />}
    </div> :
    null;
};


type FooterProps = {
  buttonName?: string
} & Pick<CardType, 'image' | 'button' | 'footer' > & ContainsEditableProps

const CardFooter = ({ footer, buttonName, image, button, editPath }: FooterProps) => {
  const pathToButton = `${editPath}.${buttonName || 'button'}`;
  return (
    <>
      {button?.link &&
        <EditableButton
          editPath={pathToButton}
          image={image}
          type={button.type}
          text={button.text}
          link={button.link}
          primaryColor={button.primaryColor}
          alignment={button.alignment}
          textColor={button.textColor} />}
      {footer && <div className="cardFooter">{footer}</div>}
    </>);
};


export default Card;
