import * as Sentry from '@sentry/react';

import { ipapiKey } from 'config';

export const fetchUserLocation = () => {
  // TODO: monitor usage and revert back to fetch('https://ipapi.co/json/')
  // if usage stays well below 30k calls per month
  return fetch(`https://api.ipapi.com/api/check?access_key=${ipapiKey}`)
    .then(res => {
      if(res.status < 400) {
        return res.json();
      } else {
        const error = `Error fetching users location, status: ${res.status}`;
        Sentry.captureException(error);
        throw new Error(error);
      }
    })
    .then(data => {
      // the 'find my location' functionality should narrow down to the city level
      return {
        userStreet: '',
        userNeighborhood: '',
        userCity: data.city,
        userState: data.region_code,
        userLat: data.latitude,
        userLong: data.longitude
      };
    });
};
