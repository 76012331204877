interface LocationParam {
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipcode: string;
}

function normalizeState(state: string): string {
  // "CN" is International Canada
  // "XX" is Unknown/Unspecified State
  // See https://github.toasttab.com/toasttab/toastweb/blob/9ee4734a94697ad5b63bde8db2545f3b7d34267f/app/toast/Constants.java#L202-L207
  return ['CN', 'XX'].includes(state) ? '' : state;
}

export function formatRestaurantAddress(location: LocationParam): string {
  const normalizedState = normalizeState(location.state);
  const addressParts = [location.address1, location.address2, location.city, normalizedState];
  return `${addressParts.filter(Boolean).join(', ')} ${location.zipcode}`;
}

export function generateRestaurantAddressQuery(restaurantName: string, location: LocationParam): string {
  const formattedAddress = formatRestaurantAddress(location);
  return new URLSearchParams({ q: `${restaurantName}, ${formattedAddress}` }).toString();
}
