import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { usePayPalScriptReducer, SCRIPT_LOADING_STATE, DISPATCH_ACTION, FUNDING, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PaymentOption } from 'public/components/online_ordering/PaymentContext';

import config from 'config';

import { AlternativePaymentMethods } from './alternativePayment/alternativePaymentConstants';

enum PaypalFeatureFlag {
  Paypal = 'paypal',
  Venmo = 'venmo',
  PaypalAndVenmo = 'paypal_venmo',
  Off = 'off'
}

export const PaymentOptionToPayPalFundingSourceMap = {
  [PaymentOption.Paypal]: FUNDING.PAYPAL,
  [PaymentOption.Venmo]: FUNDING.VENMO
};

export const PaymentOptionToAlternativePaymentMap = {
  [PaymentOption.Paypal]: AlternativePaymentMethods.PAYPAL,
  [PaymentOption.Venmo]: AlternativePaymentMethods.VENMO
};

export const PayPalProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <PayPalScriptProvider deferLoading options={{
      clientId: config.paypalClientId,
      enableFunding: 'venmo',
      components: 'buttons,marks'
    }}>
      {children}
    </PayPalScriptProvider>
  );
};

export const usePaypal = () => {
  const { altpmPaypalVenmo } = useFlags();
  const [{ isInitial, isPending }, paypalScriptDispatch] = usePayPalScriptReducer();
  const isPaypalFeatureFlagLoaded = !!altpmPaypalVenmo;
  const isPaypalOrVenmoEnabled = altpmPaypalVenmo !== PaypalFeatureFlag.Off;

  useEffect(() => {
    // if the feature flag is enabled, load the PayPal script.
    if(isPaypalFeatureFlagLoaded && isPaypalOrVenmoEnabled) {
      paypalScriptDispatch({
        type: DISPATCH_ACTION.LOADING_STATUS,
        value: SCRIPT_LOADING_STATE.PENDING
      });
    }
  }, [isPaypalFeatureFlagLoaded, isPaypalOrVenmoEnabled, paypalScriptDispatch]);

  return {
    isPaypalFeatureFlagLoaded,
    isPaypalScriptLoading: !isPaypalFeatureFlagLoaded || isPaypalOrVenmoEnabled && (isInitial || isPending),
    isPaypalOrVenmoEnabled: isPaypalFeatureFlagLoaded && isPaypalOrVenmoEnabled,
    enabledPayPalPaymentOptions: useMemo(() => getPaymentOptions(altpmPaypalVenmo), [altpmPaypalVenmo])
  };
};


const getPaymentOptions = (paypalFeatureFlag: PaypalFeatureFlag): Array<PaymentOption.Paypal | PaymentOption.Venmo> => {
  switch(paypalFeatureFlag) {
    case PaypalFeatureFlag.PaypalAndVenmo:
      return [PaymentOption.Paypal, PaymentOption.Venmo];
    case PaypalFeatureFlag.Paypal:
      return [PaymentOption.Paypal];
    case PaypalFeatureFlag.Venmo:
      return [PaymentOption.Venmo];
    default:
      return [];
  }
};
