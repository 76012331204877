import React from 'react';

import { ModalCloseButton, ModalContent } from 'src/shared/components/common/modal';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

type LogoutProps = {
  error?: boolean
}

export const Logout = ({ error }: LogoutProps) => {
  return (
    <ModalContent contentClassName="logoutModalContent" disableFocusTrap>
      <div className="header" data-testid="Logout">
        <div className="headerText">{!error ? 'Logging out...' : 'Log out failed'}</div>
        <div className="headerClose"><ModalCloseButton /></div>
      </div>
      <div className="body">
        {!error && <LoadingSpinnerOverlay withBorderRadius />}
        {error && <p>Something went wrong, please try again later</p>}
      </div>
    </ModalContent>
  );
};
