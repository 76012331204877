import React, { useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';
import InputMessage from 'shared/components/common/form_input/InputMessage';

type Props = {
  id: string;
  label?: string;
  button?: React.ReactElement;
  buttonOnClick?: () => void;
  filled?: boolean;
  required?: boolean;
  validate?: (value: string) => boolean | string | undefined;
  warn?: (value: string) => string | undefined;
  warning?: string;
  focusMessage?: string;
  children?: React.ReactElement;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormInput = (props: Props) => {
  const { id, label, button, buttonOnClick, filled, required, validate, warn, warning, focusMessage, ...rest } = props;
  const { register, watch, formState: { errors } } = useFormContext();
  const prefixRef = useRef<HTMLSpanElement>(null);
  const inputValue = watch(id);
  const [focus, setFocus] = useState(false);
  const autocomplete = (id: string) => {
    switch(id) {
      case 'firstName':
        return 'given-name';
      case 'lastName':
        return 'family-name';
      case 'emailAddress':
        return 'email';
      default:
        return '';
    }
  };
  const keyPressed = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.keyCode === 13) {
      if(button && buttonOnClick) {
        buttonOnClick();
      }
      event.stopPropagation();
      event.preventDefault();
    }
  }, [button, buttonOnClick]);
  const sanitizedId = id.replace(/'|"|[|]/g, '.');
  return (
    <div className={classnames('formInput', errors[id] && 'error', (filled || inputValue) && 'filled')} data-testid={`form-input-${sanitizedId}`}>
      <div className="inputWrapper">
        <div className={classnames('inputElementWrapper', { hasPrefix: !!props.prefix })}>
          {props.prefix ? <span className="prefix" ref={prefixRef}>{props.prefix}</span> : null}
          {props.children ?
            props.children :
            <input
              id={sanitizedId}
              aria-required={required}
              aria-describedby={`${sanitizedId}-input-message`}
              data-testid={`input-${sanitizedId}`}
              onKeyDown={keyPressed}
              autoComplete={autocomplete(sanitizedId)}
              onFocus={() => setFocus(true)}
              {...register(sanitizedId, { required: required ? 'required' : false, validate, onBlur: () => setFocus(false) })}
              {...rest} />}
          <label style={{ left: prefixRef?.current?.clientWidth ? prefixRef.current.clientWidth + 4 : undefined }} htmlFor={sanitizedId} data-testid="form-input-label">{label}
            <span aria-hidden="true">{required ? '*' : ''}</span>
          </label>
        </div>
        {button}
        {errors[id] && <Image alt="Warning icon" aria-hidden="true" src="icons/warning-red.svg" />}
      </div>
      <InputMessage id={sanitizedId} warn={warn} warning={warning} focus={focus} focusMessage={focusMessage} />
    </div>
  );
};

export default FormInput;
