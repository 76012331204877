import React from 'react';

import classnames from 'classnames';

import Link from 'src/shared/components/common/link';

import Image from 'shared/components/common/Image';

type Props = {
  title: string;
  linkToURL: string;
  className?: string | null;
  imgUrl?: string | null;
};

const CarouselListing = ({ title, linkToURL, className, imgUrl }: Props) => {
  return (
    <div className={classnames('carousel-listing', className)}>
      <Link href={linkToURL}>
        <div className="listing-image-container">
          {imgUrl && <Image alt="" className="listing-image" cdnHost="https://d2s742iet3d3t1.cloudfront.net" src={imgUrl}></Image>}
        </div>
        <div className="listing-content">
          <div className="first-line">
            <span className="rx-name">{title}</span>
          </div>
          <div className="view-rx">
            View restaurant
            <Image src="/icons/arrow-right-gray.svg" alt="next" height={18} className="icon-img" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CarouselListing;
