import React, { useMemo } from 'react';

import { QueryLazyOptions } from '@apollo/client';

import {
  Exact, ReservationAvailabilitiesQuery, ReservationHoursQuery,
  ReservationRestaurantQuery,
  useReservationAvailabilitiesLazyQuery,
  useReservationHoursLazyQuery,
  useReservationRestaurantLazyQuery
} from 'src/apollo/sites';

import { RestaurantLocation, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export type ReservationContextType = {
  availHours: ReservationAvailabilitiesQuery | undefined,
  availabilitiesLoading: boolean,
  bookingMinHoursInAdvance: number,
  bookingMaxHoursInAdvance: number,
  datesLoading: boolean,
  getReservationAvailabilitiesHours: (options?: (QueryLazyOptions<Exact<{ restaurantGuid: string, dateTime: string, partySize: number }>> | undefined)) => void
  getReservationHours: (options?: (QueryLazyOptions<Exact<{ restaurantGuid: string, date: string }>> | undefined)) => void
  getReservationRestaurant: (options?: (QueryLazyOptions<Exact<{ restaurantGuid: string }>> | undefined)) => void
  hours: ReservationHoursQuery | undefined,
  locations?: RestaurantLocation[]|null,
  loyaltySignupUrl: string | null | undefined,
  minPartySize: number,
  maxPartySize: number,
  reservationPolicy: string | null | undefined,
  reservationRestaurantLoading:boolean,
  selectedLocation: RestaurantLocation,
  timezone: string
}

type ReservationContextProviderProps = {
  children: React.ReactNode
}

const destructureReservationData = (data: ReservationRestaurantQuery|undefined): {
  bookingMinHoursInAdvance: number,
  bookingMaxHoursInAdvance: number,
  loyaltySignupUrl: string | null | undefined
  maxPartySize: number,
  minPartySize: number,
  reservationPolicy: string | null | undefined,
  timezone: string
} => {
  const BOOKING_MIN_HOURS_IN_ADVANCE_DEFAULT = 2;
  const BOOKING_MAX_HOURS_IN_ADVANCE_DEFAULT = 720;

  return {
    bookingMaxHoursInAdvance: data?.reservationRestaurant?.bookingMaxHoursInAdvance ?? BOOKING_MAX_HOURS_IN_ADVANCE_DEFAULT,
    bookingMinHoursInAdvance: data?.reservationRestaurant?.bookingMinHoursInAdvance ?? BOOKING_MIN_HOURS_IN_ADVANCE_DEFAULT,
    loyaltySignupUrl: data?.reservationRestaurant?.loyaltySignupUrl,
    maxPartySize: data?.reservationRestaurant?.maxPartySize || 10,
    minPartySize: data?.reservationRestaurant?.minPartySize || 1,
    reservationPolicy: data?.reservationRestaurant?.reservationPolicy,
    timezone: data?.reservationRestaurant?.timezone || ''
  };
};

export const ReservationContext = React.createContext<ReservationContextType | undefined>(undefined);

export const ReservationContextProvider = (props: React.PropsWithChildren<ReservationContextProviderProps>) => {
  const { locations, selectedLocation } = useRestaurant();
  const [getReservationHours, { data: hours, loading: datesLoading }] = useReservationHoursLazyQuery();
  const [getReservationAvailabilitiesHours, { data: availHours, loading: availabilitiesLoading }] = useReservationAvailabilitiesLazyQuery();
  const [getReservationRestaurant, { data: reservationData, loading: reservationRestaurantLoading }] = useReservationRestaurantLazyQuery();

  const {
    bookingMinHoursInAdvance,
    bookingMaxHoursInAdvance,
    loyaltySignupUrl,
    maxPartySize,
    minPartySize,
    reservationPolicy,
    timezone
  } = destructureReservationData(reservationData);

  const contextValues = useMemo(() => ({
    availHours,
    availabilitiesLoading,
    hours,
    locations,
    loyaltySignupUrl,
    datesLoading,
    maxPartySize,
    minPartySize,
    timezone,
    bookingMinHoursInAdvance,
    bookingMaxHoursInAdvance,
    selectedLocation,
    reservationPolicy,
    reservationRestaurantLoading,
    getReservationHours,
    getReservationAvailabilitiesHours,
    getReservationRestaurant
  }), [
    availHours,
    availabilitiesLoading,
    hours,
    locations,
    loyaltySignupUrl,
    datesLoading,
    maxPartySize,
    minPartySize,
    timezone,
    bookingMinHoursInAdvance,
    bookingMaxHoursInAdvance,
    selectedLocation,
    reservationPolicy,
    reservationRestaurantLoading,
    getReservationHours,
    getReservationAvailabilitiesHours,
    getReservationRestaurant
  ]);

  return (
    <ReservationContext.Provider value={contextValues}>
      {props.children}
    </ReservationContext.Provider>
  );
};


export const useReservationContext = () => {
  const context = React.useContext(ReservationContext);
  if(context === undefined) {
    throw new Error('useReservationContext must be used within a ReservationContextProvider');
  }
  return context;
};
