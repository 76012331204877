import { utcToZonedTime, format as formatTz } from 'date-fns-tz';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { FulfillmentType } from 'src/apollo/onlineOrdering';

export const isToday = (dateObj: Date) => {
  const today = new Date();
  return dateObj.getMonth() === today.getMonth() && dateObj.getDate() === today.getDate();
};

export const isTomorrow = (dateObj: Date) => {
  const today = new Date();
  return dateObj.getMonth() === today.getMonth() && dateObj.getDate() === today.getDate() + 1;
};

const dateToDayString = (dateObj: Date): string => {
  return isToday(dateObj) ? 'Today' : isTomorrow(dateObj) ? 'Tomorrow' : format(dateObj, 'EEE, M/d');
};

export const toDayString = (date: string): string => dateToDayString(parseISO(date));

export const toLocalTime = (date: string, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string => {
  const dateObj = parseISO(date);
  const zonedDate = utcToZonedTime(dateObj, tz);
  return formatTz(zonedDate, 'h:mm aa z', { timeZone: tz });
};

export const toLocalDateTime = (dateObj: Date, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone, showTimezone: boolean = true): string => {
  const zonedDate = utcToZonedTime(dateObj, tz);
  const formatStr = showTimezone ? 'h:mm aa z' : 'h:mm aa';
  return dateToDayString(zonedDate) + ', ' + formatTz(zonedDate, formatStr, { timeZone: tz });
};

export const toFutureString = (date: string, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string => {
  const dateObj = parseISO(date);
  const zonedDate = utcToZonedTime(dateObj, tz);

  // eslint-disable-next-line quotes
  return formatTz(zonedDate, "EEE, M/d 'at' h:mm aa z", { timeZone: tz });
};

export const formatMDY = (date: Date) => format(date, 'MM/dd/yyyy');

export const getASAPText = (fulfillmentType: FulfillmentType | undefined, parsedTime: Date | null, minimumTime: number | null): string => {
  if(fulfillmentType === FulfillmentType.Future && parsedTime) {
    return toLocalDateTime(parsedTime);
  } else {
    if(minimumTime !== null && minimumTime !== undefined) {
      const fulfillmentDate = new Date();
      if(shouldUseDateString(minimumTime, fulfillmentDate)) {
        return `ASAP (${toLocalDateTime(fulfillmentDate)})`;
      } else {
        return `ASAP (${minimumTime}-${minimumTime + 5} minutes)`;
      }
    }
    return 'ASAP';
  }
};

export const getDiningBehaviorToggleLabel = (minimumTime: number): string => {
  if(minimumTime !== null) {
    const fulfillmentDate = new Date();
    if(shouldUseDateString(minimumTime, fulfillmentDate)) {
      return `${toLocalDateTime(fulfillmentDate, undefined, false)}`;
    }
  }
  return `${minimumTime} - ${minimumTime + 5} min`;
};
// Returns true if the minimum time is more than 60 and the new fulfillment date is not the same as the current day
const shouldUseDateString = (minimumTime: number, fulfillmentDate: Date): boolean => {
  const currentDay = fulfillmentDate.getDate();
  fulfillmentDate.setHours(fulfillmentDate.getHours(), fulfillmentDate.getMinutes() + minimumTime);
  return minimumTime > 60 && currentDay !== fulfillmentDate.getDate();
};
