import React, { useMemo } from 'react';

import {
  PaymentType,
  DiningOptionBehavior,
  LoyaltyInquiryResponse,
  useCustomerLoyaltyInfoQuery
} from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import LoyaltySignup from 'public/components/default_template/online_ordering/confirmation/LoyaltySignup';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useCompletedOrder } from 'public/components/online_ordering/OrderContext';
import { CompletedOrder } from 'public/components/online_ordering/types';

const LoyaltyMessage = ({ message }: { message: string }) =>
  <div className="loyaltyMessageWrapper">
    <Image alt="Loyalty reward" src="icons/loyalty-reward.svg" />
    <div className="loyaltyMessage">{message}</div>
  </div>;


const LoyaltyNote = ({ order }: { order: CompletedOrder }) =>
  <div className="loyaltyNote">
    You&apos;ll earn toward rewards when this order is
    {order.diningOptionBehavior === DiningOptionBehavior.Delivery ? ' delivered' : ' picked up'}.
    {order.paymentType !== PaymentType.Cash && <><br />Check your email receipt for more details.</>}
  </div>;

const LoyaltySection = ({ order, customerGuid }: { order: CompletedOrder, customerGuid?: string }) => {
  const { data, loading } = useCustomerLoyaltyInfoQuery({
    variables: { input: { restaurantGuid: order.restaurant.guid } },
    skip: !customerGuid,
    fetchPolicy: 'network-only'
  });
  const cxLoyaltyData = data?.customerLoyaltyInfo as LoyaltyInquiryResponse;
  const loyaltyMessage = cxLoyaltyData?.currentState?.messages?.balance;

  const signupSection = useMemo(() => {
    return !!order.customerV3 &&
    !!order.customerV3.email &&
    !!order.customerV3.phone &&
    !!order.customerV3.firstName &&
    !!order.customerV3.lastName
      ?
      <>
        <LoyaltyMessage message="Don't miss out on rewards" />
        <LoyaltySignup
          completedOrder={order}
          customerGuid={customerGuid}
          customerFirstName={order.customerV3.firstName}
          customerLastName={order.customerV3.lastName}
          customerEmail={order.customerV3.email}
          customerPhone={order.customerV3.phone} />
      </>
      : null;
  }, [customerGuid, order]);


  return loading ?
    <LoadingSpinnerOverlay /> :
    loyaltyMessage ?
      <>
        <LoyaltyMessage message={loyaltyMessage} />
        {order.hasLoyaltyAttached && <LoyaltyNote order={order} />}
      </> :
      <>
        {order.hasLoyaltyAttached ?
          <>
            <LoyaltyMessage message="You're earning rewards" />
            <LoyaltyNote order={order} />
          </> :
          signupSection}
      </>;
};

const LoyaltySectionWrapper = () => {
  const { customer } = useCustomer();
  const { completedOrder, loadingCompletedOrder } = useCompletedOrder();

  if(loadingCompletedOrder) {
    return (
      <div className="loyaltySection" data-testid="loyalty-section-loading">
        <LoadingSpinnerOverlay />
      </div>
    );
  }

  if(!completedOrder) {
    return null;
  }

  return (
    <div className="loyaltySection" data-testid="loyalty-section-wrapper">
      <div className="loyaltyTitle">{completedOrder.restaurant.loyaltyConfig.programName || 'Rewards'}</div>
      <LoyaltySection order={completedOrder} customerGuid={customer?.id} />
    </div>
  );
};

export default LoyaltySectionWrapper;
