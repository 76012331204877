import React from 'react';
import { Helmet } from 'react-helmet-async';

import { resources } from 'config';

import useStructuredData from './hook';

type TestProps = {
  testId?: string
}

/**
 * Renders the primary schema.org "Thing". Use this Consumer to showcase the Thing on the page using Schema.org syntax.
 * If multiple components mount a "Thing" to the schema, then only the most-recently mounted will be rendered. As components dismount,
 * they dismount their schema as well.
 * @returns A schema.org compliant JSON+LD script tag or null when this Consumer is used outside its context.
 */
const Consumer = (props: TestProps) => {
  const ctx = useStructuredData();
  const schema = ctx?.primarySchema;
  if(!schema || resources.schemaHostBlocklist.includes(ctx.hostname)) {
    return null;
  }
  return <Helmet><script type="application/ld+json" data-testid={props.testId}>{JSON.stringify(schema)}</script></Helmet>;
};

export default Consumer;
