import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import isURL from 'validator/lib/isURL';

import { RequestContext } from 'src/lib/js/context';


const DEFAULT_REDIRECT_STATUS = 301;

type Props = {
  from?: string;
  to: string;
  status?: number;
}

export const RedirectWithStatus = ({ from, to, status }: Props) =>
  <Route render={({ staticContext }) => {
    // There is no `staticContext` on the client, so we need to guard against that here
    if(staticContext) {
      staticContext.statusCode = status || DEFAULT_REDIRECT_STATUS;
      if(isURL(to)) {
        const routeContext = staticContext as RequestContext;
        routeContext.url = to;
      }
    }

    return <Redirect from={from} to={to} />;
  }} />;

export default RedirectWithStatus;
