import React, { useEffect } from 'react';

import { ContainsEditableProps, useEditor, FieldType } from '@toasttab/sites-components';

import { SectionBackgroundSize, SectionImagePosition, ThemeTypeEnum, Link as LinkType, Image as ImageObject, PaddingEnum, BackgroundImage } from 'src/apollo/sites';

import { getThemeModule, getImageModules, getSectionPaddingModule, getBackgroundColorOrImageModule } from 'shared/components/common/editor_context/editableUtils';

import { InsetTextPositionEnum } from 'public/components/default_template/DefaultTemplateUtils';
import PositionedCard from 'public/components/default_template/cards/PositionedCard';

import HeroWrapper from './HeroWrapper';

type Props = {
  isFirstSection?: boolean;
  theme?: ThemeTypeEnum | null;
  imageObject?: ImageObject | null;
  imageObjectPath?: string | null;
  header?: string | null;
  title?: string | null;
  body?: string | null;
  button?: LinkType | null;
  imgPosition?: SectionImagePosition | null;
  textWithBackground?: boolean | null;
  textBackgroundSize?: SectionBackgroundSize | null;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

const CardHero = (props: Props) => {
  const { useEditableRef, isEditor } = useEditor();
  const {
    editPath, isFirstSection, theme, imageObject, imageObjectPath, header, title, body, button,
    imgPosition, textWithBackground, textBackgroundSize, backgroundColor, backgroundImage, padding = PaddingEnum.Medium
  } = props;

  const { editableRef, pushSchema } = useEditableRef<HTMLDivElement>({
    name: 'hero section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
        getThemeModule(editPath, theme),
        {
          path: `${editPath}.heroButton`,
          displayName: 'Button',
          type: FieldType.Boolean,
          value: !!button,
          activeValue: { text: 'Button', type: textWithBackground ? 'secondary' : 'primary', link: '/' }
        },
        {
          path: `${editPath}.imgPosition`,
          displayName: 'Text Position',
          type: FieldType.Enum,
          value: imgPosition,
          enums: InsetTextPositionEnum
        },
        {
          path: `${editPath}.textWithBackground`,
          displayName: 'Text Background',
          type: FieldType.Boolean,
          value: textWithBackground
        },
        ... textWithBackground ?
          [{
            path: `${editPath}.textBackgroundSize`,
            displayName: 'Text Background Size',
            type: FieldType.Enum,
            value: textBackgroundSize,
            enums: [
              { displayName: 'Small', value: 'small' },
              { displayName: 'Large', value: 'large' }
            ]
          }]
          : [],
        ...imageObjectPath && imageObject ?
          getImageModules(imageObjectPath, imageObject)
          : []
      ]
    }
  });

  useEffect(() => {
    // resubmit the changed schema when textWithBackground setting changes.
    pushSchema();
  }, [pushSchema, textWithBackground]);

  if(!imageObject) {
    if(isEditor) {
      return (
        <HeroWrapper editableRef={editableRef} className={'emptySection'} />
      );
    }
    return null;
  }
  const { __typename, ...cardImage } = imageObject;

  return (
    <HeroWrapper theme={theme} imgPosition={imgPosition} textWithBackground={textWithBackground} editableRef={editableRef}>
      <PositionedCard
        editPath={editPath}
        theme={theme}
        card={
          {
            image: cardImage,
            header, title, body, button
          }
        }
        imgPosition={imgPosition}
        textWithBackground={textWithBackground}
        textBackgroundSize={textBackgroundSize}
        isFirstSection={isFirstSection}
        buttonName="heroButton"
        isHero={true}
        pathToImageOverride={imageObjectPath} />
    </HeroWrapper>
  );
};

export default CardHero;
