type RGB = {
  r: number,
  g: number,
  b: number
}

export type Hex = `#${string}`;

// Assuming regular text; for large text, replace 4.5 with 3
const MINIMUM_CONTRAST = 4.5;

export const getTagColor = (bgColor: Hex | string, primaryColor?: Hex | string, modifierFactor: number = 70): Hex => {
  let color = bgColor;
  // When the background is white and there's a primary color defined, we want to use the primary color as the tag color.
  if(primaryColor !== undefined && bgColor.toLowerCase() === '#ffffff') {
    return primaryColor as Hex;
  }
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  let modifier = luminance > 0.5 ? -modifierFactor : modifierFactor;

  r = Math.max(0, Math.min(255, r + modifier));
  g = Math.max(0, Math.min(255, g + modifier));
  b = Math.max(0, Math.min(255, b + modifier));

  return rgbToHex({ r, g, b });
};

export const darkenByModifier = (color: Hex, modifierFactor: number) => {
  let { r, g, b } = hexToRgb(color);
  r = Math.max(0, Math.min(255, r - modifierFactor));
  g = Math.max(0, Math.min(255, g - modifierFactor));
  b = Math.max(0, Math.min(255, b - modifierFactor));
  return rgbToHex({ r, g, b });
};

const rgbToHex = (rgb: RGB): Hex => {
  const { r, g, b } = rgb;
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const hexToRgb = (hex: Hex): RGB => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

const relativeLuminance = (rgb: RGB): number => {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

const contrastRatio = (rgb1: RGB, rgb2: RGB): number => {
  const luminance1 = relativeLuminance(rgb1) + 0.05; // add 0.05 per WCAG formula
  const luminance2 = relativeLuminance(rgb2) + 0.05; // add 0.05 per WCAG formula

  return luminance1 > luminance2
    ? luminance1 / luminance2
    : luminance2 / luminance1;
};

export const generateTextColor = (hex: Hex): Hex => {
  const bgColor = hexToRgb(hex);
  const contrastWithWhite = contrastRatio(bgColor, { r: 255, g: 255, b: 255 });
  const contrastWithBlack = contrastRatio(bgColor, { r: 0, g: 0, b: 0 });

  return contrastWithWhite >= MINIMUM_CONTRAST && contrastWithWhite > contrastWithBlack ? '#ffffff' : '#000000';
};

export const isContrastCompliant = (foregroundColor: Hex, backgroundColor: Hex) => {
  const contrast = contrastRatio(hexToRgb(foregroundColor), hexToRgb(backgroundColor));
  return contrast >= MINIMUM_CONTRAST;
};
