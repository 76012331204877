import React, { useMemo } from 'react';

import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { MarkerStyle } from 'public/components/google_maps/Map';

import { server } from 'config';


type Props = {
  allowFulfillment?: boolean;
  className?: string;
  selectedDiningOption?: DiningOptionBehavior;
  sourceId?: string;
  hideDefaultLocation?: boolean;
  markerStyle?: MarkerStyle;
}

const LocationMap = (props: Props) => {
  const { selectedLocation, locations } = useRestaurant();
  const guids = useMemo(() => locations?.map(loc => `guids=${loc.externalId}`).join('&'), [locations]);
  const shortUrl = selectedLocation.shortUrl ? encodeURIComponent(selectedLocation.shortUrl) : undefined;

  const src = useMemo(() =>
    `${server.protocol}://${server.fullHost}/sites-web/v1/map?shortUrl=${shortUrl}&sourceId=${props.sourceId || ''}&order=${props.allowFulfillment ? 'true' : ''}` +
    `&${guids}&selectedGuid=${!props.hideDefaultLocation ? selectedLocation.externalId : ''}&selectedDiningOption=${props.selectedDiningOption || ''}&style=${props.markerStyle || ''}`,
  [props, guids, selectedLocation, shortUrl]);

  return (
    <iframe title="Google Maps" width="100%" height="100%" loading="eager"
      className={classnames('locationMap', props.className)}
      src={src}>
    </iframe>
  );
};

export default LocationMap;
