import React, { ReactNode, useState } from 'react';

import { ToastLogo } from '@toasttab/buffet-pui-toast-logo';
import classnames from 'classnames';

import { useDebug } from 'src/shared/components/common/debug/DebugContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import Image from 'shared/components/common/Image';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'shared/components/common/modal';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import { MenuConfigDebugForm } from './MenuConfigDebugForm';
import { MetaDebugForm } from './MetaDebugForm';
import { OORestaurantConfigForm } from './OORestaurantConfigDebugForm';


export const DebugPanel = ({ menuPage } : {menuPage?: boolean}) => {
  const [debugPanelOpen, setDebugPanelOpen] = useState(false);
  const { restaurant } = useRestaurant();
  const { customer } = useCustomer();
  const { cartGuid } = useCart();
  const { debugModeEnabled } = useDebug();

  if(process.env.NODE_ENV === 'production' || !debugModeEnabled) {
    return null;
  }
  return (
    <div className="debugPanelButton">
      <ToastLogo className="logo" aria-label="Open debug panel" onClick={() => setDebugPanelOpen(!debugPanelOpen)} />
      {debugPanelOpen &&
        <Modal isOpen={debugPanelOpen} onClose={() => setDebugPanelOpen(false)} className="debugPanelContainer">
          <ModalOverlay />
          <ModalContent wrapperClassName="wrapper" contentClassName="content">
            <div className="header">
              Debug Panel
              <ModalCloseButton className="closeButton" />
            </div>
            <div className="separator">
              <div style={{ height: '4px', background: 'linearGradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0))' }}></div>
            </div>
            <div className="body">
              <ul>
                <li><b>Customer GUID:</b> {customer?.id}</li>
                <li><b>Cart GUID: </b>{cartGuid}</li>
                <li><b>Restaurant GUID:</b> {restaurant.id}</li>
                <li><b>Site GUID: </b>{restaurant.externalRef}</li>
              </ul>
              <MetaDebugForm />
              <MenuConfigDebugForm menuPage={menuPage ?? false} />
              <OORestaurantConfigForm />
            </div>
          </ModalContent>
        </Modal>}
    </div>
  );
};

export const ExpandableForm = ({ title, children }: {title?: string, children: ReactNode}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="formContainer">
      <div className="formHeader">
        <div className="formTitle">{title ?? 'Config'}</div>
        <Image
          onClick={() => setExpanded(!expanded)}
          alt={`${expanded ? 'Collapse' : 'Expand'} section`}
          className={classnames('expandButton', expanded ? 'rotatedUp' : 'rotatedDown')}
          src="icons/chevron-right-gray.svg" />
      </div>
      <AnimatedSection expanded={expanded}>
        {children}
      </AnimatedSection>
    </div>
  );
};
