import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import PageWrapper from 'public/components/default_template/PageWrapper';
import Account from 'public/components/default_template/online_ordering/account/Account';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { PaymentContextProvider } from 'public/components/online_ordering/PaymentContext';

export const AccountPageContent = ({ nav, footer } : { nav: React.ReactNode, footer?: React.ReactNode }) =>
  <div className="defaultTemplate">
    {nav}
    <div className="paddedSection">
      <Account />
    </div>
    {footer}
  </div>;

export const WrappedAccountPage = () => <PageWrapper Component={AccountPageContent} shouldShowPreviewBanner={true} />;

export const ContextualAccountPage = ({ children }: React.PropsWithChildren<{}>) => {
  const ooClient = useOOClient();

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider>
        <PaymentContextProvider>
          {children}
        </PaymentContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};

/**
 * Wraps an Account Page with the Context required outside of a restaurant context
 */
export const RestaurantlessContextualAccountPage = ({ children }: React.PropsWithChildren<{}>) => {
  const ooClient = useOOClient();

  return (
    <ApolloProvider client={ooClient}>
      {children}
    </ApolloProvider>
  );
};

const AccountPage = () => <ContextualAccountPage><WrappedAccountPage /></ContextualAccountPage>;

export default AccountPage;
