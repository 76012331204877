import React from 'react';

import { getCreditCardImage } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';

type SavedCreditCardProps = {
  maskedPan: string,
  expirationMonth?: string | null,
  expirationYear?: string | null,
  cardType?: string
};

export const SavedCreditCard = ({ maskedPan, expirationMonth, expirationYear, cardType }: SavedCreditCardProps) => {
  return (
    <div className="savedCardLabel">
      {getCreditCardImage(cardType)}
      <div>{maskedPan.replaceAll('X', '•').replace('-', ' ')}</div>
      <div className="expiration">Exp. {expirationMonth}/{expirationYear}</div>
    </div>
  );
};
