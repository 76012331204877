import React from 'react';
import { useLocation, withRouter } from 'react-router';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { RestaurantDataFragment, Theme } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';

import Meta from 'public/components/default_template/meta/Meta';
import Map from 'public/components/google_maps/Map';

type Props = RequestContextProps & {
  color?: string | null;
  siteTheme?: Partial<RestaurantDataFragment['theme']> | Partial<Theme> | null | undefined
};

export const MapPage = ({ staticContext, color, siteTheme }: Partial<Props>) => {
  // @ts-ignore
  const referrer = typeof window !== 'undefined' ? window.__REFERRER__ : staticContext?.req?.headers.referer;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const selectedGuid = params.get('selectedGuid');
  const selectedDiningOptionParam = params.get('selectedDiningOption');
  const style = params.get('style');
  const locationGuids = params.getAll('guids');
  const order = !!params.get('order');
  const sourceId = params.get('sourceId');
  if(!locationGuids?.length || !referrer) {
    if(staticContext) {
      staticContext.statusCode = 400;
    }

    return null;
  }

  const selectedDiningOption =
    selectedDiningOptionParam ?
      Object.values(DiningOptionBehavior).find(opt => opt.toString() === selectedDiningOptionParam) :
      undefined;

  return (
    <>
      <Meta primaryColor={color ?? undefined} icon="" domain="" siteTheme={siteTheme} />
      <Map
        markerStyle={style}
        sourceId={sourceId}
        useOrderFulfillment={order}
        targetOrigin={new URL(referrer).origin}
        locationGuids={locationGuids}
        selectedGuid={selectedGuid}
        color={color ?? undefined}
        selectedDiningOption={selectedDiningOption} />
    </>
  );
};

export default withRouter<Props, React.ComponentType<Props>>(MapPage);
