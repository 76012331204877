import { useCallback } from 'react';

import { isNil } from 'lodash';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import { sumByField } from 'public/components/online_ordering/reducerUtils';
import { Cart } from 'public/components/online_ordering/types';

const toDollarFormatFixNumber = (number: Number | undefined | null) => number ? +(+number).toFixed(2) : 0;

export const getRawPhoneNumber = (val: string) => {
  return val.replace(/\D/g, '').replace(/1?(\d{10})$/, (_: any, p1: any) => p1);
};

export interface CartLineItems {
  subtotalAmount?: number;
  promoCodeAmount?: number;
  deliveryChargeTotalAmount?: number;
  nonDeliveryNonGratuityNonUbpServiceCharges?: number;
  gratuityServiceCharges?: number;
  processingServiceCharges?: number;
  tax?: number;
  tipAmount?: number;
  totalOrderAmount?: number;
  fundraisingAmount?: number;
}

export const calculateDiscountsTotal = (globalRewardAmount: number, discountsTotal: number | undefined) => globalRewardAmount || discountsTotal || 0;

export const useCartLineItems = (cart: Cart) => {
  return useCallback((tip: number | undefined | null, fundraisingAmount: number | undefined | null) => {
    const hasTip = !isNil(tip);
    const hasFundraisingAmount = !isNil(fundraisingAmount) && fundraisingAmount > 0;
    const displayTip = hasTip ? tip : 0;
    let displayTotal = cart?.order?.totalV2 || 0;
    let displaySubTotal = cart?.order?.preDiscountItemsSubtotal || 0;

    if(hasTip) {
      displayTotal = displayTotal + tip;
    }

    if(hasFundraisingAmount) {
      displayTotal = displayTotal + fundraisingAmount;
    }

    const {
      restaurantDiscount,
      loyaltyDiscounts = [],
      globalReward
    } = cart?.order?.discounts || {};
    const globalRewardAmount = globalReward?.amount || 0;
    const hasGlobalReward = Boolean(globalReward);
    const showPromoAmount = Boolean(restaurantDiscount) || Boolean(globalReward);

    const showLoyaltyAmount = loyaltyDiscounts?.length;

    // if a discount has been applied, calculate the displayed subtotal
    // based on the selections' pre discount price
    if(showPromoAmount || showLoyaltyAmount) {
      displaySubTotal = sumByField('preDiscountPrice')(cart?.order?.selections);
    }

    // since global rewards are not applied to the order, we must do this calculation here
    if(hasGlobalReward) {
      displayTotal = displayTotal - globalRewardAmount;
    }

    const totals: CartLineItems = {
      subtotalAmount: toDollarFormatFixNumber(displaySubTotal),
      tipAmount: toDollarFormatFixNumber(displayTip),
      fundraisingAmount: toDollarFormatFixNumber(fundraisingAmount),
      totalOrderAmount: toDollarFormatFixNumber(displayTotal)
    };

    if(showPromoAmount) {
      const realDiscountsTotal = calculateDiscountsTotal(globalRewardAmount, cart?.order?.discountsTotal);
      totals.promoCodeAmount = toDollarFormatFixNumber(-realDiscountsTotal);
    }
    if(
      (cart?.order?.deliveryServiceCharges || 0) > 0 ||
      cart?.diningOptionBehavior === DiningOptionBehavior.Delivery
    ) {
      totals.deliveryChargeTotalAmount = toDollarFormatFixNumber(cart?.order?.deliveryServiceCharges);
    }
    if(cart?.order?.nonDeliveryNonGratuityNonUbpServiceCharges) {
      totals.nonDeliveryNonGratuityNonUbpServiceCharges = toDollarFormatFixNumber(cart?.order.nonDeliveryNonGratuityNonUbpServiceCharges);
    }
    if(cart?.order?.gratuityServiceCharges) {
      totals.gratuityServiceCharges = toDollarFormatFixNumber(cart?.order.gratuityServiceCharges);
    }
    if(cart?.order?.processingServiceCharges) {
      totals.processingServiceCharges = toDollarFormatFixNumber(cart?.order.processingServiceCharges);
    }
    if((cart?.order?.taxV2 || 0) > 0) {
      totals.tax = toDollarFormatFixNumber(cart?.order?.taxV2);
    }

    return totals;
  }, [cart]);
};

export const useApplePayLineItems = ({
  cart,
  showTip,
  showTotal,
  deliveryChargeLabel = 'Delivery Cost'
}: any) => {
  const getCartLineItems = useCartLineItems(cart);
  return useCallback(
    (tip: number | undefined | null, fundraisingAmount: number | undefined | null, appliedGiftCardAmount: number | undefined | null, appliedToastCashAmount: number | undefined | null) => {
      const cartTotals: CartLineItems = getCartLineItems(tip, fundraisingAmount);
      const totals = [
        {
          label: 'Subtotal',
          amount: cartTotals.subtotalAmount,
          id: 'subtotal-amount'
        }
      ];
      const feesExist =
        (cartTotals.gratuityServiceCharges || 0) > 0 ||
        (cartTotals.processingServiceCharges || 0) > 0 ||
        (cartTotals.nonDeliveryNonGratuityNonUbpServiceCharges || 0) > 0;
      const taxLabel = feesExist ? 'Taxes & Fees' : 'Tax';
      const taxId = feesExist ? 'tax-and-fees-amount' : 'tax-amount';
      if((cartTotals.tax || 0) > 0 || feesExist) {
        let taxesAndFees = cartTotals.tax ? cartTotals.tax : 0;
        if(cartTotals.nonDeliveryNonGratuityNonUbpServiceCharges) {
          taxesAndFees += cartTotals.nonDeliveryNonGratuityNonUbpServiceCharges;
        }
        if(cartTotals.gratuityServiceCharges) {
          taxesAndFees += cartTotals.gratuityServiceCharges;
        }
        if(cartTotals.processingServiceCharges) {
          taxesAndFees += cartTotals.processingServiceCharges;
        }
        totals.push({
          label: taxLabel,
          amount: toDollarFormatFixNumber(taxesAndFees),
          id: taxId
        });
      }
      if(cartTotals.promoCodeAmount) {
        totals.push({
          label: 'Promo Applied',
          amount: cartTotals.promoCodeAmount,
          id: 'promo-code-amount'
        });
      }
      if(cartTotals.deliveryChargeTotalAmount) {
        totals.push({
          label: deliveryChargeLabel,
          amount: cartTotals.deliveryChargeTotalAmount,
          id: 'delivery-charge-total-amount'
        });
      }
      if(showTip) {
        totals.push({
          label: 'Tip',
          amount: toDollarFormatFixNumber(cartTotals.tipAmount),
          id: 'tip-amount'
        });
      }
      if(!isNil(fundraisingAmount) && fundraisingAmount > 0) {
        totals.push({
          label: 'Support & Give',
          amount: toDollarFormatFixNumber(cartTotals.fundraisingAmount),
          id: 'fundraising-amount'
        });
      }
      if(!isNil(appliedToastCashAmount) && appliedToastCashAmount > 0) {
        totals.push({
          label: 'Toast Cash',
          amount: toDollarFormatFixNumber(-1 * appliedToastCashAmount),
          id: 'toast-cash-amount'
        });
      }
      if(!isNil(appliedGiftCardAmount) && appliedGiftCardAmount > 0) {
        totals.push({
          label: 'Gift Card',
          amount: toDollarFormatFixNumber(-1 * appliedGiftCardAmount),
          id: 'restaurant-gift-card-amount'
        });
      }
      if(showTotal) {
        totals.push({
          label: 'Total',
          amount: toDollarFormatFixNumber(cartTotals.totalOrderAmount),
          id: 'total-order-amount'
        });
      }

      return totals;
    },
    [deliveryChargeLabel, getCartLineItems, showTip, showTotal]
  );
};
