import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ContainsEditableProps, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { MenuItemIdentifier } from 'src/apollo/sites';
import { formatImageURL } from 'src/lib/js/utilities';
import { menuItemPath } from 'src/public/components/default_template/menu_section/MenuItemCard';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import { useMenuItems } from 'shared/components/common/menu_item/MenuItemsContext';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { getOrderPath } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';


type TestProps = { testId?: string }

type MenuItemBlockBackupContent = {
  name: string
  description: string
  price: number | string
  imageSrc: string
}

type MenuItemBlockConfig = {
  hideItemName: boolean
  hideItemPrice: boolean
  hideItemDescription: boolean
}

type EditableProps = {
  classNames?: string;
  editableRef?: any;
  id: MenuItemIdentifier
  backupContent: MenuItemBlockBackupContent
  config: MenuItemBlockConfig | null
} & TestProps & ContainsEditableProps;

const EditableMenuItem = (props: EditableProps) => {
  const { editPath, id, backupContent } = props;
  const { useEditableRef } = useEditor();
  const { menuItems } = useMenuItems();

  const { restaurant, locations } = useRestaurant();
  const hasOoPage = restaurant.config?.isOnlineOrderingEnabled && !restaurant.config.ooConfig?.optedOut;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'menu item',
    displayName: 'Menu Item',
    actions: ['delete'],
    path: editPath,
    schema: { fields: [] }
  });

  const item = useMemo(() => {
    return menuItems?.find(item => item.guid === id?.itemGuid);
  }, [menuItems, id?.itemGuid]);
  const name = item?.name || backupContent?.name || 'Item Name';
  const description = item?.description || backupContent?.description || 'Item Description';
  const imgSrc = useMemo(() => {
    if(item?.imageLink) {
      if(item?.imageLink.startsWith('https://toast-stage.s3.amazonaws.com/')) {
        return item?.imageLink.replace(
          'https://toast-stage.s3.amazonaws.com/',
          'https://s3.amazonaws.com/toasttab/'
        );
      }
      return item?.imageLink;
    }
    return backupContent?.imageSrc;
  },
  [item?.imageLink, backupContent?.imageSrc]);
  const price = item?.price || backupContent?.price;

  const itemImage = useMemo(() => {
    if(imgSrc) {
      return <Image
        alt={item?.name || ''}
        src={formatImageURL(imgSrc)}
        className={classnames('itemImage')} />;
    }

    return null;
  }, [imgSrc, item?.name]);

  const itemLink = useMemo(() => {
    if(!hasOoPage || !locations) {
      return null;
    }

    // If there is more than one location, don't assume which
    // location the user would like to see.
    if(locations.length !== 1) {
      return '/order';
    }

    // If there is just one location, link directly to the item.
    if(locations[0]?.shortUrl && id?.itemGuid && item?.name) {
      return menuItemPath(
        getOrderPath(locations[0]?.shortUrl),
        item?.name,
        id?.itemGuid
      );
    }

    // Fallback to the order page if there is a data issue.
    return '/order';
  }, [hasOoPage, locations, id?.itemGuid, item?.name]);

  const menuItemContent =
  <div className={'menuItemWrapper'} ref={editableRef}>
    {itemImage}
    <div className="contentDiv">
      {!props.config?.hideItemName && <h2 className="itemName">{name}</h2>}
      {!props.config?.hideItemDescription && <p className="itemDescription">{description}</p>}
      {price && !props.config?.hideItemPrice &&
      <span className="itemPrice">
        <FormattedPrice value={price as number} />
      </span>}
    </div>
  </div>;

  if(itemLink) {
    return (
      <Link href={itemLink} className="menuItemLink">
        {menuItemContent}
      </Link>
    );
  }
  return menuItemContent;
};

const WrappedEditableMenuItem = (props: EditableProps) => {
  const ooClient = useOOClient();
  return (
    <ApolloProvider client={ooClient}>
      <EditableMenuItem {...props} />
    </ApolloProvider>
  );
};


export default WrappedEditableMenuItem;
