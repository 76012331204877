import React, { FC, useEffect } from 'react';

import { useOptionalRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { ProfileAddresses } from 'public/components/default_template/online_ordering/account/profile_addresses/ProfileAddresses';
import { ProfileInformation } from 'public/components/default_template/online_ordering/account/profile_information/ProfileInformation';
import { ProfileSettings } from 'public/components/default_template/online_ordering/account/profile_settings/profileSettings';
import { ProfileWallet } from 'public/components/default_template/online_ordering/account/profile_wallet/ProfileWallet';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

interface AccountProps { }

const Account: FC<AccountProps> = () => {
  const { customer, refetchCustomer } = useCustomer();
  const selectedLocation = useOptionalRestaurant()?.selectedLocation;
  // a logged out account screen is required for branded app acceptance to Google Play Store
  const isLoggedOut = !customer;

  // refetch the customer to get the latest data
  useEffect(() => {
    refetchCustomer();
  }, [refetchCustomer]);

  return (
    <>
      <main className="account" aria-label="My Account Information" tabIndex={-1} id="main">
        <h1 className={isLoggedOut ? 'accountHeadingDisabled' : 'accountHeading'}>My Account</h1>
        <div className="accountSections" data-testid="Account">
          <div className="informationSection"><ProfileInformation isLoggedOut={isLoggedOut} /></div>
          <div className="addressSection"><ProfileAddresses isLoggedOut={isLoggedOut} selectedLocationLat={selectedLocation?.lat} selelctedLocationLong={selectedLocation?.long} /></div>
          <div className="walletSection"><ProfileWallet isLoggedOut={isLoggedOut} /></div>
          <div className="settingsSection"><ProfileSettings isLoggedOut={isLoggedOut} /></div>
        </div>
      </main>
    </>
  );
};

export default Account;
