import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';

import { ContainsEditableProps, useEditor, FieldType } from '@toasttab/sites-components';

import { Block as BlockType } from 'src/apollo/sites';

import EditableIFramedCode from 'shared/components/common/embedded_code/EditableIFramedCode';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { server } from 'config';

type ElementDims = {
  height: number;
  width: number;
};

type EditableProps = {
  block?: BlockType;
  code?: string;
  altText?: string;
} & ContainsEditableProps;

export const EditableEmbeddedCode = (props: EditableProps) => {
  const { editPath, block } = props;
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'embedded code',
    displayName: 'Embedded HTML',
    actions: ['delete'],
    path: editPath,
    schema: {
      fields: [
        {
          displayName: 'Add your code here (HTTPS only)',
          path: `${editPath}.code`,
          type: FieldType.TextArea,
          value: block?.contents?.code ?? '',
          helperText: 'Make sure that your code contains HTTPS not HTTP or it will not be displayed on your live site.'
        },
        {
          displayName: 'What\'s in the embedded HTML?',
          path: `${editPath}.altText`,
          type: FieldType.Text,
          value: block?.contents?.altText ?? '',
          validation: { maxLen: 230 },
          helperText: 'Optional. Additional information you want to highlight to guests. Max 230 characters.'
        }
      ]
    }
  });

  const [dims, setDims] = useState<ElementDims | null>();

  const resizeIFrame = useCallback(() => {
    const wrapper = editableRef.current?.closest('.blockWrapper');
    if(wrapper) {
      setDims({ height: wrapper.clientHeight, width: wrapper.clientWidth });
    }
  }, [editableRef]);

  return (
    <div ref={editableRef}>
      {block?.contents?.code ?
        <EditableIFramedCode
          onIframeChange={resizeIFrame}
          code={block?.contents?.code}
          parentHeight={dims?.height}
          parentWidth={dims?.width} /> :
        <div className="embeddedCode empty">Nothing here yet</div>}
    </div>
  );
};

const WebEmbeddedCode = ({ block }: { block: BlockType }) => {
  const { pathname } = useLocation();
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null);
  const { selectedLocation } = useRestaurant();
  const height = ref?.parentElement?.clientHeight;
  const width = ref?.parentElement?.clientWidth;

  return (
    <iframe
      ref={setRef}
      title={block.contents.altText || 'Embedded code'}
      className="embeddedCode"
      height={height}
      width={width}
      src={`${server.protocol}://${server.fullHost}/sites-web/v1/iframe?blockGuid=${block.guid}&shortUrl=${selectedLocation.shortUrl}&height=${height}&width=${width}&path=${pathname}`}>
    </iframe>
  );
};

const EmbeddedCode = ({ block, editPath }: { block: BlockType, editPath: string }) => {
  const { isEditor } = useEditor();
  return isEditor ?
    <EditableEmbeddedCode editPath={editPath} block={block} /> :
    <WebEmbeddedCode block={block} />;
};

export default EmbeddedCode;
