import React, { useMemo } from 'react';
import { TailSpin } from 'react-loader-spinner';

import { DEFAULT_COLORS } from 'src/public/components/default_template/meta/StyleMeta';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';


export const LoadingSpinner = ({ color = DEFAULT_COLORS.primary, size, strokeWidth }: { color?: string; size?: string, strokeWidth?: string | number }) => {
  return <TailSpin color={color} width={size} height={size} strokeWidth={strokeWidth} />;
};

const ContextualLoadingSpinner = ({ size, strokeWidth }: {size?: string | number, strokeWidth?: string | number}) => {
  const { restaurant: { meta: { primaryColor } } } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();

  const color = useMemo(() => {
    return getColorFromTheme(theme => theme.colorScheme.icon.onOverlay, primaryColor);
  }, [getColorFromTheme, primaryColor]);

  return <TailSpin color={color} width={size} height={size} strokeWidth={strokeWidth} />;
};

export default ContextualLoadingSpinner;
