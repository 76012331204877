import React from 'react';

import Image from 'shared/components/common/Image';

type WarningProps = {
  message: string;
  dataTestId: string;
};

const Warning = ({ message, dataTestId } : WarningProps) => {
  return (
    <div className="warning" data-testid={dataTestId}>
      <Image className="warning-icon" alt="Warning icon" src="icons/warning-red.svg" />
      <span className="warning-message">{message}</span>
    </div>
  );
};

export default Warning;
