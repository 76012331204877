import React, { useCallback, useContext, useMemo, useState } from 'react';

type PopoverContextType = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
}

const PopoverContext = React.createContext<{ [key: string]: PopoverContextType }>({});

type Props = {
  contextKey: string;
}

export const PopoverContextProvider = ({ contextKey, children }: React.PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const prevContext = usePopovers();

  const contextData = useMemo(() => ({
    ...prevContext,
    [contextKey]: { isOpen, open, close }
  }), [isOpen, open, close, contextKey, prevContext]);

  return (
    <PopoverContext.Provider value={contextData}>
      {children}
    </PopoverContext.Provider>
  );
};

const usePopovers = () => {
  return useContext(PopoverContext);
};

export const usePopoverContext = (key: string) => {
  const context = useContext(PopoverContext);

  return context[key];
};
