import React from 'react';

import classNames from 'classnames';

import Image from 'shared/components/common/Image';

import SearchBar from 'public/components/toast_local/common/search_bar/SearchBar';

type FoodPlateProps = {
  imgSrc: string
  imgAlt: string
  className: string
};

const FoodPlate = ({ imgSrc, imgAlt, className, ...rest }: FoodPlateProps) =>
  <div className={classNames('foodPlate', className)} {...rest} >
    <div className="cropper">
      <Image src={imgSrc} alt={imgAlt} />
    </div>
  </div>;


const Hero = () => {
  return (
    <div className="toastLocalHero">
      <Image className="heroImage" src="toastLocal/splash/restaurant-road-map-1200.png" alt="Food being prepped" />
      <div className="heroContent">
        <div className="leftContent pure-u-24-24 pure-u-lg-12-24">
          <h1>Order directly<br />from the restaurants<br />you love</h1>
          <SearchBar className="full" searchMode="location" />
        </div>
        <div className="rightContent pure-u-lg-12-24 hidden-md-down">
          <FoodPlate imgSrc="toastLocal/splash/healthy-salad-plate-400.jpg" imgAlt="Fresh salad" className="foodPlate1" />
          <FoodPlate imgSrc="toastLocal/splash/fresh-fish-dinner-400.jpg" imgAlt="Fish plate" className="foodPlate2" />
          <FoodPlate imgSrc="toastLocal/splash/salad-with-spiralized-carrots-400.jpg" imgAlt="Carrot salad" className="foodPlate3" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
