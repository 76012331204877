import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import { withApollo } from '@apollo/client/react/hoc';
import { FontContextProvider } from '@toasttab/sites-components';

import { isClient } from 'src/lib/js/detector';
import useQueryString from 'src/lib/js/hooks/useQueryString';
import useTracker from 'src/lib/js/hooks/useTracker';

import { getFontFaceAndFamily } from 'public/components/default_template/meta/StyleMeta';

import { server, environment } from 'config';

import { StaticSiteContextProvider } from './StaticSiteContext';

const App = ({ staticContext, location, children }) => {
  // Enable tracking for analytics
  const tracker = useTracker({
    blacklisted: !isClient() || staticContext?.isBot || staticContext?.isEditor,
    debug: false
  });

  const { rwg_token: rwgToken } = useQueryString();

  useEffect(() => {
    if(rwgToken && typeof localStorage !== 'undefined') {
      localStorage.setItem('rwg_token', rwgToken);
      tracker.register({ rwgToken });
    }
  }, [rwgToken, tracker]);

  // Use react-helmet for head tag updates
  const defaultCanonical = `${server.protocol}://${server.host}${location.pathname != '/' ? location.pathname : ''}`;
  const helmet =
    <Helmet defaultTitle="Toast | The best food at your fingertips">
      <link rel="canonical" href={defaultCanonical} />
    </Helmet>;

  const skipToMainContent = useCallback(e => {
    if(e.type === 'keydown' && e.code !== 'Escape' && e.code !== 'Space' && e.code !== 'Enter') {
      return;
    }
    e.preventDefault();
    const mainEl = document.getElementById('main');
    mainEl.focus({ preventScroll: mainEl.getBoundingClientRect().top > 0 });
  }, []);

  return (
    <StaticSiteContextProvider value={staticContext}>
      <FontContextProvider
        renderGoogleFonts={fonts =>
          <Helmet>
            {fonts.map(font => <link key={font} href={`https://fonts.googleapis.com/css?family=${font}:wght@400;600;700&display=swap`} rel="stylesheet" />)}
          </Helmet>}
        renderFonts={styles => <Helmet><style>{styles}</style></Helmet>}
        toFontFace={font => {
          return getFontFaceAndFamily({ name: font.name, url: font.src }).fontFace;
        }}>
        <a href="#main" className="skip-link" onClick={e => skipToMainContent(e)} onKeyDown={e => skipToMainContent(e)}>Skip to Main content</a>
        {helmet}
        <div className="tsw-app-wrapper" id="tsw-app-wrapper">
          {children}
        </div>
      </FontContextProvider>
    </StaticSiteContextProvider>
  );
};

export default withRouter(withApollo(App));
