import React, { useMemo } from 'react';

import { LocationSelector } from 'src/shared/components/common/form_input/LocationInput';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useLocationSchedules } from 'public/components/online_ordering/FulfillmentContext';

const OOLocationSelector = () => {
  const { selectedLocation, updateLocation, locations } = useRestaurant();
  const locationSchedules = useLocationSchedules();
  const ooLocations = useMemo(() => locations?.filter(l => locationSchedules[l.externalId]?.onlineOrderingEnabled) || [], [locations, locationSchedules]);

  if(!ooLocations?.length || ooLocations.length === 1) {
    return null;
  }

  return (
    <div className="locationSelector" data-testid="oo-location-selector">
      <div className="dropDownWrapper">
        <LocationSelector id="oo-location-selector" locations={ooLocations} selectedLocation={selectedLocation} setSelectedLocation={updateLocation} withIcon withBorder />
      </div>
    </div>
  );
};

export default OOLocationSelector;
