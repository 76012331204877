import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import { formatMDY } from 'src/shared/js/timeUtils';

import Image from 'shared/components/common/Image';
import InputMessage from 'shared/components/common/form_input/InputMessage';

type Props = {
  id: string;
  label?: string;
  required?: boolean;
}

const DatePicker = (props: Props) => {
  const { id, label, required } = props;
  const { watch, formState: { errors }, setValue, register } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const date = watch(id);

  return (
    <div className={classnames('formInput', errors[id] && 'error', date && 'filled')}>
      <div className="inputWrapper">
        <div className="inputElementWrapper">
          <label htmlFor={id}>{label}{required ? '*' : ''}</label>
          <input
            {...register(id, { required: required ? 'required' : false })}
            data-testid="open-date-picker-button"
            aria-describedby={`${id}-input-message`}
            onKeyPress={e => e.preventDefault()}
            value={date ? formatMDY(date) : ''}
            onClick={() => setIsOpen(true)} />
          {isOpen
            ? <Calendar value={date} onChange={(date: Date) => {
              setValue(id, date, { shouldValidate: true });
              setIsOpen(false);
            }} className="calendar" />
            : null}
        </div>
        {errors[id] && <Image alt="Warning icon" src="icons/warning-red.svg" />}
      </div>
      <InputMessage id={id} />
    </div>
  );
};

export default DatePicker;
