import React from 'react';
import { withRouter } from 'react-router';

import { RequestContextProps } from 'src/lib/js/context';

import { isToastLocalRequest, isToastOrderRequest } from 'public/js/siteUtilities';

import SitesRoutes from './SitesRoutes';
import ToastLocalRoutes from './ToastLocalRoutes';
import ToastOrderRoutes from './ToastOrderRoutes';

export const Routes = ({ staticContext }: RequestContextProps) => {
  if(isToastLocalRequest(staticContext)) {
    return <ToastLocalRoutes />;
  }

  if(isToastOrderRequest(staticContext)) {
    return <ToastOrderRoutes staticContext={staticContext} />;
  }

  return <SitesRoutes staticContext={staticContext} />;
};

export default withRouter<RequestContextProps, React.ComponentType<RequestContextProps>>(Routes);
