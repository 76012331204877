import { defaultCountries, parseCountry } from 'react-international-phone';

import { CountryCode, isSupportedCountry } from 'libphonenumber-js';

/**
 * @param country Country code to validate
 * @returns Country code of the restaurant if the restaurant has a country code and it is a code supported by libphonenumber-js, otherwise default to 'US'
 */
export const asValidPhoneCountryCode = (countryCode: any) => (
  countryCode && isSupportedCountry(countryCode)
    ? countryCode
    : 'US'
) as CountryCode;

export const SUPPORTED_COUNTRIES = defaultCountries.filter(country => [
  'us',
  'ca',
  'ie',
  'gb'
].includes(parseCountry(country).iso2));
