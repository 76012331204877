import React from 'react';
import { withRouter } from 'react-router-dom';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { RequestContextProps } from 'src/lib/js/context';
import { isToastLocalRequest } from 'src/public/js/siteUtilities';

import LoadingSpinner, { LoadingSpinner as LoadingSpinnerWithColor } from './LoadingSpinner';

export const TOAST_LOCAL_COLOR = '#ff4c00';

type Props = {
  withBorderRadius?: boolean;
  fullScreen?: boolean;
  color?: string;
} & Pick<RequestContextProps, 'staticContext'>

const LoadingSpinnerOverlay = (props: Props ) => {
  const spinnerColor = props.color ?? (isToastLocalRequest(props.staticContext) ? TOAST_LOCAL_COLOR : null);
  return (
    <div
      data-testid="loading-spinner-overlay"
      className={classnames('loadingSpinnerOverlay', { withBorderRadius: props.withBorderRadius, fullScreen: props.fullScreen })}>
      {spinnerColor ? <LoadingSpinnerWithColor color={spinnerColor} /> : <LoadingSpinner />}
    </div>
  );
};

const LoadingSpinnerOverlayWithRouter = withRouter<Props & RequestContextProps, React.ComponentType<Props & RequestContextProps>>(LoadingSpinnerOverlay);

const LoadingSpinnerOverlayWrapper = (props: Props) => {
  const { isEditor } = useEditor();

  if(isEditor) {
    return <LoadingSpinnerOverlay {...props} />;
  }

  return <LoadingSpinnerOverlayWithRouter {...props} />;
};

export default LoadingSpinnerOverlayWrapper;
