import React, { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useOrderHistoryQuery } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import Button from 'src/shared/components/common/button';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import OrderCard from './OrderCard';

const PAGE_SIZE = 10;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onReorder: () => void;
};

const OrderHistoryModal = (props: Props) => {
  const { selectedLocation } = useRestaurant();
  const { data, loading, fetchMore } = useOrderHistoryQuery({
    variables: { input: { restaurantGuid: selectedLocation.externalId, pageSize: PAGE_SIZE, nextPageToken: undefined } },
    errorPolicy: 'ignore',
    ssr: false,
    skip: !props.isOpen
  });

  const paginate = useCallback(() => fetchMore({
    variables: {
      input: {
        restaurantGuid: selectedLocation.externalId,
        pageSize: PAGE_SIZE,
        nextPageToken: data?.orderHistory?.nextPageToken
      }
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if(!fetchMoreResult) return prev;
      return {
        orderHistory: {
          orders: [...prev.orderHistory.orders, ...fetchMoreResult.orderHistory.orders],
          __typename: prev.orderHistory.__typename,
          nextPageToken: fetchMoreResult.orderHistory.nextPageToken || null
        }
      };
    }
  }), [selectedLocation, fetchMore, data]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay fadeIn />
      <ModalContent contentClassName="orderHistoryModal" fadeIn>
        <ModalCloseButton className="closeButton" />
        <h2>Order History</h2>
        <div className="orders">
          {!loading || data?.orderHistory?.orders.length ?
            <>
              {data?.orderHistory?.orders.map(order => {
                return <OrderCard key={order.guid} order={order} onReorder={props.onReorder} />;
              })}
              {data?.orderHistory?.nextPageToken ? <Button variant={ButtonType.Text} onClick={paginate}>{loading ? 'Loading...' : 'Load more'}</Button> : null}
            </>
            : new Array(5).fill('')
              .map((_, index) => <Skeleton key={index} height="100px" width="100%" />)}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default OrderHistoryModal;
