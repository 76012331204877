import React from 'react';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';

type Props = {
  color: string;
};

export const ListViewIcon = ({ color }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    {/* eslint-disable-next-line max-len */}
    <path d="M5.5 6.55005H18M1 6.55005H1.5M5.5 16.4501H18M1 16.4501H1.5M5.5 11.4501H18M1 11.4501H1.5M5.5 1.55005H18M1 1.55005H1.5" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const MapViewIcon = ({ color }: Props) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    {/* eslint-disable-next-line max-len */}
    <path d="M6.91 13.36L1.5 16.45V4.09L6.91 1M6.91 13.36L13.09 16.45M6.91 13.36V1M13.09 16.45L18.5 13.36V1L13.09 4.09M13.09 16.45V4.09M13.09 4.09L6.91 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const DeliveryIcon = ({ color = DEFAULT_COLORS.text as string }: { color?: string; }) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none" aria-hidden={true}>
    {/* eslint-disable-next-line max-len */}
    <path d="M11.1553 10.0499H9.96935C9.7251 10.0655 9.48024 10.0305 9.25014 9.94711C9.02003 9.86369 8.80963 9.73367 8.63211 9.56517C8.45459 9.39666 8.31378 9.19332 8.21849 8.96788C8.1232 8.74243 8.07549 8.49974 8.07835 8.255C8.68781 8.24674 9.26999 8.00096 9.70098 7.56997C10.132 7.13898 10.3778 6.5568 10.386 5.94734V5.91528C10.3783 5.58064 10.2397 5.26235 10.0001 5.02867C9.76041 4.79499 9.43872 4.66454 9.10399 4.66528H6.18732C5.01009 4.64433 3.87272 5.09174 3.02527 5.90914C2.17782 6.72654 1.68966 7.84702 1.6681 9.02425V10.8192H5.77066M11.5719 10.0499C11.8162 10.0655 12.061 10.0305 12.2911 9.94713C12.5212 9.86372 12.7316 9.7337 12.9092 9.56521C13.0867 9.39672 13.2275 9.19338 13.3228 8.96794C13.4181 8.7425 13.4658 8.49981 13.4629 8.25508V1.33194M11.6039 10.0499H10.386M5.7706 12.8704C5.77963 13.1422 5.73275 13.413 5.63285 13.666C5.53296 13.9189 5.38219 14.1487 5.18988 14.341C4.99757 14.5333 4.76782 14.684 4.51487 14.7839C4.26191 14.8838 3.99114 14.9307 3.71933 14.9217C3.44617 14.936 3.17304 14.8928 2.91766 14.7948C2.66228 14.6968 2.43035 14.5462 2.23693 14.3528C2.04351 14.1594 1.89292 13.9275 1.79493 13.6721C1.69693 13.4167 1.65372 13.1436 1.6681 12.8704H14.2322C14.2361 11.7946 14.6609 10.7631 15.4156 9.99637C16.1703 9.22969 17.195 8.78874 18.2706 8.76783H18.3347L16.027 4.92171C16.0334 4.58369 16.1706 4.2613 16.4096 4.02224C16.6487 3.78319 16.9711 3.64606 17.3091 3.63966V2.35761C17.3027 2.01959 17.1656 1.6972 16.9265 1.45814C16.6874 1.21909 16.365 1.08196 16.027 1.07556H11.5719M18.3348 11.0773C18.0939 11.0679 17.8536 11.1081 17.6289 11.1954C17.4042 11.2827 17.1998 11.4153 17.0285 11.5849C16.8572 11.7545 16.7225 11.9575 16.6329 12.1813C16.5433 12.4051 16.5007 12.6449 16.5076 12.8859C16.5007 13.1321 16.5426 13.3772 16.6308 13.6071C16.719 13.837 16.8519 14.0472 17.0218 14.2255C17.1916 14.4038 17.3951 14.5468 17.6204 14.6461C17.8458 14.7454 18.0886 14.7991 18.3348 14.8041" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const PickupIcon = ({ color = DEFAULT_COLORS.text as string }: { color?: string; }) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" aria-hidden={true}>
    {/* eslint-disable-next-line max-len */}
    <path d="M5.12112 16.5H12.6368V5.22649H1.36328V16.5L3.2422 14.6211L5.12112 16.5ZM5.12112 16.5V5.22649M3.24219 5.22652V3.59813C3.24219 3.59813 3.21088 1.5 5.12111 1.5C6.96871 1.5 7.00003 3.59813 7.00003 3.59813M7.00003 3.59813V5.22652M7.00003 3.59813C7.00003 3.59813 6.96871 1.5 8.87895 1.5C10.7266 1.5 10.7579 3.59813 10.7579 3.59813V5.22652" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const StreetGridIcon = ({ color }: Props) =>
  <svg viewBox="0 0 433 260" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden={true}>
    {/* eslint-disable-next-line max-len */}
    <path d="M433 65.8957V49.9318H374.043V0H358.032V49.9318H253.069V0H237.058V49.9318H75.1671V0H59.1558V49.9318H8V65.8957H59.1558V127.978H8V143.942H59.1558V210.458H8V226.422H145.439V260H161.45V226.422H237.058V260H253.069V226.422H433V210.458H253.069V143.942H433V127.978H374.043V65.8957L433 65.8957ZM237.058 65.8957V85.4072H75.1671V65.8957H237.058ZM75.1671 101.371H237.058V127.978H75.1671V101.371ZM75.1671 210.458V143.942H145.439V210.458L75.1671 210.458ZM237.058 210.458H161.45V143.942H237.058V210.458ZM358.032 127.978H253.069V65.8957H358.032V127.978Z" fill="#F0F0F0" />
    {/* eslint-disable-next-line max-len */}
    <path d="M67 61.4219C67 79.8803 33.5 121 33.5 121C33.5 121 0 79.8803 0 61.4219C0 52.5578 3.52943 44.0569 9.8119 37.789C16.0944 31.5212 24.6152 28 33.5 28C42.3847 28 50.9056 31.5212 57.1881 37.789C63.4705 44.0569 67 52.5578 67 61.4219Z" fill={color} />
    {/* eslint-disable-next-line max-len */}
    <path d="M49 59.5C49 62.5656 48.1203 65.5624 46.472 68.1113C44.8238 70.6603 42.4811 72.647 39.7403 73.8201C36.9994 74.9933 33.9834 75.3002 31.0736 74.7022C28.1639 74.1041 25.4912 72.6279 23.3934 70.4601C21.2956 68.2924 19.867 65.5306 19.2882 62.5239C18.7094 59.5172 19.0065 56.4007 20.1418 53.5684C21.2771 50.7362 23.1997 48.3154 25.6664 46.6122C28.1332 44.9091 31.0333 44 34 44C35.9701 43.9991 37.921 44.3993 39.7413 45.178C41.5616 45.9566 43.2156 47.0983 44.6086 48.5378C46.0017 49.9773 47.1065 51.6863 47.86 53.5673C48.6135 55.4483 49.0009 57.4642 49 59.5Z" fill="white" />
    <path d="M33.5 143C37.0898 143 40 140.09 40 136.5C40 132.91 37.0898 130 33.5 130C29.9102 130 27 132.91 27 136.5C27 140.09 29.9102 143 33.5 143Z" fill={color} />
    <path d="M245 62C247.209 62 249 60.2091 249 58C249 55.7909 247.209 54 245 54C242.791 54 241 55.7909 241 58C241 60.2091 242.791 62 245 62Z" fill={color} />
    <path d="M366 141C369.314 141 372 138.314 372 135C372 131.686 369.314 129 366 129C362.686 129 360 131.686 360 135C360 138.314 362.686 141 366 141Z" fill={color} />
    <path d="M153 225C156.314 225 159 222.314 159 219C159 215.686 156.314 213 153 213C149.686 213 147 215.686 147 219C147 222.314 149.686 225 153 225Z" fill={color} />
  </svg>;

export const CaretDown = ({ color }: Props) =>
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} aria-hidden>
    <path d="M9 1.05L5 4.95L1 1.05" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
