import React from 'react';

import { ContainsEditableProps } from '@toasttab/sites-components';

import { SectionBackgroundSize, SectionImagePosition, ThemeTypeEnum, Link as LinkType, Image as ImageObject, PaddingEnum, VideoConfig } from 'src/apollo/sites';

import Link from 'shared/components/common/link';

import CardHero from './CardHero';
import ImageHero from './ImageHero';
import InsetHero from './InsetHero';
import VideoHero from './VideoHero';

type Props = {
  isFirstSection?: boolean;
  theme?: ThemeTypeEnum | null;
  // If the image object is passed, the imgSrc will be ignored.
  images?: ImageObject[] | null;
  imagesPath?: string | null;
  imageObject?: ImageObject | null;
  imageObjectPath?: string | null;
  imgPosition?: SectionImagePosition | null;
  videoSrc?: string | null;
  videoConfig?: VideoConfig | null;
  textInset?: boolean | null;
  textWithBackground?: boolean | null;
  textBackgroundSize?: SectionBackgroundSize | null;
  backgroundSize?: SectionBackgroundSize | null;
  imageDescription?: string | null;
  header?: string | null;
  title?: string | null;
  body?: string | null;
  button?: LinkType | null;
  link?: string | null;
  backgroundColor?: string | null;
  padding?: PaddingEnum | null;
  videoDescription?: string | null;
} & ContainsEditableProps;

const Hero = (props: Props) => {
  if(props.videoSrc) {
    return <VideoHero {...props} videoSrc={props.videoSrc} videoConfig={props.videoConfig} />;
  }

  if(props.header || props.title || props.body || props.button) {
    if(props.textInset == null || props.textInset) {
      return <InsetHero {...props} />;
    }
    return <CardHero {...props} />;
  }

  return <ImageHero {...props} />;
};

const LinkedHero = ({ link, ...rest }: Props) =>
  link
    ? <Link href={link}><Hero {...rest} /></Link>
    : <Hero {...rest} />;

export default LinkedHero;
