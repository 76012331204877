import React from 'react';

import { useEditor } from '@toasttab/sites-components';
import IframeResizer from 'iframe-resizer-react';

import { CateringFormPage as CateringFormPageTypeGen, PageConfig, SitePagesDataFragment } from 'src/apollo/sites';

import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import BetterTogetherWrapper from 'public/components/pages/better_together/BetterTogetherWrapper';

import config from 'config';

type AdditionalPageType = NonNullable<NonNullable<SitePagesDataFragment>['additionalPages']>;
export type CateringFormPageType = AdditionalPageType[0]['content'] & { __typename: 'CateringFormPage' };

type Props = {
  // Any rxGuid from the management chain should work to access the corect catering form since
  // the leadGuid uniquely identifies the specific catering form.
  rxGuid?: string;
  content?: CateringFormPageTypeGen;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
}

const CateringFormPage = (props: Props) => {
  const { useEditableRef } = useEditor();
  const { editableRef: editableCateringRef } = useEditableRef<HTMLDivElement>({
    name: 'better together page',
    actions: []
  });

  if(!props.rxGuid) return null;

  return (
    <BetterTogetherWrapper content={props.content} editPathRoot={props.editPathRoot} config={props.config} classNames={'catering'} >
      <div className="betterTogetherWrapper" ref={editableCateringRef} >
        {/* In order to avoid scrolling inside the iframe, use this library which makes the iframed content
        send a message about its height to the parent allowing the parent to set the height. */}
        <IframeResizer
          className="cateringIframe"
          data-testid="catering-iframe"
          heightCalculationMethod="max"
          src={`${config.resources.toastwebBaseUri}invoice/lead?rx=${props.rxGuid}&ot=${props.content?.leadGuid}&form=1&isSitesView=true`}
          style={{ border: '0', background: '#FFFFFF', borderRadius: '8px' }} />
      </div>
    </BetterTogetherWrapper>
  );
};

export default CateringFormPage;
