import React from 'react';

const UserIcon = ({ color }: { color: string }) =>
  <svg className="userIcon" width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="userIcon"
    stroke={color} strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
    {/* eslint-disable-next-line max-len */}
    <path d="M18.0498 19.5498C17.6498 16.5498 15.0498 14.2498 11.9498 14.2498C8.8498 14.2498 6.3498 16.5498 5.8498 19.5498M15.4498 10.2498C15.4498 12.1276 13.9276 13.6498 12.0498 13.6498C10.172 13.6498 8.6498 12.1276 8.6498 10.2498C8.6498 8.37204 10.172 6.8498 12.0498 6.8498C13.9276 6.8498 15.4498 8.37204 15.4498 10.2498ZM22.0498 12.0498C22.0498 17.5727 17.5727 22.0498 12.0498 22.0498C6.52696 22.0498 2.0498 17.5727 2.0498 12.0498C2.0498 6.52696 6.52696 2.0498 12.0498 2.0498C17.5727 2.0498 22.0498 6.52696 22.0498 12.0498Z" />
  </svg>;

export default UserIcon;
