import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';
import Popover from 'src/shared/components/common/popover/Popover';

import Image from 'shared/components/common/Image';

import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';

interface Props {
  restaurantName?: string;
}

export const ThirdPartyLoyaltyGuestConsentForm = ({ restaurantName }: Props) => {
  const { setAllowLookup, allowLookup, hasLoyaltyAccount, loadingLoyaltyAccount } = useLoyalty();
  const { customer } = useCustomer();

  restaurantName = restaurantName || 'restaurant';

  if(!allowLookup) {
    return (
      <CheckoutSection title="Rewards">
        <div className="loyaltyRedemption">
          <div className="header">Get rewarded!</div>
          <div className="loyaltyConsentContent">Lookup your account to earn points on this order and apply the discounts you have earned.</div>
          <button
            type="button"
            className="submitButton primaryCta consentButton"
            onClick={
              e => {
                setAllowLookup(true, true);
                e.stopPropagation();
              }
            }
            data-testid="loyalty-3p-consent">
              Lookup My Rewards
          </button>
          <div className="note">
            By looking up rewards, you give Toast permission to share your account information with this restaurant&apos;s
            rewards provider for the sole purpose of looking up your account.
          </div>
        </div>
      </CheckoutSection>
    );
  } else if(loadingLoyaltyAccount) {
    return (
      <div className="loyaltyRedemption">
        <div className="loyaltyLoading">
          <CheckoutSection title="Rewards">
            <Skeleton count={4} containerTestId="loader-skeleton" height="18px" />
          </CheckoutSection>
        </div>
      </div>
    );
  } else if(!hasLoyaltyAccount) {
    return (
      <CheckoutSection title="Rewards">
        <div className="loyaltyRedemption">
          <div className="errorHeader">No loyalty account found.</div>
          <div className="loyaltyConsentContent">
            Please confirm that your email address or phone number of your {restaurantName} rewards program match the information on your Toast account.
            If not, create a new rewards account or update the information on your existing one.
            <Popover
              contextKey="loyaltyInfoTooltip"
              arrowClassName="tooltipArrow"
              target={({ open }) => {
                return (
                  <button
                    className="loyaltyInfoButton"
                    onClick={e => { e.preventDefault(); open(); } }
                    aria-label="Show more info">
                    <Image src="icons/info.svg" alt="" />
                  </button>);
              }} >
              <div className="loyaltyConsentTooltipContent">
                <div data-testid="email">email: { customer?.email }</div>
                { customer?.phone && <div data-testid="phone">phone: { customer?.phone }</div> }
              </div>
            </Popover>
          </div>
        </div>
      </CheckoutSection>
    );
  }

  return null;
};
