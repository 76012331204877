import { useLocation } from 'react-router';

import { SitePagesDataFragment, useSitePagesByDomainQuery, useSitePagesByMgmtGuidQuery, useSitePagesByShortUrlQuery } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';

import useDefaultSiteData from 'shared/js/hooks/useDefaultSiteData';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';


const useSitePagesData = (staticContext?: RequestContext) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { siteRestaurant: siteData, loading: siteLoading } = useRestaurantSiteData({
    staticContext,
    domainQueryHook: useSitePagesByDomainQuery,
    shortUrlQueryHook: useSitePagesByShortUrlQuery,
    mgmtGuidQueryHook: useSitePagesByMgmtGuidQuery,
    shortUrlQueryString: params.get('shortUrl')
  });

  const { data: defaultedData, loading } = useDefaultSiteData({
    isPageQuery: true,
    staticContext,
    siteLoading,
    siteRestaurant: siteData,
    shortUrlQueryString: params.get('shortUrl')
  });

  const sitePagesData = defaultedData as SitePagesDataFragment | undefined;

  return { sitePagesData, loading };
};

export default useSitePagesData;
