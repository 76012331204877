import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useEditor } from '@toasttab/sites-components';

type Props = {
  description?: string;
  title?: string;
};

const PageMeta = ({ title, description }: Props) => {
  const { isEditor } = useEditor();

  if(isEditor) {
    return null;
  }

  return (
    <Helmet>
      {description ? <meta name="description" content={description} /> : null}
      {description ? <meta name="og:description" content={description} /> : null}
      {title ? <title>{title}</title> : null}
      {title ? <meta name="og:title" content={title} /> : null}
    </Helmet>
  );
};

export default PageMeta;
